import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent {
  title = 'ventanillaUnica_front';

  ngOnInit(): void {
    window.addEventListener('message', function (event) {
      localStorage.setItem(event.data, 'true');
      const respuesta = event.data;
      if (respuesta === 'recibido') { console.log('La página receptora confirmó que recibió el mensaje.'); }
    });
  }
}
