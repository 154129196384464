<app-header></app-header>
<app-quick-acces></app-quick-acces>
<app-breadcrumb [ruta]="rutaMigaPan"></app-breadcrumb>
<app-accesibility style="position: absolute; z-index: 2;"></app-accesibility>
<div class="container entradas-de-texto-govco">

    <div class="title">
        <h1 class="h1-tipografia-govco title-1">{{titulo}}</h1>
    </div>
    <div class="row col-sm-12 col-md-4 mt-3">
        <!-- <div class="input-group">
            <span class="input-group-text fondo-blanco color-gris cursor" (click)="buscar(texto.value)"><i
                   class="fa-solid fa-magnifying-glass"></i></span>
            <input type="text" class="form-control lineas-buscadores" placeholder="Nombre funcionalidad" #texto id="caja">
            <span class="input-group-text fondo-blanco color-gris cursor" (click)="borrar()" id="cancel">
                <i class="fa-solid fa-circle-xmark"></i>
            </span>
        </div> -->
        <div class="search-govco">
            <div class="mb-1 fw-bold">
                <label>Buscar por</label>
            </div>
            <div class="bar-search-govco">
                <input type="text" placeholder="Nombre del trámite" class="input-search-govco" aria-label="Buscador" [(ngModel)]="nombreRol" />
                <button class="icon-search-govco icon-close-search-govco" aria-label="Limpiar" (click)="borrar()"></button>
                <div class="icon-search-govco search-icon-search-govco" aria-hidden="true" (click)="buscar(nombreRol)"></div>
            </div>
        </div>
    </div>
    <div class="row col-sm-12 col-md-12 mt-3">
        <table class="table">
            <thead class="fondo-gris">
                <tr class="text-center">
                    <th scope="col">Código tramite</th>
                    <th scope="col">Nombre trámite</th>
                    <th scope="col">Roles</th>
                    <th scope="col">Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let func of lista | paginate: { itemsPerPage: 4, currentPage: pagina }" class="text-center">
                    <td>{{ func.codTramite }}</td>
                    <td>{{ func.nameTramite }}</td>
                    <td class="col-3">{{ func.rolName }}</td>
                    <td>
                        <a class="text-primary cursor" *ngIf="func.estadoTramite == true"
                           [routerLink]="['/Home/Administracion/crear-funcionalidad', func.nameTramite]">
                            <i class="govco-edit"></i>
                        </a>
                        <a class="text-danger cursor ms-5 me-2" (click)="desactivarFuncionalidad(func.tramiteMainId!)"
                           *ngIf="func.estadoTramite == true">
                            <i class="govco-minus-circle"></i>
                        </a>
                        <div class="d-flex justify-content-between">
                            <div class="col-3"></div>
                            <a class="text-success col-9" *ngIf="func.estadoTramite == false" (click)="activarFuncionalidad(func.tramiteMainId!)">
                                <i class="govco-plus-circle"></i>
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class=" d-flex justify-content-between mt-2">
            <!-- <button class="btn btn-outline-primary rounded-pill" (click)="crearFuncionalidad()">CREAR NUEVO
                TRÁMITE</button> -->
            <pagination-controls class="clase-paginador" (pageChange)="pagina = $event" previousLabel="Anterior"
                                 nextLabel="Siguiente"></pagination-controls>
        </div>
    </div>
    <div class="prueba2 col-sm-12 col-md-12 text-end">
        <button (click)="router.navigate(['/Home/Administracion']);">Regresar</button>
    </div>
</div>
<app-go-back-up class="up"></app-go-back-up>
<!-- <div class="container">
    <div class="title">
        <h1 class="h1-tipografia-govco title-1">No Permitido</h1>
    </div>
</div> -->
<app-footer></app-footer>