import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api/api.service';
import { ConfirmationModalComponent } from 'src/app/Shared/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-survey-catalog',
  templateUrl: './survey-catalog.component.html',
  styleUrls: []
})
export class SurveyCatalogComponent implements OnInit {
  authRole: boolean = false;
  adminRole: boolean = false;
  rol: string | null = '';
  itemsByPage: number = 5;
  page: number = 1;
  ruta = [{ name: 'Inicio', url: '/Home' }, { name: 'Administración', url: '/Home/Administracion' }, { name: 'Catálogo de encuestas', url: '/Home/CatalogoEncuestas' }]
  encuestas: any;
  constructor(private api: ApiService, public dialog: MatDialog, public router: Router) { }

  ngOnInit(): void {
    this.rol = localStorage.getItem('rol');
    if (this.rol == 'Funcionario') {
      this.authRole = true;
    } else if (this.rol == 'AdminFuncional' || this.rol == 'AdminTI') {
      this.authRole = true;
      this.adminRole = true;

    } else {
      this.router.navigate(['/Home']);
    }

    this.obtenerEncuestas()
  }

  obtenerEncuestas() {
    this.api.getSurveys().subscribe(res => {
      this.encuestas = res
    })
  }

  popUpConfirmacion(data: any): void {
    this.dialog.open(ConfirmationModalComponent, {
      data: { type: 'warning', title: 'Eliminar registro.', text: '¿Esta seguro que quiere eliminar la encuesta de ' + data.name + ' ?', acept: "" },
      height: 'auto',
      width: '700px',
    }).afterClosed().subscribe(result => {
      if (result) {
        this.api.deleteSurveys(data.id).subscribe(res => {
          if (res.status == 200) {
            window.location.reload()
          }
        })
      }
    });
  }

  editarEncuesta(data: any): void {
    if (data.surveyTypeId == 1) {
      let id = data.id
      this.router.navigate(['/Home/GestionContenido/' + id]);
    }
    if (data.surveyTypeId == 2) {
      let id = data.id
      this.router.navigate(['/Home/GestionContenido/' + id]);
    }


  }

  visualizarEncuesta(data: any): void {

    if (data.surveyTypeId == 1) {
      let id = data.id
      window.open('/Home/Encuesta/' + id);
    }
    if (data.surveyTypeId == 2) {
      let id = data.id
      window.open('/Home/Encuesta/' + id);
    }

  }
}
