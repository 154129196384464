import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api/api.service';
import { AlertModalInformationComponent } from 'src/app/Shared/alert-modal-information/alert-modal-information.component';
import { ConfirmationModalComponent } from 'src/app/Shared/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-survey-generation',
  templateUrl: './survey-generation.component.html',
  styleUrls: ['./survey-generation.component.scss']
})
export class SurveyGenerationComponent implements OnInit {

  rol: string | null = '';
  authRole: boolean = false;
  adminRole: boolean = false
  a: any = []

  preguntas: any = []
  respuestas: any = []

  respuestasPextra: any[] = []
  respuestasPactual: string = ""
  respuestasPtype:number =0;
  respuesta: any = {
    id: this.respuestas.length,
    surveyQuestionsId: null,
    identifier: this.respuestas.length + 1,
    description: 'Respuesta',
    tooltip: 'Ayuda',
    surveyQuestions: '',
    statusId: null
  }

  pregunta: any = {
    title: '',
    tooltip: '',
    questionTypeId: 0,
    mandatory: false,
    correctAnswer: 'nn',
    statusId: 0,
    answersQuestions: []
  }


  encuesta: any = {
    id: '',
    surveyTypeId: 1,
    statusId: 1,
    name: '',
    description: '',
    date: new Date(Date.now()).toISOString(),
    surveyQuestions: []
  }

  rutaMigaPan = [{ name: 'Inicio', url: '/Home' }, { name: 'Administración', url: '/Home/Administracion' }, { name: 'Encuestas', url: '/Home/GestionContenido' }]
  addView: boolean = false;
  pathname = window.location.pathname;
  surveyType: string = ''
  viewErrorPop: boolean = false;
  newSurvey: boolean = false;
  editMode: boolean = false;
  PreguntasExtraOriginales: any[] = [];
  textquestion: string = "";
  textquestionExtra: string = "";

  textlabelExtra: string = "";
  preguntasborradas: boolean = false;
  constructor(public fb: FormBuilder, public dialog: MatDialog, private api: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.surveyType = this.pathname.split('/').pop();

    this.rol = localStorage.getItem('rol');
    if (this.rol == 'Funcionario') {
      this.authRole = true;
    } else if (this.rol == 'AdminFuncional' || this.rol == 'AdminTI') {
      this.authRole = true;
      this.adminRole = true;

    } else {
      this.router.navigate(['/Home']);
    }

    this.obtenerEncuesta()

  }

  obligatoriedadDePregunta(e: any) {
    if (e.target.value == 1) {
      this.pregunta.mandatory = true;
    } else if (e.target.value == 2) {
      this.pregunta.mandatory = false;
    }

  }

  obtenerEncuesta() {
    let id = '';

    switch (this.surveyType) {
      case 'EUX':
        id = '1';
        break;
      case 'EST':
        id = '2';
        break;
      case 'ADD':
        this.newSurvey = true;
        break;
      default:
        this.api.getSurveyByid(this.surveyType).subscribe(res => {
          this.updateEncuesta(res);
        });
        break;
    }

    if (id !== '') {
      this.api.getSurveyByType(id).subscribe(res => {
        this.updateEncuesta(res);
      });
    }
  }

  private updateEncuesta(res) {
    this.encuesta.id = res.id;
    this.encuesta.surveyTypeId = res.surveyTypeId;
    this.encuesta.name = res.name;
    this.encuesta.description = res.description;
    this.encuesta.surveyQuestions = res.surveyQuestions;
  }



  agregarPregunta() {
    this.addView = true;
  }

  getFilteredAnswersLength(answersQuestions: any[]): number {
    return answersQuestions.filter(answer => answer.statusId === 0).length;
  }
  agregarRespuesta() {


      let index=0;
      this.respuestas.forEach(element =>
      {
        if(!element.identifier.includes('PE'))
        {
          index++;
        }

      })


    let a = {
      identifier: "R" + (index + 1),
      description: 'Respuesta' + this.respuestas.length + 1,
      tooltip: 'Tooltip Ayuda',
      statusId: 0
    }

    this.respuestas.push(a)
  }
  agregarRespuestaExtra() {

    if(this.respuestasPtype!=2 && this.respuestasPtype!=4)
    {
      let a = {
        identifier: "PER" + (this.respuestasPextra.length + 1)+"P-"+this.respuestasPactual,
        description: 'Respuesta' + this.respuestasPextra.length + 1,
        tooltip: 'Tooltip Ayuda',
        statusId: 0
      }

      this.respuestasPextra.push(a)

    }
    else {
      if(this.respuestasPextra.length==0)
      {
        let a = {
          identifier: "PER" + (this.respuestasPextra.length + 1)+"P-"+this.respuestasPactual,
          description: 'Respuesta' + this.respuestasPextra.length + 1,
          tooltip: 'Tooltip Ayuda',
          statusId: 0
        }

        this.respuestasPextra.push(a)

      }
    }

  }

  cancelar() {
    this.router.navigate(['/Home/CatalogoEncuestas']);

  }
  onchangeExtra() {



    this.respuestasPextra=[];
    this.textquestionExtra="";
    this.preguntasborradas=true;

  }
  guardarPreguntaExtra() {

    if (this.respuestasPtype == 0 || this.respuestasPextra.length == 0 ) {
      this.viewErrorPop = true
      setTimeout(() => {
        this.viewErrorPop = false
      }, 3000);
    }
    else
    {


      if(this.preguntasborradas)
      {
        this.respuestas.forEach((elementr,index) =>
        {
          if(elementr.identifier.includes(("P-"+this.respuestasPactual)))
          {


            this.respuestas[index].statusId=1;
          }
        })
      }

let textfield=false;
      this.respuestasPextra.forEach(element =>
      {

        element.identifier=element.identifier+"~"+this.respuestasPtype
        let included=false

        this.respuestas.forEach((elementr,index) =>
        {
          if(elementr.identifier.includes(element.identifier))
          {

            included=true;
            this.respuestas[index].description=element.description;
            this.respuestas[index].statusId=element.statusId;


            if(this.respuestasPtype==2 )
            {
              this.respuestas[index].description=this.textquestionExtra;
              this.respuestas[index].tooltip="Tooltip ";
              textfield=true;
            }
            if( this.respuestasPtype==4)
            {
              this.respuestas[index].description=this.textquestionExtra+"~"+this.textlabelExtra;
              this.respuestas[index].tooltip="Tooltip ";
              textfield=true;
            }



          }
        })
        if(!included)
        {

          if(this.respuestasPtype!=2 && this.respuestasPtype!=4)
          {
            this.respuestas.push(element);


          }


        }



      })

      if(!textfield)
      {
        if(this.respuestasPtype==2 )
        {
          this.respuestasPextra[0].description=this.textquestionExtra;
          this.respuestasPextra[0].tooltip="Tooltip ";
          this.respuestas.push(this.respuestasPextra[0]);
        }
        if( this.respuestasPtype==4)
        {
          this.respuestasPextra[0].description=this.textquestionExtra+"~"+this.textlabelExtra;
          this.respuestasPextra[0].tooltip="Tooltip ";
          this.respuestas.push(this.respuestasPextra[0]);

        }
      }
      this.respuestasPextra=[];
      this.respuestasPtype=0;
      this.preguntasborradas=false;


    }
  }
  guardarPregunta() {
    if (this.pregunta.title == "" || this.respuestas.length == 0 || this.pregunta.questionTypeId == 0 || this.pregunta.tooltip == "") {
      this.viewErrorPop = true
      setTimeout(() => {
        this.viewErrorPop = false
      }, 3000);
    } else {
      if (!this.newSurvey) {
        if(this.pregunta.questionTypeId==2 || this.pregunta.questionTypeId==4)
        { let respuestaslist:any[] =[];
          this.respuestas[0].description=this.textquestion;
          this.respuestas[0].tooltip="Tooltip ";
          respuestaslist.push(this.respuestas[0])
          this.pregunta.answersQuestions = respuestaslist;
        }
        else
        {
          this.pregunta.answersQuestions = this.respuestas
        }

        this.preguntas.push(this.pregunta)
        this.encuesta.surveyQuestions.push(this.pregunta)
        var hash = {};
        this.encuesta.surveyQuestions = this.encuesta.surveyQuestions.filter(function (current) {
          let exists = !hash[current.title];
          hash[current.title] = true;
          return exists;
        });


        this.respuestas = []
        this.preguntas = []
        this.addView = false;

        this.pregunta = {
          title: '',
          tooltip: '',
          questionTypeId: 0,
          mandatory: true,
          correctAnswer: 'nn',
          answersQuestions: [],
          statusId: 0,
        }

        this.openDialoRegitroExitoso()
        this.editMode = false;
      } else {

        this.pregunta.statusId = 0;
        this.pregunta.answersQuestions = this.respuestas
        this.encuesta.surveyQuestions.push(this.pregunta)
        delete this.encuesta.id;
        var hash = {};
        this.encuesta.surveyQuestions = this.encuesta.surveyQuestions.filter(function (current) {
          let exists = !hash[current.title];
          hash[current.title] = true;
          return exists;
        });

        this.respuestas = []
        this.preguntas = []
        this.addView = false;

        this.pregunta = {
          title: '',
          tooltip: '',
          questionTypeId: 0,
          mandatory: true,
          correctAnswer: 'nn',
          answersQuestions: []
        }

        this.openDialoRegitroExitoso()
        this.editMode = false;

      }
    }



  }

  limpiarForm() {


    this.pregunta = {
      title: '',
      tooltip: '',
      questionTypeId: 0,
      mandatory: true,
      correctAnswer: 'nn',
      statusId: 0,
      answersQuestions: []
    }

    this.respuestas = []
  }
  limpiarFormExtra() {


    this.respuestasPtype=0;
    this.respuestasPextra = []
  }
  cancelarEditar() {

    this.pregunta = {
      title: '',
      tooltip: '',
      questionTypeId: 0,
      mandatory: true,
      correctAnswer: 'nn',
      statusId: 0,
      answersQuestions: []
    }

    this.respuestas = []
    this.textquestion="";
    this.addView = false;
    this.editMode = false
  }
  cancelarEditarExtra() {



    this.respuestasPextra = []
    this.textquestionExtra="";
    this.respuestasPtype=0;

  }

  popUpConfirmacion(data: any): void {
    this.dialog.open(ConfirmationModalComponent, {
      data: { type: 'warning', title: 'Eliminar registro.', text: '', acept: "" },
      height: 'auto',
      width: '700px',
    }).afterClosed().subscribe(result => {
      if (result) {
        this.encuesta.surveyQuestions[data].statusId = 1
      }
    });
  }

  eliminarRespuesta(index: any): void {
    let data = this.respuestas[index]
    this.respuestas[index] = data
    this.respuestas[index].statusId = 1
  }
  eliminarRespuestaExtra(index: any): void {
    let data = this.respuestasPextra[index]
    this.respuestasPextra[index] = data
    this.respuestasPextra[index].statusId = 1
  }
  generarextra(index: any): void {
    let data = this.respuestas[index]
    this.respuestasPactual=data.identifier;
    this.preguntasborradas=false;
    this.respuestasPextra=[];
    this.textquestionExtra="";
    this.respuestasPtype=0;
    this.PreguntasExtraOriginales=[];
    this.respuestas.forEach((element, index) =>{

      if(element.identifier.includes("P-"+this.respuestasPactual) )
      {
        let llave=element.identifier.lastIndexOf("~");
        let valor= { ...element };
        if(llave!=-1)
        {

          this.respuestasPtype=Number.parseInt( element.identifier.substring(element.identifier.lastIndexOf("~")+1,element.identifier.length));

          valor.identifier=element.identifier.substring(0,element.identifier.lastIndexOf("~"));

        }

        if(element.statusId==0)
        {
          this.PreguntasExtraOriginales.push(valor.identifier);
          this.respuestasPextra.push(valor);
        }

      }
    });

    if(this.respuestasPtype==2 )
    {
      if(this.respuestasPextra.length!=0)
      {
        this.textquestionExtra= this.respuestasPextra[0].description
      }

    }
    if( this.respuestasPtype==4)
    {
      if(this.respuestasPextra.length!=0)
      {
        let llave=this.respuestasPextra[0].description.lastIndexOf("~");

        if(llave!=-1)
        {

          this.textlabelExtra=this.respuestasPextra[0].description.substring(this.respuestasPextra[0].description.lastIndexOf("~")+1,this.respuestasPextra[0].description.length);

          this.textquestionExtra=this.respuestasPextra[0].description.substring(0,this.respuestasPextra[0].description.lastIndexOf("~"));

        }
        else {
          this.textquestionExtra= this.respuestasPextra[0].description
        }

      }

    }

  }


  openDialoRegitroExitoso(): void {
    let dialogRef = this.dialog.open(AlertModalInformationComponent, {
      data: { type: 'success', title: 'Registro éxitoso.', text: 'Información de registro almacenada con éxito.', acept: "" },
    });
    setTimeout(() => {
      dialogRef.close()
    }, 1000);
  }

  editarPregunta(data: any) {
    this.respuestas = []
    this.pregunta = data
    data.answersQuestions.forEach(element => {
      if (element.statusId == 0) {

        this.respuestas.push(element)
        if((data.questionTypeId==2 || data.questionTypeId==4) && !element.identifier.includes('PE'))
        {
          this.textquestion=element.description;
        }
      }
    });
    this.addView = true;
    this.editMode = true;

  }

  crearEncuesta() {
    if (!this.newSurvey) {
      this.api.udapteSurveyById(this.encuesta.id, this.encuesta).subscribe(res => {
        if (res.status == 200) {
          this.router.navigate(['/Home/CatalogoEncuestas']);
        }
      })
    }

    if (this.newSurvey) {
      this.api.createSurvey(this.encuesta).subscribe(res => {
        if (res.status == 200) {
          this.router.navigate(['/Home/CatalogoEncuestas']);
        }
      })
    }
  }



}
