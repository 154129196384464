<form role="form" [formGroup]="legalForm" required>

    <div class="row">
        <h3 *ngIf="!editMode">Información del solicitante</h3>
        <div *ngIf="!editMode" class="col-sm-12 col-md-9 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Nombre-para-mostrar">Nombre para mostrar<span class="required">(*)</span></label>
                <input type="text" id="primer-nombre" placeholder="Primer Nombre" disabled value="{{userName}}" />
            </div>
        </div>
        <div *ngIf="!editMode" class="col-sm-12 col-md-9 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Correo-electronico">Correo electrónico<span class="required">(*)</span></label>
                <input type="text" id="primer-nombre" value="{{email}}" disabled />
            </div>
        </div>

        <h3>Datos Básicos.</h3>

        <div class="col-sm-12 col-md-9 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Razón Social">Razón Social<span class="required">(*)</span></label>
                <input type="text" id="Razón Social" placeholder="Razón Social" disabled value="{{infoUser.razonSocial}}" />
            </div>

        </div>

        <div class="row text-center">
            <div class="col-sm-12 col-md-9 text-center">
                <div class="entradas-de-texto-govco">
                    <label for="Tipo de Identificación">Tipo de Identificación<span class="required">(*)</span></label>
                    <select class="form-select" id="Tipo de Identificación" aria-label="Default select example" disabled>
                        <option *ngFor="let item of tipoIdentificacionn" [value]="item.idTipoIdentificacion">{{item.descripcion ==
                            1?item.descripcion:'nit'}}</option>
                    </select>
                </div>
            </div>

            <div class="col-sm-12 col-md-9 text-center">
                <div class="entradas-de-texto-govco">
                    <label for="NIT">NIT<span class="required">(*)</span></label>
                    <input type="number" id="NIT" placeholder="NIT" disabled value="{{infoUser.numeroIdentificacion}}" />
                </div>
            </div>
        </div>

        <legend>
            <h3>Representante Legal.</h3>
        </legend>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="primer-nombre">Primer Nombre<span class="required">(*)</span></label>
                <input type="text" id="primer-nombre" placeholder="Primer Nombre" formControlName="primerNombre" maxlength="50" />
            </div>
            <div class="formRequired"
                 *ngIf="legalForm.get('primerNombre').errors && (legalForm.get('primerNombre').dirty || legalForm.get('primerNombre').touched)">
                Campo requerido.
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="segundo-nombre">Segundo Nombre</label>
                <input type="text" id="segundo-nombre" placeholder="Segundo Nombre" formControlName="segundoNombre" maxlength="50" />
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="primer-apellido">Primer Apellido<span class="required">(*)</span></label>
                <input type="text" id="primer-apellido" placeholder="Primer Apellido" formControlName="primerApellido" maxlength="50" />
            </div>
            <div class="formRequired"
                 *ngIf="legalForm.get('primerApellido').errors && (legalForm.get('primerApellido').dirty || legalForm.get('primerApellido').touched)">
                Campo requerido.
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="segundo-apellido">Segundo Apellido</label>
                <input type="text" id="segundo-apellido" placeholder="Segundo Apellido" formControlName="segundoApellido" maxlength="50" />
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="tipoIdendificacionId">Tipo identificación<span class="required">(*)</span></label>
                <select class="form-select" id="tipoIdendificacionId" aria-label="Default select example" disabled>
                    <option *ngFor="let item of tipoIdentificacionn" [value]="item.idTipoIdentificacion">{{item.descripcion}}</option>
                </select>
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="N. Documento Identidad">Número de identificación<span class="required">(*)</span></label>
                <input type="text" id="N. Documento Identidad" placeholder="Número Documento Identidad" disabled
                       value="{{infoUser.numeroIdentificacionRL}}" maxlength="11" />
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Teléfono fijo">Teléfono fijo</label>
                <input type="text" id="Teléfono fijo" placeholder="Teléfono fijo" formControlName="telefonoFijo" maxlength="7" />
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Teléfono celular">Teléfono celular<span class="required">(*)</span></label>
                <input type="tel" id="Teléfono celular" placeholder="Teléfono celular" formControlName="telefonoCelular" maxlength="10" />
            </div>
            <div class="formRequired"
                 *ngIf="legalForm.get('telefonoCelular').errors && (legalForm.get('telefonoCelular').dirty || legalForm.get('telefonoCelular').touched)">
                Campo requerido.
            </div>
        </div>

        <div class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Correo electrónico">Correo electrónico<span class="required">(*)</span></label>
                <input type="email" pattern="[^ @]*@[^ @]*" id="Correo electrónico" placeholder="Correo electrónico" formControlName="email"
                       maxlength="50" />
            </div>
            <div class="formRequired" *ngIf="legalForm.get('email').errors && (legalForm.get('email').dirty || legalForm.get('email').touched)">
                Campo requerido.
            </div>
        </div>

        <div class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Confirmar Correo electrónico">Confirmar Correo electrónico<span class="required">(*)</span></label>
                <input id="Confirmar Correo electrónico" type="email" pattern="[^ @]*@[^ @]*" placeholder="Confirmar Correo electrónico"
                       formControlName="confirmEmail" maxlength="50" />
            </div>
            <div class="formRequired"
                 *ngIf="legalForm.get('confirmEmail').errors && (legalForm.get('confirmEmail').dirty || legalForm.get('confirmEmail').touched)">
                Campo requerido.
            </div>
            <div class="formRequired" *ngIf="legalForm.value.email != legalForm.value.confirmEmail">
                No coincide con el campo Correo electrónico .
            </div>
        </div>

        <div *ngIf="editMode" id="div_enviar" class="col-12 col-md-12 pl-4 mt-4 politics">
            <div class="col-sm-12">


                <div class="entradas-de-texto-govco">
                    <input [disabled]="legalForm.invalid" type="checkbox" name="acepta_terminos" id="acepta_terminos" (click)="aceptarPoliticas()">
                    <label for="Teléfono fijo"> He leído y acepto la
                        <a href="https://tramitesenlinea.saludcapital.gov.co/registro/tratamiento_datos" rel="noopener" target="_blank">
                            política de protección y tratamiento de datos personales.
                        </a></label>
                </div>

                <p>
                    Si requiere realizar algun cambio que no se permite en esta pantalla, haga su solicitud a través de nuestro correo institucional
                    contactenossaludcapital.gov.co
                </p>
                <p>
                    * La edición de datos, no será reflejada en las solicitudes que tiene en curso, sino que se reflejará hasta su siguiente solicitud
                </p>
            </div>
        </div>

        <div class="col-sm-12 col-md-12 text-center">
            <br />
            <br><br>
            <button *ngIf="!editMode" mat-dialog-close (click)="edit()" type="button"
                    class="btn-govco fill-btn-govco col-3 col-md-3 pl-3">Editar</button>
            <button *ngIf="editMode" mat-dialog-close [disabled]="legalForm.invalid || noacept" (click)="save()" type="button"
                    class="btn-govco fill-btn-govco col-3 col-md-3 pl-3">Guardar</button>

            <button *ngIf="!editMode" type="button" class="btn-govco secondaryButton col-3 col-md-3 pl-3" (click)="goBackHome()">Regresar</button>
            <button *ngIf="editMode" type="button" class="btn-govco secondaryButton col-3 col-md-3 pl-3" (click)="goBack()">Regresar</button>

        </div>



    </div>
</form>
