import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AddressService } from 'src/app/Services/address.service';
import { ConfirmationModalComponent } from 'src/app/Shared/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

  @Input() disabledInputs: boolean = true;
  @Input() naturalForm: any;
  @Output() $address: EventEmitter<any> = new EventEmitter<any>();
  @Output() $urbanAddres: EventEmitter<any> = new EventEmitter<any>();
  @Output() $confirmaDireccion: EventEmitter<any> = new EventEmitter<any>();
  addressForm: any = FormGroup;

  letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

  avePcpalsArray = [
    { key: 'AK', value: 'AK - Avenida Carrera' },
    { key: 'AC', value: 'AC - Avenida Calle' },
    { key: 'CL', value: 'CL - Calle' },
    { key: 'DG', value: 'DG - Diagonal' },
    { key: 'Cr', value: 'Cr -Carrera' },
    { key: 'TV', value: 'TV - Transversal' },
  ];

  complemento = [
    { key: 'AD', value: 'Administración' },
    { key: 'AL', value: 'Altillo' },
  ];

  UrbanAddress: boolean = true;
  getDireccion: undefined;

  constructor(private tramiteSharedService: AddressService, private fb: FormBuilder, public dialog: MatDialog) {

    this.addressForm = this.fb.group(
      {
        address: [{ value: ' ', disabled: this.disabledInputs }],
        addressEmit: ['', [Validators.required]],
        avePcpal: ['AK', [Validators.required]],
        numero: ['', [Validators.required]],
        letra: [''],
        bis: [''],
        letraAux: [''],

        card: [''],
        numCard: [''],
        letraCard: [''],
        bisCard: [''],
        letraAuxCard: [''],

        placaAux: ['', [Validators.required]],
        cardAux: [''],
        complemento: [''],



        // department: ['', [/*Validators.required, Validators.minLength(3), Validators.maxLength(50)*/]],
        // city: ['', [/*Validators.required, Validators.minLength(3), Validators.maxLength(50)*/]],
        // neighborhood: [''],
        // zipCode: ['', [/*Validators.minLength(1), Validators.maxLength(50)*/]],
        addressManual: ['']
      }
    )
  }

  ngOnInit(): void {
    if (this.disabledInputs) {
      this.addressForm = this.fb.group(
        {
          address: [{ value: ' ', disabled: this.disabledInputs }],
          addressEmit: [{ value: ' ', disabled: this.disabledInputs }],
          avePcpal: [{ value: ' ', disabled: this.disabledInputs }],
          numero: [{ value: ' ', disabled: this.disabledInputs }],
          letra: [{ value: ' ', disabled: this.disabledInputs }],
          bis: [{ value: ' ', disabled: this.disabledInputs }],
          letraAux: [{ value: ' ', disabled: this.disabledInputs }],

          card: [{ value: ' ', disabled: this.disabledInputs }],
          numCard: [{ value: ' ', disabled: this.disabledInputs }],
          letraCard: [{ value: ' ', disabled: this.disabledInputs }],
          bisCard: [{ value: ' ', disabled: this.disabledInputs }],
          letraAuxCard: [{ value: ' ', disabled: this.disabledInputs }],

          placaAux: [{ value: ' ', disabled: this.disabledInputs }],
          cardAux: [{ value: ' ', disabled: this.disabledInputs }],
          complemento: [{ value: ' ', disabled: this.disabledInputs }],



          // department: ['', [/*Validators.required, Validators.minLength(3), Validators.maxLength(50)*/]],
          // city: ['', [/*Validators.required, Validators.minLength(3), Validators.maxLength(50)*/]],
          // neighborhood: [''],
          // zipCode: ['', [/*Validators.minLength(1), Validators.maxLength(50)*/]],
          addressManual: ['']
        }
      )
    }
    console.log(this.naturalForm)
    if (this.naturalForm) {
      this.addressForm.get('address').setValue(this.naturalForm)
    }

  }

  updateAddress() {
    const addressKeys = ['avePcpal', 'numero', 'letra', 'bis', 'card', 'numCard', 'letraAux', 'placaAux', 'cardAux', 'complemento'];
    const addressValues = addressKeys.map(key => this.addressForm.get(key).value || '');
    const addressString = addressValues.join(' ');

    this.addressForm.get('address').setValue(addressString);
    this.addressForm.get('addressEmit').setValue(addressString);
  }

  cambiarTipoResidencia(tipo) {
    if (tipo == 'urbana') {
      this.UrbanAddress = true
      this.$urbanAddres.emit(tipo)
      this.addressForm.reset()
      this.addressForm.get('addressEmit').setValue('')
      this.addressForm.get('addressManual').setValue('')

    } else if (tipo == 'rural') {
      this.UrbanAddress = false
      this.$urbanAddres.emit(tipo)
    }

  }


  confirmarDireccion() {
    console.log(this.addressForm.value.addressManual)
    if (this.addressForm.value.addressManual != '') {
      this.addressForm.get('addressEmit').setValue(this.addressForm.value.addressManual)
    }
    this.$address.emit(this.addressForm.value.addressEmit)
    this.$confirmaDireccion.emit(true)
    console.log(this.addressForm.value.addressEmit)
    let dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: { type: 'success', title: 'Confirmación exitosa', text: '', acept: "" },
      height: 'auto',
      width: '700px',
    });
    setTimeout(() => {
      dialogRef.close()
    }, 2000);
  }



}
