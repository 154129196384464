import { Injectable } from '@angular/core';
import { environment } from './../../../environments/env';
import { HttpClient } from '@angular/common/http';
import { FuncionalidadRolesFull } from 'src/app/Models/funcionalidadesRolesFull.model';
import { FuncionalidadRoles } from 'src/app/Models/funcionalidadRoles.model';
import { Observable } from 'rxjs';

const URL = environment.backUrl;
const URLSecurity = environment.urlApiManagerSecurity;

@Injectable({
  providedIn: 'root'
})
export class FuncionalidadRolesService {

  constructor(private http: HttpClient) { }

  traerFuncionalidadRoles(): Observable<any[]> {
    return this.http.get<any[]>(`${URL}/TramitesMainRol/list/tramite/full`)
  }
  traerFuncionalidadRolesAutenticacion(type:string,search:string): Observable<any> {
    return this.http.get<any>(`${URLSecurity}/v2/Security/GetRolesAplications?type=${type}&search=${search}`)
  }
  consultarFuncionalidadRoles(nombre: string): Observable<FuncionalidadRolesFull[]> {
    return this.http.get<FuncionalidadRolesFull[]>(`${URL}/TramitesMainRol/list/tramite/full/${nombre.substring(0, 19)}`);
  }

  desactivarFuncionalidadRoles(id: string): Observable<any> {
    return this.http.delete<number>(`${URL}/TramitesMainRol/off/${id}`, { observe: 'response' });
  }

  activarFuncionalidadRoles(id: number): Observable<any> {
    return this.http.delete<number>(`${URL}/TramitesMainRol/on/${id}`, { observe: 'response' });
  }
  guardarFuncionalidadRolesAutenticacion(objeto: any): Observable<any> {
    return this.http.put<any>(`${URL}/TramitesMainRol`, objeto, { observe: 'response' });
  }
  guardarFuncionalidadAutenticacion(objeto: any): Observable<any> {
    return this.http.put<any>(`${URLSecurity}/v2/Security/PutAplication`, objeto, { observe: 'response' });
  }
  guardarFuncionalidadRoles(objeto: FuncionalidadRoles[]): Observable<any> {
    return this.http.post<number>(`${URL}/TramitesMainRol`, objeto, { observe: 'response' });
  }

  borrarFuncionalidadRoles(id: number): Observable<any> {
    return this.http.delete<number>(`${URL}/TramitesMainRol/${id}`, { observe: 'response' });
  }
}
