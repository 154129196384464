import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api/api.service';
import { AlertModalInformationComponent } from 'src/app/Shared/alert-modal-information/alert-modal-information.component';
import { ConfirmationModalComponent } from 'src/app/Shared/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-survey-generation',
  templateUrl: './survey-generation.component.html',
  styleUrls: ['./survey-generation.component.scss']
})
export class SurveyGenerationComponent implements OnInit {
  tramiteForm: any = FormGroup;
  rol: string | null = '';
  authRole: boolean = false;
  adminRole: boolean = false
  a: any = []
  bligatoriedadPregunta: boolean = false
  preguntas: any = []
  respuestas: any = []
  respuesta: any = {
    id: this.respuestas.length,
    surveyQuestionsId: null,
    identifier: this.respuestas.length + 1,
    description: 'Respuesta',
    tooltip: 'Ayuda',
    surveyQuestions: '',
    statusId: null
  }

  pregunta: any = {
    title: '',
    tooltip: '',
    questionTypeId: 0,
    mandatory: false,
    correctAnswer: 'nn',
    statusId: 0,
    answersQuestions: []
  }


  encuesta: any = {
    id: '',
    surveyTypeId: 1,
    statusId: 1,
    name: '',
    description: '',
    date: new Date(Date.now()).toISOString(),
    surveyQuestions: []
  }

  rutaMigaPan = [{ name: 'Inicio', url: '/Home' }, { name: 'Administración', url: '/Home/Administracion' }, { name: 'Encuestas', url: '/Home/GestionContenido' }]
  addView: boolean = false;
  pathname = window.location.pathname;
  surveyType: string = ''
  viewErrorPop: boolean = false;
  newSurvey: boolean = false;
  editMode: boolean = false;

  constructor(public fb: FormBuilder, public dialog: MatDialog, private api: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.surveyType = this.pathname.split('/').pop();

    this.rol = localStorage.getItem('rol');
    if (this.rol == 'Funcionario') {
      this.authRole = true;
    } else if (this.rol == 'AdminFuncional' || this.rol == 'AdminTI') {
      this.authRole = true;
      this.adminRole = true;

    } else {
      this.router.navigate(['/Home']);
    }

    this.obtenerEncuesta()

  }

  obligatoriedadDePregunta(e: any) {
    if (e.target.value == 1) {
      this.pregunta.mandatory = true;
    } else if (e.target.value == 2) {
      this.pregunta.mandatory = false;
    }

  }

  obtenerEncuesta() {
    let id = '';

    switch (this.surveyType) {
      case 'EUX':
        id = '1';
        break;
      case 'EST':
        id = '2';
        break;
      case 'ADD':
        this.newSurvey = true;
        break;
      default:
        this.api.getSurveyByid(this.surveyType).subscribe(res => {
          this.updateEncuesta(res);
        });
        break;
    }

    if (id !== '') {
      this.api.getSurveyByType(id).subscribe(res => {
        this.updateEncuesta(res);
      });
    }
  }

  private updateEncuesta(res) {
    this.encuesta.id = res.id;
    this.encuesta.surveyTypeId = res.surveyTypeId;
    this.encuesta.name = res.name;
    this.encuesta.description = res.description;
    this.encuesta.surveyQuestions = res.surveyQuestions;
  }



  agregarPregunta() {
    this.addView = true;
  }


  agregarRespuesta() {
    let a = {
      identifier: "R" + (this.respuestas.length + 1),
      description: 'Respuesta' + this.respuestas.length + 1,
      tooltip: 'Tooltip Ayuda',
      statusId: 0
    }

    this.respuestas.push(a)
  }

  cancelar() {
    this.router.navigate(['/Home/CatalogoEncuestas']);

  }

  guardarPregunta() {
    if (this.pregunta.title == "" || this.respuestas.length == 0 || this.pregunta.questionTypeId == 0 || this.pregunta.tooltip == "") {
      this.viewErrorPop = true
      setTimeout(() => {
        this.viewErrorPop = false
      }, 3000);
    } else {
      if (!this.newSurvey) {
        this.pregunta.answersQuestions = this.respuestas
        this.preguntas.push(this.pregunta)
        this.encuesta.surveyQuestions.push(this.pregunta)
        var hash = {};
        this.encuesta.surveyQuestions = this.encuesta.surveyQuestions.filter(function (current) {
          let exists = !hash[current.title];
          hash[current.title] = true;
          return exists;
        });


        this.respuestas = []
        this.preguntas = []
        this.addView = false;

        this.pregunta = {
          title: '',
          tooltip: '',
          questionTypeId: 0,
          mandatory: true,
          correctAnswer: 'nn',
          answersQuestions: [],
          statusId: 0,
        }

        this.openDialoRegitroExitoso()
        this.editMode = false;
      } else {

        this.pregunta.statusId = 0;
        this.pregunta.answersQuestions = this.respuestas
        this.encuesta.surveyQuestions.push(this.pregunta)
        delete this.encuesta.id;
        var hash = {};
        this.encuesta.surveyQuestions = this.encuesta.surveyQuestions.filter(function (current) {
          let exists = !hash[current.title];
          hash[current.title] = true;
          return exists;
        });

        this.respuestas = []
        this.preguntas = []
        this.addView = false;

        this.pregunta = {
          title: '',
          tooltip: '',
          questionTypeId: 0,
          mandatory: true,
          correctAnswer: 'nn',
          answersQuestions: []
        }

        this.openDialoRegitroExitoso()
        this.editMode = false;

      }
    }



  }

  limpiarForm() {


    this.pregunta = {
      title: '',
      tooltip: '',
      questionTypeId: 0,
      mandatory: true,
      correctAnswer: 'nn',
      statusId: 0,
      answersQuestions: []
    }

    this.respuestas = []
  }

  cancelarEditar() {

    this.pregunta = {
      title: '',
      tooltip: '',
      questionTypeId: 0,
      mandatory: true,
      correctAnswer: 'nn',
      statusId: 0,
      answersQuestions: []
    }

    this.respuestas = []
    this.addView = false;
    this.editMode = false
  }

  popUpConfirmacion(data: any): void {
    this.dialog.open(ConfirmationModalComponent, {
      data: { type: 'warning', title: 'Eliminar registro.', text: '', acept: "" },
      height: 'auto',
      width: '700px',
    }).afterClosed().subscribe(result => {
      if (result) {
        this.encuesta.surveyQuestions[data].statusId = 1
      }
    });
  }

  eliminarRespuesta(index: any): void {
    let data = this.respuestas[index]
    this.respuestas[index] = data
    this.respuestas[index].statusId = 1
  }

  openDialoRegitroExitoso(): void {
    let dialogRef = this.dialog.open(AlertModalInformationComponent, {
      data: { type: 'success', title: 'Registro éxitoso.', text: 'Información de registro almacenada con éxito.', acept: "" },
    });
    setTimeout(() => {
      dialogRef.close()
    }, 1000);
  }

  editarPregunta(data: any) {
    this.respuestas = []
    this.pregunta = data
    data.answersQuestions.forEach(element => {
      if (element.statusId == 0) {
        this.respuestas.push(element)
      }
    });
    this.addView = true;
    this.editMode = true;
  }

  crearEncuesta() {
    if (!this.newSurvey) {
      this.api.udapteSurveyById(this.encuesta.id, this.encuesta).subscribe(res => {
        if (res.status == 200) {
          this.router.navigate(['/Home/CatalogoEncuestas']);
        }
      })
    }

    if (this.newSurvey) {
      this.api.createSurvey(this.encuesta).subscribe(res => {
        if (res.status == 200) {
          this.router.navigate(['/Home/CatalogoEncuestas']);
        }
      })
    }
  }



}
