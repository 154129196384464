import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Services/api/api.service';

@Component({
  selector: 'app-citizen-portal',
  templateUrl: './citizen-portal.component.html',
  styleUrls: ['./citizen-portal.component.scss']
})



export class CitizenPortalComponent implements OnInit {
  listaInfoImagenes: any;

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.getImagesInfo()
    this.listaInfoImagenes = [];
  }

  /**
   * Mueve las imagenes del carrusel al darle click a las flechas
   */
  cambio(event: any) {
    if (event.target.id === "down") {
      let a = this.listaInfoImagenes.pop()
      this.listaInfoImagenes.unshift(a)
    } else if (event.target.id === "up") {
      let a = this.listaInfoImagenes.slice(0, 1)
      this.listaInfoImagenes.shift()
      this.listaInfoImagenes.push(a[0])
    }
  }

  /**
   * Consulta de imagenes y url para el carrusel portales para el ciudadano
   */
  getImagesInfo() {
    this.api.getImagesInfoCitizienPortal().subscribe(res => {
      if (res) {
        this.listaInfoImagenes = res
      }
    })
  }
}
