<div class="container-quickAcces">

    <ul>

        <li *ngIf="authRole">
            <a [routerLink]="[rutaEncuesta]" target="_blank">
                <img [style]="{width: 'auto'}" src="../../../assets/Images/encuesta_sqs.jpg" alt="">
            </a>
        </li>
        <li>
            <a href="https://www.gov.co/home" target="_blank">
                <img [style]="{width: 'auto'}" src="../../../assets/Images/alcaldiac_logo.png" alt="">
            </a>
        </li>
    </ul>

    <div *ngIf="!sesionActiva" class="accessButtons">
        <button type="button" (click)="logIn()"><label>INGRESO DE USUARIOS</label></button>
    </div>
</div>