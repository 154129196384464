import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api/api.service';
import { AlertModalInformationComponent } from 'src/app/Shared/alert-modal-information/alert-modal-information.component';
import {FuncionalidadesService} from "../../../../Services/roles-usuarios-funcionalidades/funcionalidades.service";
import {
  FuncionalidadRolesService
} from "../../../../Services/roles-usuarios-funcionalidades/funcionalidades-roles.service";

@Component({
  selector: 'app-edit-procedure-modal',
  templateUrl: './edit-procedure-modal.component.html',
  styleUrls: ['./edit-procedure-modal.component.scss']
})
export class EditProcedureModalComponent implements OnInit {

  categorias: any;
  idex: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private funcionalidadesService: FuncionalidadesService, private funcionalidadRolesService: FuncionalidadRolesService,
              public dialog: MatDialog, public fb: FormBuilder, public api: ApiService, public router: Router) { }

  tramiteForm: any = FormGroup;
  ngSelect = this.data.data.categoryName;

  ngOnInit(): void {
    this.getPersonalidadLegalAplicable()


    this.tramiteForm = this.fb.group({
      idTramite: this.data.data.idTramite,
      id: [this.data.data.id, [Validators.required]],
      name: [this.data.data.name, [Validators.required]],
      description: [this.data.data.description, [Validators.required]],
      hasCost: [this.data.data.hasCost, [Validators.required]],
      //
      cost: [this.data.data.hasCost ? this.data.data.cost : 0, [Validators.required]],
      durationTypeId: [this.data.data.durationTypeId, [Validators.required]],
      //
      durationInDays: [this.data.data.durationInDays, [Validators.required]],
      imageUrl: [this.data.data.imageUrl, [Validators.required]],
      manualLink: [this.data.data.manualLink, [Validators.required]],
      redirectUrl: [this.data.data.redirectUrl || '', [Validators.required]],
      requirementsUrl: [this.data.data.requirementsUrl, [Validators.required]],
      paperworkCategoryId: ['', [Validators.required]],
      legalPersonEnabled: [this.data.data.legalPersonEnabled, [Validators.required]],
      naturalPersonEnabled: [this.data.data.naturalPersonEnabled, [Validators.required]],

    })
  }

  guardarTramite() {
    let data = {
      idTramite: this.tramiteForm.value.idTramite,
      id: this.tramiteForm.value.id,
      name: this.tramiteForm.value.name,
      description: this.tramiteForm.value.description,
      hasCost: this.tramiteForm.value.hasCost,
      cost: this.tramiteForm.value.cost,
      durationTypeId: this.tramiteForm.value.durationTypeId,
      durationInDays: this.tramiteForm.value.durationInDays,
      imageUrl: this.tramiteForm.value.imageUrl,
      manualLink: this.tramiteForm.value.manualLink,
      redirectUrl: this.tramiteForm.value.redirectUrl,
      requirementsUrl: this.tramiteForm.value.requirementsUrl,
      paperworkCategoryId: this.tramiteForm.value.paperworkCategoryId,
      legalPersonEnabled: this.tramiteForm.value.legalPersonEnabled,
      naturalPersonEnabled: this.tramiteForm.value.naturalPersonEnabled
    }
    this.api.editarTramite(data.id, data).subscribe(resp => {
      if (resp.status == 200) {
        if (resp.status == 200) {

          let nombre =this.data.data.name;


            this.funcionalidadesService.traerFuncionalidadesAutenticacion("nombre",nombre)
              .subscribe(resp2 => {
                resp2.data.forEach(item =>
                {
                  let aplication=
                    {
                      nombreAplicativo: this.tramiteForm.value.name,
                      urlAplicativo: this.tramiteForm.value.redirectUrl,
                      siglaAplicativo: item.siglaAplicativo,
                      idAplicativo: item.idAplicativo,
                      idResponsable: localStorage.getItem('clientId'),
                      idRoles: item.idRoles,
                      estado: item.estado=="Habilitado" ? "true":"false"
                    }

                  this.funcionalidadRolesService.guardarFuncionalidadAutenticacion(aplication)
                    .subscribe(resp3 => {

                      this.openDialoRegitroExitoso();

                    });

                });

              });



        }
      }
    })

  }

  volver() {
    location.reload()
  }

  // openDialoRegitroExitoso(): void {
  //   let dialogRef = this.dialog.open(AlertModalInformationComponent, {
  //     data: { type: 'success', title: 'Registro éxitoso.', text: 'Información de registro almacenada con éxito.', acept: "" },
  //     height: 'auto',
  //     width: '700px',
  //   });
  //   setTimeout(() => {
  //     dialogRef.close()
  //     location.reload();
  //   }, 3000);
  // }

  openDialoRegitroExitoso(): void {
    let dialogRef = this.dialog.open(AlertModalInformationComponent, {
      data: { type: 'success', title: 'Registro éxitoso.', text: 'Información de registro almacenada con éxito.', acept: "" },
      // height: '400px',
      // width: '540px',
    });
    setTimeout(() => {
      dialogRef.close()
      location.reload();
    }, 1000);
  }

  getPersonalidadLegalAplicable() {
    this.api.getPersonalidadAplicable().subscribe(resp => {
      this.categorias = resp
      this.categorias.map((element: any, index: any) => {
        if (element.description == this.ngSelect) {
          this.idex = index
          this.tramiteForm.get('paperworkCategoryId').setValue(index + 1);
        }
      })
    })
  }

  costo(e: any) {
    if (e.target.value == '1') {
      this.tramiteForm.get('hasCost').setValue(true);
      this.tramiteForm.get('cost').setValue('');
    }
    if (e.target.value == '2') {
      this.tramiteForm.get('hasCost').setValue(false);
      this.tramiteForm.get('cost').setValue('0');
    }
  }

  mirar(e: any) {
    if (e.target.value == 'PJPN') {
      this.tramiteForm.get('legalPersonEnabled').setValue(true);
      this.tramiteForm.get('naturalPersonEnabled').setValue(true);
    }
    if (e.target.value == 'PJ') {
      this.tramiteForm.get('legalPersonEnabled').setValue(true);
      this.tramiteForm.get('naturalPersonEnabled').setValue(false);
    }
    if (e.target.value == 'PN') {
      this.tramiteForm.get('legalPersonEnabled').setValue(false);
      this.tramiteForm.get('naturalPersonEnabled').setValue(true);
    }
  }


  asignarValor(e: any) {
    this.tramiteForm.get('paperworkCategoryId').setValue(e.target.value);
  }


}
