import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { RegisterLegalPersonComponent } from './register-legal-person/register-legal-person.component';
import { RegisterNatrualPersonComponent } from './register-natrual-person/register-natrual-person.component';
import { ConfirmationModalComponent } from 'src/app/Shared/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-register-person-modal',
  templateUrl: './register-person-modal.component.html',
  styleUrls: ['./register-person-modal.component.scss']
})
export class RegisterPersonModalComponent {
  router: any;

  constructor(private authService: MsalService, public dialog: MatDialog, public msal: MsalService) { }

  public onLogOut(): void {
    this.msal.logoutPopup({
      mainWindowRedirectUri: "/"
    }).subscribe(data => {
      console.log(data)
    })
    localStorage.clear();

  }

  openDialogLogOut(): void {
    this.dialog.open(ConfirmationModalComponent, {
      data: { type: 'warning', title: 'confirmar', text: '¿Seguro de que desea cerrar su sesión?.' },
      height: '400px',
      width: '540px',
    }).afterClosed().subscribe(data => {
      if (data) {
        this.onLogOut();
        setTimeout(() => {
          //window.location.reload()
        }, 2000);
      }
    });
  }

  openRegisterNaturalPerson() {
    this.dialog.open(RegisterNatrualPersonComponent, {
      data: { register: true },
      disableClose: true,
      height: '80%',
      width: '70%',
    }).afterClosed().subscribe(data => {
      if (data) {
        console.log(data)
      }
    });
  }
  openRegisterLegalPerson() {
    this.dialog.open(RegisterLegalPersonComponent, {
      data: { register: true },
      disableClose: true,
      height: '600px',
      width: '800px',
      panelClass: 'scroll',
    });
  }
}
