import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Roles } from 'src/app/Models/roles.model';
import { environment } from './../../../environments/env';

const URL = environment.backUrl;
const URLSecurity = environment.urlApiManagerSecurity;

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private http: HttpClient) { }

  traerRoles(): Observable<Roles[]> {
    return this.http.get<Roles[]>(`${URL}/Rol/list`);
  }
  traerRolesAutransversal(search:string,type:string): Observable<any> {
    return this.http.get<any>(`${URLSecurity}/v2/Security/GetRoles?type=${type}&search=${search}`);
  }

  traerRol(nombre: string): Observable<Roles[]> {
    return this.http.get<Roles[]>(`${URL}/Rol/${nombre}`);
  }

  desactivarRol(id: number): Observable<any> {
    return this.http.delete<number>(`${URL}/Rol/off/${id}`, { observe: 'response' });
  }
  activarRolAutenticacion(rol: any): Observable<any> {
    return this.http.put<any>(`${URLSecurity}/v2/Security/UpdateRole`, rol, { observe: 'response' });
  }
  activarRol(id: number): Observable<any> {
    return this.http.delete<number>(`${URL}/Rol/on/${id}`, { observe: 'response' });
  }
  actualizarRolAutenticacion(rol: any): Observable<any> {
    return this.http.put<any>(`${URLSecurity}/v2/Security/UpdateRole`, rol, { observe: 'response' });
  }

  actualizarRol(rol: Roles): Observable<any> {
    return this.http.put<number>(`${URL}/Rol/update`, rol, { observe: 'response' });
  }

  crearRol(rol: Roles): Observable<any> {
    return this.http.post<number>(`${URL}/Rol`, rol, { observe: 'response' });
  }
  crearRolAutenticacion(rol: any): Observable<any> {
    return this.http.post<any>(`${URLSecurity}/v2/Security/AddRole`, rol, { observe: 'response' });
  }
  revisarRol(nombre: string): Observable<number> {
    return this.http.get<number>(`${URL}/Rol/search/${nombre}`);
  }
}
