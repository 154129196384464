import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inbox-management',
  templateUrl: './inbox-management.component.html',
  styleUrls: ['./inbox-management.component.scss']
})
export class InboxManagementComponent implements OnInit {

  constructor(private router: Router) { }
  rol: string | null = '';
  authRole: boolean = false;

  ngOnInit(): void {
    this.rol = localStorage.getItem('rol');
    if (this.rol == 'Funcionario' || this.rol == 'AdminFuncional') {
      this.authRole = true;
    }
  }

  viweBadejaFuncionario() {

    this.router.navigate(['Home/BandejaDeFuncionarios']);
  }

}
