import { event } from 'jquery';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  ruta = [{ name: 'Inicio', url: '/Home' }]
  carga: boolean = false;
  loginRedir: boolean;
  id: string;
  sesionInit: boolean = false;
  auth: boolean = false;

  ngOnInit(): void {
    this.validateAuth()
  }

  validateAuth() {

    this.id = localStorage.getItem('clientId');
    if (this.id != null) {
      if (this.id === '') {
        this.sesionInit = false;
      } else {

        this.sesionInit = true;
        this.auth = true
      }
    }
  }

  mostrarCarga() {
    setTimeout(() => {
      this.carga = true
    }, 2000)
  }

  estadoCarga($event: any) {
    if (event) {
      this.mostrarCarga()
    }
  }


  loginTramite(e: any) {
    alert('abriendo login')
    this.loginRedir = true
  }

}
