import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Usuarios } from 'src/app/Models/usuarios.model';
import { UsuariosService } from 'src/app/Services/roles-usuarios-funcionalidades/usuarios.service';
import { Router } from '@angular/router';
// import Swal from 'sweetalert2';

@Component({
  selector: 'app-crear-usuario',
  templateUrl: './crear-usuario.component.html',
  styleUrls: ['./crear-usuario.component.scss']
})
export class CrearUsuarioComponent implements OnInit {

  titulo = 'Crear usuario';
  formulario!: FormGroup;
  mensaje = 'Campo obligatorio';
  datos!: Usuarios;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.crearFormulario();
  }

  get correoObligatorio(): boolean {
    return (
      this.formulario.get('Correo')?.touched &&
      this.formulario.get('Correo')?.errors?.['required']
    );
  }

  get passwordObligatorio(): boolean {
    return (
      this.formulario.get('Password')?.touched &&
      this.formulario.get('Password')?.errors?.['required']
    );
  }

  crearFormulario(): void {
    this.formulario = this.fb.group({
      Correo: ['', Validators.required],
      Password: ['', Validators.required],
      Nombres: [''],
      Apellidos: [''],
      Usuario: [''],
      Cargo: [''],
      Area: ['']
    });
  }



  borrar(): void {
    this.formulario.reset();
  }
}
