<form role="form" [formGroup]="legalForm" required class="scroll">

    <div class="row">

        <legend>
            <h3>Registro Persona Jurídica.</h3>

        </legend>

        <div class="alert alert alert-warning" role="alert">
            <strong>Importante!</strong>
            <p>
                Por favor registre los datos exactamente como aparecen en la Registro de Cámara de Comercio, estos
                datos serán usados para la generación de los Documentos asociados al trámite solicitado y su
                posterior reporte a entidades de vigilancia y control.
            </p>
        </div>

        <h3>Datos Básicos.</h3>

        <div class="col-sm-12 col-md-12 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Razón Social">Razón Social<span class="required">(*)</span></label>
                <input type="text" id="Razón Social" placeholder="Razón Social" formControlName="nombreRazonSocial" maxlength="255" />
            </div>
            <div class="formRequired"
                 *ngIf="legalForm.get('nombreRazonSocial').errors && (legalForm.get('nombreRazonSocial').dirty || legalForm.get('nombreRazonSocial').touched)">
                Campo requerido.
            </div>

        </div>

        <div class="row text-center">
            <div class="col-sm-12 col-md-12 text-center">
                <div class="entradas-de-texto-govco">
                    <label for="Tipo de Identificación">Tipo de Identificación<span class="required">(*)</span></label>
                    <select class="form-select" id="Tipo de Identificación" aria-label="Default select example" formControlName="tipoDocumento">
                        <option value="" selected disabled>Seleccione</option>
                        <option *ngFor="let tipoiden of tipoIdentificacionn" [value]="tipoiden.idTipoIdentificacion">{{tipoiden.descripcion}}</option>
                    </select>
                </div>
                <div class="formRequired"
                     *ngIf="legalForm.get('tipoDocumento').errors && (legalForm.get('tipoDocumento').dirty || legalForm.get('tipoDocumento').touched)">
                    Campo requerido.
                </div>
            </div>

            <div class="col-sm-12 col-md-6 text-center">
                <div class="entradas-de-texto-govco">
                    <label for="NIT">Número de identificación<span class="required">(*)</span></label>
                    <input type="text" id="NIT" placeholder="NIT" formControlName="nit"
                           oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="11" />
                </div>

                <div class="formRequired" *ngIf="legalForm.get('nit').errors && (legalForm.get('nit').dirty || legalForm.get('nit').touched)">
                    Campo requerido.
                </div>
            </div>

            <div class="col-sm-12 col-md-6 text-center">
                <div class="entradas-de-texto-govco">
                    <label for="DV">DV<span class="required">(*)</span></label>
                    <input type="text" id="DV" placeholder="DV" formControlName="dv"
                           oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="1" />
                </div>
                <div class="formRequired" *ngIf="legalForm.get('dv').errors && (legalForm.get('dv').dirty || legalForm.get('dv').touched)">
                    Campo requerido.
                </div>
            </div>
        </div>

        <legend>
            <h3>Representante Legal.</h3>
        </legend>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="primer-nombre">Primer Nombre<span class="required">(*)</span></label>
                <input type="text" id="primer-nombre" placeholder="Primer Nombre" formControlName="primerNombre" maxlength="50"
                       pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ\s]+" />
            </div>
            <div class="formRequired"
                 *ngIf="legalForm.get('primerNombre').hasError('required') && (legalForm.get('primerNombre').dirty || legalForm.get('primerNombre').touched)">
                Campo requerido.
            </div>
            <div class="formRequired text-start"
                 *ngIf="legalForm.get('primerNombre').hasError('pattern') && (legalForm.get('primerNombre').dirty || legalForm.get('primerNombre').touched)">
                Solo se permiten letras.
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="segundo-nombre">Segundo Nombre</label>
                <input type="text" id="segundo-nombre" placeholder="Segundo Nombre" formControlName="segundoNombre" maxlength="50"
                       pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ\s]+" />
            </div>
            <div class="formRequired text-start"
                 *ngIf="legalForm.get('segundoNombre').hasError('pattern') && (legalForm.get('segundoNombre').dirty || legalForm.get('segundoNombre').touched)">
                Solo se permiten letras.
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="primer-apellido">Primer Apellido<span class="required">(*)</span></label>
                <input type="text" id="primer-apellido" placeholder="Primer Apellido" formControlName="primerApellido" maxlength="50"
                       pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ\s]+" />
            </div>
            <div class="formRequired"
                 *ngIf="legalForm.get('primerApellido').hasError('required') && (legalForm.get('primerApellido').dirty || legalForm.get('primerApellido').touched)">
                Campo requerido.
            </div>
            <div class="formRequired text-start"
                 *ngIf="legalForm.get('primerApellido').hasError('pattern') && (legalForm.get('primerApellido').dirty || legalForm.get('primerApellido').touched)">
                Solo se permiten letras.
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="segundo-apellido">Segundo Apellido</label>
                <input type="text" id="segundo-apellido" placeholder="Segundo Apellido" formControlName="segundoApellido" maxlength="50"
                       pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ\s]+" />
            </div>
            <div class="formRequired text-start"
                 *ngIf="legalForm.get('segundoApellido').hasError('pattern') && (legalForm.get('segundoApellido').dirty || legalForm.get('segundoApellido').touched)">
                Solo se permiten letras.
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="tipoIdendificacionId">Tipo identificación<span class="required">(*)</span></label>
                <select class="form-select" id="tipoIdendificacionId" aria-label="Default select example"
                        formControlName="tipoDocumentoRepresentanteLegal">
                    <option value="" selected disabled>Seleccione</option>
                    <option *ngFor="let tipoiden of tipoIdentificacioRepLegal" [value]="tipoiden.idTipoIdentificacion">{{tipoiden.descripcion}}
                    </option>
                </select>
                <div class="formRequired"
                     *ngIf="legalForm.get('tipoDocumentoRepresentanteLegal').errors && (legalForm.get('tipoDocumentoRepresentanteLegal').dirty || legalForm.get('tipoDocumentoRepresentanteLegal').touched)">
                    Campo requerido.
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="N. Documento Identidad">Número de Identificación:<span class="required">(*)</span></label>
                <input type="text" id="N. Documento Identidad" placeholder="Número Documento Identidad"
                       formControlName="numeroDocumentoRepresentanteLegal"
                       oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="10" />
            </div>
            <div class="formRequired"
                 *ngIf="legalForm.get('numeroDocumentoRepresentanteLegal').errors && (legalForm.get('numeroDocumentoRepresentanteLegal').dirty || legalForm.get('numeroDocumentoRepresentanteLegal').touched)">
                Campo requerido.
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Teléfono fijo">Teléfono fijo</label>
                <input type="text" id="Teléfono fijo" placeholder="Teléfono fijo" formControlName="telefonoFijo"
                       oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="7" />
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Teléfono celular">Teléfono celular<span class="required">(*)</span></label>
                <input type="text" id="Teléfono celular" placeholder="Teléfono celular" formControlName="telefonoCelular"
                       oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="10" />
            </div>
            <div class="formRequired"
                 *ngIf="legalForm.get('telefonoCelular').errors && (legalForm.get('telefonoCelular').dirty || legalForm.get('telefonoCelular').touched)">
                Campo requerido.
            </div>
        </div>

        <div class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Correo electrónico">Correo electrónico<span class="required">(*)</span></label>
                <input type="email" pattern="[^ @]*@[^ @]*" id="Correo electrónico" placeholder="Correo electrónico" formControlName="email"
                       maxlength="50" />
            </div>
            <div class="formRequired" *ngIf="legalForm.get('email').errors && (legalForm.get('email').dirty || legalForm.get('email').touched)">
                Campo requerido.
            </div>
        </div>

        <!-- <div class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Confirmar Correo electrónico">Confirmar Correo electrónico<span class="required">(*)</span></label>
                <input id="Confirmar Correo electrónico" type="email" pattern="[^ @]*@[^ @]*" placeholder="Confirmar Correo electrónico"
                       formControlName="confirmEmail" maxlength="50" />
            </div>
            <div class="formRequired"
                 *ngIf="legalForm.get('confirmEmail').errors && (legalForm.get('confirmEmail').dirty || legalForm.get('confirmEmail').touched)">
                Campo requerido.
            </div>
            <div class="formRequired" *ngIf="legalForm.value.email != legalForm.value.confirmEmail">
                No coincide con el campo Correo electrónico .
            </div>
        </div> -->

        <div class="col-12 col-md-12 pl-12 text-center">
            <div class="col-12">
                <br><br>
                <button [disabled]="legalForm.invalid" (click)="SaveLegalPerson()" type="button" class="btn-govco fill-btn-govco">Guardar</button>
                <button type="button" class="btn-govco fill-btn-govco" (click)="openDialogFail()">Regresar</button>
            </div>
        </div>

    </div>
</form>
