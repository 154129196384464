<form id="my-form" class="form">
    <div *ngIf="!disabledInputs" class="options">
        <label class="option">
            ¿La dirección de residencia está ubicada en área urbana?
        </label>

        <label>
            <input type="radio" id="radio11" name="radioButton1" [value]="true" [(ngModel)]="UrbanAddress" (change)="cambiarTipoResidencia('urbana')"
                   checked>
            <label for="radio11">Si</label>
        </label>


        <label>
            <input type="radio" id="radio12" name="radioButton1" [value]="false" [(ngModel)]="UrbanAddress" (change)="cambiarTipoResidencia('rural')">
            <label for="radio12">No</label>
        </label>

    </div>
    <div class="row" [formGroup]="addressForm" required>
        <div *ngIf="!disabledInputs" class="alert alert alert-warning" role="alert">
            <p>
                <strong>¡Importante!</strong> Por favor registre su dirección de residencia tal como
                aparece en el recibo público, en las casillas indicadas para esto. Una vez
                completado los datos, favor dar clic sobre el botón "Confirmar Dirección".
                Esta funcionalidad permitirá autocompletar datos de UPZ, Localidad y Barrio
                para las direcciones de Bogotá D.C. y estandarizar la dirección para el
                resto de ciudades.
            </p>
        </div>
        <div class="col-sm-12 col-md-3 text-center" *ngIf="UrbanAddress && !disabledInputs">
            <div class=" entradas-de-texto-govco">
                <label for="primer-nombre">*Vía Principal:<span class="required">(*)</span></label>
                <select type="text" name="username" [disabled]="disabledInputs" formControlName="avePcpal" (change)="updateAddress()">
                    <option [disabled]="disabledInputs" *ngFor="let item of avePcpalsArray" value={{item.key}}>{{item.value}}</option>
                </select>
            </div>
        </div>
        <div class="col-sm-12 col-md-3 text-center" *ngIf="UrbanAddress && !disabledInputs">
            <div class="entradas-de-texto-govco">
                <label for="primer-nombre">*Número:<span class="required">(*)</span></label>
                <input type="number" name="username" placeholder="Número" [disabled]="disabledInputs" formControlName="numero"
                       (change)="updateAddress()" />
            </div>
        </div>
        <div class="col-sm-12 col-md-3 text-center" *ngIf="UrbanAddress && !disabledInputs">
            <div class="entradas-de-texto-govco">
                <label for="primer-nombre">Letra:</label>
                <select type="text" name="username" [disabled]="disabledInputs" formControlName="letra" (change)="updateAddress()">
                    <option [disabled]="disabledInputs" value="" hidden>Vacio/A-Z</option>
                    <option [disabled]="disabledInputs" value="">Vacio</option>
                    <option [disabled]="disabledInputs" *ngFor="let item of letters">{{item}}</option>
                </select>
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center" *ngIf="UrbanAddress && !disabledInputs">
            <div class="entradas-de-texto-govco">
                <label for="primer-nombre">Bis:</label>
                <select type="text" name="username" [disabled]="disabledInputs" formControlName="bis" (change)="updateAddress()">
                    <option [disabled]="disabledInputs" value="" hidden>Vacio/Bis</option>
                    <option [disabled]="disabledInputs" value="" selected>Vacio</option>
                    <option [disabled]="disabledInputs">BIS</option>
                </select>
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center" *ngIf="UrbanAddress && !disabledInputs">
            <div class="entradas-de-texto-govco">
                <label for="primer-nombre">Cardinalidad:</label>
                <select type="text" name="username" [disabled]="disabledInputs" formControlName="card" (change)="updateAddress()">
                    <option [disabled]="disabledInputs" value="">Vacio</option>
                    <option [disabled]="disabledInputs">NORTE</option>
                    <option [disabled]="disabledInputs">SUR</option>
                    <option [disabled]="disabledInputs">ESTE</option>
                    <option [disabled]="disabledInputs">OESTE</option>
                </select>
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center" *ngIf="UrbanAddress && !disabledInputs">
            <div class="entradas-de-texto-govco">
                <label for="primer-nombre">*Número:<span class="required">(*)</span></label>
                <input type="number" name="username" placeholder="Número" [disabled]="disabledInputs" formControlName="numCard"
                       (change)="updateAddress()" />
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center" *ngIf="UrbanAddress && !disabledInputs">
            <div class="entradas-de-texto-govco">
                <label for="primer-nombre">Letra:</label>
                <select type="text" name="username" [disabled]="disabledInputs" formControlName="letraAux" (change)="updateAddress()">
                    <option [disabled]="disabledInputs" value="" hidden>Vacio/A-Z</option>
                    <option [disabled]="disabledInputs" value="">Vacio</option>
                    <option [disabled]="disabledInputs" *ngFor="let item of letters">{{item}}</option>
                </select>
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center" *ngIf="UrbanAddress && !disabledInputs">
            <div class="entradas-de-texto-govco">
                <label for="primer-nombre">*Placa:<span class="required">(*)</span></label>
                <input type="text" name="username" placeholder="Placa" [disabled]="disabledInputs" formControlName="placaAux"
                       (change)="updateAddress()" />
            </div>
        </div>
        <div class="col-sm-12 col-md-3 text-center" *ngIf="UrbanAddress && !disabledInputs">
            <div class="entradas-de-texto-govco">
                <label for="primer-nombre">Cardinalidad:</label>
                <select type="text" name="username" [disabled]="disabledInputs" formControlName="cardAux" (change)="updateAddress()">
                    <option [disabled]="disabledInputs" value="">Vacio</option>
                    <option [disabled]="disabledInputs">NORTE</option>
                    <option [disabled]="disabledInputs">SUR</option>
                    <option [disabled]="disabledInputs">ESTE</option>
                    <option [disabled]="disabledInputs">OESTE</option>
                </select>
            </div>
        </div>
        <div class="col-sm-12 col-md-3 text-center" *ngIf="UrbanAddress && !disabledInputs">
            <div class="entradas-de-texto-govco">
                <label for="primer-nombre">Adicional:</label>
                <select type="text" name="username" [disabled]="disabledInputs">
                    <option selected="selected">1</option>
                </select>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 text-center" *ngIf="UrbanAddress && !disabledInputs">
            <div class="entradas-de-texto-govco">
                <label for="primer-nombre">Complemento:</label>
                <input type="text" name="username" placeholder="Complemento" [disabled]="true" formControlName="complemento"
                       (change)="updateAddress()" />
            </div>
        </div>
        <div class="col-sm-12 col-md-12 text-start dir" *ngIf=" UrbanAddress">
            <div class="col-sm-12 col-md-7  entradas-de-texto-govco">
                <label>Dirección</label>
                <input type="text" name="username" placeholder="Dirección" [disabled]="true" formControlName="address" />
            </div>
            <div class="col-sm-12 col-md-3" *ngIf="!disabledInputs">
                <button type="button" class="btn-govco fill-btn-govco" [disabled]="addressForm.invalid" (click)="confirmarDireccion()">CONFIRMAR
                    DIRECCIÓN</button>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 dir" *ngIf=" !UrbanAddress">
            <div class="col-sm-12 col-md-6 entradas-de-texto-govco">
                <label>Dirección área rural<span class="required">(*)</span></label>
                <input type="text" name="username" placeholder="Dirección área rural" [disabled]="disabledInputs" formControlName="addressManual" />
            </div>
            <div class="col-sm-12 col-md-5" *ngIf="!disabledInputs">
                <button type="button" class="btn-govco fill-btn-govco" [disabled]="addressForm.value.addressManual.length < 5?true:false"
                        (click)="confirmarDireccion()">CONFIRMAR
                    DIRECCIÓN</button>
            </div>
        </div>
    </div>
</form>