<form role="form" [formGroup]="naturalForm">
    <div class="row">

        <h3 *ngIf="!editMode">Información del solicitante</h3>

        <div *ngIf="!editMode" class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="primer-nombre">Primer Nombre<span class="required">(*)</span></label>
                <input type="text" placeholder="Primer Nombre" disabled value="{{userName1}}" />
            </div>
        </div>

        <div *ngIf="!editMode" class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="primer-nombre">Segundo Nombre<span class="required">(*)</span></label>
                <input type="text" placeholder="Segundo Nombre" [disabled]="true" value="{{userName2}}"  />
            </div>
        </div>


        <div *ngIf="!editMode" class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="primer-nombre">Primer Apellido<span class="required">(*)</span></label>
                <input type="text" placeholder="Primer Apellido" [disabled]="true" value="{{userLastName1}}" />
            </div>
        </div>

        <div *ngIf="!editMode" class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="primer-nombre">Segundo Apellido:<span class="required">(*)</span></label>
                <input type="text" placeholder="Segundo Apellido:" [disabled]="true" value="{{userLastName2}}" />
            </div>
        </div>

        <div *ngIf="!editMode" class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="primer-nombre">Nombre para mostrar<span class="required">(*)</span></label>
                <input type="text" placeholder="Nombre para mostrar" [disabled]="true" value="{{nombreParaMostrar}}" />
            </div>
        </div>

        <div *ngIf="!editMode" class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="primer-nombre">Correo electrónico<span class="required">(*)</span></label>
                <input type="text" placeholder="Correo electrónico" [disabled]="true" value="{{email}}" />
            </div>
        </div>

        <h3>Datos Básicos.</h3>

        <div class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="primer-nombre">Primer Nombre<span class="required">(*)</span></label>
                <input type="text" placeholder="Primer Nombre" formControlName="primerNombre" [disabled]="true" />
            </div>
        </div>

        <div class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="segundo-nombre">Segundo Nombre</label>
                <input type="text" id="segundo-nombre" placeholder="Segundo Nombre" formControlName="segundoNombre" [disabled]="editMode" />
            </div>
        </div>

        <div class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="primer-apellido">Primer Apellido<span class="required">(*)</span></label>
                <input type="text" id="primer-apellido" placeholder="Primer Apellido" formControlName="primerApellido" [disabled]="editMode" />
            </div>
        </div>

        <div class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="segundo-apellido">Segundo Apellido</label>
                <input type="text" id="segundo-apellido" placeholder="Segundo Apellido" formControlName="segundoApellido" [disabled]="editMode" />
            </div>
        </div>

        <div class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="tipoIdendificacionId">Tipo identificación<span class="required">(*)</span></label>
                <select class="form-select" id="tipoIdendificacionId" aria-label="Default select example" formControlName="tipoDocumento"
                        [disabled]="editMode">
                    <option *ngFor="let item of tipoIdentificacionn" [value]="item.idTipoIdentificacion">{{item.descripcion}}</option>
                </select>
            </div>
        </div>

        <div class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="N. Documento Identidad">Número de identificación<span class="required">(*)</span></label>
                <input type="text" id="N. Documento Identidad" placeholder="Número Documento Identidad" formControlName="numeroIdentificacionn"
                       [disabled]="editMode" />
            </div>
        </div>

        <div class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Teléfono fijo">Teléfono fijo</label>
                <input type="text" id="Teléfono fijo" placeholder="Teléfono fijo" formControlName="telefonoFijo" [disabled]="editMode"
                       oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="7" />
            </div>
        </div>

        <div class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Teléfono celular">Teléfono celular<span class="required">(*)</span></label>
                <input type="text" id="Teléfono celular" placeholder="Teléfono celular" formControlName="telefonoCelular" [disabled]="editMode"
                       oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="10" />
            </div>
        </div>

        <div class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Correo electrónico">Correo electrónico<span class="required">(*)</span></label>
                <input type="text" id="Correo electrónico" placeholder="Correo electrónico" formControlName="email" [disabled]="editMode" />
            </div>
        </div>

        <div class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Confirmar Correo electrónico">Confirmar Correo electrónico<span class="required">(*)</span></label>
                <input type="text" id="Confirmar Correo electrónico" placeholder="Confirmar Correo electrónico" formControlName="email"
                       [disabled]="editMode" />
            </div>
        </div>

        <legend>
            <h3>Datos Geográficos.</h3>
        </legend>

        <div class="row text-center">
            <div class="col-sm-12 col-md-12 text-center">
                <div class="entradas-de-texto-govco">
                    <label for="Nacionalidad">Nacionalidad<span class="required">(*)</span></label>
                    <select class="form-select" id="Nacionalidad" aria-label="Default select example" formControlName="nacionalidad"
                            [disabled]="editMode">
                        <option *ngFor="let pais of paiss;  let i = index" [value]="pais.idPais">
                            {{pais.nombre}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row text-center">
            <div class="col-sm-12 col-md-12 text-center">
                <div class="entradas-de-texto-govco">
                    <label for="Departamento de residencia">Departamento de residencia<span class="required">(*)</span></label>
                    <select class="form-select" id="Departamento de residencia" aria-label="Default select example"
                            formControlName="departamentoResidencia" [disabled]="editMode">
                        <option *ngFor="let departamento of departaments;  let i = index" [value]="departamento.idDepartamento">
                            {{departamento.descripcion}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row text-center">
            <div class="col-sm-12 col-md-12 text-center">
                <div class="entradas-de-texto-govco">
                    <label for="Ciudad de residencia">Ciudad de residencia<span class="required">(*)</span></label>
                    <select class="form-select" id="Departamento de residencia" aria-label="Default select example"
                            formControlName="departamentoResidencia" [disabled]="editMode">
                        <option *ngFor="let departamento of departaments;  let i = index" [value]="departamento.idDepartamento">
                            {{departamento.descripcion}}</option>
                    </select>
                </div>
            </div>
        </div>
        <h3>Dirección</h3>
        <div *ngIf="infoUser.direResi" class="col-sm-12 col-md-12 text-center dir">
            <app-address [disabledInputs]="true" [naturalForm]="infoUser.direResi"></app-address>
        </div>

        <legend>
            <h3>Datos Demográficos.</h3>
        </legend>

        <div class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Fecha de nacimiento">Fecha de nacimiento<span class="required">(*)</span></label>
                <input type="datetime-local" id="Fechadenacimiento" formControlName="fechaNacimiento" />
            </div>
        </div>

        <div class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Sexo">Sexo<span class="required">(*)</span></label>
                <select class="form-select" id="Sexo" aria-label="Default select example" formControlName="sexo">
                    <option *ngFor="let sex of sexoo" [value]="sex.idSexo">
                        {{sex.descripcionSexo}}</option>
                </select>
            </div>
        </div>

        <div class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Género">Género<span class="required">(*)</span></label>
                <select class="form-select" id="Género" aria-label="Default select example" formControlName="genero">
                    <option *ngFor="let genero of generoo" [value]="genero.orden">
                        {{genero.descripcion}}</option>
                </select>
            </div>
        </div>

        <div class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Orientación sexual">Orientación sexual<span class="required">(*)</span></label>
                <select class="form-select" id="Orientación sexual" aria-label="Default select example" formControlName="orientacionSexual">
                    <option *ngFor="let orientacion of orientacionn" [value]="orientacion.orden">
                        {{orientacion.descripcion}}</option>
                </select>
            </div>
        </div>

        <div class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Etnia">Etnia<span class="required">(*)</span></label>
                <select class="form-select" id="Etnia" aria-label="Default select example" formControlName="etnia">
                    <option *ngFor="let etnia of etniaa" [value]="etnia.idEtnia">{{etnia.nombre}}</option>
                </select>
            </div>
        </div>

        <div class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Estado Civil">Estado Civil<span class="required">(*)</span></label>
                <select class="form-select" id="Estado Civil" aria-label="Default select example" formControlName="estadoCivil">
                    <option *ngFor="let estadoCivil of estadoCivill" [value]="estadoCivil.idEstadoCivil">
                        {{estadoCivil.nombre}}</option>
                </select>
            </div>
        </div>

        <div class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Nivel Educativo">Nivel Educativo<span class="required">(*)</span></label>
                <select class="form-select" id="Nivel Educativo" aria-label="Default select example" formControlName="nivelEducativo">
                    <option *ngFor="let niveleducativo of nivelEducativoo" [value]="niveleducativo.idNivelEducativo">{{niveleducativo.nombre}}
                    </option>
                </select>
            </div>
        </div>

        <div *ngIf="editMode" id="div_enviar" class="col-12 col-md-12 pl-4 mt-4 politics">
            <div class="col-sm-12">


                <div class="entradas-de-texto-govco">
                    <input type="checkbox" name="acepta_terminos" id="acepta_terminos" (click)="aceptarPoliticas()">
                    <label for="Teléfono fijo"> He leído y acepto la
                        <a href="https://tramitesenlinea.saludcapital.gov.co/registro/tratamiento_datos" target="_blank" rel="noopener">
                            política de protección y tratamiento de datos personales.
                        </a></label>
                </div>

                <p>
                    Si requiere realizar algun cambio que no se permite en esta pantalla, haga su solicitud a través de nuestro correo institucional
                    contactenossaludcapital.gov.co
                </p>
                <p>
                    * La edición de datos, no será reflejada en las solicitudes que tiene en curso, sino que se reflejará hasta su siguiente solicitud
                </p>
            </div>
        </div>

        <div class="col-sm-12 col-md-12 text-center">
            <br />
            <button *ngIf="!editMode" mat-dialog-close (click)="edit()" type="button"
                    class="btn-govco fill-btn-govco col-3 col-md-3 pl-3">Editar</button>
            <button *ngIf="editMode" mat-dialog-close [disabled]="naturalForm.invalid || noacept" (click)="save()" type="button"
                    class="btn-govco fill-btn-govco col-3 col-md-3 pl-3">Guardar</button>
            <button *ngIf="!editMode" type="button" class="btn-govco secondaryButton col-3 col-md-3 pl-3" (click)="goBackHome()">Regresar</button>
            <button *ngIf="editMode" type="button" class="btn-govco secondaryButton col-3 col-md-3 pl-3" (click)="goBack()">Regresar</button>
        </div>


    </div>
</form>