import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-functionary-page',
  templateUrl: './functionary-page.component.html',
  styleUrls: ['./functionary-page.component.scss']
})
export class FunctionaryPageComponent implements OnInit {
  rol: string | null = '';
  authRole: boolean = false;
  rutaMigaPan = [{ name: 'Inicio', url: '/Home' }, { name: 'Funcionario', url: '/Home/functionary' }]

  constructor(public router: Router,) { }

  ngOnInit(): void {
    this.rol = localStorage.getItem('rol');
    if (this.rol == 'Funcionario' || this.rol == 'AdminFuncional' || this.rol == 'AdminTI' || 'Coordinador') {
      this.authRole = true;
    } else {
      this.router.navigate(['/Home']);
    }
  }

}
