import { Component, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";

@Component({
  selector: 'app-accesibility',
  templateUrl: './accesibility.component.html',
  styleUrls: ['./accesibility.component.scss']
})
export class AccesibilityComponent {
  fontUp = 0;
  fontDown = 0;
  colorBG: any;
  activeContrast: any = 0;

  constructor(@Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2) {
  }

  up() {
    if (this.fontUp <= 10) {
      const elementsToUpdate = this.document.querySelectorAll("h1, p, a, h3, strong, h2, label, input, button");
      elementsToUpdate.forEach((element: HTMLElement) => {
        const currentFontSize = parseFloat(window.getComputedStyle(element).fontSize);
        const newFontSize = currentFontSize + 1;
        element.style.fontSize = newFontSize + "px";
      });
      this.fontUp += 1
      this.fontDown -= 1


    }
    // this.font = this.font < 100 ? this.font + 1 : this.font;

    // Actualiza el tamaño de fuente para h1, p y a

  }

  down() {
    if (this.fontDown <= 5) {
      const elementsToUpdate = this.document.querySelectorAll("h1, p, a, h3, strong, h2, label, input, button");
      elementsToUpdate.forEach((element: HTMLElement) => {
        const currentFontSize = parseFloat(window.getComputedStyle(element).fontSize);
        const newFontSize = currentFontSize - 1;
        element.style.fontSize = newFontSize + "px";
      });
      this.fontDown += 1
      this.fontUp -= 1


    }
  }

  moveContrast() {
    if (this.activeContrast == 0) {
      this.activeContrast = true;
      setTimeout(() => {
        this.renderer.addClass(this.document.body, 'body-cs');
      }, 200);
    } else {
      this.activeContrast = false;
      setTimeout(() => {
        this.renderer.removeClass(this.document.body, 'body-cs');
      }, 200);
    }
  }

}
