<app-header></app-header>
<app-quick-acces></app-quick-acces>
<app-breadcrumb [ruta]="rutaMigaPan"></app-breadcrumb>

<div class="container entradas-de-texto-govco">
    <app-accesibility style="position: absolute; z-index: 2;"></app-accesibility>
    <div class="title">
        <h1 class="h1-tipografia-govco title-1">{{titulo}}</h1>
    </div>
    <div class="row col-sm-12 col-md-12 mt-3">
        <form [formGroup]="formulario" (ngSubmit)="previsualizar()">
            <div class="d-flex justify-content-start mt-4">
                <div class="col-md-5">
                    <label class="mt-1 me-3">Código del trámite</label>
                    <input type="text" class="form-control" formControlName="CodigoTramite">
                </div>
            </div>
            <div class="d-flex justify-content-start">
                <span *ngIf="codigoTramiteObligatorio" class="text-danger">
                    {{ mensaje }}
                </span>
            </div>
            <div class=" col-md-5">
                <label class="mt-1 me-3">Nombre</label>
                <input type="text" class="form-control" formControlName="Nombre">
            </div>
            <div class="d-flex justify-content-start">
                <span *ngIf="nombreObligatorio" class="text-danger">
                    {{ mensaje }}
                </span>
            </div>
            <div class="d-flex justify-content-start mt-4">
                <div class="d-flex col-md-5 mt-2 me-5">
                    <label class="azul-titulos fw-bold">Roles disponibles</label>
                </div>
                <div class="d-flex col-md-5 mt-2 me-5">
                    <label class="azul-titulos fw-bold">Roles seleccionados</label>
                </div>
            </div>
            <div class="d-flex justify-content-start" style="gap: 30px;">
                <div class="card col-md-4 mt-3 scroll-listas-2">
                    <ul class="list-group scroll" style="height: 300px;">
                        <li class="list-group-item cursor" *ngFor="let item of list_roles_origen" value="{{ item.rolId }}"
                            (click)="seleccionRolOrigen(item.rolId)"
                            [ngClass]="{'active': indiceRolOrigen === item.rolId, 'deshabilitar': correcto == true}">
                            {{ item.rolName }}
                        </li>
                    </ul>
                </div>
                <div class="mt-3 align-self-center flex-column">
                    <div class="mb-3">
                        <a class="text-primary cursor" (click)="agregarRol()"><i class="govco-arrow-right"></i></a>
                    </div>
                    <div class="mb-3">
                        <a class="text-primary cursor" (click)="quitarRol()"><i class="govco-arrow-left"></i></a>
                    </div>
                </div>
                <div class="card col-md-4 mt-3  scroll-listas-2">
                    <ul class="list-group">
                        <li class="list-group-item cursor" *ngFor="let item of list_roles_destino" value="{{ item.rolId }}"
                            (click)="seleccionRolDestino(item.rolId)"
                            [ngClass]="{'active': indiceRolDestino === item.rolId, 'deshabilitar': correcto == true}">
                            {{ item.rolName }}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="d-flex justify-content-start mt-4">
                <button class="btn btn-primary" [disabled]="validation">PREVISUALIZAR</button>
            </div>
        </form>
        <div class="table-responsive mt-3" *ngIf="correcto == true">
            <div class="mb-5">
                <p class="azul-titulos fw-bold">Información de la funcionalidad a {{ datoTabla }}</p>
            </div>
            <table class="table">
                <thead class="fondo-gris">
                    <tr class="text-center">
                        <th scope="col">Código tramite</th>
                        <th scope="col">Nombre funcionalidad</th>
                        <th scope="col">Roles</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-center">
                        <td>{{ tablaPrevisualizar.codTramite }}</td>
                        <td>{{ tablaPrevisualizar.nameTramite }}</td>
                        <td class="col-3">{{ tablaPrevisualizar.rolName }}</td>
                    </tr>
                </tbody>
            </table>
            <div class="d-flex justify-content-end mt-4 mb-3">
                <div class="me-2">
                    <button class="btn btn-primary" (click)="guardar()">GUARDAR</button>
                </div>
                <div class="ms-2 me-2">
                    <button class="btn btn-secondary" (click)="cancelar()">CANCELAR</button>
                </div>
                <div class="ms-2">
                    <button class="btn btn-outline-primary" (click)="editar()">EDITAR</button>
                </div>
            </div>
        </div>
    </div>
    <div class="prueba2 col-sm-12 col-md-12 text-end">
        <button (click)="router.navigate(['/Home/Administracion']);">Regresar</button>
    </div>
</div>
<app-go-back-up class="up"></app-go-back-up>
<app-footer></app-footer>