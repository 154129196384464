<div class="content-example-barra">
  <div class="barra-accesibilidad-govco">
    <button id="botoncontraste" class="icon-contraste" (click)="moveContrast()">
      <span id="titlecontraste">Contraste</span>
    </button>
    <button id="botondisminuir" class="icon-reducir" (click)="down()">
      <span id="titledisminuir">Reducir letra</span>
    </button>
    <button id="botonaumentar" class="icon-aumentar" (click)="up()">
      <span id="titleaumentar">Aumentar letra</span>
    </button>
  </div>
</div>