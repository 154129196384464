<app-header></app-header>
<app-quick-acces></app-quick-acces>
<app-breadcrumb [ruta]="rutaMigaPan"></app-breadcrumb>
<div class="container" *ngIf="authRole">
    <app-accesibility style="position: absolute; z-index: 2;"></app-accesibility>
    <div class="title">
        <h1 class="h1-tipografia-govco title-1">Administración</h1>
    </div>
    <div class="row col-sm-12 col-md-12">

        <div class="col-sm-12 col-md-4">
            <div class="card">
                <div>
                    <h3 class="h3-tipografia-govco title-3">Administración de trámites</h3>
                    <h3 class="govco-angle-right"></h3>
                </div>
                <hr>
                <ul class="entradas-de-texto-govco">
                    <li class="descripcion-emergente-govco">
                        <span class="govco-angle-right"></span>
                        <a [routerLink]="['/Home/RegsitrarTramite']">
                            Registrar información del trámite
                        </a>
                        <div>
                            <span class="tiptext" style="height: auto;">Permite ingresar la información de trámites</span>
                        </div>
                    </li>

                    <li class="descripcion-emergente-govco">
                        <span class="govco-angle-right"></span>
                        <a [routerLink]="['/Home/CatalogoTramites']">
                            Catálogo de trámites
                        </a>
                        <div>
                            <span class="tiptext" style="height: auto; ">Visualice el resumen de la información de trámites</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-sm-12 col-md-4">
            <div class="card ">
                <div>
                    <h3 class="h3-tipografia-govco title-3">Gestión de contenido</h3>
                    <h3><span class="govco-angle-right"></span></h3>
                </div>
                <hr>
                <ul class="entradas-de-texto-govco">
                    <li>
                        <span class="govco-angle-right"></span>
                        <a [routerLink]="['/Home/GestionContenido', 'EUX']">
                            Encuesta de experiencia de la aplicación
                        </a>

                    </li>
                    <li>
                        <span class="govco-angle-right"></span>
                        <a [routerLink]="['/Home/GestionContenido/', 'EST']">
                            Encuesta de satisfacción de trámites
                        </a>

                    </li>
                    <li>
                        <span class="govco-angle-right"></span>
                        <a [routerLink]="['/Home/CatalogoEncuestas']">
                            Catálogo de encuestas
                        </a>

                    </li>
                </ul>
            </div>
        </div>
        <div class="col-sm-12 col-md-4">
            <div class="card">
                <div>
                    <h3 class="h3-tipografia-govco title-3">Reportes</h3>
                    <h3><span class="govco-angle-right"></span></h3>
                </div>
                <hr>
                <ul class="entradas-de-texto-govco">
                    <li class="descripcion-emergente-govco">
                        <span class="govco-angle-right"></span>
                        <a [routerLink]="['/Home/Reportes/', 'Tramites']">
                            Información de trámites
                        </a>
                        <div>
                            <span class="tiptext" style="height: auto; ">Permite realizar la consulta de información de trámites, incluyendo la hoja
                                de
                                ruta</span>
                        </div>

                    </li>
                    <li class="descripcion-emergente-govco">
                        <span class="govco-angle-right"></span>
                        <a [routerLink]="['/Home/Reportes/', 'Encuestas']">
                            Información de encuestas
                        </a>
                        <div>
                            <span class="tiptext" style="height: auto; ">Permite realizar la consulta de información de encuestas de experiencia de la
                                aplicación y encuestas de satisfacción de trámites</span>
                        </div>

                    </li>
                    <li class="descripcion-emergente-govco">
                        <span class="govco-angle-right"></span>
                        <a [routerLink]="['/Home/registroAcciones']">
                            Consultar registro de acciones
                        </a>
                    </li>
                </ul>
            </div>

        </div>

        <div class="col-sm-12 col-md-4">
            <div class="card">
                <div>
                    <h3 class="h3-tipografia-govco title-3">Administración de funcionarios</h3>
                    <h3 class="govco-angle-right"></h3>
                </div>
                <hr>
                <ul class="entradas-de-texto-govco">
                    <li class="descripcion-emergente-govco">
                        <span class="govco-angle-right"></span>
                        <a [routerLink]="['/Home/Administracion/usuarios']">
                            Consultar funcionario
                        </a>
                    </li>

                    <li class="descripcion-emergente-govco">
                        <span class="govco-angle-right"></span>
                        <a [routerLink]="['/Home/Administracion/cons-func-rol-usu']">
                            Consultar roles asignados a un usuario
                        </a>
                    </li>

                    <li class="descripcion-emergente-govco">
                        <span class="govco-angle-right"></span>
                        <a [routerLink]="['/Home/Administracion/asig-func-rol-usu']">
                            Asignación de funcionalidades y roles a usuarios
                        </a>
                    </li>
                </ul>
            </div>

        </div>
        <div class="col-sm-12 col-md-4">
            <div class="card">
                <div>
                    <h3 class="h3-tipografia-govco title-3">Administración de roles</h3>
                    <h3><span class="govco-angle-right"></span></h3>
                </div>
                <hr>
                <ul class="entradas-de-texto-govco">
                    <li>
                        <span class="govco-angle-right"></span>
                        <a [routerLink]="['/Home/Administracion/roles']">
                            Consultar, editar y crear roles
                        </a>

                    </li>
                    <li>
                        <span class="govco-angle-right"></span>
                        <a [routerLink]="['/Home/Administracion/asig-rol-usu']">
                            Asignación masiva de usuarios a roles
                        </a>

                    </li>
                </ul>
            </div>
        </div>
        <div class="col-sm-12 col-md-4">
            <div class="card">
                <div>
                    <h3 class="h3-tipografia-govco title-3">Administrar funcionalidades</h3>
                    <h3><span class="govco-angle-right"></span></h3>
                </div>
                <hr>
                <ul class="entradas-de-texto-govco">
                    <li class="descripcion-emergente-govco">
                        <span class="govco-angle-right"></span>
                        <a [routerLink]="['/Home/Administracion/funcionalidades']">
                            Consultar y editar funcionalidades
                        </a>
                    </li>
                    <!-- <li class="descripcion-emergente-govco">
                        <span class="govco-angle-right"></span>
                        <a [routerLink]="['/Home/Administracion/crear-funcionalidad']">
                            Crear nueva funcionalidad
                        </a>
                    </li> -->
                </ul>
            </div>
        </div>
    </div>

</div>
<app-go-back-up class="up"></app-go-back-up>
<div class="container" *ngIf="!authRole">
    <div class="title">
        <h1 class="h1-tipografia-govco title-1">No Permitido</h1>
    </div>
</div>
<app-footer></app-footer>