import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'src/app/Services/Authentication/auth.service';
import { ApiService } from 'src/app/Services/api/api.service';
import { UsuarioRolesService } from 'src/app/Services/roles-usuarios-funcionalidades/usuarios-roles.service';
import {UsuariosService} from "../../../Services/roles-usuarios-funcionalidades/usuarios.service";



@Component({
  selector: 'app-procedure',
  templateUrl: './procedure.component.html',
  styleUrls: ['./procedure.component.scss']
})
export class ProcedureComponent implements OnInit {
  @Output() $login: EventEmitter<any> = new EventEmitter<any>();
  @Input() tramite: any;
  @Input() indice: any;

  dataTramite = {
    title: '',
    image: '',
    descripcion: '',
    urlManual: '',
    urlRequisitos: '',
    duracionDias: '',
    tipoTramite: '',
    tieneCosto: false,
    costo: '',
    tipoDuracion: '',
    redirectUrl: '',
    id: '',
    idTramite: '',

  }
  id: string;
  sesionInit: boolean = false;
  sesionData: any;
  rolesAsigandos: any;

  constructor(public api: ApiService, private msaService: MsalService, public usuarioRolService: UsuarioRolesService, private apiAuth: AuthService,
              private usuarioRolesService: UsuarioRolesService,private userService: UsuariosService) { }

  ngOnInit(): void {
    this.sesionInit = false
    if (this.tramite) {
      this.dataTramite = this.tramite
      this.dataTramite = {
        title: this.tramite.name || '',
        image: this.tramite.imageUrl || '',
        descripcion: this.tramite.description || '',
        urlManual: this.tramite.manualLink || '',
        urlRequisitos: this.tramite.requirementsUrl || '',
        duracionDias: this.tramite.durationInDays || '',
        tipoTramite: this.tramite.categoryName || '',
        tieneCosto: this.tramite.hasCost,
        costo: this.tramite.cost,
        tipoDuracion: this.tramite.durationTypeId,
        redirectUrl: this.tramite.redirectUrl,
        id: this.tramite.id,
        idTramite: this.tramite.idTramite

      }
    }
    this.validateAuth()
  }

  consultarRolesAsignadosPorAdmin() {
    let id = localStorage.getItem('clientId')
    if (id != null && id != "") {
      this.userService.traerUsuariosAutenticacion("codigo",id).subscribe(res => {
        this.rolesAsigandos = res.data
      })
    }
  }

  addRolVutAramite(idTramite: any) {
    let list_usu_roles = []
    list_usu_roles.push({
      id: "0",
      usersId: localStorage.getItem('clientId'),
      rolesId: "447dacb5-eeb9-48f3-a204-05b88992db7c",
      tramitesMainId: idTramite,
      roles: undefined,
      tramites: undefined,
      fechaCreacion: undefined
    })
    this.usuarioRolesService.guardarUsuarioRoles(list_usu_roles).subscribe((resp) => {
    })
  }

  registrarSolicittud(dataTramite: any) {
    const id = localStorage.getItem('clientId');
    const authData = JSON.parse(localStorage.getItem(`${id}-b2c_1_iniciosesionconregistro.8c611a0d-cdee-4ab8-8c13-99a223fc4f35-saludcapitalb2c.b2clogin.com-`));
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const rolesVu = this.rolesAsigandos;

    if (!dataTramite.idTramite) {
      return;
    }

    let rolesfiltered=this.rolesAsigandos.filter(rol => rol.idAplicativo.toLowerCase() === dataTramite.idTramite.toLowerCase());


    if (rolesfiltered.length > 0) {
      currentUser.roles = [
        {
          codigoUsuario: rolesfiltered[0].idUsuario,
          email: localStorage.getItem('email'),
          rol: rolesfiltered[0].nombreRol,
          codigoRol: rolesfiltered[0].idRol,
          descripcion: rolesfiltered[0].nombreRol,
          value: rolesfiltered[0].nombreRol,
          nombreCompleto: localStorage.getItem('userName')
        }
      ]
    } else {
      this.addRolVutAramite(dataTramite.idTramite.toLowerCase())
      currentUser.roles = [
        {
          codigoUsuario: rolesVu[0].idUsuario,
          email: localStorage.getItem('email'),
          rol: "Ciudadano",
          codigoRol: "58EDA51F-7E19-47C4-947F-F359BD1FC732",
          descripcion: "Ciudadano",
          value: "Ciudadano",
          nombreCompleto: localStorage.getItem('userName')
        }
      ]
    }

    localStorage.setItem('currentUser', JSON.stringify(currentUser));



    const url = dataTramite.redirectUrl;
    const ventana = window.open(url);
let contador =0;
    const intervalo = setInterval(() => {
      console.log('Verificando cambios...');
      const flujob2c = JSON.parse(localStorage.getItem('flujob2c'));


      const datos = {
        oid: localStorage.getItem('clientId'),
        currentUser: JSON.stringify(currentUser),
        authoritySession: JSON.stringify(authData),
        idToken: flujob2c.idToken,
        flujo: flujob2c,
        rolesVu: JSON.stringify(rolesVu)
      };


      ventana.postMessage(datos, url);
      console.log(localStorage.getItem('clientId'));

      if (localStorage.getItem(localStorage.getItem('clientId')) === 'true') {
        localStorage.removeItem(localStorage.getItem('clientId'));
        clearInterval(intervalo);
        console.log('Cambio detectado, la función ha terminado.');
      }
      if(contador==20)
      {
        localStorage.removeItem(localStorage.getItem('clientId'));
        clearInterval(intervalo);
        console.log('No ha respondido la solicitud.');
      }
      contador++;
    }, 2000);
  }

  validateAuth() {
    this.id = localStorage.getItem('clientId');
    this.consultarRolesAsignadosPorAdmin()
    if (this.id != null) {
      if (this.id != '') {
        this.sesionInit = true;
      }
    }
  }


  logIn() {
    this.$login.emit(true)
  }
}
