import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api/api.service';
import { AlertModalInformationComponent } from 'src/app/Shared/alert-modal-information/alert-modal-information.component';
import { ConfirmationModalComponent } from 'src/app/Shared/confirmation-modal/confirmation-modal.component';
import { EditProcedureModalComponent } from './edit-procedure-modal/edit-procedure-modal.component';
@Component({
  selector: 'app-procedure-catalog',
  templateUrl: './procedure-catalog.component.html',
  styleUrls: ['./procedure-catalog.component.scss']
})
export class ProcedureCatalogComponent implements OnInit {
  ruta = [{ name: 'Inicio', url: '/Home' }, { name: 'Administración', url: '/Home/Administracion' }, { name: 'Catálogo de trámites', url: '/Home/CatalogoTramites' }]

  tramites: any = [{}]
  authRole: boolean = false;
  adminRole: boolean = false;
  rol: string | null = '';
  page: number = 1;
  itemsByPage: number = 5;
  filtro: string = '';
  busqueda: string = '';
  filter: any;
  tramites2: any = [{}]
  carga: boolean = false;
  a: number = 5;
  constructor(public dialog: MatDialog, public router: Router, public api: ApiService) { }

  ngOnInit(): void {
    this.rol = localStorage.getItem('rol');
    if (this.rol == 'Funcionario') {
      this.authRole = true;
    } else if (this.rol == 'AdminFuncional' || this.rol == 'AdminTI') {
      this.authRole = true;
      this.adminRole = true;

    } else {
      this.router.navigate(['/Home']);
    }


    this.obtenerTramitesBandeja()
  }

  popUpConfirmacion(data: any): void {
    this.dialog.open(ConfirmationModalComponent, {
      data: { type: 'warning', title: 'Eliminar registro.', text: '¿Esta seguro que quiere eliminar el tramite de ' + data.name + ' ?', acept: "" },
      height: 'auto',
      width: '700px',
    }).afterClosed().subscribe(result => {
      if (result) {
        this.borrarTramite(data.id)
      }
    });
  }

  clearFilter(e: any) {
    this.filter = false;
  }

  consultar() {
    const { tramites, filtro, busqueda } = this;
    let expresion = new RegExp(`${this.busqueda}.*`, "i");
    switch (filtro) {
      case '2': {
        this.tramites2 = tramites.filter(({ name }) => expresion.test(name));
        break;
      }
      case '3': {
        this.tramites2 = tramites.filter(({ durationInDays }) => expresion.test(durationInDays));
        break;
      }
      case '4': {
        if (['si', 'Si', 'SI'].includes(busqueda)) {
          this.tramites2 = tramites.filter(({ hasCost }) => hasCost === true);
        } else if (['no', 'No', 'NO'].includes(busqueda)) {
          this.tramites2 = tramites.filter(({ hasCost }) => hasCost === false);
        }
        break;
      }
      case '5': {
        this.tramites2 = tramites.filter(({ requirementsUrl }) => expresion.test(requirementsUrl));
        break;
      }
      case '6': {
        if (busqueda === 'Persona Natural') {
          this.tramites2 = tramites.filter(({ naturalPersonEnabled }) => naturalPersonEnabled === true);
        } else if (busqueda === 'Persona Juridica') {
          this.tramites2 = tramites.filter(({ legalPersonEnabled }) => legalPersonEnabled === true);
        } else if (busqueda === 'Persona Natural/Persona Juridica') {
          this.tramites2 = tramites.filter(({ legalPersonEnabled, naturalPersonEnabled }) => legalPersonEnabled === true && naturalPersonEnabled === true);
        }
        break;
      }
      case '7': {
        this.tramites2 = tramites.filter(({ redirectUrl }) => expresion.test(redirectUrl));
        break;
      }
      default: {
        this.tramites2 = tramites;
      }
    }

    if (this.tramites2.length === 0) {
      this.openDialogFail();
    }

    this.page = 1;
  }

  obtenerTramitesBandeja() {
    let data = [
      {
        name: "string",
        defaultLabel: true,
        labelId: 0
      }
    ]

    this.api.getPaperWorkProcedure(data).subscribe(resp => {
      this.tramites = resp.items
      this.tramites2 = resp.items
    })
  }

  openDialogFail(): void {
    let dialogRef = this.dialog.open(AlertModalInformationComponent, {
      data: { type: 'fail', title: 'Lo sentimos', text: 'No se encontraron resultados.' },
      // height: '400px',
      width: '440px',
    });
    setTimeout(() => {
      dialogRef.close()
    }, 1000);
  }

  editarTramite(data: any): void {
    this.dialog.open(EditProcedureModalComponent, {
      data: { data },
      height: '80%',
      width: '80%',
    });
  }

  borrarTramite(id: any) {
    this.api.eliminarTramite(id).subscribe(resp => {
      if (resp.status == 200) {
        this.obtenerTramitesBandeja()
      }

    })
  }

  estadoCarga($event: any) {
    setTimeout(() => {
      this.carga = true
    }, 2000)
  }

  pageChanged(e: any) {
    this.page = e
    this.a = e * this.itemsByPage
    if (this.a > this.tramites2.length) {
      this.a = this.tramites2.length
    }
  }
}
