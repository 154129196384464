import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api/api.service';
import { AlertModalInformationComponent } from 'src/app/Shared/alert-modal-information/alert-modal-information.component';
import { ReassignOfficialRequestComponent } from '../../official-inbox/reassign-official-request/reassign-official-request.component';
import { ReassignmentRequestComponent } from '../../official-inbox/reassignment-request/reassignment-request.component';
import { FuncionalidadesService } from 'src/app/Services/roles-usuarios-funcionalidades/funcionalidades.service';
import { Funcionalidades } from 'src/app/Models/funcionalidades.model';
import Swal from "sweetalert2";

@Component({
  selector: 'app-citizen-tray',
  templateUrl: './citizen-tray.component.html',
  styleUrls: ['./citizen-tray.component.scss']
})
export class CitizenTrayComponent implements OnInit {


  ruta = [{ name: 'Inicio', url: '/Home' }, { name: 'Bandeja de tramites', url: '/Home/BandejaDeCiudadano' }]

  tramitesAsignados = [
    {
      idSolicitud: 19956,
      nombreTramite: "Licencias Profesionales de la Salud",
      tipoTramite: "Licencias Profesionales de la Salud",
      solicitante: "LUZ  DARY SOTO VASQUEZ",
      tipoSolicitante: "P Natural",
      numeroIdentificacion: 43064348,
      fechaRegistro: "2023-02-17T00:00:00",
      fechaAsignacion: null,
      estadoSolicitud: "En curso"
    },
    {
      idSolicitud: 19958,
      nombreTramite: "Licencias Profesionales de la Salud",
      tipoTramite: "Licencias Profesionales de la Salud",
      solicitante: "JUAN DAVID BASQUEZ",
      tipoSolicitante: "P Natural",
      numeroIdentificacion: 111111,
      fechaRegistro: "2023-02-14T00:00:00",
      fechaAsignacion: null,
      estadoSolicitud: "En Pausa"
    },
    {
      idSolicitud: 19959,
      nombreTramite: "Licencias Profesionales de la Salud",
      tipoTramite: "Licencias Profesionales de la Salud",
      solicitante: "HEIDY REYES DIAZ",
      tipoSolicitante: "P Juridica",
      numeroIdentificacion: 2222222,
      fechaRegistro: "2023-02-14T00:00:00",
      fechaAsignacion: null,
      estadoSolicitud: "Cédula de ciudadanía"
    },
    {
      idSolicitud: 19960,
      nombreTramite: "Licencias Profesionales de la Salud",
      tipoTramite: "Licencias Profesionales de la Salud",
      solicitante: "LUZ  DARY SOTO VASQUEZ",
      tipoSolicitante: "Cédula de ciudadanía",
      numeroIdentificacion: 43064348,
      fechaRegistro: "2023-02-14T00:00:00",
      fechaAsignacion: null,
      estadoSolicitud: "Cédula de ciudadanía"
    },
    {
      idSolicitud: 19961,
      nombreTramite: "Licencias Profesionales de la Salud",
      tipoTramite: "Licencias Profesionales de la Salud",
      solicitante: "LUZ DARY SOTO VASQUEZ",
      tipoSolicitante: "Cédula de ciudadanía",
      numeroIdentificacion: 43064348,
      fechaRegistro: "2022-04-27T00:00:00",
      fechaAsignacion: null,
      estadoSolicitud: "Cédula de ciudadanía"
    },
    {
      idSolicitud: 19962,
      nombreTramite: "Licencias Profesionales de la Salud",
      tipoTramite: "Licencias Profesionales de la Salud",
      solicitante: "LUZ  DARY SOTO VASQUEZ",
      tipoSolicitante: "Cédula de ciudadanía",
      numeroIdentificacion: 43064348,
      fechaRegistro: "2022-04-27T00:00:00",
      fechaAsignacion: null,
      estadoSolicitud: "Cédula de ciudadanía"
    }
  ]

  tramitesPorAsignar = [
    {
      idSolicitud: 19956,
      nombreTramite: "Licencias Profesionales de la Salud",
      tipoTramite: "Licencias Profesionales de la Salud",
      solicitante: "LUZ  DARY SOTO VASQUEZ",
      tipoSolicitante: "Cédula de ciudadanía",
      numeroIdentificacion: 43064348,
      fechaRegistro: "2022-04-27T00:00:00",
      fechaAsignacion: null,
      estadoSolicitud: "Cédula de ciudadanía"
    },
    {
      idSolicitud: 19958,
      nombreTramite: "Licencias Profesionales de la Salud",
      tipoTramite: "Licencias Profesionales de la Salud",
      solicitante: "LUZ  DARY SOTO VASQUEZ",
      tipoSolicitante: "Cédula de ciudadanía",
      numeroIdentificacion: 43064348,
      fechaRegistro: "2022-04-27T00:00:00",
      fechaAsignacion: null,
      estadoSolicitud: "Cédula de ciudadanía"
    },
    {
      idSolicitud: 19959,
      nombreTramite: "Licencias Profesionales de la Salud",
      tipoTramite: "Licencias Profesionales de la Salud",
      solicitante: "LUZ  DARY SOTO VASQUEZ",
      tipoSolicitante: "Cédula de ciudadanía",
      numeroIdentificacion: 43064348,
      fechaRegistro: "2022-04-27T00:00:00",
      fechaAsignacion: null,
      estadoSolicitud: "Cédula de ciudadanía"
    },
    {
      idSolicitud: 19960,
      nombreTramite: "Licencias Profesionales de la Salud",
      tipoTramite: "Licencias Profesionales de la Salud",
      solicitante: "LUZ  DARY SOTO VASQUEZ",
      tipoSolicitante: "Cédula de ciudadanía",
      numeroIdentificacion: 43064348,
      fechaRegistro: "2022-04-27T00:00:00",
      fechaAsignacion: null,
      estadoSolicitud: "Cédula de ciudadanía"
    },
    {
      idSolicitud: 19961,
      nombreTramite: "Licencias Profesionales de la Salud",
      tipoTramite: "Licencias Profesionales de la Salud",
      solicitante: "LUZ  DARY SOTO VASQUEZ",
      tipoSolicitante: "Cédula de ciudadanía",
      numeroIdentificacion: 43064348,
      fechaRegistro: "2022-04-27T00:00:00",
      fechaAsignacion: null,
      estadoSolicitud: "Cédula de ciudadanía"
    },
    {
      idSolicitud: 19962,
      nombreTramite: "Licencias Profesionales de la Salud",
      tipoTramite: "Licencias Profesionales de la Salud",
      solicitante: "LUZ  DARY SOTO VASQUEZ",
      tipoSolicitante: "Cédula de ciudadanía",
      numeroIdentificacion: 43064348,
      fechaRegistro: "2022-04-27T00:00:00",
      fechaAsignacion: null,
      estadoSolicitud: "Cédula de ciudadanía"
    }
  ]

  filterBy = ['ID solicitud', 'Tipo de trámite', 'Solicitante', 'Tipo de solicitante', 'No. Doc de Identidad', 'Fecha de registro (entrada)', 'Estado de la solicitud']

  allProcedures: any[] = [];
  allProcedures2: any[] = [];

  filter: any;
  page: number = 1;
  itemsByPage: number = 5;
  adminRole: boolean = false;
  rol: string | null = '';
  authRole: boolean = false;
  busqueda: string = '';
  filtro: string = '0';
  asigned: boolean = false;
  reasigned: boolean = false;
  porAsignar: any[] = [];
  procedures: any[] = [];
  tramitesList: any = [{}];
  tramiteId: string = ""
  fechaFin: any = ''
  fechaInicio: any = ''
  totalItems: any;
  resultado: boolean = false;
  tipoPersona: string;
  tipofiltro = '0'
  valueFiltro: ''
  funcionalidades: Funcionalidades[] = [];

  fechas: boolean = false;
  constructor(public dialog: MatDialog, private apiservices: ApiService, public router: Router, private funcionalidadesService: FuncionalidadesService) {

  }

  ngOnInit(): void {
    this.filter = true
    console.log(this.allProcedures)
    this.rol = localStorage.getItem('rol');
    this.tipoPersona = localStorage.getItem('tipoPersona')

    if (this.rol == 'Funcionario' || this.rol == 'Coordinador' || this.rol == 'Ciudadano') {
      this.authRole = true;
      this.asigned = true;

    } else if (this.rol == 'AdminFuncional' || this.rol == 'AdminTI') {
      this.authRole = true;
      this.adminRole = true;
      this.reasigned = true;

    } else {
      this.router.navigate(['/Home']);
    }
    this.getAll()
    this.getListaTramites()
    // this.consultarInformacionTramites()
    this.cargarFuncionalidades()
  }

  clearFilter(e: any) {
    this.filter = false;
    console.log(e)
    console.log(this.filtro)
  }


  getListaTramites() {
    this.apiservices.getListaTramites().subscribe(resp => {
      this.tramitesList = resp
      this.tramitesList = this.tramitesList.sort((a, b) => {
        if (a.description < b.description) return -1;
        if (a.description > b.description) return 1;
        return 0;
      })
    })
  }

  tramitefechas() {
    switch (this.tramiteId)
    {case '45f51ebb-f91a-4c82-a475-84d2a296cdd0':
      this.fechas=true;
      break;
      case '78aa6ad1-56ac-46ad-ac10-518564298a92':
        this.fechas=true;
        break;
      case '379846f5-372d-4d6f-bc24-a5275c0a7ee5':
        this.fechas=true;
        break;
      case '16ea6827-5a89-4af0-b9e3-fcf0bf28824b':
        this.fechas=true;
        break;

      default:this.fechas=false;
    }
  }

  cargarFuncionalidades(): void {
    this.funcionalidadesService.traerFuncionalidades()
      .subscribe(resp => {
        this.funcionalidades = resp;
      });
  }

  consultarSolicitudFiltro() {

    if(this.fechas)
    {
      if(this.fechaInicio=='' || this.fechaFin=='')
      {
        Swal.fire({
          title: 'Operación no exitosa',
          text: "Debe de ingresar un rango de fechas para este tramite",
          iconHtml: '<div class="material-icons">close</div>',
          customClass: {
            icon: 'no-bordes',
            title: 'text-danger'
          },
          allowOutsideClick: false,
          showCloseButton: true,
          showConfirmButton: true,
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#0d6efd'
        })
        return;
      }
    }



    let miObjeto = JSON.parse(localStorage.getItem('currentUser'));
    let documentoId = 0;
    if (this.tipoPersona == 'PN') {
      documentoId = miObjeto.datosPersonales.numeroIdentificacion.toString()
    } else if (this.tipoPersona == 'PJ') {
      documentoId = miObjeto.datosPersonales.numeroIdentificacionRL.toString()
    }

    let procedure:string ;
    let filter = documentoId.toString();
    let filterType = 'nume_identificacion';
    let tramite = '';
    const pagination = '0';

    switch (this.tramiteId) {
      case '59e9f8e4-5ce7-418c-a937-ebda267aa6bc': procedure = 'getProcedure19';
        break;
      case '2140d1fb-a4c2-4078-87d7-4232ee2cfcbb': procedure = 'getProcedure22';
        break;
      case '56de45b7-c6e3-4a88-b274-3739c3bb3085': procedure = 'getProcedure5';
        break;
      case '2e19becd-1e9d-4b83-a627-3cfa7c6cb086': procedure = 'getProcedure10';
        break;
      case '45f51ebb-f91a-4c82-a475-84d2a296cdd0': procedure = 'getProcedure6';
        filterType='idTramite';
        filter='45f51ebb-f91a-4c82-a475-84d2a296cdd0';
        break;
      case '78aa6ad1-56ac-46ad-ac10-518564298a92': procedure = 'getProcedure6';
        filterType='idTramite';
        filter='78aa6ad1-56ac-46ad-ac10-518564298a92';

        break;
      case '379846f5-372d-4d6f-bc24-a5275c0a7ee5': procedure = 'getProcedure6';
        filterType='idTramite';
        filter='379846f5-372d-4d6f-bc24-a5275c0a7ee5';
        break;
      case '47ccdaa9-73bd-4a90-b4f7-b58660a76214': procedure = 'getProcedure35';
        break;
      case '4b298d53-bc90-4475-a0b7-7ceec4117014': procedure = 'getProcedure15';
        break;
      case '5a3467da-2e72-4c4d-8e04-f64cc5b62dce': procedure = 'getProcedure3';
        break;
      case 'cf75fcfc-7508-414f-a05e-9c0cc71ea098': procedure = 'getProcedure13';
      tramite='cf75fcfc-7508-414f-a05e-9c0cc71ea098';
        break;
      case 'de618a19-01a8-41c5-835a-aec04114569d': procedure = 'getProcedure13';
        tramite='de618a19-01a8-41c5-835a-aec04114569d';
        break;
      case 'fb2205a1-b3c6-4b32-97f0-c1f3b369c550': procedure = 'getProcedure14';
        tramite='fb2205a1-b3c6-4b32-97f0-c1f3b369c550';
        break;
      case '993f615c-cbae-4143-adb8-95fd7a33c026': procedure = 'getProcedure14';
        tramite='993f615c-cbae-4143-adb8-95fd7a33c026';
        break;

      default :
          Swal.fire({
            title: 'Operación no exitosa',
            text: "Ese tramite aun no se encuentra disponible",
            iconHtml: '<div class="material-icons">close</div>',
            customClass: {
              icon: 'no-bordes',
              title: 'text-danger'
            },
            allowOutsideClick: false,
            showCloseButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#0d6efd'
          })
        this.allProcedures2=[];
        this.totalItems = 0;
        this.resultado = false;
            return
      break;
    }



    if(procedure=='getProcedure6' || procedure=='getProcedure16' || procedure=='getProcedure17' || procedure=='getProcedure35')
    {

      let datefinal = new Date(Date.now());

      // Get year, month, and day part from the date
      let yearfinal = datefinal.toLocaleString("default", {year: "numeric"});
      let monthfinal = datefinal.toLocaleString("default", {month: "2-digit"});
      let dayfinal = datefinal.toLocaleString("default", {day: "2-digit"});
      // Generate yyyy-mm-dd date string
      let formattedDatefinal = yearfinal + "-" + monthfinal + "-" + dayfinal;

      let dateInicial = new Date(Date.now());
      dateInicial.setDate(dateInicial.getDate() - 180);

      // Get year, month, and day part from the date
      let yearInicial  = dateInicial.toLocaleString("default", {year: "numeric"});
      let monthInicial  = dateInicial.toLocaleString("default", {month: "2-digit"});
      let dayInicial  = dateInicial.toLocaleString("default", {day: "2-digit"});
      // Generate yyyy-mm-dd date string
      let formattedDateInicial  = yearInicial + "-" + monthInicial + "-" + dayInicial;

      this.apiservices.obtenerDatosFechaObligatoria(procedure, filter, filterType, pagination,this.fechaInicio,this.fechaFin,tramite).subscribe(res => {
        console.log(res)
        if (res.code === 200 && res.data.length > 0) {
          this.allProcedures2 = res.data
          this.totalItems = res.count
          this.resultado = true;

        } else if (res.code === 200 && res.data.length == 0) {
          this.modalDocumentoNoEncontrado()
        }
      })
    }
    else {
      this.apiservices.obtenerDatos(procedure, filter, filterType, pagination,tramite).subscribe(res => {
        console.log(res)
        if (res.code === 200 && res.data.length > 0) {
          this.allProcedures2 = res.data
          this.totalItems = res.count
          this.resultado = true;

        } else if (res.code === 200 && res.data.length == 0) {
          this.modalDocumentoNoEncontrado()
        }
      })
    }

  }

  // consultarInformacionTramites() {
  //   this.busqueda = ''
  //   let miObjeto = JSON.parse(localStorage.getItem('currentUser'));
  //   let fechaActual = new Date();
  //   let fechaManana = new Date(fechaActual.getTime() + 86400000);
  //   let formatoFechaManana = fechaManana.toISOString().split('T')[0];
  //   let documentoId = 0
  //   if (this.tipoPersona == 'PN') {
  //     documentoId = miObjeto.datosPersonales.numeroIdentificacion.toString()
  //   } else if (this.tipoPersona == 'PJ') {
  //     documentoId = miObjeto.datosPersonales.numeroIdentificacionRL.toString()
  //   }

  //   let data = {
  //     pageSize: 10,
  //     pageNumber: 1,
  //     reportProcess: {
  //       name: "string",
  //       initialDate: "2023-01-01",
  //       endDate: formatoFechaManana,
  //       type: "3",
  //       value: documentoId,
  //       paperworkId: '2d3567e6-5a3c-457a-b3ff-b36fb73b3131'
  //     }

  //   }
  //   this.apiservices.getTramiteReportes(data).subscribe(res => {
  //     if (res.items.length > 0) {
  //       this.allProcedures = res.items
  //       this.totalItems = res.totalRows
  //       this.resultado = true;
  //       console.log(this.allProcedures)
  //     } else {
  //       this.modalDocumentoNoEncontrado()
  //     }

  //     // this.page = 1

  //   })

  // }

  modalDocumentoNoEncontrado(): void {
    this.dialog.open(AlertModalInformationComponent, {
      data: { type: 'fail', title: 'Lo sentimos', text: 'No se encontraron resultados.' },
      width: '540px',
    });
  }

  pageChanged(e: any) {
    this.page = e
    let a = e * this.itemsByPage
    if (a > this.allProcedures.length) {
      a = this.allProcedures.length
    }
  }

  public changeColor(diaspasados, i): string {
    const targetDate = new Date(diaspasados);
    const currentDate = new Date();
    const timeDifference = Math.abs(currentDate.getTime() - targetDate.getTime());
    let daysPassed = Math.floor(timeDifference / (1000 * 3600 * 24));
    console.log(daysPassed)
    let mensajeDias = "";
    let color = document.getElementById(`color_${i}`);
    if (daysPassed <= 9) {
      mensajeDias = `Quedan ` + (9 - daysPassed) + ` días. Días transcurridos ${daysPassed}`;
      (<HTMLElement>color).style.backgroundColor = "#d1e7dd";
    } else if (daysPassed >= 10 && daysPassed <= 14) {
      mensajeDias = `¡Atención usuario! Trámite próximo a vencerse. quedan ` + (14 - daysPassed) + `. Dias transcurridos ${daysPassed}`;
      (<HTMLElement>color).style.backgroundColor = "#fff3cd";
    } else if (daysPassed >= 15 && daysPassed <= 20) {
      mensajeDias = `¡Atención usuario! Trámite próximo a vencerse. quedan ` + (20 - daysPassed) + `. Dias transcurridos ${daysPassed}`;
      (<HTMLElement>color).style.backgroundColor = "#f8d7da";
    } else {
      mensajeDias = `¡Atención usuario! El trámite se encuentra vencido. Dias transcurridos ${daysPassed}`;
      (<HTMLElement>color).style.backgroundColor = "#f8d7da";
    }
    return mensajeDias;
  }

  // consultar() {
  //   this.consultarInformacionTramites()
  // }

  consultarIDSolicitud() {
    let miObjeto = JSON.parse(localStorage.getItem('currentUser'));
    let fechaActual = new Date();
    let fechaManana = new Date(fechaActual.getTime() + 86400000);
    let formatoFechaManana = fechaManana.toISOString().split('T')[0];

    let data = {
      pageSize: 10,
      pageNumber: 1,
      reportProcess: {
        name: "string",
        initialDate: "2023-01-01",
        endDate: formatoFechaManana,
        type: "1",
        value: this.busqueda,
        paperworkId: '2d3567e6-5a3c-457a-b3ff-b36fb73b3131'
      }

    }
    /*
    this.apiservices.getTramiteReportes(data).subscribe(res => {
      if (res.items.length > 0) {
        this.allProcedures = res.items
        this.totalItems = res.totalRows
        this.resultado = true;
        console.log(this.allProcedures)
      } else {
        // this.modalDocumentoNoEncontrado()
      }

      // this.page = 1

    })


     */
    console.log(miObjeto.datosPersonales.numeroIdentificacion)
    let documentoId = 0;

    let procedure = '';
    const filter = this.busqueda;
    const filterType = 'consecutivo';
    const pagination = '0';
    let tramite = '';
    if (this.busqueda.includes("REAT")) {
      //TITULOS
      procedure = 'getProcedure19';
    }
    if (this.busqueda.includes("RECDO")) {
      //dispo
      procedure = 'getProcedure5';
    }
    if (this.busqueda.includes("REEX")) {
      //exhu
      procedure = 'getProcedure10';
    }
    if (this.busqueda.includes("REATCAP")) {
      //exhu
      procedure = 'getProcedure22';
    }
    if (this.busqueda.includes("REDFF")) {
      //exhu
      procedure = 'getProcedure6';
    }
    if (this.busqueda.includes("REPJF")) {
      //exhu
      procedure = 'getProcedure6';
    }
    if (this.busqueda.includes("REREF")) {
      //exhu
      procedure = 'getProcedure6';
    }
    if (this.busqueda.includes("RECRD")) {
      //exhu
      procedure = 'getProcedure35';
    }
    if (this.busqueda.includes("REACH")) {
      //aguas
      procedure = 'getProcedure3';
    }
    if (this.busqueda.includes("REINC")) {
      //inhumacion
      procedure = 'getProcedure13';
      tramite='de618a19-01a8-41c5-835a-aec04114569d';
    }
    if (this.busqueda.includes("RECRC")) {
      //cremacion
      procedure = 'getProcedure14';
      tramite='fb2205a1-b3c6-4b32-97f0-c1f3b369c550';
    }
    // if(this.busqueda.includes("REAT")){
    //   procedure = 'getProcedure19';
    // }
    // if(this.busqueda.includes("REAT")){
    //   procedure = 'getProcedure19';
    // }


    if(procedure=='')
    {
      Swal.fire({
        title: 'Operación no exitosa',
        text: "Ese tramite aun no se encuentra disponible",
        iconHtml: '<div class="material-icons">close</div>',
        customClass: {
          icon: 'no-bordes',
          title: 'text-danger'
        },
        allowOutsideClick: false,
        showCloseButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#0d6efd'
      })
      this.allProcedures2=[];
      this.totalItems = 0;
      this.resultado = false;
      return;
    }

    if(procedure=='getProcedure6' || procedure=='getProcedure16' || procedure=='getProcedure17' || procedure=='getProcedure35')
    {

      let datefinal = new Date(Date.now());

      // Get year, month, and day part from the date
      let yearfinal = datefinal.toLocaleString("default", {year: "numeric"});
      let monthfinal = datefinal.toLocaleString("default", {month: "2-digit"});
      let dayfinal = datefinal.toLocaleString("default", {day: "2-digit"});
      // Generate yyyy-mm-dd date string
      let formattedDatefinal = yearfinal + "-" + monthfinal + "-" + dayfinal;

      let dateInicial = new Date(Date.now());
      dateInicial.setDate(dateInicial.getDate() - 180);

      // Get year, month, and day part from the date
      let yearInicial  = dateInicial.toLocaleString("default", {year: "numeric"});
      let monthInicial  = dateInicial.toLocaleString("default", {month: "2-digit"});
      let dayInicial  = dateInicial.toLocaleString("default", {day: "2-digit"});
      // Generate yyyy-mm-dd date string
      let formattedDateInicial  = yearInicial + "-" + monthInicial + "-" + dayInicial;

      this.apiservices.obtenerDatosFechaObligatoria(procedure, filter, filterType, pagination,formattedDateInicial,formattedDatefinal,tramite).subscribe(res => {
        console.log(res)
        if (res.code === 200 && res.data.length > 0) {
          this.allProcedures2 = res.data
          this.totalItems = res.count
          this.resultado = true;

        } else if (res.code === 200 && res.data.length == 0) {
          this.modalDocumentoNoEncontrado()
        }
      })
    }
    else
    {
      this.apiservices.obtenerDatos(procedure, filter, filterType, pagination,tramite).subscribe(res => {
        console.log(res)
        if (res.code === 200 && res.data.length > 0) {
          this.allProcedures2 = res.data
          this.totalItems = res.count
          this.resultado = true;

        } else if (res.code === 200 && res.data.length == 0) {
          this.modalDocumentoNoEncontrado()
        }
      })
    }




  }

  solicitudReasignacion(data: any): void {
    this.dialog.open(ReassignmentRequestComponent, {
      data: { infoTramite: data, titulo: 'Solicitud de reasignación de trámite', readOnly: false }
    });
  }

  validarTramite(data: any): void {
    alert('Ir a validar tramite')
    console.log(data)
  }

  openViewReasiggment(): void {
    this.dialog.open(ReassignmentRequestComponent, {
      data: { infoTramite: { nombreTramite: 'ramite de ejemplo' }, titulo: 'Solicitud enviada para reasignación de trámite', readOnly: true }
    });
  }


  openViewSolicitudNegada(): void {
    this.dialog.open(ReassignmentRequestComponent, {
      data: { infoTramite: { nombreTramite: 'ramite de ejemplo' }, titulo: 'Solicitud negada para reasignación de trámite', readOnly: true }
    });
  }

  openViewNegacionSolicitud(data: any): void {
    this.dialog.open(ReassignmentRequestComponent, {
      data: {
        infoTramite: data, titulo: 'Negación de reasignación de trámite', readOnly: false, admin: true
      }
    });
  }

  solicitudReasignacionFuncionario(data: any): void {
    this.dialog.open(ReassignmentRequestComponent, {
      data: { infoTramite: data, titulo: 'Solicitud de reasignación de trámite', readOnly: true, admin: true }
    });
  }

  reasignarSolicitud(data: any): void {
    this.dialog.open(ReassignOfficialRequestComponent, {
      data: { infoTramite: data, titulo: 'Reasignar trámite', readOnly: false, admin: true }
    });
  }

  getAll() {
    this.apiservices.getAll().subscribe((resp) => {
      console.log(resp)
    });
  }



}
