<div class="container-register col-sm-12 col-md-12 text-center">
    <div style="text-align: right;"><mat-icon (click)="openDialogLogOut()">close</mat-icon></div>
    <h3>Complete su registro</h3>
    <div class="col-sm-12 col-md-12 text-start">
        <p>
            Tenga en cuenta, que para realizar nuestros trámites en línea, es obligatorio
            diligenciar previamente el REGISTRO DEL CIUDADANO (persona natural o jurídica),
            el cual servirá para la realización de trámites posteriores ante la Secretaría Distrital de Salud.
        </p>


    </div>
    <button class="col-sm-12 col-md-12" type="button" (click)="openRegisterLegalPerson()"><label>REGISTRO PERSONA JURÍDICA</label></button>
    <button class="col-sm-12 col-md-12" type="button" (click)="openRegisterNaturalPerson()"><label>REGISTRO PERSONA NATURAL</label></button>

</div>