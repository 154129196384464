import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: []
})
export class BreadcrumbComponent implements OnInit {
  @Input() ruta: any;

  locations: any = []
  ngOnInit(): void {

    this.locations = location.pathname.split('/')
  }


}
