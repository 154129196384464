import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReassignOfficialRequestComponent } from '../reassign-official-request/reassign-official-request.component';

@Component({
  selector: 'app-reassignment-request',
  templateUrl: './reassignment-request.component.html',
  styleUrls: ['./reassignment-request.component.scss']
})
export class ReassignmentRequestComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { infoTramite: any, titulo: any, readOnly: boolean, admin: boolean }, public dialog: MatDialog) { }

  ngOnInit(): void {
    console.log(this.data.infoTramite.nombreTramite)
  }

  reasignarSolicitud(data: any): void {
    console.log(data.infoTramite.idSolicitud)
    this.dialog.open(ReassignOfficialRequestComponent, {
      data: { infoTramite: data.infoTramite, readOnly: false, admin: true }
    });
  }



}
