import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Services/api/api.service';

@Component({
  selector: 'app-online-services-module',
  templateUrl: './online-services-module.component.html',
  styleUrls: ['./online-services-module.component.scss']
})
export class OnlineServicesModuleComponent implements OnInit {
  ruta = [{ name: 'Inicio', url: '/Home' }, { name: 'Servicios en línea', url: '/Home/OnlineServices/Consultas' }]
  panelOpenState = false;
  services = [
    {
      id: 'Consultas',
      title: 'Consultas en línea',
      tramites: [
        {
          name: '',
          imageUrl: '',
          urlRedirect: ''
        },
        {
          title: '',
          image: '',
          ulr: ''
        }

      ]
    },
    {
      id: 'Otros',
      title: 'Otros servicios digitales',
      tramites: [
        {
          name: '',
          imageUrl: '',
          urlRedirect: ''
        },
        {
          title: '',
          image: '',
          ulr: ''
        }
      ]
    },
    {
      id: 'Mapas',
      title: 'Mapas interactivos',
      tramites: [
        {
          name: '',
          imageUrl: '',
          urlRedirect: ''
        },
        {
          title: '',
          image: '',
          ulr: ''
        }
      ]
    },

  ]

  constructor(public api: ApiService) { }

  abrir: any;
  pathname = window.location.pathname;
  openAcordion = false


  ngOnInit(): void {
    this.abrir = this.pathname.split('/').pop();
    this.getOlineServices()
  }

  getOlineServices() {
    this.api.getOnlineServices().subscribe(resp => {
      if (resp.status == 200) {
        console.log(resp.body.onlineConsultations)
        this.services[0].tramites = []
        this.services[1].tramites = []
        this.services[2].tramites = []
        this.services[0].tramites = resp.body.onlineConsultations
        this.services[1].tramites = resp.body.otherDigitalServices
        this.services[2].tramites = resp.body.interactiveMaps
      }
    })
  }


}
