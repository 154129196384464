import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ApiService } from 'src/app/Services/api/api.service';
import { AuthService } from 'src/app/Services/Authentication/auth.service';
import { ConfirmationModalComponent } from 'src/app/Shared/confirmation-modal/confirmation-modal.component';
import { PoliciesComponent } from '../policies/policies.component';
import { RegisterPersonModalComponent } from '../register-person-modal/register-person-modal.component';
import { UsuarioRolesService } from 'src/app/Services/roles-usuarios-funcionalidades/usuarios-roles.service';
import {UsuariosService} from "../../Services/roles-usuarios-funcionalidades/usuarios.service";


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  aceptAuth: boolean = false;
  @Output() $carga: EventEmitter<any> = new EventEmitter<any>();
  auth: boolean = false;
  view: boolean = false;
  permis: any;
  logIn: any;
  id: any = '';
  sesionInit: boolean = false;
  pn: boolean = false
  pj: boolean = false
  sesionData: any = {};
  rolesUser: any;
  infoUser: any;
  temporizador: NodeJS.Timeout;


  constructor(private authService: AuthService, private router: Router, public dialog: MatDialog, public msal: MsalService, private apiservices: ApiService,
              private userService: UsuariosService) { }

  userName = localStorage.getItem('userName');
  lastNameUser = localStorage.getItem('lastName')
  roleUser = localStorage.getItem('rol')
  tipoPersona = localStorage.getItem('tipoPersona')
  nombreParaMostrar = localStorage.getItem('nombreParaMostrar')!=""?localStorage.getItem('nombreParaMostrar'):localStorage.getItem('userName').split(" ")[0]+" "+localStorage.getItem('lastName').split(" ")[0];

  ngOnInit(): void {
    // this.onLogOut()
    this.auth = false;
    this.validateAuth();
    if (this.tipoPersona == 'PN') {
      this.pn = true;
      this.apiservices.getPoliticaSeguridad(this.id).subscribe(res => {
        if (res.code == 200 && res.message === 'Politica de seguridad no encontrada') {
          this.openDialogPolicies()
          this.getUserVentanilla(this.id)
        }
      }, err => {
        console.log(err)
        alert('Error del servidor')
      });
    }
    if (this.tipoPersona == 'PJ') {
      this.pj = true;
      this.apiservices.getPoliticaSeguridad(this.id).subscribe(res => {
        if (res.code == 200 && res.message === 'Politica de seguridad no encontrada') {
          this.openDialogPolicies()
          this.getUserVentanilla(this.id)
        }
      }, err => {
        console.log(err)
        alert('Error del servidor')
      });
    }


  }

  iniciarTemporizador() {
    this.temporizador = setTimeout(() => {
      this.onLogOut();
    }, 10 * 60 * 1000); // 10 minutos en milisegundos
  }

  @HostListener('document:mousemove', ['$event'])
  reiniciarTemporizador() {
    clearTimeout(this.temporizador);
    this.iniciarTemporizador();
  }

  validateAuth() {
    this.id = localStorage.getItem('clientId');
    if (this.id != null) {
      if (this.id === '') {
        this.sesionInit = true;
      } else {
        this.iniciarTemporizador();
        this.consultarRolesAsignadosPorAdmin()
        this.getCodigoPersonaVentanilla()
        this.sesionInit = false;
        this.auth = true


      }
    }

  }


  getCodigoPersonaVentanilla() {
    this.id = localStorage.getItem('clientId');
    if (this.id != null) {
      if (this.id === '') {
        this.sesionInit = true;
      } else {

        this.sesionInit = false;
        this.auth = true
        this.$carga.emit(true)

      }
    }


  }

  openRegisterModal(): void {
    this.dialog.open(RegisterPersonModalComponent, {
      data: { infoDoc: '' },
      disableClose: true
    });
  }





  viewMenu() {
    this.view = !this.view
  }

  viwePerfil() {
    this.router.navigate(['Home/myProfileNatural']);
  }

  viwePerfil2() {
    this.router.navigate(['Home/myProfileLegal']);
  }

  changePassword() {
    this.router.navigate(['Home/functionary']);
  }



  viwePerfil3() {
    this.router.navigate(['Home/BandejaDeFuncionarios']);
  }

  viweAdmin() {
    this.router.navigate(['Home/Administracion']);
  }

  viwePerfil4() {
    this.router.navigate(['Home/functionary']);
  }

  public onLogOut(): void {
    this.msal.logoutPopup({
      mainWindowRedirectUri: "/"
    }).subscribe(data => {
      console.log(data)
    })
    localStorage.clear();

  }

  openDialogPolicies(): void {
    let dialogRef = this.dialog.open(PoliciesComponent, {
      data: { acept: this.aceptAuth },
      width: '700px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.aceptAuth = result;
      if (result) {
        // alert("acpeta politicas")
        let tiempoTranscurrido = Date.now();
        let hoy = new Date(tiempoTranscurrido);
        let data = {
          fecha: hoy.toISOString(),
          id_usuario: this.id,
          nombre: this.userName,
          apellido: this.lastNameUser,
          nume_identificacion: this.infoUser.numeroIdentificacion

        }
        this.apiservices.addPoliticaSeguridad(data).subscribe(res => {
          console.log(res)
          // this.onLogOut();
          setTimeout(() => {
            this.openDialoRegitroExitoso()
          }, 2000);
        }, err => {
          console.log(err)
        })

      } else {
        this.openDialogRechace()
      }

    });
  }

  openDialogRechace(): void {
    this.dialog.open(ConfirmationModalComponent, {
      data: { type: 'warning', title: 'Rechazo de Política de protección de datos personales .', text: 'Se ha rechazado la política de protección de datos personales, por lo tanto no puede realizar ningún trámite y será redireccionado a la página principal', acept: this.aceptAuth },
      height: 'auto',
      width: '700px',
    }).afterClosed().subscribe(result => {
      console.log(result)
      this.aceptAuth = result;
      if (result) {
        this.onLogOut();
        setTimeout(() => {
          window.location.reload()
        }, 2000)

      } else {
        this.openDialogPolicies();
      }
    });
  }

  openDialogLogOut(): void {
    this.dialog.open(ConfirmationModalComponent, {
      data: { type: 'warning', title: 'confirmar', text: '¿Seguro de que desea cerrar su sesión?.' },
      height: '400px',
      width: '540px',
    }).afterClosed().subscribe(data => {
      if (data) {
        this.onLogOut();
        setTimeout(() => {
          window.location.reload()
        }, 2000);
      }
    });
  }


  Inicio() {
    this.router.navigate(['Home']);
  }

  getUserVentanilla(idUsuario: any) {
    this.apiservices.getIdUserVentanilla(idUsuario).subscribe(res => {
      if (res.code == 200 && res.data != -1) {
        localStorage.setItem('idUser', res.data)
        this.getRoleUser(this.id)
        this.getInfoUser(res.data)
        this.consultarRolesAsignadosPorAdmin()
      }
    }, err => {
      console.log(err)
    })
  }

  getInfoUser(idUsuarioVentanilla: any) {
    this.apiservices.consultarLegalPerson(idUsuarioVentanilla).subscribe(resp => {
      console.log(resp)
      this.infoUser = resp.data
      console.log(this.infoUser)
      if (this.infoUser.razonSocial) {
        localStorage.setItem('tipoPersona', 'PJ');
        this.infoUser.personTypeId = 2
      }
      if
        (this.infoUser.razonSocial == null) {
        localStorage.setItem('tipoPersona', 'PN');
        this.infoUser.personTypeId = 1
      }
      this.sesionData.idTokenClaims["datosPersonales"] = this.infoUser;
      this.sesionData.idTokenClaims["roles"] = [this.rolesUser];
      this.sesionData.idTokenClaims["tieneCodigoVentanilla"] = true;
      this.sesionData.idTokenClaims["aceptoPoliticaSeguridad"] = true;
      this.authService.setCurrentUser(this.sesionData);

      // location.reload()

    })
  }

  getRoleUser(data: any) {
    this.apiservices.getUserRole(data).subscribe(res => {
      if (res.code == 200 && res.data.length > 0) {
        // localStorage.setItem('rol', res.data[0].rol);
        // this.rolesUser = res.data[0]
        this.getInfoUser(localStorage.getItem('idUser'))
        // location.reload()

      }
      if (res.code == 200 && res.data.length == 0) {
        localStorage.setItem('rol', 'nn');
        // location.reload()
      }
    }, err => {
      alert('Error del servidor')
      location.reload()
    })
  }

  consultarRolesAsignadosPorAdmin() {
    let id = localStorage.getItem('clientId')
    this.userService.traerUsuariosAutenticacion("codigo",id).subscribe(res => {

      console.log(res)
      let authData = JSON.parse(localStorage.getItem(id + '-b2c_1_iniciosesionconregistro.8c611a0d-cdee-4ab8-8c13-99a223fc4f35-saludcapitalb2c.b2clogin.com-'));

      res.data.forEach((ele) => {
        if (ele.idAplicativo === '61861ead-09a8-45fd-a152-e1bc2dbf0d48') {
          localStorage.setItem('rol', ele.nombreRol);
          this.rolesUser = [{
            codigoUsuario: ele.idUsuario,
            email: localStorage.getItem('email'),
            rol: ele.nombreRol,
            descripcion: ele.nombreRol,
            value: ele.nombreRol,
            nombreCompleto: localStorage.getItem('userName')

          }]
          console.log(authData)
        }
      })
      console.log(this.roleUser)
      if (this.roleUser == null || this.roleUser == 'nn') {
        this.openRegisterModal()
      }
    })
  }

  openDialoRegitroExitoso(): void {
    this.dialog.open(ConfirmationModalComponent, {
      data: { type: 'success', title: 'Registro éxitoso.', text: 'Información almacenada con éxito. Por favor, ingrese nuevamente con las credenciales registradas', acept: "" },
      height: 'auto',
      width: '700px',
    });
    setTimeout(() => {
      // dialogRef.close()
      // location.reload();
      this.onLogOut();
      // location.reload();
    }, 3000);
  }

}
