<app-header></app-header>
<app-quick-acces></app-quick-acces>
<app-breadcrumb [ruta]="rutaMigaPan"></app-breadcrumb>
<div class="container" *ngIf="authRole">
    <app-accesibility></app-accesibility>
    <div class="title">
        <h1 class="h1-tipografia-govco title-1">Encuesta</h1>
    </div>
    <h3 class="h3-tipografia-govco title-3">Información básica</h3>
    <div class="row col-sm-12 col-md-12">
        <div *ngIf="surveyType == 'ADD'" class="row col-sm-12 col-md-12 text-center">
            <div class="entradas-de-texto-govco col-sm-5 col-md-5 text-start">
                <label for="Razón Social">Seleccione la encuesta<span class="required">(*)</span></label>

            </div>
            <div role="group" class="entradas-de-texto-govco col-sm-5 col-md-7 text-start fechas">
                <select class="form-select" id="Tipo de Identificación" aria-label="Default select example" [(ngModel)]="encuesta.surveyTypeId">
                    <option value="" selected disabled>Seleccione</option>
                    <option selected value="1">Encuesta de experiencia de la aplicación</option>
                    <option selected value="2">Encuesta de satisfacción de trámites</option>
                </select>
            </div>
        </div>
        <div class="row col-sm-6 col-md-12">
            <div class="row col-sm-12 col-md-12 text-center">
                <div class="entradas-de-texto-govco col-sm-5 col-md-5 text-start">
                    <label for="Razón Social">Nombre de la encuesta<span class="required">(*)</span></label>

                </div>
                <div class="entradas-de-texto-govco col-sm-7 col-md-7 text-start">
                    <input type="text" id="Razón Social" placeholder="Nombre de la encuesta" [(ngModel)]="encuesta.name" />

                </div>

            </div>
        </div>
        <div class="row col-sm-12 col-md-12 text-center">
            <div class="entradas-de-texto-govco col-sm-5 col-md-5 text-start">
                <label for="Razón Social">Descripción<span class="required">(*)</span></label>

            </div>

            <div class="entradas-de-texto-govco col-sm-5 col-md-5 text-start">
                <textarea class="col-sm-12 col-md-12 text-start" type="text" id="Razón Social"
                          placeholder="Ingrese la descripción del trámite, como se visualiza en gov.co" cols="40" rows="5" style="resize: both;"
                          [(ngModel)]="encuesta.description">

                    </textarea>

            </div>
        </div>
    </div>
    <div class="row">
        <div class="row col-sm-6 col-md-6">
            <div class="entradas-de-texto-govco col-sm-12 col-md-12 text-start">
                <a style="color: #004884;" (click)="agregarPregunta()">

                    <span class="govco-plus-circle"></span>
                    <strong>Agregar pregunta</strong>
                </a>
            </div>
            <div *ngIf="addView" class="row col-sm-6 col-md-12">
                <div class="row col-sm-12 col-md-12 text-center">
                    <div class="entradas-de-texto-govco col-sm-5 col-md-5 text-start">
                        <label for="Razón Social">Título<span class="required">(*)</span></label>

                    </div>
                    <div class="entradas-de-texto-govco col-sm-7 col-md-7 text-start">
                        <input type="text" id="Razón Social" placeholder="Título de la pregunta" [(ngModel)]="pregunta.title" />

                    </div>

                </div>
            </div>
            <div *ngIf="addView" class="row col-sm-6 col-md-12">
                <div class="row col-sm-12 col-md-12 text-center">
                    <div class="entradas-de-texto-govco col-sm-5 col-md-5 text-start">
                        <label for="Razón Social">Tooltip<span class="required">(*)</span></label>

                    </div>
                    <div class="entradas-de-texto-govco col-sm-7 col-md-7 text-start">
                        <input type="text" id="Razón Social" placeholder="Texto de ayuda de la pregunta" [(ngModel)]="pregunta.tooltip" />

                    </div>

                </div>
            </div>
            <div *ngIf="addView" class="row col-sm-6 col-md-12">
                <div class="row col-sm-12 col-md-12 text-center">
                    <div class="entradas-de-texto-govco col-sm-5 col-md-5 text-start">
                        <label for="Razón Social">Tipo de pregunta<span class="required">(*)</span></label>

                    </div>
                    <div class="entradas-de-texto-govco col-sm-7 col-md-7 text-start">
                        <select class="form-select" id="Tipo de Identificación" aria-label="Default select example"
                                [(ngModel)]="pregunta.questionTypeId" >
                            <option value="" selected disabled>Seleccione</option>
                            <option value={{1}}>Radio Button</option>
                            <option value={{2}}>Caja Texto</option>
                            <option value={{3}}>Selección</option>
                            <option value={{4}}>Texto</option>
                        </select>
                    </div>

                </div>
            </div>
            <div *ngIf="addView" class="row col-sm-6 col-md-12">
                <div class="row col-sm-12 col-md-12 text-center">
                    <div class="entradas-de-texto-govco col-sm-5 col-md-5 text-start">
                        <label for="Razón Social">Obligatoria<span class="required">(*)</span></label>

                    </div>
                    <div role="group" aria-label="Radio Button - Alineación horizontal"
                         class="radio entradas-de-texto-govco col-sm-5 col-md-5 text-center" style="display: flex;">
                        <input type="radio" id="radio11" name="radioButton1" value="{{1}}" (click)="obligatoriedadDePregunta($event)">
                        <label for="radio11">Si</label>
                        <input type="radio" id="radio12" name="radioButton1" value="{{2}}" checked (click)="obligatoriedadDePregunta($event)">
                        <label for="radio12">No</label>

                    </div>

                </div>
            </div>
        </div>
        <div *ngIf="addView" class="row col-sm-6 col-md-6 text-end">
            <div class="row col-sm-6 col-md-12">
                <div class="row col-sm-12 col-md-12 text-start">


                    <div class="col-xxl-12 col-md-12 col-lg-12">
                        <label for="Razón Social">Respuestas<span class="required">(*)</span></label>
                    </div>

                    <div class="table-responsive">
                        <div class="entradas-de-texto-govco col-sm-12 col-md-12 text-end">
                            <a style="color: #004884;" (click)="agregarRespuesta()">

                                <span class="govco-plus-circle"></span>
                            </a>
                        </div>
                        <div class="row col-sm-12 col-md-12 text-start tabla">
                            <div class="table-responsive">


                                <table class="table" aria-describedby="respuestas" *ngIf="pregunta.questionTypeId==1 || pregunta.questionTypeId==3">
                                    <thead class="encabezado-tabla">
                                        <tr>
                                            <th>Id</th>
                                            <th>Respuesta</th>
                                            <th>Tooltip</th>
                                            <th> </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let respuesta of respuestas  let indice=index">
                                            <td *ngIf="respuesta.statusId == 0 && !respuesta.identifier.includes('PE') ">{{respuestas[indice].identifier}}</td>
                                            <td *ngIf="respuesta.statusId == 0 && !respuesta.identifier.includes('PE')"><input type="text" value="{{respuestas[indice].description}}"
                                                       [(ngModel)]="respuestas[indice].description">
                                            </td>
                                            <td *ngIf="respuesta.statusId == 0 && !respuesta.identifier.includes('PE')"><input type="text" value="{{respuestas[indice].tooltip}}"
                                                       [(ngModel)]="respuestas[indice].tooltip"></td>

                                            <td *ngIf="respuesta.statusId == 0 && !respuesta.identifier.includes('PE')"><span class="govco-minus-circle" style="color: #004884;"
                                                      (click)="eliminarRespuesta(indice)"></span></td>
                                          <td *ngIf="respuesta.statusId == 0 && !respuesta.identifier.includes('PE')"><span data-bs-toggle="modal" data-bs-target="#modal-seguimiento-auditoria" class="govco-plus-circle" style="color: #004884;"
                                                                                    (click)="generarextra(indice) "></span></td>
                                        </tr>



                                    </tbody>
                                </table>

                              <div class="row col-sm-12 col-md-12 text-start" *ngIf="pregunta.questionTypeId==1" >
                                <div role="group" aria-label="Radio Button - Alineación horizontal" class="entradas-de-texto-govco col-sm-4 col-md-4 text-center" *ngFor="let option of respuestas; let i = index" >
                                  <input

                                    type="radio"
                                    [id]="'radio-' + i"
                                    [value]="option"
                                    name="dynamicRadio"
                                    *ngIf="option.statusId == 0 && !option.identifier.includes('PE')"
                                  />
                                  <label  *ngIf="option.statusId == 0 && !option.identifier.includes('PE')" [for]="'radio-' + i">{{ option.description }}</label>
                                </div>
                              </div>

                              <div *ngIf="pregunta.questionTypeId==2 && respuestas.length!=0"  class="entradas-de-texto-govco col-sm-7 col-md-7 text-start">
                                <input type="text" id="textfield" placeholder="Texto de la pregunta" disabled [(ngModel)]="textquestion" />
                              </div>
                              <div *ngIf="pregunta.questionTypeId==4 && respuestas.length!=0"  class="entradas-de-texto-govco col-sm-7 col-md-7 text-start">
                                <label  >Texto que aparecera como una nota</label>
                                <input type="text" id="text" placeholder="Texto que aparecera"  [(ngModel)]="textquestion" />
                              </div>

                            </div>
                        </div>

                    </div>

                    <div class="col-sm-12 col-md-12 text-end">

                        <br><br>
                        <button *ngIf="!editMode" mat-dialog-close type="button" class="btn-govco fill-btn-govco col-4 col-md-4 pl-4"
                                (click)="guardarPregunta()">GUARDAR</button>
                        <button *ngIf="editMode" mat-dialog-close type="button" class="btn-govco fill-btn-govco col-4 col-md-4 pl-4"
                                (click)="guardarPregunta()">EDITAR</button>
                        <button *ngIf="!editMode" type="button" class="btn-govco secondaryButton col-4 col-md-4 pl-4"
                                (click)="limpiarForm()">LIMPIAR</button>
                        <button *ngIf="editMode" type="button" class="btn-govco secondaryButton col-4 col-md-4 pl-4"
                                (click)="cancelarEditar()">CANCELAR</button>
                    </div>
                    <div *ngIf="viewErrorPop" class="container-alerta-govco">
                        <div class="alert alerta-govco alerta-error-govco aerror" role="alert">
                            <span class="alerta-icon-govco alerta-icon-error-govco aerror"></span>
                            <p class="alerta-content-text">Los datos ingresados no están completos o contienen errores, por favor verifique nuevamente
                            </p>
                        </div>
                    </div>


                </div>
            </div>
        </div>
        <br>
        <br>
        <br>
        <div class="row col-sm-12 col-md-12 text-start">
            <div class="entradas-de-texto-govco col-sm-12 col-md-12 text-start">
                <h3 class="h3-tipografia-govco title-3">Preguntas creadas</h3>
            </div>


            <div class="row col-sm-6 col-md-12">
                <div class="row col-sm-12 col-md-12 text-start">
                    <div class="table-responsive">
                        <table class="table" aria-describedby="preguntas creadas">
                            <thead class="encabezado-tabla">
                                <tr>
                                    <th>Id Pregunta</th>
                                    <th>Título </th>
                                    <th>Tooltip</th>
                                    <th>Tipo de pregunta</th>
                                    <th>Obligatoria</th>
                                    <th>Respuestas posibles</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let pregunta of encuesta.surveyQuestions  let indice=index">
                                    <td *ngIf="pregunta.statusId == 0">{{pregunta.id? pregunta.id : indice}}</td>
                                    <td *ngIf="pregunta.statusId == 0">{{pregunta.title}}</td>
                                    <td *ngIf="pregunta.statusId == 0"> {{pregunta.tooltip}}</td>
                                    <td *ngIf="pregunta.questionTypeId == 1 && pregunta.statusId == 0"> Radio button</td>
                                    <td *ngIf="pregunta.questionTypeId == 2  && pregunta.statusId == 0"> Texto</td>
                                    <td *ngIf="pregunta.questionTypeId == 3  && pregunta.statusId == 0"> Selección</td>
                                    <td *ngIf="pregunta.statusId == 0"> {{pregunta.mandatory? 'Obligatoria':'No Obligatoria'}}</td>
                                    <td *ngIf="pregunta.statusId == 0">  {{ getFilteredAnswersLength(pregunta.answersQuestions) }}</td>
                                    <td *ngIf="pregunta.statusId == 0">
                                        <span class=" govco-edit" style="color: #004884;" (click)="editarPregunta(pregunta)"></span>
                                        <span class="govco-minus-circle" style="color: #004884;" (click)="popUpConfirmacion(indice)"></span>
                                    </td>
                                </tr>



                            </tbody>
                        </table>

                    </div>

                    <div class="col-sm-12 col-md-12 text-center btns">

                        <br><br>
                        <button mat-dialog-close type="button" class="btn-govco fill-btn-govco col-3 col-md-3 pl-3"
                                (click)="crearEncuesta()">CREAR</button>
                        <button type="button" class="btn-govco secondaryButton col-3 col-md-3 pl-3" (click)="cancelar()">CANCELAR</button>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>
<app-go-back-up class="up"></app-go-back-up>
<div class="container" *ngIf="!authRole">
    <div class="title">
        <h1 class="h1-tipografia-govco title-1">No Permitido</h1>
    </div>
</div>
<app-footer></app-footer>

<!-- Modal para Auditoria-->
<div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false"
     id="modal-seguimiento-auditoria" tabindex="-1" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header" style="background: #004884">
        <h5 class="modal-title" style="color:#FFFFFF;" id="exampleModalLabel">Agregar Opcion</h5>
        <button type="button" class="btn-close " data-bs-dismiss="modal"
                aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <br>
        <div  class="row col-sm-6 col-md-12">
          <div class="row col-sm-12 col-md-12 text-center">
            <div class="entradas-de-texto-govco col-sm-5 col-md-5 text-start">
              <label for="Razón Social">Tipo de pregunta<span class="required">(*)</span></label>

            </div>
            <div class="entradas-de-texto-govco col-sm-7 col-md-7 text-start">
              <select class="form-select" id="Tipo de Identificación" aria-label="Default select example"
                      [(ngModel)]="respuestasPtype" (change)="onchangeExtra()" >
                <option value="" selected disabled>Seleccione</option>
                <option value=1>Radio Button</option>
                <option value=2>Caja de Texto</option>
                <option value=3>Selección</option>
                <option value=4>Texto</option>
              </select>
            </div>

          </div>
        </div>

        <div class="row col-sm-10 col-md-10 text-end">
          <div class="row col-sm-10 col-md-10">
            <div class="row col-sm-12 col-md-12 text-start">


              <div class="col-xxl-12 col-md-12 col-lg-12">
                <label for="Razón Social">Respuestas<span class="required">(*)</span></label>
              </div>

              <div class="table-responsive">
                <div class="entradas-de-texto-govco col-sm-12 col-md-12 text-end" >
                  <a style="color: #004884;" (click)="agregarRespuestaExtra()">

                    <span class="govco-plus-circle"></span>
                  </a>
                </div>
                <div class="row col-sm-12 col-md-12 text-start tabla">
                  <div class="table-responsive">


                    <table class="table" aria-describedby="respuestas" *ngIf="respuestasPtype==1 || respuestasPtype==3">
                      <thead class="encabezado-tabla">
                      <tr>
                        <th>Id</th>
                        <th>Respuesta</th>
                        <th>Tooltip</th>
                        <th> </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let respuestape of respuestasPextra  let indice=index">
                        <td *ngIf="respuestape.statusId == 0">{{respuestape.identifier}}</td>
                        <td *ngIf="respuestape.statusId == 0"><input type="text" value="{{respuestape.description}}"
                                                                   [(ngModel)]="respuestape.description">
                        </td>
                        <td *ngIf="respuestape.statusId == 0"><input type="text" value="{{respuestape.tooltip}}"
                                                                   [(ngModel)]="respuestape.tooltip"></td>

                        <td *ngIf="respuestape.statusId == 0"><span class="govco-minus-circle" style="color: #004884;"
                                                                  (click)="eliminarRespuestaExtra(indice)"></span></td>
                      </tr>



                      </tbody>
                    </table>

                    <div class="row col-sm-12 col-md-12 text-start" *ngIf="respuestasPtype==1" >
                      <div role="group" aria-label="Radio Button - Alineación horizontal" class="entradas-de-texto-govco col-sm-4 col-md-4 text-center" *ngFor="let option of respuestasPextra; let i = index" >
                        <input

                          type="radio"
                          [id]="'radio-' + i"
                          [value]="option"
                          name="dynamicRadio"
                          *ngIf="option.statusId == 0"
                        />
                        <label  *ngIf="option.statusId == 0" [for]="'radio-' + i">{{ option.description }}</label>
                      </div>
                    </div>

                    <div *ngIf="respuestasPtype==2 && respuestasPextra.length!=0"  class="entradas-de-texto-govco col-sm-7 col-md-7 text-start">
                      <input type="text" id="Razón Social" placeholder="Texto de la pregunta" [(ngModel)]="textquestionExtra" />
                    </div>
                    <div *ngIf="respuestasPtype==4 && respuestasPextra.length!=0"  class="entradas-de-texto-govco col-sm-7 col-md-7 text-start">
                      <label  >Texto que aparecera de subtitulo</label>
                      <input type="text" id="textlabelextra" placeholder="Texto que aparecera" [(ngModel)]="textquestionExtra" />
                      <label  >Texto que aparecera como una nota</label>
                      <input type="text" id="textextra" placeholder="Texto que aparecera" [(ngModel)]="textlabelExtra" />
                    </div>

                  </div>
                </div>

              </div>

              <div class="col-sm-12 col-md-12 text-end">

                <br><br>
                <button *ngIf="!editMode" mat-dialog-close type="button" data-bs-dismiss="modal" class="btn-govco fill-btn-govco col-4 col-md-4 pl-4"
                        (click)="guardarPreguntaExtra()">GUARDAR</button>
                <button *ngIf="editMode" mat-dialog-close type="button" data-bs-dismiss="modal" class="btn-govco fill-btn-govco col-4 col-md-4 pl-4"
                        (click)="guardarPreguntaExtra()">EDITAR</button>
                <button *ngIf="!editMode" type="button" class="btn-govco secondaryButton col-4 col-md-4 pl-4"
                        (click)="limpiarFormExtra()">LIMPIAR</button>
                <button *ngIf="editMode" type="button" data-bs-dismiss="modal" class="btn-govco  secondaryButton col-4 col-md-4 pl-4"
                        (click)="cancelarEditarExtra()">CANCELAR</button>
              </div>
              <div *ngIf="viewErrorPop" class="container-alerta-govco">
                <div class="alert alerta-govco alerta-error-govco aerror" role="alert">
                  <span class="alerta-icon-govco alerta-icon-error-govco aerror"></span>
                  <p class="alerta-content-text">Los datos ingresados no están completos o contienen errores, por favor verifique nuevamente
                  </p>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
