import { map } from 'rxjs';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ApiService } from 'src/app/Services/api/api.service';
import { AlertModalInformationComponent } from 'src/app/Shared/alert-modal-information/alert-modal-information.component';
import { MatDialog } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'src/app/Services/Authentication/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-formalities',
  templateUrl: './formalities.component.html',
  styleUrls: ['./formalities.component.scss']
})
export class FormalitiesComponent implements OnInit {
  @Output() $carga: EventEmitter<any> = new EventEmitter<any>();
  @Output() $login: EventEmitter<any> = new EventEmitter<any>();
  listaCategorias: any;
  tramites: any[] = [{}];
  totalTramites: any;
  page: number = 1;
  idCategoria: number = 0;
  sesionData: any = {};
  rolesUser: any;
  infoUser: any;

  categoriasSeleccionadas: any[] = [];
  textProcedure: string = ''
  categoriasPredeterminadas: any;
  mostrandoA: any = 5;

  constructor(private router: Router, private apiAuth: AuthService, private msaService: MsalService, private api: ApiService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getCategorys()
    this.getTramitesByIdCategoria(this.idCategoria)

  }

  /**
   * Valida que tipos de tramite traer en
   * base al id de la categoria/etiqueta seleccionada
   * @param idCategoria 
   */
  validarIdCategoria(idCategoria: number) {
    this.listaCategorias[idCategoria].selected = !this.listaCategorias[idCategoria].selected
    // this.categoriasSeleccionadas = []

    //Trae todos los tramites y des selecciona las otras categorias
    if (idCategoria == 0) {
      this.categoriasSeleccionadas = []
      for (let index = 1; index < this.listaCategorias.length; index++) {
        this.listaCategorias[index].selected = false;
      }
      this.getTramitesByIdCategoria(idCategoria)
    }
    //Consulta entre los 6 id de categorias predeterminados(etiquetas)
    else if (idCategoria > 0 && idCategoria < this.categoriasPredeterminadas) {
      this.categoriasSeleccionadas = []
      this.listaCategorias[0].selected = false
      this.listaCategorias.map((ele: any, index: any) => {
        if (ele.selected && ele.tagId < this.categoriasPredeterminadas) {
          this.categoriasSeleccionadas.push({
            name: this.listaCategorias[index].name,
            defaultLabel: true,
            labelId: this.listaCategorias[index].tagId,
          })
        }
        if (ele.selected && ele.tagId >= this.categoriasPredeterminadas) {
          this.categoriasSeleccionadas.push({
            name: this.listaCategorias[index].name,
            defaultLabel: false,
            labelId: this.listaCategorias[index].tagId,
          })
        }
      })
      this.page = 1;
      this.mostrandoA = 5;
      this.getFormalitiesByTextTag()
    }
    //Si no coincide con ningun id de categoria se agrega como
    //una nueva categoria con su id
    else {
      this.listaCategorias[0].selected = false
      this.listaCategorias.map((ele: any, index: any) => {
        if (ele.selected && ele.tagId >= this.categoriasPredeterminadas) {
          this.categoriasSeleccionadas.push({
            name: this.listaCategorias[index].name,
            defaultLabel: false,
            labelId: this.listaCategorias[index].tagId,
          })
        }
      })
      // this.prueba.push({
      //   name: this.listaCategorias[id].name,
      //   defaultLabel: false,
      //   labelId: 0,
      // })
      this.page = 1;
      this.getFormalitiesByTextTag()
    }
  }

  /**
   * Consulta los tramites por el id de la categoria
   * seleccionada en la eqtiqueta 
   * @param idCategoria 
   */
  getTramitesByIdCategoria(idCategoria: number) {
    this.idCategoria = idCategoria
    const data = {
      pageSize: 30,
      pageNumber: 1,
      paperworkLabels: [
        {
          name: null,
          defaultLabel: true,
          labelId: idCategoria
        }
      ]
    }
    this.api.getTramites(data).subscribe(res => {
      if (res && res.items.length > 0) {
        this.tramites = res.items.reverse();
        this.totalTramites = this.tramites.length;
        setTimeout(() => {
          this.$carga.emit(true)
        }, 3000);
      } else {
        this.openDialogFail()
        this.$carga.emit(true)
      }
    })
    this.page = 1;
  }

  /**
   * Consulta los tramites por los id de categoria que
   * se seleccionen como etiqueta mas de 1
   */
  getTramitesByIdCategorias() {
    const data = {
      pageSize: 30,
      pageNumber: 1,
      paperworkLabels: this.categoriasSeleccionadas
    }
    this.api.getTramites(data).subscribe(res => {
      if (res) {
        this.tramites = res.items.reverse();
        this.totalTramites = this.tramites.length;
      }
    })
    this.page = 1;
  }


  getFormalitiesByTextTag() {
    const data = {
      pageSize: 30,
      pageNumber: 1,
      paperworkLabels: this.categoriasSeleccionadas
    }
    this.api.getTramites(data).subscribe(res => {
      if (res && res.items.length > 0) {
        this.tramites = res.items.reverse();
        this.totalTramites = this.tramites.length;
      }

    })
    this.page = 1;
  }




  /**
   *Funcion que trae la cantidad de tramites por categoria tramites por categoria
   */
  getCategorys() {
    this.api.getTramitesByModality().subscribe(res => {
      if (res) {
        this.listaCategorias = res;
        this.categoriasPredeterminadas = res.length
      }
    })
  }

  //Función que trae los tramites por el texto ingresado en el buscador
  consultProcedure() {
    this.listaCategorias[0].selected = false;
    this.listaCategorias.map(element => {
      element.selected = false
    })

    const categoriaExistente = this.listaCategorias.find(categoria => categoria.name === this.textProcedure);

    if (!categoriaExistente && this.textProcedure !== '') {
      const data = {
        pageSize: 30,
        pageNumber: 1,
        paperworkLabels: [
          {
            name: this.textProcedure,
            defaultLabel: false,
            labelId: 0,
          }
        ]
      };

      this.api.getTramites(data).subscribe(res => {
        if (res && res.items.length > 0) {
          let a = res.items
          let tramitesMap: any = a.map(item => {
            return [JSON.stringify(item), item]
          });
          let personasMapArr = new Map(tramitesMap);
          let unicos = [...personasMapArr.values()]; // Conversión a un array
          this.tramites = unicos.reverse();
          this.totalTramites = this.tramites.length;
          this.listaCategorias.push({
            name: this.textProcedure,
            tagId: this.listaCategorias.length,
            count: res.items.length,
            defaultLabel: false,
            selected: true
          });

          this.categoriasSeleccionadas = this.listaCategorias
            .filter(categoria => categoria.selected)
            .map(categoria => ({
              name: categoria.name,
              defaultLabel: false,
              labelId: categoria.tagId,
            }));
        } else {
          this.openDialogFail()
        }
      });

      this.page = 1;
    }
  }

  //funcion que borra las etiquetas que no estan predefinidas
  deleteLabel(indice: number) {
    this.listaCategorias.splice(indice, 1)
  }

  openDialogFail(): void {
    this.dialog.open(AlertModalInformationComponent, {
      data: { type: 'fail', title: 'Lo sentimos', text: 'No se encontraron resultados.' },
      width: '540px',
    });
  }

  pageChanged(e: any) {
    this.page = e
    this.mostrandoA = e * 5
    if (this.mostrandoA > this.totalTramites) {
      this.mostrandoA = this.totalTramites
    }
  }

  loginTramite(e: any) {
    this.logIn()
  }

  logIn() {
    this.msaService.loginPopup().subscribe((data) => {
      this.sesionData = data
      if (this.sesionData.uniqueId != '') {
        this.apiAuth.setValorToken(this.sesionData)
        this.getUserVentanilla(this.sesionData.uniqueId)
      }

    }, error => {
      this.apiAuth.logoutSys();
    });
  }

  getUserVentanilla(idUsuario: any) {
    this.api.getIdUserVentanilla(idUsuario).subscribe(res => {
      if (res.code == 200) {
        localStorage.setItem('idUser', res.data)
        this.getRoleUser(this.sesionData.uniqueId)
      }
    }, err => {
      console.log(err)
      this.addPerson()
    })
  }

  getRoleUser(data: any) {
    this.api.getUserRole(data).subscribe(res => {
      if (res.code == 200 && res.data.length > 0) {
        localStorage.setItem('rol', res.data[0].rol);
        this.rolesUser = res.data[0]
        this.getInfoUser(localStorage.getItem('idUser'))
        // location.reload()

      }
      if (res.code == 200 && res.data.length == 0) {
        localStorage.setItem('rol', 'nn');
        location.reload()
      }
    }, err => {
      alert('Error del servidor')
      location.reload()
    })
  }

  getInfoUser(idUsuarioVentanilla: any) {
    this.api.consultarLegalPerson(idUsuarioVentanilla).subscribe(resp => {
      console.log(resp)
      this.infoUser = resp.data
      console.log(this.infoUser)
      if (this.infoUser.razonSocial) {
        localStorage.setItem('tipoPersona', 'PJ');
        this.infoUser.personTypeId = 2
      }
      if
        (this.infoUser.razonSocial == null) {
        localStorage.setItem('tipoPersona', 'PN');
        this.infoUser.personTypeId = 1
      }
      this.sesionData.idTokenClaims["datosPersonales"] = this.infoUser;
      this.sesionData.idTokenClaims["roles"] = [this.rolesUser];
      this.sesionData.idTokenClaims["tieneCodigoVentanilla"] = true;
      this.sesionData.idTokenClaims["aceptoPoliticaSeguridad"] = true;
      this.apiAuth.setCurrentUser(this.sesionData);
      location.reload()

    })
  }

  addPerson() {
    let data = {
      oid: this.sesionData.uniqueId,
      email: this.sesionData.idTokenClaims.emails[0]
    }
    this.api.addUserSistem(data).subscribe(res => {
      location.reload()
      this.router.navigate(['Home']);
    })
  }




}
