<div class="close">
    <mat-dialog-actions>
        <mat-icon mat-dialog-close>close</mat-icon>
    </mat-dialog-actions>
</div>
<h2 mat-dialog-title>Documento Encontrado</h2>
<div class="container">
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <label>ID Trámite</label>
            <input type="text" disabled value={{data.infoDoc.id_tramite}}>
        </div>
        <div class="col-sm-12 col-md-6">
            <label>Nombre del Trámite</label>
            <input type="text" disabled value={{data.infoDoc.procedureName}}>
        </div>
        <div class="col-sm-12 col-md-6">
            <label>Nombre del solicitante</label>
            <input type="text" disabled value={{data.infoDoc.persona}}>
        </div>
        <div class="col-sm-12 col-md-6">
            <label>Núm. documento id</label>
            <input type="text" disabled value={{data.infoDoc.nume_identificacion}}>
        </div>
        <div class="col-sm-12 col-md-6">
            <label>Fecha de resolución</label>
            <input type="text" disabled value={{data.infoDoc.fecha_resolucion_articulo}}>
        </div>
        <div class="col-sm-12 col-md-6">
            <label>Número de resolución</label>
            <input type="text" disabled value={{data.infoDoc.num_resolucion_articulo}}>
        </div>
        <div class="col-sm-12 col-md-6">
            <label>Estado del trámite</label>
            <input type="text" disabled value={{data.infoDoc.estado_tramite}}>
        </div>

    </div>
    <div mat-dialog-actions class="buttonContainer">
        <label>En caso de requerir información adicional, escribanos a <a href="#">contactenos@saludcapital.com</a></label>
        <button mat-button color="warn" [mat-dialog-close]="true">Regresar</button>
    </div>
</div>
