import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  ruta = [{ name: 'Inicio', url: '/Home' }, { name: 'Mi Perfil', url: '/Home/myProfile' }]
  carga: boolean = false;
  constructor(private router: Router) { }

  pathname = window.location.pathname;
  userType: any;
  natural = false;
  legal = false;
  rol: string | null = '';
  authRole: boolean = false;

  ngOnInit(): void {
    this.mostrarCarga()
    this.userType = this.pathname.split('/').pop();
    console.log(this.userType)
    if (this.userType === 'myProfileNatural') {
      this.natural = true;
    } else if (this.userType === 'myProfileLegal') {
      this.legal = true;
    }

    this.rol = localStorage.getItem('rol');
    if (this.rol == 'Ciudadano') {
      this.authRole = true;
    } else {
      this.router.navigate(['/Home']);
    }

  }

  mostrarCarga() {
    setTimeout(() => {
      this.carga = true
    }, 2000)
  }



}
