import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/Shared/confirmation-modal/confirmation-modal.component';

export interface DialogData {
  aceptAuth: false;
}

@Component({
  selector: 'app-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.scss']
})
export class PoliciesComponent {

  constructor(public dialogRef: MatDialogRef<PoliciesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public dialog: MatDialog) { }


  onNoClick(): void {
    this.openDialogLogOut()
  }


  openDialogLogOut(): void {
    this.dialog.open(ConfirmationModalComponent, {
      data: { type: 'warning', title: 'Rechazo de Política de protección de datos personales .', text: 'Se ha rechazado la política de protección de datos personales, por lo tanto no puede realizar ningún trámite y será redireccionado a la página principal' },
      height: 'auto',
      width: '700px',
    }).afterClosed().subscribe(data => {
      console.log(data)
    });
  }

}
