import { Component } from '@angular/core';

@Component({
  selector: 'app-go-back-up',
  templateUrl: './go-back-up.component.html',
  styleUrls: ['./go-back-up.component.scss']
})
export class GoBackUpComponent {

  backGoToUp() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

}
