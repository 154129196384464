import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    let requests = request;

    let headers: any = {
      'Trace-Source': 'internal-user'
    };
    const accessToken: any = JSON.parse(localStorage.getItem('currentUser'))?.idToken || '';
    const currentUser = { access_token: accessToken };
    if (currentUser && currentUser?.access_token) {
      headers = {
        ...headers,
        Authorization: 'Bearer ' + currentUser?.access_token,
      };
    }

    const responseType = request.responseType;
    const isFormData = request.body instanceof FormData;

    if (currentUser && currentUser?.access_token) {
      if (isFormData || responseType === 'blob') {
        requests = request.clone({
          setHeaders: { ...headers },
        })
      } else {
        requests = request.clone({
          setHeaders: {
            ...headers,
            'Content-Type': request.method === 'PATCH' ? /*'application/json-patch+json'*/ 'application/merge-patch+json' : 'application/json'
          }
        });
      }
    }
    return next.handle(requests).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          console.log('Debe autenticarse, por favor.', 5000);
          localStorage.clear();
          localStorage.setItem('oneError', '1');
        }
        return throwError(err);
      })
    );
  }
}
