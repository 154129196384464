import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../../environments/env';
import { Observable } from 'rxjs';
import { Usuarios } from 'src/app/Models/usuarios.model';

const URL = environment.backUrl;
const URLSecurity = environment.urlApiManagerSecurity;

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  constructor(private http: HttpClient) { }


  traerUsuarios(): Observable<Usuarios[]> {
    return this.http.get<Usuarios[]>(`${URL}/UsersLogin/list`);
  }/*pdt*/
  traerUsuariosAutenticacion(type:string,search:string): Observable<any> {
    return this.http.get<any>(`${URLSecurity}/v2/Security/GetUsers?type=${type}&search=${search}`);
  }
  traerUsuario(nombre: string): Observable<Usuarios[]> {
    return this.http.get<Usuarios[]>(`${URL}/UsersLogin/${nombre}`);
  }

  guardarUsuario(usuario: any): Observable<any> {
    return this.http.post<number>(`${URL}/UsersLogin`, usuario, { observe: 'response' });
  }

  traerIdUsuarioPorADId(ADId: string): Observable<string> {
    return this.http.get<string>(`${URL}/UsersLogin/idUser/${ADId}`);
  }
}

