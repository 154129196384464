import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-reassign-official-request',
  templateUrl: './reassign-official-request.component.html',
  styleUrls: ['./reassign-official-request.component.scss']
})
export class ReassignOfficialRequestComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { infoTramite: any, titulo: any, readOnly: boolean, admin: boolean }) { }

  ngOnInit(): void {
    console.log(this.data.infoTramite.nombreTramite)
  }

}
