import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FuncionalidadRolesFull } from 'src/app/Models/funcionalidadesRolesFull.model';
import { ApiService } from 'src/app/Services/api/api.service';
import { FuncionalidadRolesService } from 'src/app/Services/roles-usuarios-funcionalidades/funcionalidades-roles.service';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Component({
  selector: 'app-listar-func',
  templateUrl: './listar-func.component.html',
  styleUrls: ['./listar-func.component.scss']
})
export class ListarFuncComponent implements OnInit {
  rutaMigaPan = [{ name: 'Inicio', url: '/Home' }, { name: 'Administración', url: '/Home/Administracion' }, { name: 'Consultar roles', url: '/Home/Administracion/roles' }]

  titulo = 'Consultar roles de tramites';
  mensajeDesactivacion = 'Al desactivar una funcionalidad, ningún usuario podrá ingresar ni realizar tareas ' +
    'en ella, sin embargo las configuraciones del usuario se conservarán, de modo que ' +
    'si en el futuro se reactiva la funcionalidad, los usuarios podrán ingresar ' +
    'nuevamente de acuerdo a los roles con los que cuenten';
  mensajeActivacion = 'Al reactivar una funcionalidad, los usuarios podrán ingresar nuevamente de acuerdo ' +
    'con los roles con los que cuenten actualmente';
  funcionalidadRoles: any[] = [];
  lista: any[] = [];
  objeto!: any;
  roles = '';
  pagina = 1;
  nombreRol: string;
  rol: string;
  authRole: boolean = false;
  adminRole: boolean = false;
  constructor(private funcionalidadRolesService: FuncionalidadRolesService,
    public router: Router, private apiservices: ApiService) { }

  ngOnInit(): void {
    this.rol = localStorage.getItem('rol');
    if (this.rol == 'AdminFuncional' || this.rol == 'AdminTI') {
      this.authRole = true;
      this.adminRole = true;

    } else {
      this.router.navigate(['/Home']);
    }
    this.cargarFuncionalidadRoles();

    $("#cancel").on("click", function () {
      $("#caja").val('');
    });
  }

  cargarFuncionalidadRoles(): void {
    this.funcionalidadRolesService.traerFuncionalidadRolesAutenticacion(" "," ")
      .subscribe(resp => {
        this.lista = [];
        this.roles = '';
        this.funcionalidadRoles = resp.data;
        this.funcionalidadRoles.forEach(item => {

          console.log('aca cargo')
          if(item.codigoAplicativo!="" && item.codigoAplicativo!=" " )
          {
            if(item.roles!=null)
            {
              item.roles.split(',').forEach((element: any) => {
                if (element!="") {
                  this.roles = this.roles + '*' + element +' ';
                }
              });
            }

            this.objeto = {
              tramiteMainId: item.idAplicativo,
              codTramite: item.siglaAplicativo+((item.codigoAplicativo!="" && item.codigoAplicativo!="0") ?"-"+item.codigoAplicativo:"" ),
              nameTramite: item.nombreAplicativo,
              rolId: item.idRoles,
              rolName: this.roles,
              estadoRol: item.estado,
              estadoTramite: item.estado == "Habilitado",
              urlaplicativo:item.urlAplicativo,
              siglaaplicativo:item.siglaAplicativo,
              codigoaplicativo: item.codigoAplicativo

            }
            this.lista.push(this.objeto);
          }

          this.roles = '';
        });
      });
  }

  borrar(): void {
    this.nombreRol = '';
    this.cargarFuncionalidadRoles();
  }

  buscar(texto: string): void {
    if (texto != '' && texto!=undefined) {

      this.funcionalidadRolesService.traerFuncionalidadRolesAutenticacion("nombre",texto)
        .subscribe(resp => {
          if (resp.length != 0) {
            this.lista = [];
            this.roles = '';
            this.funcionalidadRoles = resp.data;
            this.funcionalidadRoles.forEach(item => {

              if(item.codigoAplicativo!="" && item.codigoAplicativo!=" " )
              {
                if(item.roles!=null)
                {
                  item.roles.split(',').forEach((element: any) => {
                    if (element!="") {
                      this.roles = this.roles + '*' + element +' ';
                    }
                  });
                }

                this.objeto = {
                  tramiteMainId: item.idAplicativo,
                  // codigo: item[0].codigo,
                  codTramite: item.siglaAplicativo+((item.codigoAplicativo!="" && item.codigoAplicativo!="0") ?"-"+item.codigoAplicativo:"" ),
                  nameTramite: item.nombreAplicativo,
                  rolId: item.idRoles,
                  rolName: this.roles,
                  estadoRol: item.estado,
                  estadoTramite: true ?  item.estado=="Habilitado" : false,
                  urlaplicativo:item.urlAplicativo,
                  siglaaplicativo:item.siglaAplicativo,
                  codigoaplicativo: item.codigoAplicativo

                }
                console.log(this.objeto);
                this.lista.push(this.objeto);
              }

              this.roles = '';
            });
          } else {
            this.ventanaEmergenteError();
          }
        });
    } else {
      this.cargarFuncionalidadRoles();
    }
  }

  desactivarFuncionalidad(id: string): void {
    this.ventanaEmergenteWarning(this.mensajeDesactivacion)
      .then(resp => {
        if (resp.value) {
          let filteredAplication = this.lista.filter(item => item.tramiteMainId == id);

          console.log(filteredAplication);
          let aplication=
            {
              nombreAplicativo: filteredAplication[0].nameTramite,
              urlAplicativo: filteredAplication[0].urlaplicativo,
              siglaAplicativo: filteredAplication[0].siglaaplicativo,
              codigoAplicativo: filteredAplication[0].codigoaplicativo,
              idRoles: filteredAplication[0].rolId,
              idAplicativo: filteredAplication[0].tramiteMainId,
              idResponsable: localStorage.getItem('clientId'),
              estado: "false"
            }

          this.funcionalidadRolesService.guardarFuncionalidadAutenticacion(aplication)
            .subscribe(rta => {
              if (rta.status == 200) {
                this.ventanaEmergenteExito();
                this.registrarAccion('UPDATE', id)
                this.cargarFuncionalidadRoles();
              } else {
                this.ventanaEmergenteError();
              }
            });


        }
      });
  }
  activarFuncionalidad(id: number): void {
    this.ventanaEmergenteWarning(this.mensajeDesactivacion)
      .then(resp => {
        if (resp.value) {
          let filteredAplication = this.lista.filter(item => item.tramiteMainId == id);


          console.log(filteredAplication);
          let aplication=
            {
              nombreAplicativo: filteredAplication[0].nameTramite,
              urlAplicativo: filteredAplication[0].urlaplicativo,
              siglaAplicativo: filteredAplication[0].siglaaplicativo,
              codigoAplicativo: filteredAplication[0].codigoaplicativo,
              idRoles: filteredAplication[0].rolId,
              idAplicativo: filteredAplication[0].tramiteMainId,
              idResponsable: localStorage.getItem('clientId'),
              estado: "true"
            }

          this.funcionalidadRolesService.guardarFuncionalidadAutenticacion(aplication)
            .subscribe(rta => {
              if (rta.status == 200) {
                this.ventanaEmergenteExito();
                this.registrarAccion('UPDATE', id)
                this.cargarFuncionalidadRoles();
              } else {
                this.ventanaEmergenteError();
              }
            });


        }
      });
  }
  registrarAccion(type: any, id: any) {
    let user = localStorage.getItem('userName');
    let registros = []
    let fechaHoraActual = new Date();

    // if (type == 'UPDATE') {
    let accionRegistro = {
      fecha: fechaHoraActual,
      usuario: user,
      operacion: type,
      entidadAfectada: 'N/A',
      tramite: id,
      rol: 'N/A',
      estacion: 'string',
      servidor: "string"
    }
    registros.push(accionRegistro)
    // }
    this.apiservices.registrarAcciones(registros).subscribe(res => {
      console.log(res)
    })
    registros = []

  }




  ventanaEmergenteWarning(mensaje: string): Promise<SweetAlertResult> {
    let respuesta =
      Swal.fire({
        html: `${mensaje}` +
          '<h4 class="text-warning fw-bold mt-3">¿Desea continuar?</h4>',
        iconHtml: '<div class="material-icons">warning</div>',
        customClass: {
          icon: 'no-bordes'
        },
        allowOutsideClick: false,
        showCloseButton: true,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#0d6efd'
      });
    return respuesta;
  }

  ventanaEmergenteExito(): void {
    Swal.fire({
      title: 'Operación exitosa',
      iconHtml: '<div class="material-icons">done</div>',
      customClass: {
        icon: 'no-bordes',
        title: 'text-success'
      },
      allowOutsideClick: false,
      showCloseButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#0d6efd'
    });
  }

  ventanaEmergenteError(): void {
    Swal.fire({
      title: 'Operación no exitosa',
      text: 'La acción solicitada no se pudo completar',
      iconHtml: '<div class="material-icons">close</div>',
      customClass: {
        icon: 'no-bordes',
        title: 'text-danger'
      },
      allowOutsideClick: false,
      showCloseButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#0d6efd'
    });
  }

}
