import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api/api.service';

@Component({
  selector: 'app-registration-actions',
  templateUrl: './registration-actions.component.html',
  styleUrls: ['./registration-actions.component.scss']
})
export class RegistrationActionsComponent implements OnInit {
  ruta = [{ name: 'Inicio', url: '/Home' }, { name: 'Administración', url: '/Home/functionary' }, { name: 'Registro de acciones', url: '/Home/registroAcciones' }]
  authRole: boolean = false;
  rol: string | null = '';
  adminRole: boolean = false;
  allProcedures: any[] = [];
  tipoPersona: string;
  resultado: boolean = false;
  totalItems: any;
  itemsByPage: number = 10;
  page: number = 1;


  constructor(public router: Router, private apiservices: ApiService) { }

  ngOnInit(): void {
    this.rol = localStorage.getItem('rol');
    this.tipoPersona = localStorage.getItem('tipoPersona')

    if (this.rol == 'Funcionario' || this.rol == 'Coordinador') {
      this.authRole = true;
    } else if (this.rol == 'AdminFuncional' || this.rol == 'AdminTI') {
      this.authRole = true;
      this.adminRole = true;
    } else {
      this.router.navigate(['/Home']);
    }
    // this.consultarInformacionTramites()
    this.consultarRegistroAcciones()
  }

  pageChanged(e: any) {
    this.page = e
    let a = e * this.itemsByPage
    if (a > this.allProcedures.length) {
      a = this.allProcedures.length
    }
    this.consultarRegistroAcciones()
  }

  consultarRegistroAcciones() {
    this.apiservices.consultarRegistroAcciones(this.page, this.itemsByPage).subscribe(
      (res: any) => {
        console.log(res);
        if (res.informacion.length > 0) {
          this.totalItems = res.totalRegistros;
          this.allProcedures = res.informacion;

        }
      },
      error => {
        console.error("Error al consultar registros:", error);
      }
    );
  }

}
