import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { RegisterLegalPersonComponent } from './register-legal-person/register-legal-person.component';
import { RegisterNatrualPersonComponent } from './register-natrual-person/register-natrual-person.component';
import { ConfirmationModalComponent } from 'src/app/Shared/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-register-person-modal',
  templateUrl: './register-person-modal.component.html',
  styleUrls: ['./register-person-modal.component.scss']
})
export class RegisterPersonModalComponent {
  router: any;

  constructor( public dialog: MatDialog, public msal: MsalService) { }

  public onLogOut(): void {

    let accounts =this.msal.instance.getAllAccounts()
    this.msal.instance.setActiveAccount(accounts[0])
    let change=false
    let dominio="";
    if(accounts[0])
    {
      if(accounts[0].idTokenClaims)
      {
        dominio=accounts[0].idTokenClaims.idp;

        if(dominio!=undefined && dominio!="" && dominio!=null)
        {
          if(dominio.includes( "google"))
          {
            dominio="https://accounts.google.com/Logout";
            change=true;
          }
          if(dominio.includes( "live"))
          {
            dominio="https://login.live.com/oauth20_logout.srf";
            change=true;
          }
          if(dominio.includes( "microsoft"))
          {
            dominio="https://login.microsoftonline.com/saludcapital.onmicrosoft.com/oauth2/v2.0/logout";
            change=true;
          }
        }
      }
    }


    if(!change)
    {
      dominio="";
    }



    this.msal.logoutPopup({
      account: this.msal.instance.getActiveAccount(),
      mainWindowRedirectUri: "/"
    }).subscribe({
      next: () => {


        if(dominio!="")
        {
          // Crea un iframe para realizar el logout en Google y microsoft
          const iframe = document.createElement('iframe');
          iframe.src = dominio;
          iframe.style.display = 'none'; // Oculta el iframe
          document.body.appendChild(iframe);

          // Espera unos segundos para asegurarte de que el logout  ocurra
          setTimeout(() => {
            // Limpia el iframe
            document.body.removeChild(iframe);


          }, 3000);
        }

      },
      error: (error) => console.error('Logout failed', error),
    })
    localStorage.clear();

  }

  openDialogLogOut(): void {
    this.dialog.open(ConfirmationModalComponent, {
      data: { type: 'warning', title: 'confirmar', text: '¿Seguro de que desea cerrar su sesión?.' },
      height: '400px',
      width: '540px',
    }).afterClosed().subscribe(data => {
      if (data) {
        this.onLogOut();
        setTimeout(() => {
          //window.location.reload()
        }, 2000);
      }
    });
  }

  openRegisterNaturalPerson() {
    this.dialog.open(RegisterNatrualPersonComponent, {
      data: { register: true },
      disableClose: true,
      height: '80%',
      width: '70%',
    }).afterClosed().subscribe(data => {
      if (data) {
        console.log(data)
      }
    });
  }
  openRegisterLegalPerson() {
    this.dialog.open(RegisterLegalPersonComponent, {
      data: { register: true },
      disableClose: true,
      height: '600px',
      width: '800px',
      panelClass: 'scroll',
    });
  }
}
