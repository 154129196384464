<div class="container">
    <app-accesibility style="position: absolute;"></app-accesibility>
    <h1 class="h1-tipografia-govco title-1">{{encuestaExpApp.name}}</h1>
    <p *ngIf="encuestaExpApp.description != ''" class="alert alert alert-primary" role="alert">{{encuestaExpApp.description}} <a
           class="aservice-text-govco">aqui</a>
    </p>
    <div class="filterButtons row col-sm-12 col-md-12 text-start" *ngIf="surveyType == 'EST'">
        <select class="form-select" id="Tipo de Identificación" aria-label="Default select example" [(ngModel)]="tramiteId">
            <option value="" selected disabled>Seleccione el tramite que realizó</option>
            <option *ngFor="let tramite of tramitesList" value="{{tramite.id}}">
                {{tramite.description}}

            </option>
        </select>
    </div>

    <div class="filterButtons row col-sm-12 col-md-12 text-start">
        <div class="row col-sm-12 col-md-12 text-start to"
             *ngFor="let item of encuestaExpApp.surveyQuestions;let primero = first;let ultimo = last; let indice=index">

            <label *ngIf="item.statusId == 0" class="descripcion-emergente-govco"><strong>{{indice + 1 + ' .'}}</strong>
                {{item.title + (item.mandatory? ' *': '') }}
                <span class="tiptext" style="height: auto; left: 50%; top: 30px;">{{item.tooltip}}</span>
            </label>
            <div *ngIf="item.questionTypeId===3 && item.statusId == 0">
                <select class="form-select" [(ngModel)]="pregunta[indice]" *ngIf="item.statusId === 0">
                    <option *ngFor="let answer of item.answersQuestions" [value]="answer.id">
                        {{ answer.description }}
                    </option>
                </select>
            </div>
        </div>

    </div>

    <div class="row col-sm-12 col-md-12 text-start" *ngIf="clientId != null">
        <div class=" entradas-de-texto-govco col-sm-7 col-md-7">
            <label for="Razón Social"><strong>{{encuestaExpApp.surveyQuestions.length + 1 +' .'}}</strong>¿Le gustaría ser contactado para obtener
                respuesta a
                sus
                observaciones?s<span class="required">(*)</span></label>

        </div>
        <div role="group" aria-label="Radio Button - Alineación horizontal" class="entradas-de-texto-govco col-sm-1 col-md-1 text-center">
            <input type="radio" id="radio9" name="radioButton1" value={{1}} checked (change)="aceptarSerContactado($event)">
            <label>Si</label>


        </div>
        <div role="group" aria-label="Radio Button - Alineación horizontal" class="entradas-de-texto-govco col-sm-1 col-md-1 text-center">
            <input type="radio" id="radio10" name="radioButton1" value={{2}} (change)="aceptarSerContactado($event)">
            <label>No</label>


        </div>


    </div>

    <div class="row datosPersonales col-sm-12 col-md-12 text-center" *ngIf="infoSolicitante && clientId != null">

        <div role="group" aria-label="Radio Button - Alineación horizontal" class="entradas-de-texto-govco col-sm-3 col-md-3 text-center">
            <label>Nombre completo</label>
            <input type="text" name="radioButton1" disabled [(ngModel)]="infoSolicitante.nombre">



        </div>
        <div role="group" aria-label="Radio Button - Alineación horizontal" class="entradas-de-texto-govco col-sm-3 col-md-3 text-center">
            <label>Correo electrónico</label>
            <input type="text" name="radioButton1" disabled [(ngModel)]="infoSolicitante.email">



        </div>

        <div role="group" aria-label="Radio Button - Alineación horizontal" class="entradas-de-texto-govco col-sm-3 col-md-3 text-center">
            <label>Documento de identidad</label>
            <input type="text" name="radioButton1" disabled [(ngModel)]="infoSolicitante.id">



        </div>
        <div role="group" aria-label="Radio Button - Alineación horizontal" class="entradas-de-texto-govco col-sm-3 col-md-3 text-center">
            <label>Teléfono</label>
            <input width="100px" type="text" name="radioButton1" disabled [(ngModel)]="infoSolicitante.telefono">



        </div>


    </div>

    <div class="col-sm-12 col-md-12 text-end" style="display: flex; justify-content: flex-end;">

        <br><br>

        <div *ngIf="viewErrorPop" class="container-alerta-govco col-7 col-md-7 pl-7">
            <div class="alert alerta-govco alerta-error-govco aerror" role="alert">
                <span class="alerta-icon-govco alerta-icon-error-govco aerror"></span>
                <p class="alerta-content-text">Los datos ingresados no están completos o contienen errores, por favor verifique nuevamente</p>
            </div>
        </div>

        <div *ngIf="viewSuccessPop" class="container-alerta-govco col-7 col-md-7 pl-7">
            <div class="alert alerta-govco alerta-success-govco asuccess" role="alert">
                <span class="alerta-icon-govco alerta-icon-success-govco asuccess"></span>
                <p class="alerta-content-text">Sus respuestas se han enviado exitosamente</p>
            </div>
        </div>

        <!-- <div *ngIf="!isDisabled" class="container-alerta-govco col-2 col-md-2 pl-2 text-end"> -->
        <button type=" button" (click)="enviarEncuesta()">ENVIAR ENCUESTA</button>

        <!-- </div> -->
    </div>

</div>