import { Component } from '@angular/core';

@Component({
  selector: 'app-other-virtual-windows',
  templateUrl: './other-virtual-windows.component.html',
  styleUrls: ['./other-virtual-windows.component.scss']
})
export class OtherVirtualWindowsComponent {

}
