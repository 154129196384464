import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './Views/home/home.component';
import { QuickAccesComponent } from './Views/quick-acces/quick-acces.component';
import { FormalitiesComponent } from './Views/formalities/formalities.component';
import { ProcedureComponent } from './Views/formalities/procedure/procedure.component';
import { OnlineServicesComponent } from './Views/online-services/online-services.component';
import { DocumentAuthenticityComponent } from './Views/document-authenticity/document-authenticity.component';
import { CitizenPortalComponent } from './Views/citizen-portal/citizen-portal.component';
import { OtherVirtualWindowsComponent } from './Views/other-virtual-windows/other-virtual-windows.component';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatPaginatorModule } from '@angular/material/paginator';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { GoBackUpComponent } from './Shared/go-back-up/go-back-up.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxPaginationModule } from 'ngx-pagination';
import { DocumentFoundComponent } from './Views/document-found/document-found.component';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertModalInformationComponent } from './Shared/alert-modal-information/alert-modal-information.component';
import { msalConfig } from './auth-config';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE } from '@azure/msal-angular';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { SliderModule } from 'angular-image-slider';
import { OnlineServicesModuleComponent } from './Views/online-services-module/online-services-module.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { AccesibilityComponent } from './Shared/accesibility/accesibility.component';
import { RegisterPersonModalComponent } from './Views/register-person-modal/register-person-modal.component';
import { HeaderComponent } from './Views/header/header.component';
import { FooterComponent } from './Shared/footer/footer.component';
import { FunctionaryPageComponent } from './Views/functionary-page/functionary-page.component';
import { InboxManagementComponent } from './Views/inbox-management/inbox-management.component';
import { ManagementComponent } from './Views/management/management.component';
import { MyProfileComponent } from './Views/my-profile/my-profile.component';
import { BreadcrumbComponent } from './Shared/breadcrumb/breadcrumb.component';
import { LegalPersonProfileComponent } from './Views/my-profile/legal-person-profile/legal-person-profile.component';
import { NaturalPersonProfileComponent } from './Views/my-profile/natural-person-profile/natural-person-profile.component';
import { AddressComponent } from './Views/my-profile/natural-person-profile/address/address.component';
import { ConfirmationModalComponent } from './Shared/confirmation-modal/confirmation-modal.component';
import { PoliciesComponent } from './Views/policies/policies.component';
import { RegisterLegalPersonComponent } from './Views/register-person-modal/register-legal-person/register-legal-person.component';
import { RegisterNatrualPersonComponent } from './Views/register-person-modal/register-natrual-person/register-natrual-person.component';
import { OfficialInboxComponent } from './Views/official-inbox/official-inbox.component';
import { ReassignmentRequestComponent } from './Views/official-inbox/reassignment-request/reassignment-request.component';
import { ReassignOfficialRequestComponent } from './Views/official-inbox/reassign-official-request/reassign-official-request.component';
import { AuthInterceptor } from './interceptor/auth-interceptor.service';
import { AdministrationModuleComponent } from './Views/administration-module/administration-module.component';
import { RegisterProcedureComponent } from './Views/administration-module/register-procedure/register-procedure.component';
import { ProcedureCatalogComponent } from './Views/administration-module/procedure-catalog/procedure-catalog.component';
import { EditProcedureModalComponent } from './Views/administration-module/procedure-catalog/edit-procedure-modal/edit-procedure-modal.component';
import { ReportsModuleComponent } from './Views/administration-module/reports-module/reports-module.component';
import { SurveyCatalogComponent } from './Views/administration-module/survey-catalog/survey-catalog.component';
import { SurveyGenerationComponent } from './Views/administration-module/survey-generation/survey-generation.component';
import { SurveyComponent } from './Views/administration-module/survey/survey.component';
import { ServiceAreaComponent } from './Shared/service-area/service-area.component';
import { UsuariosComponent } from './Views/administration-module/usuarios/usuarios.component';
import { AsigFuncRolUsuComponent } from './Views/administration-module/usuarios/asig-func-rol-usu/asig-func-rol-usu.component';
import { ListarUsuariosComponent } from './Views/administration-module/usuarios/listar-usuarios/listar-usuarios.component';
import { ConsFuncRolesUsuComponent } from './Views/administration-module/usuarios/cons-func-rol-usu/cons-func-rol-usu.component';
import { CrearUsuarioComponent } from './Views/administration-module/usuarios/crear-usuario/crear-usuario.component';
import { RolesComponent } from './Views/administration-module/roles/roles.component';
import { AsigRolUsuComponent } from './Views/administration-module/roles/asig-rol-usu/asig-rol-usu.component';
import { ListarRolesComponent } from './Views/administration-module/roles/listar-roles/listar-roles.component';
import { FuncionalidadesComponent } from './Views/administration-module/funcionalidades/funcionalidades.component';
import { CrearFuncComponent } from './Views/administration-module/funcionalidades/crear-editar-func/crear-editar-func.component';
import { ListarFuncComponent } from './Views/administration-module/funcionalidades/listar-func/listar-func.component';
import { UsuariosService } from './Services/roles-usuarios-funcionalidades/usuarios.service';
import { CitizenTrayComponent } from './Views/citizen-tray/citizen-tray/citizen-tray.component';
import { RegistrationActionsComponent } from './Views/administration-module/registration-actions/registration-actions.component';
import {MatSelectModule} from "@angular/material/select";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    QuickAccesComponent,
    FormalitiesComponent,
    ProcedureComponent,
    OnlineServicesComponent,
    DocumentAuthenticityComponent,
    CitizenPortalComponent,
    OtherVirtualWindowsComponent,
    GoBackUpComponent,
    DocumentFoundComponent,
    AlertModalInformationComponent,
    OnlineServicesModuleComponent,
    AccesibilityComponent,
    RegisterPersonModalComponent,
    HeaderComponent,
    FooterComponent,
    FunctionaryPageComponent,
    InboxManagementComponent,
    ManagementComponent,
    MyProfileComponent,
    BreadcrumbComponent,
    LegalPersonProfileComponent,
    NaturalPersonProfileComponent,
    AddressComponent,
    ConfirmationModalComponent,
    PoliciesComponent,
    RegisterLegalPersonComponent,
    RegisterNatrualPersonComponent,
    OfficialInboxComponent,
    ReassignmentRequestComponent,
    ReassignOfficialRequestComponent,
    AdministrationModuleComponent,
    RegisterProcedureComponent,
    ProcedureCatalogComponent,
    EditProcedureModalComponent,
    ReportsModuleComponent,
    SurveyCatalogComponent,
    SurveyGenerationComponent,
    SurveyComponent,
    ServiceAreaComponent,
    UsuariosComponent,
    AsigFuncRolUsuComponent,
    ListarUsuariosComponent,
    ConsFuncRolesUsuComponent,
    CrearUsuarioComponent,
    RolesComponent,
    AsigRolUsuComponent,
    ListarRolesComponent,
    FuncionalidadesComponent,
    CrearFuncComponent,
    ListarFuncComponent,
    CitizenTrayComponent,
    RegistrationActionsComponent


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatIconModule,
    BrowserAnimationsModule,
    MatPaginatorModule,
    HttpClientModule,
    NgxPaginationModule,
    MatDialogModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    SliderModule,
    MatExpansionModule,
    MatSelectModule,
    NgxMatSelectSearchModule


  ],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: UsuarioRolTramiteInterceptorService,
    //   multi: true,
    //   deps: [UsuariosService]
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
