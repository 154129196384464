import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from './../../environments/env';

const urlApiManagerShared = environment.urlApiManagerShared

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  setAddress = new BehaviorSubject(null);
  getAddress$ = this.setAddress.asObservable();

  consultAddress = new BehaviorSubject(false);
  consultAddress$ = this.consultAddress.asObservable();

  setServiceAddress = new BehaviorSubject(null);
  getServiceAddress$ = this.setServiceAddress.asObservable();

  constructor(private http: HttpClient) {
  }



  getAddress2(direccion: string): Observable<any> {
    const url = 'http://sig.saludcapital.gov.co/wsdireccion/direccion.asmx';
    const headers = new HttpHeaders({
      'Content-Type': 'application/soap+xml; charset=utf-8'
    });

    const body = `<?xml version="1.0" encoding="utf-8"?>
      <soap:Envelope xmlns:soap="http://www.w3.org/2003/05/soap-envelope" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">
        <soap:Body>
          <obtenerCodDireccion xmlns="http://200.75.49.126/WsDireccion">
            <Direccion>${direccion}</Direccion>
            <usuario></usuario>
            <clave></clave>
          </obtenerCodDireccion>
        </soap:Body>
      </soap:Envelope>`;

    return this.http.post<any>(url + '?op=obtenerCodDireccion', body, { headers });
  }

  getZones() {
    // return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/SubRed/GetSubRed`);
    return this.http.get<any>(`${urlApiManagerShared}/v1/SubRed/GetSubRed`);
  }

  getLocalities() {
    // return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/Localidad/GetAllLocalidad`);
    return this.http.get<any>(`${urlApiManagerShared}/v1/Localidad/GetAllLocalidad`);
  }

  getUpz() {
    // return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/Upz/GetUpz`);
    return this.http.get<any>(`${urlApiManagerShared}/v1/Upz/GetUpz`);
  }

  getNeighborhood(): Observable<any> {
    return this.http.get<any>(`${urlApiManagerShared}/v1/Barrio/GetBarrios`);
  }
}
