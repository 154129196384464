import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { Roles } from 'src/app/Models/roles.model';
import { ApiService } from 'src/app/Services/api/api.service';
import { RolesService } from 'src/app/Services/roles-usuarios-funcionalidades/roles.service';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Component({
  selector: 'app-listar-roles',
  templateUrl: './listar-roles.component.html',
  styleUrls: ['./listar-roles.component.scss']
})
export class ListarRolesComponent implements OnInit {
  rutaMigaPan = [{ name: 'Inicio', url: '/Home' }, { name: 'Administración', url: '/Home/Administracion' }, { name: 'Consultar roles', url: '/Home/Administracion/roles' }]

  titulo = 'Consulta de roles';
  mensajeDesactivacion = 'Al desactivar un rol los usuarios que lo tenían asignado perderán los permisos ' +
    'relacionados con el rol, sin embargo las configuraciones de rol se conservarán y ' +
    'si en el futuro se reactiva los usuarios recuperarán los permisos del rol';
  mensajeActivacion = 'Al reactivar un rol, éste será restablecido para todos los usuarios que lo tenían ' +
    'asignado, quienes podrán nuevamente usarlo en las mismas funcionalidades que lo ' +
    'tenían en el momento de la inactivación del rol';
  errorAccion = 'La acción solicitada no se pudo completar';
  rolVacio = 'El nombre del rol no puede estar vacio';
  rolExistente = 'El nombre del rol ya existe';
  errorEdicion = 'El rol no pudo ser editado';
  errorCreacion = 'El rol no pudo ser creado';
  mensajeRolNoEncontrado = 'No se encontró un rol con este nombre';
  roles: any[] = [];
  rol: any | undefined;
  pagina = 1;
  dato = null;
  mostrar = false;
  nuevoNombre: string | undefined;
  nuevoRol: string | undefined;
  nombreRol = ''
  rolUser: string;
  authRole: boolean = false;
  adminRole: boolean = false;

  constructor(private rolesService: RolesService, private apiservices: ApiService, public router: Router) { }

  ngOnInit(): void {
    this.rolUser = localStorage.getItem('rol');
    if (this.rolUser == 'AdminFuncional' || this.rolUser == 'AdminTI') {
      this.authRole = true;
      this.adminRole = true;

    } else {
      this.router.navigate(['/Home']);
    }
    this.cargarRoles();

    $("#cancel").on("click", function () {
      $("#caja").val('');
    });
  }

  cargarRoles(): void {
    this.rolesService.traerRolesAutransversal(" "," ")
      .subscribe(resp => {
        this.roles = resp.data;

      });
  }

  borrar(): void {
    this.nombreRol = ''
    this.cargarRoles();
  }

  buscar(texto: string): void {
    if (texto === '') {
      this.cargarRoles();
    } else {
      this.rolesService.traerRolesAutransversal(texto,"rol")
        .subscribe(resp => {

          if (resp.data.length > 0) {
            this.roles = resp.data;
            this.pagina = 1;
          } else {
            this.ventanaEmergenteError(this.mensajeRolNoEncontrado);
            this.cargarRoles();
          }
        });
    }
  }

  mostrarBotones(num: any, nombre: string): void {
    this.dato = num;
    this.nuevoNombre = nombre.trim();
  }

  botonCancelar(): void {
    this.dato = null;
  }

  botonesCrearRol(): void {
    this.mostrar = true;
  }

  cancelarRol(): void {
    this.mostrar = false;
  }

  desactivarRol(tipo: any): void {
    this.ventanaEmergenteWarning(this.mensajeDesactivacion)
      .then(resp => {
        if (resp.value) {

          let filteredrole = this.roles.filter(item => item.idRole == tipo.idRole);

          this.rol = {
            idRole: filteredrole[0].idRole,
            description: filteredrole[0].description,
            displayName: filteredrole[0].displayName,
            value: filteredrole[0].value,
            created: new Date(),
            isEnabled: false
          }
          this.rolesService.activarRolAutenticacion(this.rol)
            .subscribe(rta => {
              if (rta.status == 200) {
                this.ventanaEmergenteExito();
                this.cargarRoles();
                this.registrarAccion('UPDATE')
              } else {
                this.ventanaEmergenteError(this.errorAccion);
              }
            });
        }
      });
  }

  activarRol(tipo: any): void {
    this.ventanaEmergenteWarning(this.mensajeActivacion)
      .then(resp => {
        if (resp.value) {
          let filteredrole = this.roles.filter(item => item.idRole == tipo.idRole);

          this.rol = {
            idRole: filteredrole[0].idRole,
            description: filteredrole[0].description,
            displayName: filteredrole[0].displayName,
            value: filteredrole[0].value,
            created: new Date(),
            isEnabled: true
          }

          this.rolesService.activarRolAutenticacion(this.rol)
            .subscribe(rta => {
              if (rta.status == 200) {
                this.ventanaEmergenteExito();
                this.cargarRoles();
                this.registrarAccion('UPDATE')
              } else {
                this.ventanaEmergenteError(this.errorAccion);
              }
            });
        }
      });
  }

  editarRol(id: number, nombre: string, estado: boolean): void {
    if (nombre == "") {
      this.ventanaEmergenteError(this.rolVacio);
    } else {

      let filteredrole = this.roles.filter(item => item.displayName == nombre);

      if(filteredrole.length> 0)
      {
        this.ventanaEmergenteError(this.rolExistente);
      }
      else
      {

        this.rol = {
          idRole: id,
          description: nombre,
          displayName: nombre,
          value: nombre,
          created: new Date(),
          isEnabled: estado
        }
        this.rolesService.actualizarRolAutenticacion(this.rol)
          .subscribe(resp => {
            if (resp.status == 200) {
              this.ventanaEmergenteExito();
              this.cargarRoles();
              this.registrarAccion('UPDATE')
              this.dato = null;
            } else {
              this.ventanaEmergenteError(this.errorEdicion);
            }
          });
      }
    }
  }

  registrarAccion(type: any) {
    let user = localStorage.getItem('userName');
    let registros = []
    let fechaHoraActual = new Date();

    let accionRegistro = {
      fecha: fechaHoraActual,
      usuario: user,
      operacion: type,
      entidadAfectada: 'N/A',
      tramite: 'N/A',
      rol: type === 'INSERT' ? this.rol.displayName : this.rol.idRole,
      estacion: 'string',
      servidor: "string"
    }
    registros.push(accionRegistro)
    this.apiservices.registrarAcciones(registros).subscribe(res => {
      console.log(res)
    })
    registros = []

  }

  crearRol(nombre: string): void {
    if (nombre == "" || nombre == undefined) {
      this.ventanaEmergenteError(this.rolVacio);
    } else {
      let filteredrole = this.roles.filter(item => item.displayName == nombre);

      if(filteredrole.length> 0)
      {
        this.ventanaEmergenteError(this.rolExistente);
      }
      else
      {
        this.rol = {

          description: nombre,
          displayName: nombre,
          value: nombre
        }

        this.rolesService.crearRolAutenticacion(this.rol)
          .subscribe(resp => {
            if (resp.status == 200) {
              this.ventanaEmergenteExito();
              this.registrarAccion('INSERT')
              this.mostrar = false;
              this.cargarRoles();
            } else {
              this.ventanaEmergenteError(this.errorCreacion);
            }
          });
      }

    }
  }

  ventanaEmergenteWarning(mensaje: string): Promise<SweetAlertResult> {
    let respuesta =
      Swal.fire({
        html: `${mensaje}` +
          '<h4 class="text-warning fw-bold mt-3">¿Desea continuar?</h4>',
        iconHtml: '<div class="material-icons">warning</div>',
        customClass: {
          icon: 'no-bordes'
        },
        allowOutsideClick: false,
        showCloseButton: true,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#0d6efd'
      });
    return respuesta;
  }

  ventanaEmergenteExito(): void {
    Swal.fire({
      title: 'Operación exitosa',
      iconHtml: '<div class="material-icons">done</div>',
      customClass: {
        icon: 'no-bordes',
        title: 'text-success'
      },
      allowOutsideClick: false,
      showCloseButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#0d6efd'
    });
  }

  ventanaEmergenteError(mensaje: string): void {
    Swal.fire({
      title: 'Operación no exitosa',
      text: mensaje,
      iconHtml: '<div class="material-icons">close</div>',
      customClass: {
        icon: 'no-bordes',
        title: 'text-danger'
      },
      allowOutsideClick: false,
      showCloseButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#0d6efd'
    });
  }
}
