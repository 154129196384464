<app-header></app-header>
<app-quick-acces></app-quick-acces>
<app-breadcrumb [ruta]="ruta"></app-breadcrumb>
<div class="container" *ngIf="authRole">
    <app-accesibility></app-accesibility>
    <h1 class="h1-tipografia-govco title-1">Consultar solicitud</h1>
    <p>Ingrese el número de solicitud(radicado)</p>
    <div class="row">
        <div>
            <div class="col-sm-12 col-md-12">
                <div class="row prueba">
                    <div class="col-sm-12 col-md-4">
                        <div class="entradas-de-texto-govco">
                            <label for="input-basico-id">ID de solicitud(radicado)*</label>
                            <input type="text" [(ngModel)]="busqueda">
                        </div>
                    </div>

                    <!-- <div class="col-sm-12 col-md-4">
                        <div class="search-govco">
                            <br />
                            <br />
                            <div class="bar-search-govco">
                                <input type="text"
                                       placeholder="{{tramiteId != '' && fechaFin != '' && fechaInicio!= ''? 'Realice su búsqueda':'Buscar por palabra'}}"
                                       [(ngModel)]="busqueda" class="input-search-govco" aria-label="Buscador"
                                       disabled="{{tramiteId != '' && fechaFin != '' && fechaInicio!= ''? false:true}}" />
                                <button class="icon-search-govco icon-close-search-govco" aria-label="Limpiar"></button>
                                <div class="icon-search-govco search-icon-search-govco" (click)="consultar()" disabled="false" aria-hidden="true">
                                </div>
                                <div *ngIf="tramiteId != '' && fechaFin != '' && fechaInicio!= ''" class="icon-search-govco search-icon-search-govco"
                                     style="background-color: #004884;" (click)="consultar()" aria-hidden="true">
                                </div>
                            </div>
                        </div>
                    </div> -->



                  <div class="col-sm-12 col-md-4" style="margin-top: 50px;">
                        <button (click)="consultarIDSolicitud()">Consultar</button>
                    </div>





                </div>
                <div class="entradas-de-texto-govco">
                    <h2>Resultados de mis solicitudes</h2>
                </div>
                <div class="row">
                    <!-- <div class="col-sm-12 col-md-12">
                        <div class="entradas-de-texto-govco col-sm-12 col-md-4">
                            <label for="input-basico-id">Selecciona la información sobre la cual deseas realizar su consulta</label>
                            <select class="form-select" id="Tipo de Identificación" aria-label="Default select example" [(ngModel)]="tipofiltro">
                                <option value="0" selected disabled>Seleccione una opción</option>
                                <option value="1">Nombre del trámite</option>
                                <option value="2">Fecha de registro (entrada)</option>

                            </select>
                        </div>
                    </div> -->
                    <label for="input-basico-id">Selecciona la información sobre la cual deseas realizar su consulta</label>

                    <div class="col-sm-12 col-md-4">
                        <div class="entradas-de-texto-govco">
                            <label for="input-basico-id">Nombre del trámite</label>
                            <select class="form-select" id="Tipo de Identificación" aria-label="Default select example" (change)="tramitefechas()" [(ngModel)]="tramiteId">
                                <option value="" selected disabled>Seleccione una opción</option>
                                <option *ngFor="let func of funcionalidades" value="{{ func.id }}">{{ func.nameTramite }}</option>
                            </select>
                        </div>
                    </div>
                     <div *ngIf="fechas" class="col-sm-12 col-md-3">
                        <div class="entradas-de-texto-govco">
                            <label for="input-basico-id">Fecha inicio</label>
                            <input type="date" [(ngModel)]="fechaInicio">
                        </div>
                    </div>
                    <div *ngIf="fechas" class="col-sm-12 col-md-3">
                        <div class="entradas-de-texto-govco">
                            <label for="input-basico-id">Fecha fin</label>
                            <input type="date" [(ngModel)]="fechaFin">
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-1 text-center" style=" margin-top: 50px;">
                        <button class="btn btn-primary" (click)="consultarSolicitudFiltro()">Consultar</button>
                    </div>
                </div>
                <div *ngIf="resultado" class="prueba2 col-sm-12 col-md-12">

                    <div class="col-sm-12 col-md-1 camtidadItems descripcion-emergente-govco">
                        <span class="tiptext" style="height: auto; top: 95%;">Número de Registros por página</span>
                        <input type="number" disabledText="true" id="input-basico-id" [(ngModel)]="itemsByPage" placeholder="5" min="5" />
                    </div>

                    <div class="col-sm-12 col-md-3">
                        <div class="has-text-centered">
                            <pagination-controls (pageChange)="pageChanged($event)" nextLabel="Siguiente"
                                                 previousLabel="Anterior"></pagination-controls>
                        </div>
                    </div>
                </div>
                <br>
                <div *ngIf="resultado" class="prueba2 col-sm-12 col-md-12 text-end">
                    <div class="col-sm-12 col-md-5">
                        <label>Mostrando{{allProcedures2.length > 0 ? ((page - 1) * itemsByPage) + 1 : 0}}-{{itemsByPage * page >
                            allProcedures2.length?allProcedures2.length:itemsByPage * page}} de
                            {{allProcedures2.length}} resultados</label>
                    </div>
                </div>
                <br>
                <!-- <div class="row">
                    <div class="col-md-12 info_table">
                        <div class="table-responsive">
                            <table class="table" aria-describedby="reporte de tramites">

                                <thead>

                                    <tr>
                                        <th class="descripcion-emergente-govco">
                                            <span class="tiptext" style="height: auto; right: 95%;">Esta tabla le muestra aquella lista de trámites
                                                cuyo
                                                funcionario asignado solicitó una reasignación hacia otro funcionario, principalmente por temas de
                                                expertise</span>
                                            ID solicitud
                                        </th>
                                        <th>Nombre del trámite</th>
                                        <th>Tipo de trámite</th>
                                        <th>Solicitante</th>
                                        <th>Tipo de solicitante</th>
                                        <th> No. Doc de Identidad</th>
                                        <th>Fecha de registro (entrada)</th>
                                        <th>Estado de la solicitud</th>
                                        <th>Tiempo de atención restante</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let tramite of allProcedures   | paginate: { itemsPerPage: itemsByPage, currentPage: page }; let primero = first ;let ultimo = last; let indice=index">
                                        <td>{{tramite.procedureId}}</td>
                                        <td>{{tramite.procedureName}}</td>
                                        <td>tipo tramite</td>
                                        <td>{{tramite.applicantName}}</td>
                                        <td *ngIf="tipoPersona == 'PN'">Persona Nátural</td>
                                        <td *ngIf="tipoPersona == 'PJ'">Persona Juridica</td>
                                        <td>{{tramite.identificationNumber}}</td>
                                        <td>{{tramite.dateResolution| date: 'dd/MM/yyyy hh:mm a'}}</td>
                                        <td>{{tramite.statusProcedure}}</td>
                                        <td id="color_{{indice}}">{{changeColor(tramite.dateResolution, indice)}}</td>


                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-md-12 info_table">
                        <div class="table-responsive">
                            <table class="table" aria-describedby="reporte de tramites">
                                <thead>
                                    <tr>
                                        <th>ID Trámite</th>
                                        <th>Solicitud</th>
                                        <th>Consecutivo</th>
                                        <th>Fecha de Creación</th>
                                        <th>Tipo de Identificación</th>
                                        <th>Número de Identificación</th>
                                        <th>Persona</th>
                                        <th>Estado del Trámite</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let tramite of allProcedures2   | paginate: { itemsPerPage: itemsByPage, currentPage: page }; let primero = first ;let ultimo = last; let indice=index">
                                        <td>{{ tramite.id_tramite }}</td>
                                        <td>{{ tramite.solicitud }}</td>
                                        <td>{{ tramite.consecutivo }}</td>
                                        <td id="color_{{indice}}">{{changeColor(tramite.fecha_creacion, indice)}}</td>
                                        <!-- <td>{{ tramite.fecha_creacion | date: 'dd/MM/yyyy hh:mm a' }}</td> -->
                                        <td>{{ tramite.tipo_identificacion }}</td>
                                        <td>{{ tramite.nume_identificacion }}</td>
                                        <td>{{ tramite.persona }}</td>
                                        <td>{{ tramite.estado_tramite }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="prueba2 col-sm-12 col-md-12 text-end">
        <div class="col-sm-12 col-md-5" (click)="this.router.navigate(['/Home/functionary']);">
            <button>Regresar</button>
        </div>
    </div> -->
</div>
<div class="container" *ngIf="!authRole">
    <div class="title">
        <h1 class="h1-tipografia-govco title-1">No Permitido</h1>
    </div>
</div>
<app-footer></app-footer>
