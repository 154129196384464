import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../../environments/env';
import { Observable } from 'rxjs';
import { UsuarioRoles } from 'src/app/Models/usuarioRoles.model';
import { UsuarioRolesLast } from 'src/app/Models/usuariosRolesLast.model';

const URL = environment.backUrl;
const URLSecurity = environment.urlApiManagerSecurity;

@Injectable({
  providedIn: 'root'
})
export class UsuarioRolesService {

  constructor(private http: HttpClient) { }

  traerUsuariosRoles(): Observable<UsuarioRoles[]> {
    return this.http.get<UsuarioRoles[]>(`${URL}/UserRol/list`);
  }

  traerUsuarioRoles(id: string, opcion: number): Observable<UsuarioRoles[]> {
    return this.http.get<UsuarioRoles[]>(`${URL}/UserRol/${id}/${opcion}`);
  }

  guardarUsuarioRoles(info: UsuarioRoles[]): Observable<any> {
    return this.http.post<number>(`${URL}/UserRol`, info, { observe: 'response' });
  }

  traerUltimasAsignaciones(info): Observable<any> {/*pdt*/
    return this.http.post<UsuarioRolesLast[]>(`${URL}/UserRol/last-assign`, info);
  }
  traerUltimasAsignacionesAutenticacion(type:string,search:string,pagenunmber:string,pagesize:string): Observable<any> {
    return this.http.get<any>(`${URLSecurity}/v2/Security/GetAsignaciones?type=${type}&search=${search}&pagenumber=${pagenunmber}&pagesize=${pagesize}`);
  }
  actualizarUsuarioRoles(info: UsuarioRoles): Observable<any> {
    return this.http.put<number>(`${URL}/UserRol/update`, info, { observe: 'response' });
  }
  actualizarUsuarioRolesAutenticacion(info: any,idresponsable:string): Observable<any> {
    return this.http.put<any>(`${URLSecurity}/v2/Security/UpdateUserRoleAplications/${idresponsable}`, info, { observe: 'response' });
  }
}
