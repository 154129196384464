import { Component } from '@angular/core';
import { ApiService } from 'src/app/Services/api/api.service';
import { MatDialog, } from '@angular/material/dialog';
import { DocumentFoundComponent } from '../document-found/document-found.component';
import { AlertModalInformationComponent } from 'src/app/Shared/alert-modal-information/alert-modal-information.component';
import {FuncionalidadRolesFull} from "../../Models/funcionalidadesRolesFull.model";
import {FuncionalidadRolesService} from "../../Services/roles-usuarios-funcionalidades/funcionalidades-roles.service";
import {ConfirmationModalComponent} from "../../Shared/confirmation-modal/confirmation-modal.component";

@Component({
  selector: 'app-document-authenticity',
  templateUrl: './document-authenticity.component.html',
  styleUrls: ['./document-authenticity.component.scss']
})
export class DocumentAuthenticityComponent {
  listatramites: any[] = [];

  constructor(private funcionalidadRolesService: FuncionalidadRolesService,
              private api: ApiService, public dialog: MatDialog) { }
  authRole: boolean = false;
  documentInfo: any;
  documentNumber: string = '';

  Codigo: string='';

  ngOnInit(): void {
    let rol = localStorage.getItem('rol');
    if (rol != 'nn' && rol != null) {
      this.authRole = true;
    }
    this.funcionalidadRolesService.traerFuncionalidadRoles()
      .subscribe(resp => {
        this.listatramites = [];

        resp.forEach(item => {
          if(item[0].codTramite.includes("-") && item[0].nameTramite.toLowerCase() !="localhost")
          {
            let tramites = {
              tramiteMainId: item[0].tramiteMainId,
              codTramite: item[0].codTramite.substring(item[0].codTramite.indexOf("-")+1,item[0].codTramite.length),
              nameTramite: item[0].nameTramite,
            }
            this.listatramites.push(tramites);
          }

        });

        this.listatramites.sort((a, b) => a.codTramite - b.codTramite);

      });
  }

  /**
   * Consulta si exite el documento generado a partir el numero de una solicitud
   */
  validarDocument() {

if(this.Codigo=='')
{
  this.dialog.open(ConfirmationModalComponent, {
    data: { type: 'warning', title: 'Trámite incorrecto.', text: 'Debe de seleccionar un tramite primero para realizar la busqueda', acept: "" },
    height: 'auto',
    width: '700px',
  })
}
else {

  if(this.documentNumber=='')

  {
    this.dialog.open(ConfirmationModalComponent, {
      data: { type: 'warning', title: 'Codigo incorrecto.', text: 'Debe de Ingresar un Codigo de Verificacion Primero', acept: "" },
      height: 'auto',
      width: '700px',
    })
  }
  else {
    let CodeNumber= Number.parseInt(this.Codigo ) ;

    let valid=false;

    if(CodeNumber==5)
    {
      valid=true;
      this.api.obtenerDocumentoDispMedicos(this.documentNumber).subscribe(res => {
        if(res.id_tramite=="404")
        {
          this.modalDocumentoNoEncontrado()
        }
        else {
          this.documentInfo = res;
          if(this.documentInfo.fecha_resolucion_articulo!=null)
          {
            let fechaResolucion = new Date(this.documentInfo.fecha_resolucion_articulo); // Convertir a Date
            let dia = fechaResolucion.getDate().toString().padStart(2, '0'); // Obtener el día y agregar cero si es necesario
            let mes = (fechaResolucion.getMonth() + 1).toString().padStart(2, '0'); // Obtener el mes y agregar cero si es necesario
            let anio = fechaResolucion.getFullYear(); // Obtener el año

            this.documentInfo.fecha_resolucion_articulo = `${dia}/${mes}/${anio}`;
          }

          this.modalInfoDocumento(this.documentInfo)
        }
      })
    }
    if(CodeNumber==6 || CodeNumber==16 || CodeNumber==17)
    {
      valid=true;
      this.api.obtenerDocumentoFundaciones(this.documentNumber).subscribe(res => {
        if(res.id_tramite=="404")
        {
          this.modalDocumentoNoEncontrado()
        }
        else {
          this.documentInfo = res;
          if(this.documentInfo.fecha_resolucion_articulo!=null)
          {
            let fechaResolucion = new Date(this.documentInfo.fecha_resolucion_articulo); // Convertir a Date
            let dia = fechaResolucion.getDate().toString().padStart(2, '0'); // Obtener el día y agregar cero si es necesario
            let mes = (fechaResolucion.getMonth() + 1).toString().padStart(2, '0'); // Obtener el mes y agregar cero si es necesario
            let anio = fechaResolucion.getFullYear(); // Obtener el año

            this.documentInfo.fecha_resolucion_articulo = `${dia}/${mes}/${anio}`;
          }

          this.modalInfoDocumento(this.documentInfo)
        }
      })
    }
    if(CodeNumber==10)
    {
      valid=true;
      this.api.obtenerDocumentoExhumacion(this.documentNumber).subscribe(res => {
        if(res.id_tramite=="404")
        {
          this.modalDocumentoNoEncontrado()
        }
        else {
          this.documentInfo = res;
          if(this.documentInfo.fecha_resolucion_articulo!=null)
          {
            let fechaResolucion = new Date(this.documentInfo.fecha_resolucion_articulo); // Convertir a Date
            let dia = fechaResolucion.getDate().toString().padStart(2, '0'); // Obtener el día y agregar cero si es necesario
            let mes = (fechaResolucion.getMonth() + 1).toString().padStart(2, '0'); // Obtener el mes y agregar cero si es necesario
            let anio = fechaResolucion.getFullYear(); // Obtener el año

            this.documentInfo.fecha_resolucion_articulo = `${dia}/${mes}/${anio}`;
          }

          this.modalInfoDocumento(this.documentInfo)
        }
      })
    }
    if(CodeNumber==13 || CodeNumber==14)
    {
      valid=true;
      this.api.obtenerDataInhumacion(this.documentNumber).subscribe(res => {

        if(res.message!="Solicitud OK")
        {
          this.modalDocumentoNoEncontrado()
        }
        else {



          this.api.obtenerDocumentoInhumacion(res.data.idControlTramite).subscribe(resp2 => {
            if(resp2.data.legth==0)
            {
              this.modalDocumentoNoEncontrado()
            }
            else {
              let tramite=""
              switch (resp2[0].idTramite) {
                case 'a289c362-e576-4962-962b-1c208afa0273':
                  tramite = 'Inhumación Indivual';

                  break;
                case 'ad5ea0cb-1fa2-4933-a175-e93f2f8c0060':
                  //inhumacion fetal
                  tramite = 'Inhumación Fetal';

                  break;
                case 'e69bda86-2572-45db-90dc-b40be14fe020':
                  //cremacion individual
                  tramite = 'Cremación Individual';

                  break;
                case 'f4c4f874-1322-48ec-b8a8-3b0cac6fca8e':
                  //cremacionfetal
                  tramite = 'Cremación Fetal ';

                  break;
              }

              this.documentInfo =
                {
                  id_tramite:resp2[0].consecutivo,
                  procedureName:tramite,
                  persona:resp2[0].nombreSolicitante+" "+resp2[0].apellidoSolicitante,
                  nume_identificacion:resp2[0].numeroIdentificacion,
                  fecha_resolucion_articulo:resp2[0].fechaLicencia,
                  num_resolucion_articulo:resp2[0].numeroLicencia,
                  estado_tramite:"Aprobado y firmado"
                };
              if(this.documentInfo.fecha_resolucion_articulo!=null)
              {
                let fechaResolucion = new Date(this.documentInfo.fecha_resolucion_articulo); // Convertir a Date
                let dia = fechaResolucion.getDate().toString().padStart(2, '0'); // Obtener el día y agregar cero si es necesario
                let mes = (fechaResolucion.getMonth() + 1).toString().padStart(2, '0'); // Obtener el mes y agregar cero si es necesario
                let anio = fechaResolucion.getFullYear(); // Obtener el año

                this.documentInfo.fecha_resolucion_articulo = `${dia}/${mes}/${anio}`;
              }

              this.modalInfoDocumento(this.documentInfo)
            }
          })
        }
      })
    }
    if(CodeNumber==19)
    {
      valid=true;
      this.api.obtenerDocumentoTitulos(this.documentNumber).subscribe(res => {
        if(res.id_tramite=="404")
        {
          this.modalDocumentoNoEncontrado()
        }
        else {
          this.documentInfo = res;
          if(this.documentInfo.fecha_resolucion_articulo!=null)
          {
            let fechaResolucion = new Date(this.documentInfo.fecha_resolucion_articulo); // Convertir a Date
            let dia = fechaResolucion.getDate().toString().padStart(2, '0'); // Obtener el día y agregar cero si es necesario
            let mes = (fechaResolucion.getMonth() + 1).toString().padStart(2, '0'); // Obtener el mes y agregar cero si es necesario
            let anio = fechaResolucion.getFullYear(); // Obtener el año

            this.documentInfo.fecha_resolucion_articulo = `${dia}/${mes}/${anio}`;
          }

          this.modalInfoDocumento(this.documentInfo)
        }
      })
    }
    if(CodeNumber==35)
    {
      valid=true;
      this.api.obtenerDocumentoDiscapacidad(this.documentNumber).subscribe(res => {
        if(res.id_tramite=="404")
        {
          this.modalDocumentoNoEncontrado()
        }
        else {
          this.documentInfo = res;
          if(this.documentInfo.fecha_resolucion_articulo!=null)
          {
            let fechaResolucion = new Date(this.documentInfo.fecha_resolucion_articulo); // Convertir a Date
            let dia = fechaResolucion.getDate().toString().padStart(2, '0'); // Obtener el día y agregar cero si es necesario
            let mes = (fechaResolucion.getMonth() + 1).toString().padStart(2, '0'); // Obtener el mes y agregar cero si es necesario
            let anio = fechaResolucion.getFullYear(); // Obtener el año

            this.documentInfo.fecha_resolucion_articulo = `${dia}/${mes}/${anio}`;
          }

          this.modalInfoDocumento(this.documentInfo)
        }
      })
    }
    if(!valid)
    {
      this.modalDocumentoNoDisponible();
    }

/*
    else {

      let documentoId = 0;

      let procedure = 'getProcedure19';
      let filter = this.documentNumber;
      let filterType = 'consecutivo';
      let pagination = '0';

      if (this.documentNumber.includes("REAT")) {
        //TITULOS
        procedure = 'getProcedure19';
      }
      if (this.documentNumber.includes("RECDO")) {
        //dispo
        procedure = 'getProcedure5';
      }
      if (this.documentNumber.includes("REEX")) {
        //exhu
        procedure = 'getProcedure10';
      }
      if (this.documentNumber.includes("REATCAP")) {
        //exhu
        procedure = 'getProcedure22';
      }
      this.api.obtenerDatos(procedure, filter, filterType, pagination).subscribe(res => {
        console.log(res)
        if (res.code === 200 && res.data.length > 0) {
          console.log(res)
          this.documentInfo = res.data[0]
          this.modalInfoDocumento(this.documentInfo)
        } else if (res.code === 200 && res.data.length == 0) {
          this.modalDocumentoNoEncontrado()
        }
      })
    }
    */
  }

}


  }
  /**
   * Modal con la información del documento consultado con exito
   * @param infoDoc
   */
  modalInfoDocumento(infoDoc: object): void {
    let dialogRef = this.dialog.open(DocumentFoundComponent, {
      data: { infoDoc: infoDoc },
      width: '60%',
    });
  }

  /**
   * Modal que indica que el número de documento no se encuentra
   */
  modalDocumentoNoEncontrado(): void {
    this.dialog.open(AlertModalInformationComponent, {
      data: { type: 'fail', title: 'Lo sentimos', text: 'No se encontraron resultados.' },
      width: '540px',
    });
  }
  /**
   * Modal que indica que el tramite no cuenta con codigo de verificacion
   */
  modalDocumentoNoDisponible(): void {
    this.dialog.open(AlertModalInformationComponent, {
      data: { type: 'fail', title: 'Lo sentimos', text: 'El Tramite no cuenta con Codigo de Verificación.' },
      width: '540px',
    });
  }
}
