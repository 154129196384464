import { CrearFuncComponent } from './Views/administration-module/funcionalidades/crear-editar-func/crear-editar-func.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdministrationModuleComponent } from './Views/administration-module/administration-module.component';
import { ProcedureCatalogComponent } from './Views/administration-module/procedure-catalog/procedure-catalog.component';
import { RegisterProcedureComponent } from './Views/administration-module/register-procedure/register-procedure.component';
import { ReportsModuleComponent } from './Views/administration-module/reports-module/reports-module.component';
import { SurveyCatalogComponent } from './Views/administration-module/survey-catalog/survey-catalog.component';
import { SurveyGenerationComponent } from './Views/administration-module/survey-generation/survey-generation.component';
import { FunctionaryPageComponent } from './Views/functionary-page/functionary-page.component';
import { HomeComponent } from './Views/home/home.component';
import { MyProfileComponent } from './Views/my-profile/my-profile.component';
import { OfficialInboxComponent } from './Views/official-inbox/official-inbox.component';
import { OnlineServicesModuleComponent } from './Views/online-services-module/online-services-module.component'
import { SurveyComponent } from './Views/administration-module/survey/survey.component';
import { UsuariosComponent } from './Views/administration-module/usuarios/usuarios.component';
import { ListarUsuariosComponent } from './Views/administration-module/usuarios/listar-usuarios/listar-usuarios.component';
import { AsigFuncRolUsuComponent } from './Views/administration-module/usuarios/asig-func-rol-usu/asig-func-rol-usu.component';
import { ConsFuncRolesUsuComponent } from './Views/administration-module/usuarios/cons-func-rol-usu/cons-func-rol-usu.component';
import { CrearUsuarioComponent } from './Views/administration-module/usuarios/crear-usuario/crear-usuario.component';
import { ListarRolesComponent } from './Views/administration-module/roles/listar-roles/listar-roles.component';
import { AsigRolUsuComponent } from './Views/administration-module/roles/asig-rol-usu/asig-rol-usu.component';
import { ListarFuncComponent } from './Views/administration-module/funcionalidades/listar-func/listar-func.component';
import { CitizenTrayComponent } from './Views/citizen-tray/citizen-tray/citizen-tray.component';
import { RegistrationActionsComponent } from './Views/administration-module/registration-actions/registration-actions.component';
const routes: Routes = [
  { path: '', redirectTo: '/Home', pathMatch: 'full' },
  { path: 'Home', component: HomeComponent },
  { path: 'Home/login', component: HomeComponent },
  { path: 'Home/OnlineServices/:service', component: OnlineServicesModuleComponent },
  { path: 'Home/functionary', component: FunctionaryPageComponent },
  { path: 'Home/myProfile', component: MyProfileComponent },
  { path: 'Home/myProfileNatural', component: MyProfileComponent },
  { path: 'Home/myProfileLegal', component: MyProfileComponent },
  { path: 'Home/BandejaDeFuncionarios', component: OfficialInboxComponent },
  { path: 'Home/Administracion', component: AdministrationModuleComponent },
  { path: 'Home/RegsitrarTramite', component: RegisterProcedureComponent },
  { path: 'Home/CatalogoTramites', component: ProcedureCatalogComponent },
  { path: 'Home/Reportes', component: ReportsModuleComponent },
  { path: 'Home/Reportes/:tipoReporte', component: ReportsModuleComponent },
  { path: 'Home/CatalogoEncuestas', component: SurveyCatalogComponent },
  { path: 'Home/GestionContenido/:tipoEncuesta', component: SurveyGenerationComponent },
  { path: 'Home/GestionContenido', component: SurveyGenerationComponent },
  { path: 'Home/Encuesta/:id', component: SurveyComponent },
  { path: 'Home/Encuesta/:id/:idT/:idS', component: SurveyComponent },
  //PROYECTO LOGIN RUTAS
  //USUARIOS MODULO
  { path: 'Home/Administracion/usuarios', component: ListarUsuariosComponent },
  { path: 'Home/Administracion/cons-func-rol-usu/:id', component: ConsFuncRolesUsuComponent },
  { path: 'Home/Administracion/cons-func-rol-usu', component: ConsFuncRolesUsuComponent },
  { path: 'Home/Administracion/asig-func-rol-usu', component: AsigFuncRolUsuComponent },
  //USUARIOS MODULO
  { path: 'Home/Administracion/roles', component: ListarRolesComponent },
  { path: 'Home/Administracion/asig-rol-usu', component: AsigRolUsuComponent },
  //FUNCIONALIDADES MODULO
  { path: 'Home/Administracion/funcionalidades', component: ListarFuncComponent },
  { path: 'Home/Administracion/crear-funcionalidad', component: CrearFuncComponent },
  { path: 'Home/Administracion/crear-funcionalidad/:nombre', component: CrearFuncComponent },
  { path: 'Home/registroAcciones', component: RegistrationActionsComponent },
  //BANDEJA DE CIUDADANOS
  { path: 'Home/BandejaDeCiudadano', component: CitizenTrayComponent }
];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
