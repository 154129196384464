<app-header></app-header>
<app-quick-acces></app-quick-acces>
<app-breadcrumb [ruta]="ruta"></app-breadcrumb>
<div class="container" *ngIf="authRole">
    <app-accesibility></app-accesibility>
    <app-go-back-up class="up"></app-go-back-up>

    <div class="row">
        <div class="col-md-12 info_table">
            <br>
            <div class="row col-sm-12 col-md-12 text-start" style="justify-content: end;">
                <div class=" col-sm-12 col-md-1 camtidadItems descripcion-emergente-govco">
                    <span class="tiptext" style="height: auto; top: 95%;">Número de Registros por página</span>
                    <input type="number" disabledText="true" id="input-basico-id" [(ngModel)]="itemsByPage" placeholder="5" min="5"
                           (ngModelChange)="itemsByPage" />
                </div>
            </div>
            <br>
            <!-- <div class="row col-sm-12 col-md-12  text-end" style=" justify-content: end;">
                <div class="col-sm-12">

                    <label>Mostrando{{allProcedures.length > 0 ? ((page - 1) * itemsByPage) + 1 : 0}}-{{itemsByPage * page >
                        totalItems?totalItems:itemsByPage * page}} de
                        {{totalItems}} resultados</label>
                </div>
            </div> -->
            <br>
            <div class="table-responsive">
                <table class="table" aria-describedby="reporte de tramites">

                    <thead>

                        <tr>
                            <th class="descripcion-emergente-govco">
                                <span class="tiptext" style="height: auto; right: 40%;">Esta tabla le muestra aquella lista de trámites
                                    cuyo
                                    funcionario asignado solicitó una reasignación hacia otro funcionario, principalmente por temas de
                                    expertise</span>
                                ID transacción
                            </th>
                            <th>Fecha y hora</th>
                            <th>Usuario</th>
                            <th>Operación</th>
                            <th>Entidad afectada</th>
                            <th>Trámite</th>
                            <th>Rol</th>
                            <th>Estación</th>
                            <th>Servidor</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let tramite of allProcedures   | paginate: { itemsPerPage: itemsByPage, currentPage: page, totalItems:totalItems }; let primero = first ;let ultimo = last; let indice=index">
                            <td>{{tramite.id}}</td>
                            <td>{{tramite.fecha}}</td>
                            <td>{{tramite.usuario}}</td>
                            <td>{{tramite.operacion}}</td>
                            <td>{{tramite.entidadAfectada}}</td>
                            <td>{{tramite.tramite}}</td>
                            <td>{{tramite.rol}}</td>
                            <td>{{tramite.estacion}}</td>
                            <td>{{tramite.servidor}}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="col-sm-12 col-md-12 text-center">
                    <div class=" has-text-centered">
                        <pagination-controls (pageChange)="pageChanged($event)" nextLabel="Siguiente" previousLabel="Anterior"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container" *ngIf="!authRole">
    <div class="title">
        <h1 class="h1-tipografia-govco title-1">No Permitido</h1>
    </div>
</div>
<app-footer></app-footer>