import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/Shared/confirmation-modal/confirmation-modal.component';
import { ApiService } from 'src/app/Services/api/api.service';

@Component({
  selector: 'app-legal-person-profile',
  templateUrl: './legal-person-profile.component.html',
  styleUrls: ['./legal-person-profile.component.scss']
})
export class LegalPersonProfileComponent implements OnInit {
  editMode: boolean = false;
  legalForm: any = FormGroup;
  public tipoIdentificacioRepLegal: any[] = [];
  public tipoIdentificacionn: any[] = [];
  infoUser: any;
  constructor(private router: Router, public fb: FormBuilder, public dialog: MatDialog, private apiservices: ApiService,) { }
  userName = localStorage.getItem('userName');
  email = localStorage.getItem('email');
  idPersona = localStorage.getItem('clientId')
  noacept = true;


  ngOnInit(): void {
    this.legalForm = this.fb.group({
      primerNombre: ['', [Validators.required]],
      segundoNombre: [''],
      primerApellido: ['', [Validators.required]],
      segundoApellido: [''],
      telefonoFijo: [''],
      telefonoCelular: ['', [Validators.required]],
      email: ['', [Validators.required]],
      confirmEmail: ['', [Validators.required]]

    })

    this.apiservices.getTipoIdentificacion().subscribe(resp => this.tipoIdentificacionn = resp.data);

    this.legalForm.disable();
    this.getUserVentanilla()
  }



  edit() {
    this.legalForm.enable();
    this.editMode = true;
  }

  getUserVentanilla() {
    let id = localStorage.getItem('clientId');
    this.apiservices.getIdUserVentanilla(id).subscribe(res => {

      if (res.code == 200) {

        localStorage.setItem('idUser', res.data)
        this.getUserInfo(res.data)
      }
    }, err => {
      console.log(err)
    })
  }

  getUserInfo(id: any) {
    this.apiservices.consultarLegalPerson(id).subscribe(resp => {

      this.infoUser = resp.data
      this.legalForm.get('primerNombre').setValue(resp.data.primerNombre);
      this.legalForm.get('segundoApellido').setValue(resp.data.segundoApellido);
      this.legalForm.get('segundoNombre').setValue(resp.data.segundoNombre);
      this.legalForm.get('primerApellido').setValue(resp.data.primerApellido);
      this.legalForm.get('telefonoFijo').setValue(resp.data.telefonoFijo);
      this.legalForm.get('telefonoCelular').setValue(resp.data.telefonoCelular);
      this.legalForm.get('email').setValue(resp.data.email);
      this.legalForm.get('confirmEmail').setValue(resp.data.email);

    })
  }

  goBack() {
    this.legalForm.disable();
    this.editMode = false;
  }

  goBackHome() {
    this.router.navigate(['Home']);
  }

  save() {


    let data = {
      idPersona: localStorage.getItem('idUser'),
      tipoIdentificacion: this.infoUser.tipoIdentificacion,
      numeIdentificacion: this.infoUser.numeroIdentificacion,
      pNombre: this.legalForm.value.primerNombre,
      sNombre: this.legalForm.value.segundoNombre,
      pApellido: this.legalForm.value.primerApellido,
      sApellido: this.legalForm.value.segundoApellido,
      email: this.legalForm.value.email,
      telefonoFijo: this.legalForm.value.telefonoFijo,
      telefonoCelular: this.legalForm.value.telefonoCelular,
      nacionalidad: 0,
      departamento: 0,
      ciudadNacimiento: 0,
      ciudadNacimientoOtro: "string",
      depaResi: 0,
      ciudadResi: 0,
      direResi: "string",
      estadogeo: 0,
      cx: 0,
      cy: 0,
      dirCodificada: "string",
      zona: 0,
      localidad: 0,
      upz: 0,
      barrio: "string",
      fechaNacimiento: "2023-01-27T17:52:05.468Z",
      edad: 0,
      sexo: 0,
      genero: 0,
      orientacion: 0,
      etnia: 0,
      estadoCivil: 0,
      nivelEducativo: 0,
      tipoIdenRl: this.infoUser.tipoIdentificacionRL,
      numeIdenRl: this.infoUser.numeroIdentificacionRL,
      nombreRs: this.infoUser.razonSocial
    }

    this.apiservices.actualizarLegalPerson(data).subscribe(resp => {

      let response = resp
      if (response.code == 200) {
        this.openDialoRegitroExitoso()
      }
    })
  }

  openDialoRegitroExitoso(): void {
    let dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: { type: 'success', title: 'Modificación exitosa', text: 'Información modificada con éxito.', acept: "" },
      height: 'auto',
      width: '700px',
    });
    setTimeout(() => {
      dialogRef.close()
      this.router.navigate(['Home/']);
    }, 3000);
  }

  aceptarPoliticas() {
    this.noacept = !this.noacept
  }

}
