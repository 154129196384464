import { Component } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-service-area',
  templateUrl: './service-area.component.html',
  styleUrls: ['./service-area.component.scss']
})
export class ServiceAreaComponent {

  constructor(private router: Router) {}
  navigateToSurvey(id:string) {
    window.open(`/Home/Encuesta/${id}`, '_blank');

  }
}
