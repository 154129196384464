<form role="form" [formGroup]="naturalForm" class="scroll">
    <div class="row">

        <legend>
            <h3>Registro Persona Natural.</h3>
        </legend>

        <div class="alert alert alert-warning" role="alert">
            <strong>¡Importante!</strong>
            <p>
                Si el registro es para el Servicio Digital Certificado de Discapacidad, es necesario que la persona a registrar sea la persona que
                requiere el certificado de discapacidad.
            </p>
            <p>
                Por favor registre los datos exactamente como aparecen en su documento de identidad, estos datos serán usados para la generación de
                los Documentos asociados al trámite solicitado y su posterior reporte a entidades de vigilancia y control.
            </p>
        </div>

        <h3>Datos Básicos.</h3>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="primer-nombre">Primer Nombre<span class="required">(*)</span></label>
                <input type="text" id="primer-nombre" placeholder="Primer Nombre" formControlName="primerNombre" maxlength="50"
                       pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ\s]+" />
            </div>
            <div class="formRequired"
                 *ngIf="naturalForm.get('primerNombre').hasError('required') && (naturalForm.get('primerNombre').dirty || naturalForm.get('primerNombre').touched)">
                Campo requerido.
            </div>
            <div class="formRequired text-start"
                 *ngIf="naturalForm.get('primerNombre').hasError('pattern') && (naturalForm.get('primerNombre').dirty || naturalForm.get('primerNombre').touched)">
                Solo se permiten letras.
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="segundo-nombre">Segundo Nombre</label>
                <input type="text" id="segundo-nombre" placeholder="Segundo Nombre" formControlName="segundoNombre" maxlength="50"
                       pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ\s]+" />
            </div>
            <div class="formRequired text-start"
                 *ngIf="naturalForm.get('segundoNombre').hasError('pattern') && (naturalForm.get('segundoNombre').dirty || naturalForm.get('segundoNombre').touched)">
                Solo se permiten letras.
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="primer-apellido">Primer Apellido<span class="required">(*)</span></label>
                <input type="text" id="primer-apellido" placeholder="Primer Apellido" formControlName="primerApellido" maxlength="50"
                       pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ\s]+" />
            </div>
            <div class="formRequired"
                 *ngIf="naturalForm.get('primerApellido').hasError('required') && (naturalForm.get('primerApellido').dirty || naturalForm.get('primerApellido').touched)">
                Campo requerido.
            </div>
            <div class="formRequired text-start"
                 *ngIf="naturalForm.get('primerApellido').hasError('pattern') && (naturalForm.get('primerApellido').dirty || naturalForm.get('primerApellido').touched)">
                Solo se permiten letras.
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="segundo-apellido">Segundo Apellido</label>
                <input type="text" id="segundo-apellido" placeholder="Segundo Apellido" formControlName="segundoApellido" maxlength="50"
                       pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ\s]+" />
            </div>
            <div class="formRequired text-start"
                 *ngIf="naturalForm.get('segundoApellido').hasError('pattern') && (naturalForm.get('segundoApellido').dirty || naturalForm.get('segundoApellido').touched)">
                Solo se permiten letras.
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="tipoIdendificacionId">Tipo identificación<span class="required">(*)</span></label>
                <select class="form-select" id="tipoIdendificacionId" aria-label="Default select example" formControlName="tipoDocumento">
                    <option value="" selected disabled>Seleccione</option>
                    <option *ngFor="let item of tipoIdentificacionn" [value]="item.idTipoIdentificacion">{{item.descripcion}}</option>
                </select>
            </div>
            <div class="formRequired"
                 *ngIf="naturalForm.get('tipoDocumento').errors && (naturalForm.get('tipoDocumento').dirty || naturalForm.get('tipoDocumento').touched)">
                Campo requerido.
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="N. Documento Identidad">Número de identificación<span class="required">(*)</span></label>
                <input type="text" id="N. Documento Identidad" placeholder="Número Documento Identidad" formControlName="numeroIdentificacion"
                       oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="11" />
            </div>
            <div class="formRequired"
                 *ngIf="naturalForm.get('numeroIdentificacion').errors && (naturalForm.get('numeroIdentificacion').dirty || naturalForm.get('numeroIdentificacion').touched)">
                Campo requerido.
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Teléfono fijo">Teléfono fijo</label>
                <input type="text" id="Teléfono fijo" placeholder="Teléfono fijo" formControlName="telefonoFijo"
                       oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="7" />
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Teléfono celular">Teléfono celular<span class="required">(*)</span></label>
                <input type="text" id="Teléfono celular" placeholder="Teléfono celular" formControlName="telefonoCelular"
                       oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="10" />
            </div>
            <div class="formRequired"
                 *ngIf="naturalForm.get('telefonoCelular').errors && (naturalForm.get('telefonoCelular').dirty || naturalForm.get('telefonoCelular').touched)">
                Campo requerido.
            </div>
        </div>

        <div class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Correo electrónico">Correo electrónico<span class="required">(*)</span></label>
                <input type="email" id="Correo electrónico" placeholder="Correo electrónico" formControlName="email" maxlength="50" />
            </div>
            <div class="formRequired" *ngIf="naturalForm.get('email').errors && (naturalForm.get('email').dirty || naturalForm.get('email').touched)">
                Campo requerido.
            </div>
        </div>

        <!-- <div class="col-sm-12 col-md-6 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Confirmar Correo electrónico">Confirmar Correo electrónico<span class="required">(*)</span></label>
                <input type="email" id="Confirmar Correo electrónico" placeholder="Confirmar Correo electrónico" formControlName="confirmEmail"
                       maxlength="50" />
            </div>
            <div class="formRequired"
                 *ngIf="naturalForm.get('confirmEmail').errors && (naturalForm.get('confirmEmail').dirty || naturalForm.get('confirmEmail').touched)">
                Campo requerido.
            </div>
            <div class="formRequired" *ngIf="naturalForm.value.email != naturalForm.value.confirmEmail">
                No coincide con el campo Correo electrónico .
            </div>
        </div> -->

        <legend>
            <h3>Datos Geográficos.</h3>
        </legend>

        <div class="row text-center">
            <div class="col-sm-12 col-md-12 text-center">
                <div class="entradas-de-texto-govco">
                    <label for="Nacionalidad">Nacionalidad<span class="required">(*)</span></label>
                    <select class="form-select" id="Nacionalidad" aria-label="Default select example" formControlName="nacionalidad"
                            (change)="obtenerNacionalidad()">
                        <option value="" selected disabled>Seleccione</option>
                        <option *ngFor="let nacionalidad of paiss" [value]="nacionalidad.idPais">{{nacionalidad.nombre}}</option>
                    </select>
                </div>
                <div class="formRequired"
                     *ngIf="naturalForm.get('nacionalidad').errors && (naturalForm.get('nacionalidad').dirty || naturalForm.get('nacionalidad').touched)">
                    Campo requerido.
                </div>
            </div>
        </div>

        <div class="row text-center">
            <div class="col-sm-12 col-md-12 text-center">
                <div class="entradas-de-texto-govco">
                    <label for="Nacionalidad" *ngIf="nacionalidadPersona === 'Nacional'">Departamento de nacimiento<span
                              class="required">(*)</span></label>
                    <select *ngIf="nacionalidadPersona === 'Nacional'" class="form-select" id="Departamento de nacimiento"
                            aria-label="Default select example" formControlName="departamentoNacimiento" (change)="obtenerMunicipio()">
                        <option value="" selected disabled>Seleccione</option>
                        <option *ngFor="let departamento of departaments" [value]="departamento.idDepartamento">{{departamento.descripcion}}</option>
                    </select>
                </div>
                <div class="formRequired"
                     *ngIf="naturalForm.get('departamentoNacimiento').errors && (naturalForm.get('departamentoNacimiento').dirty || naturalForm.get('departamentoNacimiento').touched)">
                    Campo requerido.
                </div>
            </div>
        </div>

        <div class="row text-center">
            <div class="col-sm-12 col-md-12 text-center">
                <div class="entradas-de-texto-govco">
                    <label for="Nacionalidad">Ciudad de nacimiento<span class="required">(*)</span></label>
                    <select *ngIf="nacionalidadPersona === 'Nacional'" class="form-select" id="Nacionalidad" aria-label="Default select example"
                            formControlName="ciudadNacimiento">
                        <option value="" selected disabled>Seleccione</option>
                        <option *ngFor="let municipio of municipios" [value]="municipio.idMunicipio">{{municipio.descripcion}}</option>
                    </select>
                    <input *ngIf="nacionalidadPersona === 'Extranjero'" type="tel" id="Departamento de nacimiento"
                           placeholder="Departamento de nacimiento" formControlName="ciudadNacimientoOtro" />
                </div>
                <div class="formRequired"
                     *ngIf="naturalForm.get('ciudadNacimiento').errors && (naturalForm.get('ciudadNacimiento').dirty || naturalForm.get('ciudadNacimiento').touched)">
                    Campo requerido.
                </div>
            </div>
        </div>

        <div class="row text-center">
            <div class="col-sm-12 col-md-12 text-center">
                <div class="entradas-de-texto-govco">
                    <label for="Departamento de residencia">Departamento de residencia<span class="required">(*)</span></label>
                    <select class="form-select" id="Departamento de residencia" aria-label="Default select example"
                            formControlName="departamentoResidencia" (change)="cambioDepResi()">
                        <option value="" selected disabled>Seleccione</option>
                        <option *ngFor="let departamento of departaments" [value]="departamento.idDepartamento">{{departamento.descripcion}}</option>
                    </select>
                </div>
                <div class="formRequired"
                     *ngIf="naturalForm.get('departamentoResidencia').errors && (naturalForm.get('departamentoResidencia').dirty || naturalForm.get('departamentoResidencia').touched)">
                    Campo requerido.
                </div>
            </div>
        </div>

        <div class="row text-center">
            <div class="col-sm-12 col-md-12 text-center">
                <div class="entradas-de-texto-govco">
                    <label for="Ciudad de residencia">Ciudad de residencia<span class="required">(*)</span></label>
                    <select class="form-select" id="Ciudad de residencia" aria-label="Default select example" formControlName="ciudadResidencia">
                        <option value="" disabled selected>Seleccione</option>
                        <option *ngFor="let municipio of municipioN" [value]="municipio.idMunicipio">{{municipio.descripcion}}</option>
                    </select>
                </div>
                <div class="formRequired"
                     *ngIf="naturalForm.get('ciudadResidencia').errors && (naturalForm.get('ciudadResidencia').dirty || naturalForm.get('ciudadResidencia').touched)">
                    Campo requerido.
                </div>
            </div>
        </div>
        <div *ngIf="naturalForm.value.departamentoResidencia.length == '' ?false:true" class="col-sm-12 col-md-12 text-center">
            <app-address [disabledInputs]="false" ($address)="getAddress($event)" ($urbanAddres)="cambioTipoDir($event)"></app-address>
        </div>
        <div *ngIf="!direccionAreaUrbanaBgta && tipodir == 'urbana'" class="alert alert alert-warning text-center" role="alert">
            <strong>¡Advertencia!</strong>
            <p>
                La dirección no logro ser georreferenciada, verifique la dirección. Si la dirección es correcta, por favor ingrese los siguientes
                datos.
            </p>
        </div>
        <div class="col-sm-12 col-md-6 text-center" *ngIf="datosDireccion && direccionConfirmada && tipodir == 'urbana'">
            <div class="entradas-de-texto-govco">
                <label for="Letra">Zona</label>
                <select class="form-select" id="Letra" aria-label="Default select example" formControlName="zona" [(ngModel)]="zonaSeleccionado"
                        (change)="filtrarLocalidad()">
                    <option value="" disabled selected>Seleccione</option>
                    <option *ngFor="let zona of zoneList" [value]="zona.idSubRed">
                        {{zona.nombre}}
                    </option>
                </select>
                <div class="formRequired" *ngIf="naturalForm.value.zona === undefined">
                    Campo requerido.
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 text-center" *ngIf="datosDireccion && direccionConfirmada && tipodir == 'urbana'">
            <div class="entradas-de-texto-govco">
                <label for="Letra">Localidad</label>
                <select class="form-select" id="Letra" aria-label="Default select example" formControlName="localidad"
                        [(ngModel)]="localidadSeleccionada" (change)="filtrarUpz()">
                    <option value="" disabled selected>Seleccione</option>
                    <option *ngFor="let localidad of localityList" [value]="localidad.idLocalidad">
                        {{localidad.nombre}}
                    </option>
                </select>
                <div class="formRequired" *ngIf="naturalForm.value.localidad === undefined">
                    Campo requerido.
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 text-center" *ngIf="datosDireccion && direccionConfirmada && tipodir == 'urbana'">
            <label for="Letra">UPZ</label>
            <select class="form-select" id="Letra" aria-label="Default select example" formControlName="upz" [(ngModel)]="upzSeleccionado"
                    (change)="filtrarBarrio()">
                <option value="" disabled selected>Seleccione</option>
                <option *ngFor="let upz of upzList" [value]="upz.id_upz">
                    {{upz.nom_upz}}
                </option>
            </select>
            <div class="formRequired" *ngIf="naturalForm.value.upz === undefined">
                Campo requerido.
            </div>
        </div>
        <div class="col-sm-12 col-md-6 text-center" *ngIf="datosDireccion && direccionConfirmada && tipodir == 'urbana'">
            <label for="Letra">Barrio</label>
            <select class="form-select" id="Letra" aria-label="Default select example" formControlName="barrio" [(ngModel)]="barrioSeleccionado">
                <option value="" disabled selected>Seleccione</option>
                <option *ngFor="let barrio of neighborhoodList" [value]="barrio.id_barrio">
                    {{barrio.nombre_barrio}}
                </option>
            </select>
            <div class="formRequired" *ngIf="naturalForm.value.barrio === undefined">
                Campo requerido.
            </div>
        </div>

        <legend>
            <h3>Datos Demográficos.</h3>
        </legend>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Fecha de nacimiento">Fecha de nacimiento<span class="required">(*)</span></label>
                <input type="date" id="Fecha de nacimiento" placeholder="Fecha de nacimiento" formControlName="fechaNacimiento" />
            </div>
            <div class="formRequired"
                 *ngIf="naturalForm.get('fechaNacimiento').errors && (naturalForm.get('fechaNacimiento').dirty || naturalForm.get('fechaNacimiento').touched)">
                Campo requerido.
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Sexo">Sexo<span class="required">(*)</span></label>
                <select class="form-select" id="Sexo" aria-label="Default select example" formControlName="sexo">
                    <option value="" disabled selected>Seleccione</option>
                    <option *ngFor="let sexo of sexoo" [value]="sexo.idSexo">{{sexo.descripcionSexo}}</option>
                </select>
            </div>
            <div class="formRequired" *ngIf="naturalForm.get('sexo').errors && (naturalForm.get('sexo').dirty || naturalForm.get('sexo').touched)">
                Campo requerido.
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Género">Género<span class="required">(*)</span></label>
                <select class="form-select" id="Género" aria-label="Default select example" formControlName="genero">
                    <option value="" disabled selected>Seleccione</option>
                    <option *ngFor="let genero of generoo" [value]="genero.orden">{{genero.descripcion}}</option>
                </select>
            </div>
            <div class="formRequired"
                 *ngIf="naturalForm.get('genero').errors && (naturalForm.get('genero').dirty || naturalForm.get('genero').touched)">
                Campo requerido.
            </div>
        </div>

        <div class="col-sm-12 col-md-3 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Orientación sexual">Orientación sexual<span class="required">(*)</span></label>
                <select class="form-select" id="Orientación sexual" aria-label="Default select example" formControlName="orientacionSexual">
                    <option value="" disabled selected>Seleccione</option>
                    <option *ngFor="let orientacion of orientacionn" [value]="orientacion.orden">{{orientacion.descripcion}}</option>
                </select>
            </div>
            <div class="formRequired"
                 *ngIf="naturalForm.get('orientacionSexual').errors && (naturalForm.get('orientacionSexual').dirty || naturalForm.get('orientacionSexual').touched)">
                Campo requerido.
            </div>
        </div>

        <div class="col-sm-12 col-md-4 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Etnia">Etnia<span class="required">(*)</span></label>
                <select class="form-select" id="Etnia" aria-label="Default select example" formControlName="etnia">
                    <option value="" disabled selected>Seleccione</option>
                    <option *ngFor="let etnia of etniaa" [value]="etnia.idEtnia">{{etnia.nombre}}</option>
                </select>
            </div>
            <div class="formRequired" *ngIf="naturalForm.get('etnia').errors && (naturalForm.get('etnia').dirty || naturalForm.get('etnia').touched)">
                Campo requerido.
            </div>
        </div>

        <div class="col-sm-12 col-md-4 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Estado Civil">Estado Civil<span class="required">(*)</span></label>
                <select class="form-select" id="Estado Civil" aria-label="Default select example" formControlName="estadoCivil">
                    <option value="" disabled selected>Seleccione</option>
                    <option *ngFor="let estadoCivil of estadoCivill" [value]="estadoCivil.idEstadoCivil">{{estadoCivil.nombre}}
                    </option>
                </select>
            </div>
            <div class="formRequired"
                 *ngIf="naturalForm.get('estadoCivil').errors && (naturalForm.get('estadoCivil').dirty || naturalForm.get('estadoCivil').touched)">
                Campo requerido.
            </div>
        </div>

        <div class="col-sm-12 col-md-4 text-center">
            <div class="entradas-de-texto-govco">
                <label for="Nivel Educativo">Nivel Educativo<span class="required">(*)</span></label>
                <select class="form-select" id="Nivel Educativo" aria-label="Default select example" formControlName="nivelEducativo">
                    <option value="" disabled selected>Seleccione</option>
                    <option *ngFor="let niveleducativo of nivelEducativoo" [value]="niveleducativo.idNivelEducativo">{{niveleducativo.nombre}}
                    </option>
                </select>
            </div>
            <div class="formRequired"
                 *ngIf="naturalForm.get('nivelEducativo').errors && (naturalForm.get('nivelEducativo').dirty || naturalForm.get('nivelEducativo').touched)">
                Campo requerido.
            </div>
        </div>

        <div class="col-12 col-md-12 pl-4 text-center">
            <div class="col-12">
                <br><br>
                <button [disabled]="naturalForm.invalid" type="button" class="btn-govco fill-btn-govco" (click)="SaveNaturalPerson()">Guardar</button>
                <button type="button" class="btn-govco fill-btn-govco" (click)="openDialogFail()">Regresar</button>
            </div>
        </div>
    </div>
</form>