<app-header></app-header>
<app-quick-acces></app-quick-acces>
<app-breadcrumb [ruta]="ruta"></app-breadcrumb>
<div class="container" *ngIf="authRole">
    <app-accesibility></app-accesibility>
    <h1 class="h1-tipografia-govco title-1">Catálogo de encuestas</h1>
    <div class="row">

        <div class="col-sm-12 col-md-12">
            <h3>Encuestas creadas</h3>
            <div class="row">
                <div class="col-md-12">
                    <div class="colum">
                        <div class="entradas-de-texto-govco col-sm-12 col-md-12 text-end">
                            <a class="descripcion-emergente-govco" [routerLink]="['/Home/GestionContenido/ADD']">
                                <span class="tiptext" style="height: auto; right: 95%; text-align: start;">Crear nueva encuesta</span>
                                <span class="govco-plus-circle"></span>
                            </a>
                        </div>
                        <table class="table" class=" table table-general" aria-describedby="tableDescActiveRows">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Nombre</th>
                                    <th>
                                        Acciones
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let encuesta of encuestas let indice = index">
                                    <td *ngIf="encuesta.surveyTypeId == 1">EUX</td>
                                    <td *ngIf="encuesta.surveyTypeId == 2">EST</td>
                                    <td>{{encuesta.name}}</td>
                                    <td>
                                        <span class="govco-eye-1" style="color: #004884;" (click)="visualizarEncuesta(encuesta)"></span>
                                        <span class=" govco-edit" style="color: #004884;" (click)="editarEncuesta(encuesta)"></span>
                                        <span class="govco-minus-circle" style="color: #004884;" (click)="popUpConfirmacion(encuesta)"></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="prueba2 col-sm-12 col-md-12 text-end">
            <button (click)="router.navigate(['/Home/Administracion']);">Regresar</button>
        </div>
    </div>
    <hr>

</div>
<div class="container" *ngIf="!authRole">
    <div class="title">
        <h1 class="h1-tipografia-govco title-1">No Permitido</h1>
    </div>
</div>
<app-footer></app-footer>