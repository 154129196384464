import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { Funcionalidades } from 'src/app/Models/funcionalidades.model';
import { FuncionalidadRolesFull } from 'src/app/Models/funcionalidadesRolesFull.model';
import { UsuarioRoles } from 'src/app/Models/usuarioRoles.model';
import { Usuarios } from 'src/app/Models/usuarios.model';
import { UsuarioRolesLast } from 'src/app/Models/usuariosRolesLast.model';
import { FuncionalidadRolesService } from 'src/app/Services/roles-usuarios-funcionalidades/funcionalidades-roles.service';
import { FuncionalidadesService } from 'src/app/Services/roles-usuarios-funcionalidades/funcionalidades.service';
import { RolesService } from 'src/app/Services/roles-usuarios-funcionalidades/roles.service';
import { MatTableDataSource } from '@angular/material/table';
import { UsuarioRolesService } from 'src/app/Services/roles-usuarios-funcionalidades/usuarios-roles.service';
import { UsuariosService } from 'src/app/Services/roles-usuarios-funcionalidades/usuarios.service';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { AlertModalInformationComponent } from 'src/app/Shared/alert-modal-information/alert-modal-information.component';
import { ApiService } from 'src/app/Services/api/api.service';

@Component({
  selector: 'app-asig-rol-usu',
  templateUrl: './asig-rol-usu.component.html',
  styleUrls: ['./asig-rol-usu.component.scss']
})
export class AsigRolUsuComponent implements OnInit {
  rutaMigaPan = [{ name: 'Inicio', url: '/Home' }, { name: 'Administración', url: '/Home/Administracion' }, { name: 'Asignación de roles', url: '/Home/Administracion/asig-rol-usu' }]

  titulo = 'Asignación de funcionarios a roles de un trámite';
  tituloUltimasAsignaciones = 'Consulte las últimas asignaciones'
  informacionIncompleta = 'Se debe seleccionar al menos un usuario y un rol de alguna funcionalidad';
  confirmacionAccion = 'Los roles y funcionalidades seleccionadas serán asignados a los usuarios seleccionados';
  errorAsignacion = 'Las asignaciones seleccionadas no pudieron realizarse';
  errorModificarUltAsig = 'Este usuario ya tiene un rol asignado al tramite seleccionado';
  errorModificacion = 'La acción solicitada no se pudo completar';
  errorMasDeUnRol = 'No se puede asignar más de un rol a un mismo tramite, ¿Desea modificar el anterior rol ' + 'al nuevo rol seleccionado?';
  mensajeUsuarioNoEncontrado = 'EL usuario no fue encontrado'
  errorAsignacionesRepetidas = 'No se pudieron hacer algunas asignaciones debido a que ya existen en base ' + 'de datos ó el usuario ya tiene asignado un rol al tramite seleccionado';
  modelo1 = 0;
  m_usu = 0;
  m_func = 0;
  m_rol = 0;
  numero = null;
  estado = false;
  cadena: string[] = [];
  objeto: FuncionalidadRolesFull | undefined;
  list_roles_origen: any[] = [];
  list_roles_destino: any[] = [];
  list_roles_asig: any[] = [];
  indiceRolOrigen: any;
  indiceRolDestino: any;
  rolOrigen: any | undefined;
  rolDestino: any | undefined;
  rol_func_final: any[] = [];
  funcionalidades: any[] = [];

  funcionalidadActual: any  ;
  funcionalidadesAll: any[] = [];
  usuarios: any[] = [];
  usuariosRoles: any[] = [];
  ult_asig: any[] = [];
  usuarioRol: UsuarioRoles | undefined;
  list_usu_n: string[] = [];
  paginador1: number = 1;
  paginador2: number = 1;
  cambio2 = false;
  indice: string = '';
  nombreUsuario: string = '';
  rol: string;
  authRole: boolean = false;
  adminRole: boolean = false;
  contador = 0;
  listRoles: any;
  tipofiltro = '0'
  valueFiltro: ''
  totalItems: 0;
  usuarios2 = []
  dataSource = new MatTableDataSource<any>(this.usuarios);
  direccionIP: string;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private funcionalidadRolesService: FuncionalidadRolesService,
    private funcionalidadesService: FuncionalidadesService,
    private rolesService: RolesService,
    private usuariosService: UsuariosService,
    private usuarioRolesService: UsuarioRolesService,
    public dialog: MatDialog,
    public router: Router,
    private apiservices: ApiService) { }

  ngOnInit(): void {
    this.rol = localStorage.getItem('rol');
    this.obtenerip()

    if (this.rol == 'AdminFuncional' || this.rol == 'AdminTI') {
      this.authRole = true;
      this.adminRole = true;

    } else {
      this.router.navigate(['/Home']);
    }
    this.cargarFuncionalidades();
    this.cargarUsuarios();
    this.cargarRoles()
    // this.ultimasAsignaciones();

    $("#cancel").on("click", function () {
      $("#caja").val('');
    });
    this.dataSource.paginator = this.paginator;
  }

  cargarRoles(): void {
    this.rolesService.traerRolesAutransversal(" "," ")
      .subscribe(resp => {
        this.listRoles = resp.data;
      });
  }

  cargarFuncionalidades(): void {
    this.funcionalidadesService.traerFuncionalidadesAutenticacion(" "," ")
      .subscribe(resp => {
        resp.data.forEach(item =>
        {

          if(item.codigoAplicativo!="" && item.codigoAplicativo!="00")
          {
            this.funcionalidades.push(item)
          }


        });
        this.funcionalidadesAll = this.funcionalidades;
      });
  }

  obtenerip() {
    this.apiservices.getIPAddress().subscribe(res => {
      if (res) {
        this.direccionIP = res.ip;
      }
    },
      error => {
        console.error('Error al obtener la dirección IP:', error);
      }
    );
  }


  registrarAccion(type: any) {

    let user = localStorage.getItem('userName');
    let registros = []
    let fechaHoraActual = new Date();
    if (type == 'INSERT') {
      this.usuariosRoles.map((ele, index) => {
        let accionRegistro = {
          fecha: fechaHoraActual,
          usuario: user,
          operacion: type,
          entidadAfectada: ele.usersId,
          tramite: ele.tramitesMainId,
          rol: ele.rolesId,
          estacion: 'string',
          servidor: "string"
        }
        registros.push(accionRegistro)
      })
    } else if ('UPDATE') {
      let accionRegistro = {
        fecha: fechaHoraActual,
        usuario: user,
        operacion: type,
        entidadAfectada: this.usuarioRol.usersId,
        tramite: this.usuarioRol.tramitesMainId,
        rol: this.usuarioRol.rolesId,
        estacion: 'string',
        servidor: "string"
      }
      registros.push(accionRegistro)
    }
    this.apiservices.registrarAcciones(registros).subscribe(res => {
      console.log(res)
    })
    this.cancelar();
    registros = []

  }

  cargarUsuarios(): void {
    this.usuariosService.traerUsuariosAutenticacion(" "," ")
      .subscribe(resp => {
        this.usuarios=[];

        resp.data.forEach(value => {
          let userid=this.usuarios.filter(usuario  => usuario.idUsuario == value.idUsuario);

          if (userid.length==0)
          {
            this.usuarios.push(value)
          }
        })

      });
  }

  ultimasAsignaciones(value: string,page:any): void {
    if(page==1)
    {
      this.paginator.pageIndex = 0;
    }
    this.paginador2=page;

    let data = {
      pagina: this.paginador2,
      regPorPagina: 10,
      informacion: value,
      filtro: this.tipofiltro
    }

    this.usuarioRolesService.traerUltimasAsignacionesAutenticacion(this.tipofiltro,value,page+"","10")
      .subscribe(resp => {
        this.ult_asig = resp.data;
        this.totalItems = resp.count

        //TODO AJUSTE PABLO
        /*
        resp.informacion.forEach(element => {
          element.email = this.usuarios.find(x => x.adId == element.userADId ).email;
        });
*/

        if (this.totalItems == 0) {
          this.modalDocumentoNoEncontrado()
        }
      });
  }

  func_seleccion(e: any, x: number): void {

    let Aplication=this.funcionalidades.filter(x => x.nombreAplicativo == e.value);
    this.funcionalidadActual=Aplication[0];
    if(x==1)
    {
      this.list_roles_origen = Aplication[0].listaRoles;

    }
    else {
      this.list_roles_asig = Aplication[0].listaRoles;
    }
  }

  seleccionRolOrigen(id: any): void {
    this.indiceRolOrigen = id;
    this.rolOrigen = this.list_roles_origen.find(x => x.idRol == id);
  }

  seleccionRolDestino(id: any): void {
    this.indiceRolDestino = id;
    this.rolDestino = this.list_roles_destino.find(x => x.idRol == id);
  }

  pageChanged1(event) {
    this.paginador1 = event
    // this.ultimasAsignaciones(this.valueFiltro)
  }

  pageChanged2(event) {
    this.paginador2 = event.pageIndex + 1
    this.ultimasAsignaciones(this.valueFiltro,this.paginador2)
  }

  modalDocumentoNoEncontrado(): void {
    this.dialog.open(AlertModalInformationComponent, {
      data: { type: 'fail', title: 'Lo sentimos', text: 'No se encontraron resultados.' },
      width: '540px',
    });
  }

  agregarRol(): void {
    if (this.rolOrigen != undefined) {
      this.rol_func_final.forEach(item => {
        if (this.rolOrigen?.tramiteMainId == item.tramiteMainId) {
          this.objeto = item;
          this.estado = true;
        }
      });

      if (this.estado) {
        this.ventanaEmergenteWarning(this.errorMasDeUnRol)
          .then(resp => {
            if (resp.value) {
              this.cambio(this.objeto, this.rolOrigen);
            }
          });
      }

      if (!this.estado) {
        if (!this.cadena.includes(this.rolOrigen.rolName!)) {
          this.list_roles_destino.push(this.rolOrigen!);
          this.cadena.push(this.rolOrigen.rolName);
          this.rol_func_final.push(this.rolOrigen);
        } else {
          this.rol_func_final.push(this.rolOrigen);
        }
      }
    }
  }

  cambio(antiguo: any, nuevo: any): void {  this.rol_func_final.forEach(item => {
      if (antiguo.nombreRol == item.nombreRol && antiguo.idRol != item.idRol) {

        this.cambio2 = true;
      }
    });

    if (this.cambio2) {
      this.cadena.push(nuevo.nombreRol!);
      this.list_roles_destino.push(nuevo);
    } else {
      this.cadena = this.cadena.filter(x => x != antiguo.nombreRol);
      this.list_roles_destino = this.list_roles_destino.filter(x => x.nombreRol != antiguo.nombreRol);
      if (!this.cadena.includes(nuevo.nombreRol!)) {
        this.cadena.push(nuevo.nombreRol!);
        this.list_roles_destino.push(nuevo);
      }
    }

    this.rol_func_final = this.rol_func_final.filter(x => x.idRol != antiguo.idRol);
    this.rol_func_final.push(nuevo);

    this.estado = false;
    this.cambio2 = false;
    this.indiceRolOrigen = 0;
    this.rolOrigen = undefined;
    this.objeto = undefined;
  }

  quitarRol(): void {
    if (this.rolDestino != undefined) {
      this.list_roles_destino = this.list_roles_destino.filter(x => x.idRol != this.rolDestino?.idRol);
      this.rol_func_final = this.rol_func_final.filter(x => x.idRol != this.rolDestino?.idRol);
      this.cadena = this.cadena.filter(x => x != this.rolDestino?.rolName);
      this.indiceRolDestino = 0;
      this.rolDestino = undefined;
    }
  }

  buscar(texto: string): void {
    if (texto != '' && texto!=undefined) {
      this.usuariosService.traerUsuariosAutenticacion("nombre",texto)
        .subscribe(resp => {
          this.usuarios=[];

          resp.data.forEach(value => {
            let userid=this.usuarios.filter(usuario  => usuario.idUsuario == value.idUsuario);

            if (userid.length==0)
            {
              this.usuarios.push(value)
            }
          })
          if(this.usuarios.length==0)
          {
            this.ventanaEmergenteError(this.mensajeUsuarioNoEncontrado);
            this.cargarUsuarios();
          }

        });

    } else {
      this.cargarUsuarios();
    }

  }

  borrar(): void {
    this.nombreUsuario = ''
    this.cargarUsuarios();
  }

  todos(e: any): void {
    this.list_usu_n = [];
    this.usuarios.forEach(x => {
      x.inicio = e.target.checked
      if (e.target.checked) {
        this.list_usu_n.push(x.idUsuario!)
      }
    });

  }

  mostrarBotones(num: any, usuADId: any, funcId: any, rolId: any, rolFunc: string): void {
    this.funcionalidadRolesService.consultarFuncionalidadRoles(rolFunc)
      .subscribe(resp => {
        this.list_roles_asig = resp;
      });
    this.numero = num;
    this.m_usu = usuADId;
    this.m_func = funcId;
    this.m_rol = rolId;
  }

  botonCancelar(): void {
    this.numero = null;
    // this.ultimasAsignaciones();
  }

  cancelar(): void {
    this.modelo1 = 0;
    this.list_usu_n = [];
    this.indiceRolOrigen = 0;
    this.indiceRolDestino = 0;
    this.rolOrigen = undefined;
    this.rolDestino = undefined;
    this.cadena = [];
    this.list_roles_origen = [];
    this.list_roles_destino = [];
    this.rol_func_final = [];
    this.cargarUsuarios();
    $("#all").prop("checked", false);
  }

  agregarUsuario(e: any): void {
    if (e.target.checked) {

      this.list_usu_n.push(e.target.value+"");



    } else {
      this.list_usu_n = this.list_usu_n.filter(val => val != e.target.value+"");


    }
  }

  modificarFunc(num: any, e: any): void {
    this.ult_asig[num].tramiteId = e.target.value;
    this.ult_asig[num].nameTramite = e.target[e.target.selectedIndex].text;
    this.func_seleccion(e, 2);
    this.ult_asig[num].rolId = undefined;
  }

  modificarRol(num: any, e: any): void {
    this.ult_asig[num].rolId = e.target.value;
    this.ult_asig[num].rolName = e.target[e.target.selectedIndex].text;
  }

  modificarUsu(num: any, e: any): void {
    this.usuariosService.traerIdUsuarioPorADId(e.target.value).subscribe(res => {
      this.ult_asig[num].userId = res;
    });
    this.ult_asig[num].userName = e.target[e.target.selectedIndex].text;
    this.ult_asig[num].userADId = e.target.value;
  }

  async guardar(): Promise<void> {
    if (this.list_usu_n.length == 0 || this.rol_func_final.length == 0) {
      this.ventanaEmergenteError(this.informacionIncompleta);
    } else {
      const resp = await this.ventanaEmergenteWarning(this.confirmacionAccion)
      if (resp.value) {
        this.procesarAsignaciones();

      }
    }
  }

  async procesarAsignaciones() {

    for (const item of this.rol_func_final) {
      this.list_usu_n.forEach(usuario => {
        let roldto=
          {
            idAplicativo: this.funcionalidadActual.idAplicativo,
            idRole: item.idRol,
            idUsuario: usuario
          }
        this.usuariosRoles.push(roldto);

      });


      this.usuarioRolesService.actualizarUsuarioRolesAutenticacion(this.usuariosRoles,localStorage.getItem('clientId')).subscribe((resp) => {
        if (resp.status === 200) {
          this.ventanaEmergenteExito();
          this.cancelar();
          this.registrarAccion('INSERT')
        } else {
          this.ventanaEmergenteError(this.errorAsignacion);
        }
      });


    }


  }



  modificarAsignaciones(num: any): void {
    if (this.ult_asig[num].rolId == undefined) {
      this.ventanaEmergenteError('Debe escoger un rol');
    } else {
      this.contador = 0;
      this.usuarioRolesService.traerUsuarioRoles(this.ult_asig[num].userId!, 1)
        .subscribe(obj => {
          obj.forEach(x => {
            if (x.tramitesMainId == this.ult_asig[num].tramiteId && x.id != this.ult_asig[num].userRolId) {
              this.contador++;
            }
          });

          if (this.contador == 0) {
            this.usuarioRol = {
              id: this.ult_asig[num].userRolId,
              usersId: this.ult_asig[num].userId,
              rolesId: this.ult_asig[num].rolId,
              tramitesMainId: this.ult_asig[num].tramiteId,
              roles: undefined,
              tramites: undefined,
              fechaCreacion: this.ult_asig[num].fechaCreacion,

            }

            this.usuarioRolesService.actualizarUsuarioRoles(this.usuarioRol)
              .subscribe(resp => {
                this.OnHandleResponse(resp);
              });
          } else {
            this.ventanaEmergenteError(this.errorModificarUltAsig);
          }
        });
    }
  }
  OnHandleResponse(resp:any)
  {
    if (resp.status == 200) {
      this.ventanaEmergenteExito();
      this.numero = null;
      this.registrarAccion('UPDATE')

    } else {
      this.ventanaEmergenteError(this.errorModificacion);
      this.numero = null;
    }
  }


  ventanaEmergenteExito(): void {
    Swal.fire({
      title: 'Operación exitosa',
      iconHtml: '<div class="material-icons">done</div>',
      customClass: {
        icon: 'no-bordes',
        title: 'text-success'
      },
      allowOutsideClick: false,
      showCloseButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#0d6efd'
    });
  }

  ventanaEmergenteError(mensaje: string): void {
    Swal.fire({
      title: 'Operación no exitosa',
      text: `${mensaje}`,
      iconHtml: '<div class="material-icons">close</div>',
      customClass: {
        icon: 'no-bordes',
        title: 'text-danger'
      },
      allowOutsideClick: false,
      showCloseButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#0d6efd'
    });
  }

  ventanaEmergenteWarning(mensaje: string): Promise<SweetAlertResult> {
    let respuesta =
      Swal.fire({
        html: `${mensaje}` +
          '<h4 class="text-warning fw-bold mt-3">¿Desea continuar?</h4>',
        iconHtml: '<div class="material-icons">warning</div>',
        customClass: {
          icon: 'no-bordes'
        },
        allowOutsideClick: false,
        showCloseButton: true,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#0d6efd'
      });
    return respuesta;
  }

  filterMyOptions($event: any) {
    this.funcionalidades = [];
    if (!!$event) {
      this.funcionalidadesAll.forEach(item => {

        const expresion = new RegExp(`${$event}.*`, 'i');
        const result: boolean = expresion.test(item.nombreAplicativo);

        if (result) {
          this.funcionalidades.push(item);
        }

      });
    } else {
      this.funcionalidades = this.funcionalidadesAll;
    }
  }
}
