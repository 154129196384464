<div class="policiesContainer">
    <div class="close">
        <mat-dialog-actions>
            <mat-icon mat-dialog-close>close</mat-icon>
        </mat-dialog-actions>
    </div>
    <div class="dody">
        <h3>Política de protección de datos personales </h3>
        <p class="alert alert alert-primary" role="alert">Autorizo el uso de datos personales, conforme a la Ley 1581 de 2012 y las demás normativa
            referidas
            sobre la materia, cómo el decreto reglamentario de la misma (Decreto 1377 de 2013), Por el cual
            se reglamenta parcialmente la Ley 1581 de 2012, Derogado Parcialmente por el Decreto 1081 de 2015
        </p>
        <label>
            <input type="checkbox" [(ngModel)]="data.aceptAuth" />
            <p>He leído y acepto la <a href="http://www.saludcapital.gov.co/Documents/Politica_Proteccion_Datos_P.pdf" target="_blank" rel="noopener">
                    política de protección y tratamiento de datos personales.
                </a></p>
        </label>

    </div>

    <div>
        <button class="btn-govco fill-btn-govco" [disabled]="!data.aceptAuth" mat-dialog-close [mat-dialog-close]="data.aceptAuth"
                type="submit">AUTORIZO</button>
        <button mat-dialog-close (click)="onNoClick()" type="submit">NO AUTORIZO</button>

    </div>
</div>