import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { UsuarioRoles } from 'src/app/Models/usuarioRoles.model';
import { ApiService } from 'src/app/Services/api/api.service';
import { UsuarioRolesService } from 'src/app/Services/roles-usuarios-funcionalidades/usuarios-roles.service';
import { UsuariosService } from 'src/app/Services/roles-usuarios-funcionalidades/usuarios.service';
import { AlertModalInformationComponent } from 'src/app/Shared/alert-modal-information/alert-modal-information.component';
import { ConfirmationModalComponent } from 'src/app/Shared/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-register-legal-person',
  templateUrl: './register-legal-person.component.html',
  styleUrls: ['./register-legal-person.component.scss']
})
export class RegisterLegalPersonComponent implements OnInit {

  legalForm: any = FormGroup;
  list_usu_roles: UsuarioRoles[] = [];


  public tipoIdentificacionn: any[] = [];
  public tipoIdentificacioRepLegal: any[] = [];
  razonSolcialRequired: boolean = false;
  validate = false;

  constructor(public fb: FormBuilder, private apiservices: ApiService, public dialog: MatDialog, private router: Router, public msal: MsalService, private userService: UsuariosService, private usuarioRolesService: UsuarioRolesService) {

  }

  ngOnInit(): void {
    let nombres:string[] = localStorage.getItem('userName').split(" ");
    let apellidos:string[] = localStorage.getItem('lastName').split(" ");

    console.log(nombres.length);
    console.log(apellidos.length);


    this.legalForm = this.fb.group({
      nombreRazonSocial: ['', [Validators.required]],
      nit: ['', [Validators.required]],
      dv: ['', [Validators.required]],
      tipoDocumentoRepresentanteLegal: ['', [Validators.required]],
      numeroDocumentoRepresentanteLegal: ['', [Validators.required]],
      primerNombre: [nombres[0], [Validators.required]],
      segundoNombre: [nombres.length > 1?nombres[1]:''],
      primerApellido: [apellidos[0], [Validators.required]],
      segundoApellido: [apellidos.length > 1?apellidos[1]:''],
      tipoDocumento: ['', [Validators.required]],
      // numeroIdentificacion: ['', [Validators.required]],
      telefonoFijo: [''],
      telefonoCelular: ['', [Validators.required]],
      email: [{ value: localStorage.getItem('email'), disabled: true }, [Validators.required]],
      confirmEmail: [{ value: localStorage.getItem('email'), disabled: true }, [Validators.required]]

    })

    this.apiservices.getTipoIdentificacion().subscribe(resp => {
      this.tipoIdentificacionn.push(resp.data.find((element: { descripcion: string; }) => element.descripcion === "NIT"));
      this.tipoIdentificacioRepLegal = resp.data;

    });
  }

  resetForm() {
    this.legalForm = this.fb.group({
      nombreRazonSocial: ['', [Validators.required]],
      nit: ['', [Validators.required]],
      dv: ['', [Validators.required]],
      tipoDocumentoRepresentanteLegal: ['', [Validators.required]],
      numeroDocumentoRepresentanteLegal: ['', [Validators.required]],
      primerNombre: ['', [Validators.required]],
      segundoNombre: [''],
      primerApellido: ['', [Validators.required]],
      segundoApellido: [''],
      tipoDocumento: ['', [Validators.required]],
      // numeroIdentificacion: ['', [Validators.required]],
      telefonoFijo: [''],
      telefonoCelular: ['', [Validators.required]],
      email: ['', [Validators.required]],
      confirmEmail: ['', [Validators.required]]

    })
  }


  async SaveLegalPerson() {
    let data = {
      //primerNombre: localStorage.getItem('userName'),
      primerNombre: this.legalForm.value.primerNombre,
      //primerApellido: localStorage.getItem('lastName'),
      segundoNombre: this.legalForm.value.segundoNombre,
      primerApellido: this.legalForm.value.primerApellido,
      segundoApellido: this.legalForm.value.segundoApellido,
      tipoDocumento: this.legalForm.value.tipoDocumento,
      numeroIdentificacion: this.legalForm.value.nit,
      telefonoFijo: this.legalForm.value.telefonoFijo || 'No regist',
      telefonoCelular: this.legalForm.value.telefonoCelular,
      // email: this.legalForm.value.email,
      email: localStorage.getItem('email'),
      tipoDocumentoRepresentanteLegal: this.legalForm.value.tipoDocumentoRepresentanteLegal,
      numeroDocumentoRepresentanteLegal: parseInt(this.legalForm.value.numeroDocumentoRepresentanteLegal),
      nombreRazonSocial: this.legalForm.value.nombreRazonSocial
    }
    this.apiservices.saveLegalPerson(data).subscribe(resp => {
      if (resp.code == 200) {
        if (resp.data) {
          this.registroCompleto(resp.data)
        }
      }
    }, err => {
      let dialogRef = this.dialog.open(AlertModalInformationComponent, {
        data: { type: 'fail', title: 'Registro Fallido.', text: err.error.message, acept: "" },
        height: 'auto',
        width: '700px',
      });
      setTimeout(() => {
        dialogRef.close()
        window.location.reload()
      }, 3000);
    })
  }

  registroCompleto(idPersonRegister: any) {
    let tiempoTranscurrido = Date.now();
    let hoy = new Date(tiempoTranscurrido);
    let data = {
      oid: localStorage.getItem('clientId'),
      email: localStorage.getItem('email'),
      nombreCompleto: localStorage.getItem('userName'),
      created: hoy,
      idPersonaVentanilla: idPersonRegister,
      isEnabled: true
    }
    this.apiservices.actualizarRegistroPersona(data).subscribe(resp => {
      if (resp.code == 200) {
        this.guardarUsuarioBdVu()
        //agregar rol para  finalizar
        // this.openDialoRegitroExitoso()
      }
    })
  }

  guardarUsuarioBdVu() {
    let data = {
      id: localStorage.getItem('clientId'),
      email: localStorage.getItem('email'),
      pwd: this.legalForm.value.numeroIdentificacion,
      firtsName: this.legalForm.value.primerNombre,
      lastName: this.legalForm.value.primerApellido,
      userName: localStorage.getItem('userName'),
      position: "NA",
      area: "NA"
    }
    this.userService.guardarUsuario(data).subscribe(res => {
      if (res.status == 200) {
        console.log(res)
        this.addUserRole()
      }
    })
  }

  addRolVu() {
    this.list_usu_roles.push({
      id: "0",
      usersId: localStorage.getItem('clientId'),
      rolesId: "447dacb5-eeb9-48f3-a204-05b88992db7c",
      //OLD tramitesMainId: "d010d048-02e8-42d8-b61f-320911047ff4",
      tramitesMainId: "34029A78-EAAC-4CEA-9AD2-6040665C9916",
      roles: undefined,
      tramites: undefined,
      fechaCreacion: undefined
    })
    this.usuarioRolesService.guardarUsuarioRoles(this.list_usu_roles).subscribe((resp) => {
      this.openDialoRegitroExitoso()
    })
  }

  addUserRole() {
    let data = {
      idUser: localStorage.getItem('clientId'),
      //Siempre que se ingresa de esta manera es un ciudadano
      idRole: '58EDA51F-7E19-47C4-947F-F359BD1FC732'
    }
    this.apiservices.addUserRole(data).subscribe(resp => {
      if (resp.code == 200) {
        localStorage.setItem('rol', 'Ciudadano');
        localStorage.setItem('tipoPersona', 'PJ');
        this.addRolVu()
      }
    })
  }

  // async addPoliticaSeguridad(){
  //   let data = {
  //     fecha: 'string',
  //     id_usuario: 'string',
  //     nombre: 'string',
  //     apellido: 'string'
  //   }
  //   this.apiservices.addPoliticaSeguridad(data).subscribe(res =>{
  //     console.log(data);
  //   })
  // }

  // async getPoliticaSeguridad() {
  //   let data = '074bf5b9-6292-4a84-bb66-333ffbf24440'
  //   this.apiservices.getPoliticaSeguridad(data).subscribe(res => {
  //     console.log(data);
  //   })
  // }


  openDialoRegitroExitoso(): void {
    let dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: { type: 'success', title: 'Registro éxitoso.', text: 'Información de registro almacenada con éxito.', acept: "" },
      height: 'auto',
      width: '700px',
    });

    setTimeout(() => {
      dialogRef.close()
      location.reload();
    }, 3000);
  }


  openDialogFail(): void {
    this.dialog.open(ConfirmationModalComponent, {
      data: { type: 'warning', title: 'Eliminar registro.', text: 'Los datos ingresados se perderán y será redirecionado a la página principal.', acept: "" },
      height: 'auto',
      width: '700px',
    }).afterClosed().subscribe(result => {

      if (result) {
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      }
    });
  }

  async onLogOut() {
    this.msal.logoutPopup().subscribe((data) => {
      console.log(data)
    })
    localStorage.clear();
    this.router.navigate(['Home']);
  }
}
