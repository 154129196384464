import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/env';
import { Funcionalidades } from 'src/app/Models/funcionalidades.model';
const URL = environment.backUrl;
const URLSecurity = environment.urlApiManagerSecurity;
@Injectable({
  providedIn: 'root'
})
export class FuncionalidadesService {

  constructor(private http: HttpClient) { }

  traerFuncionalidades(): Observable<Funcionalidades[]> {
    return this.http.get<Funcionalidades[]>(`${URL}/TramitesMain/list`);
  }
  traerFuncionalidadesAutenticacion(type:string,search:string): Observable<any> {
    return this.http.get<any>(`${URLSecurity}/v2/Security/GetRolesAplications?type=${type}&search=${search}`)
  }



  traerFuncionalidad(nombre: string): Observable<Funcionalidades> {
    return this.http.get<Funcionalidades>(`${URL}/TramitesMain/${nombre}`);
  }

  crearFuncionalidad(objeto: Funcionalidades): Observable<any> {
    return this.http.post<number>(`${URL}/TramitesMain`, objeto, { observe: 'response' });
  }

  desactivarFuncionalidad(id: number): Observable<number> {
    return this.http.delete<number>(`${URL}/TramitesMain/off/${id}`);
  }

  activarFuncionalidad(id: number): Observable<number> {
    return this.http.delete<number>(`${URL}/TramitesMain/on/${id}`);
  }

  editarFuncionalidad(objeto: Funcionalidades): Observable<any> {
    return this.http.put<number>(`${URL}/TramitesMain/update`, objeto, { observe: 'response' });
  }

  revisarNombreFuncionalidad(nombre: string): Observable<number> {
    return this.http.get<number>(`${URL}/TramitesMain/search/name/${nombre}`);
  }

  revisarCodigoFuncionalidad(codigo: string): Observable<number> {
    return this.http.get<number>(`${URL}/TramitesMain/search/code/${codigo}`);
  }
}
