import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { filter, Subject, lastValueFrom } from 'rxjs';
import { LoginI, TokenI } from 'src/app/Models/auth';
import { ResponseI } from 'src/app/Models/response';
import { environment } from 'src/environments/env';
import { CookieService } from 'ngx-cookie-service';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { takeUntil } from 'rxjs/operators';
import { b2cPolicies } from 'src/app/auth-config';
import CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit, OnDestroy {


  url: string = environment.tokenUrl;
  baseUrl: string = environment.backUrl;
  public static token: any;
  refreshTokenIntervalId: any;


  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(private http: HttpClient,
    private cookie: CookieService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    // public jwtHelper: JwtHelperService
  ) { }


  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });
  }


  async LoginByTokenAsync(form: LoginI): Promise<boolean> {

    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      withCredentials: true
    };

    let direccion = this.url + 'login';
    try {
      let data = await lastValueFrom(this.http.post<TokenI>(direccion, form, options));

      this.loadCook(data);

      return true;
    } catch (error: any) {
      return false;
    }
  }

  loadCook(data: TokenI) {
    this.setCookie('acclien', data.accessToken);
    this.setCookie('trclien', 'internal-user');
    this.setCookie('rtclien', data.refreshToken);
    this.setCookie('urclien', data.userId);
  }

  async refreshTokenAsync(refreshToken: string, userId: string) {
    let direccion = this.url + 'refresh-token';
    try {
      this.http.post<any>(direccion, {
        tokenValue: refreshToken,
        userID: userId,
      })

      return true;
    } catch (error: any) {
      return false;
    }
  }

  async logOutAsync() {
    let direccion = this.url + 'logout';
    try {
      if (AuthService.token)

        this.http.post<ResponseI>(direccion, {
          tokenValue: AuthService.token.refreshToken,
          userID: AuthService.token.userId,
        })

      AuthService.token = undefined;
    } catch (error: any) {
      AuthService.token = undefined;
    }
  }

  // hasTheRol(rolName: any): boolean {
  //   const token = this.jwtHelper.decodeToken(this.getCookie('acclien'));
  //   return token.Epidemiologia.includes(rolName);
  // }


  Log(): boolean {
    return true;
  }

  getCookie(name: string) {
    return this.cookie.get(name);
  }

  setCookie(name: string, value: string) {
    this.cookie.set(name, value);
  }

  rmCookie() {
    this.cookie.deleteAll();
  }


  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  loginB2C() {
    this.authService.loginPopup()
      .subscribe(
        (data) => {
          const idToken = this.handleLoginSuccess(data);

        },
        (error) => {
          this.handleLoginError(error);
        }
      );
  }

  private handleLoginSuccess(data) {
    return data.idToken;
  }

  private handleLoginError(error) {
    this.logoutSys();
  }

  loginB2C01(userFlowRequest?: RedirectRequest | PopupRequest) {

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        let a1 = this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });

      } else {
        let a2 = this.authService.loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });

      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        let a3 = this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);

      } else {
        let a8 = this.authService.loginRedirect(userFlowRequest);

      }
    }
  }

  logoutB2C() {
    this.authService.logout();
    this.logoutSys();
  }

  editProfile() {
    let editProfileFlowRequest = {
      scopes: ["openid"],
      authority: b2cPolicies.authorities.editProfile.authority,
    };

    this.loginB2C01(editProfileFlowRequest);
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  logoutSys() {
    this.rmCookie();
    this.ngOnDestroy();
  }

  setValorToken(sesionData: any) {

    localStorage.setItem('flujob2c', JSON.stringify(sesionData));
    localStorage.setItem('clientId', sesionData.uniqueId);
    localStorage.setItem('userName', sesionData.idTokenClaims.given_name);
    localStorage.setItem('lastName', sesionData.idTokenClaims.family_name);
    localStorage.setItem('idToken', sesionData.idToken);
    localStorage.setItem('nombreParaMostrar', sesionData.idTokenClaims.name);
    localStorage.setItem('email', sesionData.idTokenClaims.emails[0])
    this.setCurrentUser(sesionData)
  }

  setCurrentUser(claims: any, idToken?: any, refreshToken?: any): void {
    if (claims) {

      let tokenNuevo = this.signToken(claims['idTokenClaims'], "llaveSegura")
      const currentUser: any = { ...claims['idTokenClaims'], idToken: tokenNuevo };
      localStorage.setItem('currentUser', JSON.stringify(currentUser));

    }

  }



  getLoggedUser(): any {
    return localStorage.getItem('currentUser');
  }

  signToken(payload: any, key: string) {
    let secret = key;
    let token: any = this.encodeToken(payload);

    let signature: any = CryptoJS.HmacSHA256(token, secret);
    signature = this.base64url(signature);

    return token + "." + signature;
  }


  encodeToken(payload: any) {
    let header = {
      "alg": "HS256",
      "typ": "JWT"
    };

    let stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
    let encodedHeader = this.base64url(stringifiedHeader);

    let stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(payload));
    let encodedData = this.base64url(stringifiedData);

    return encodedHeader + "." + encodedData
  }

  base64url(source: any) {
    let encodedSource = CryptoJS.enc.Base64.stringify(source);

    encodedSource = encodedSource.replace(/=+$/, '');

    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');

    return encodedSource;
  }
}
