<app-header></app-header>
<app-quick-acces></app-quick-acces>
<app-breadcrumb [ruta]="rutaMigaPan"></app-breadcrumb>
<div class="container" *ngIf="authRole">
    <app-accesibility></app-accesibility>
    <div class="title">
        <h1 class="h1-tipografia-govco title-1">Registrar información del trámite</h1>
    </div>
    <h3 class="h3-tipografia-govco title-3">Información General</h3>

    <div class="row col-sm-12 col-md-12" [formGroup]="tramiteForm">
        <div class="row col-sm-6 col-md-6">
            <div class="row col-sm-12 col-md-12 text-center">
                <div class="entradas-de-texto-govco col-sm-5 col-md-5 text-start">
                    <label for="Razón Social">Nombre del trámite<span class="required">(*)</span></label>

                </div>
                <div class="entradas-de-texto-govco col-sm-7 col-md-7 text-start">
                    <input type="text" id="Razón Social" placeholder="Nombre del trámite" formControlName="name" />

                </div>

            </div>

            <div class="row col-sm-12 col-md-12 text-center">
                <div class="entradas-de-texto-govco col-sm-5 col-md-5 text-start">
                    <label for="Razón Social">Duración<span class="required">(*)</span></label>

                </div>
                <div class="entradas-de-texto-govco col-sm-7 col-md-7 text-start">
                    <input type="number" id="Razón Social" placeholder="Duración" formControlName="durationInDays" min="1" inputmode="numeric"
                           pattern="\d+" />
                    <select *ngIf="this.tramiteForm.value.durationInDays != ''" class="form-select" id="Tipo de Identificación"
                            aria-label="Default select example" formControlName="durationTypeId">
                        <option value="" selected disabled>Seleccione</option>
                        <option value="2">días calendario</option>
                        <option value="1">días hábiles</option>
                    </select>

                </div>

            </div>

            <div class="row col-sm-12 col-md-12 text-center">
                <div class="entradas-de-texto-govco col-sm-5 col-md-5 text-start">
                    <label for="Razón Social">Costo<span class="required">(*)</span></label>

                </div>
                <div role="group" aria-label="Radio Button - Alineación horizontal"
                     class="radio entradas-de-texto-govco col-sm-5 col-md-5 text-center" style="display: flex;">
                    <input type="radio" id="radio11" name="radioButton1" value="1" (change)="costo($event)">
                    <label for="radio11">Si</label>
                    <input type="radio" id="radio12" name="radioButton1" value="2" (change)="costo($event)" checked>
                    <label for="radio12">No</label>

                </div>
            </div>

            <div *ngIf="this.tramiteForm.value.hasCost" class="row col-sm-12 col-md-12 text-center">
                <div class="entradas-de-texto-govco col-sm-5 col-md-5 text-start">
                    <label for="Razón Social">Valor<span class="required">(*)</span></label>

                </div>
                <div class="entradas-de-texto-govco col-sm-7 col-md-7 text-start">
                    <input type="number" id="Razón Social" placeholder="Valor del tramite" formControlName="cost" />

                </div>
            </div>

            <div class="row col-sm-12 col-md-12 text-center">
                <div class="entradas-de-texto-govco col-sm-5 col-md-5 text-start">
                    <label for="Razón Social">Clasificación (Atención al público)<span class="required">(*)</span></label>

                </div>
                <div class="entradas-de-texto-govco col-sm-7 col-md-7 text-start">
                    <select class="form-select" id="Tipo de Identificación" aria-label="Default select example" formControlName="paperworkCategoryId">
                        <option value="" selected disabled>Seleccione</option>
                        <option *ngFor="let categoriaTramite of categorias" value="{{categoriaTramite.id}}">
                            {{categoriaTramite.description}}
                        </option>
                    </select>

                </div>

            </div>

            <div class="row col-sm-12 col-md-12 text-center">
                <div class="entradas-de-texto-govco col-sm-5 col-md-5 text-start">
                    <label for="Razón Social">Realizar trámite<span class="required">(*)</span></label>

                </div>
                <div class="entradas-de-texto-govco col-sm-7 col-md-7 text-start">
                    <input type="url" id="Razón Social" placeholder="URL hacia pantalla de inicio" formControlName="redirectUrl" />

                </div>

            </div>

            <div class="row col-sm-12 col-md-12 text-center">
                <div class="entradas-de-texto-govco col-sm-5 col-md-5 text-start">
                    <label for="Razón Social">Personalidad legal aplicable<span class="required">(*)</span></label>

                </div>
                <div class="entradas-de-texto-govco col-sm-7 col-md-7 text-start">
                    <select class="form-select" id="Tipo de Identificación" aria-label="Default select example" (change)="mirar($event)">
                        <option value="" selected disabled>Seleccione</option>
                        <option value="PJPN">Persona Jurídica y Persona Natural</option>
                        <option value="PJ">Persona jurídica</option>
                        <option value="PN">Persona natural</option>
                    </select>

                </div>

            </div>
        </div>

        <div class="row col-sm-6 col-md-6">
            <div class="row col-sm-12 col-md-12 text-center">
                <div class="entradas-de-texto-govco col-sm-5 col-md-5 text-start">
                    <label for="Razón Social">Imagen<span class="required">(*)</span></label>

                </div>
                <div class="entradas-de-texto-govco col-sm-7 col-md-7 text-start">
                    <input type="text" id="Razón Social" placeholder="URL para traer imagen" formControlName="imageUrl" />

                </div>

            </div>

            <div class="row col-sm-12 col-md-12 text-center">
                <div class="entradas-de-texto-govco col-sm-5 col-md-5 text-start">
                    <label for="Razón Social">Manual<span class="required">(*)</span></label>

                </div>
                <div class="entradas-de-texto-govco col-sm-7 col-md-7 text-start">
                    <input type="text" id="Razón Social" placeholder="URL Manual de gov.co" formControlName="manualLink" />

                </div>

            </div>

            <div class="row col-sm-12 col-md-12 text-center">
                <div class="entradas-de-texto-govco col-sm-5 col-md-5 text-start">
                    <label for="Razón Social">Ver requisitos<span class="required">(*)</span></label>

                </div>
                <div class="entradas-de-texto-govco col-sm-7 col-md-7 text-start">
                    <input type="text" id="Razón Social" placeholder="Ingrese URL hacia gov.co" formControlName="requirementsUrl" />

                </div>

            </div>

            <div class="row col-sm-12 col-md-12 text-center">
                <div class="entradas-de-texto-govco col-sm-5 col-md-5 text-start">
                    <label for="Razón Social">Descripción<span class="required">(*)</span></label>

                </div>

                <div class="entradas-de-texto-govco col-sm-7 col-md-7 text-start">
                    <textarea type="text" id="Razón Social" placeholder="Ingrese la descripción del trámite, como se visualiza en gov.co" cols="40"
                              rows="5" style="resize: both;" formControlName="description">

                        </textarea>

                </div>



            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-12 text-center btns">

        <br><br>
        <button mat-dialog-close type="button" [disabled]="tramiteForm.invalid" class="btn-govco fill-btn-govco col-2 col-md-2 pl-2"
                (click)="guardarTramite()">GUARDAR</button>
        <button type="button" class="btn-govco secondaryButton col-2 col-md-2 pl-2" (click)="this.tramiteForm.reset()">LIMPIAR</button>


        <button class="btn-govco col-2 col-md-2 pl-2" (click)="this.router.navigate(['/Home/Administracion']);">Regresar</button>

    </div>

</div>
<app-go-back-up class="up"></app-go-back-up>
<div class="container" *ngIf="!authRole">
    <div class="title">
        <h1 class="h1-tipografia-govco title-1">No Permitido</h1>
    </div>
</div>
<app-footer></app-footer>