<app-header ($carga)="estadoCarga($event)"></app-header>
<app-quick-acces></app-quick-acces>
<app-breadcrumb [ruta]="ruta"></app-breadcrumb>
<div class="container">
  <div class="col-sm-12 col-md-4 serviceArea">
    <app-service-area></app-service-area>
  </div>
  <app-formalities ($login)="loginTramite($event)" ($carga)="estadoCarga($event)"></app-formalities>
  <app-go-back-up class="up"></app-go-back-up>
  <app-accesibility></app-accesibility>
  <div class="containerSerivces">
    <div>
      <div class="contOne">
        <div>
          <app-online-services class="onlineServices"></app-online-services>
          <app-document-authenticity class="consultAuthenticity"></app-document-authenticity>
        </div>
        <app-other-virtual-windows class="three"></app-other-virtual-windows>
      </div>
      <aside>
        <app-citizen-portal></app-citizen-portal>
      </aside>
    </div>

  </div>

</div>

<!-- spinner -->
<div class="container-indicador-de-carga-govco" [hidden]="carga">
  <div class="spinner-modal-indicador-de-carga-govco">
    <div class="spinner-indicador-de-carga-govco" style="width: 60px; height: 60px; border-width: 8px;" role="status" aria-hidden="true">
      <span class="visually-hidden">Cargando...</span>
    </div>
  </div>
</div>
<app-footer></app-footer>