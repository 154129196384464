import {Component, OnInit, ViewChild} from '@angular/core';
import {UsuariosService} from 'src/app/Services/roles-usuarios-funcionalidades/usuarios.service';
import {UsuarioRolesService} from 'src/app/Services/roles-usuarios-funcionalidades/usuarios-roles.service';
import {FuncionalidadesService} from 'src/app/Services/roles-usuarios-funcionalidades/funcionalidades.service';
import {FuncionalidadRolesService} from 'src/app/Services/roles-usuarios-funcionalidades/funcionalidades-roles.service';
import {Usuarios} from 'src/app/Models/usuarios.model';
import {Funcionalidades} from 'src/app/Models/funcionalidades.model';
import {UsuarioRoles} from 'src/app/Models/usuarioRoles.model';
import {UsuarioRolesLast} from 'src/app/Models/usuariosRolesLast.model';
import {FuncionalidadRolesFull} from 'src/app/Models/funcionalidadesRolesFull.model';
import {Router} from '@angular/router';
import * as $ from 'jquery';
import Swal, {SweetAlertResult} from 'sweetalert2';
import {RolesService} from 'src/app/Services/roles-usuarios-funcionalidades/roles.service';
import {MatDialog} from '@angular/material/dialog';
import {AlertModalInformationComponent} from 'src/app/Shared/alert-modal-information/alert-modal-information.component';
import {ApiService} from 'src/app/Services/api/api.service';
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs';
import {PaginationControlsComponent} from "ngx-pagination";
@Component({
  selector: 'app-asig-func-rol-usu',
  templateUrl: './asig-func-rol-usu.component.html',
  styleUrls: ['./asig-func-rol-usu.component.scss']
})
export class AsigFuncRolUsuComponent implements OnInit {
  rutaMigaPan = [{name: 'Inicio', url: '/Home'}, {
    name: 'Administración',
    url: '/Home/Administracion'
  }, {name: 'Asignación de tramites y roles', url: '/Home/Administracion/asig-func-rol-usu'}]
  titulo = 'Asignación de trámites y roles a funcionarios';
  tituloUltimasAsignaciones = 'Consulte las últimas asignaciones'
  informacionIncompleta = 'La acción solicitada no se pudo completar, se debe seleccionar al menos un usuario, ' +
    'funcionalidad y rol';
  confirmacionAccion = 'Los roles y funcionalidades seleccionadas serán asignados a los usuarios seleccionados';
  errorAsignacion = 'Las asignaciones seleccionadas no pudieron realizarse';
  errorModificacion = 'La acción solicitada no se pudo completar';
  errorMasDeUnRol = 'No se puede asignar más de un rol a un mismo tramite, ¿Desea modificar el anterior rol ' + 'al nuevo rol seleccionado?';
  errorAsignacionesRepetidas = 'No se pudieron hacer algunas asignaciones debido a que ya existen en base ' + 'de datos ó el usuario ya tiene asignado un rol al tramite seleccionado';
  errorModificarUltAsig = 'Este usuario ya tiene un rol asignado al tramite seleccionado';
  errorTramiteNoEncontrado = 'El trámite consultado no fue encontrado'
  indiceFuncOrigen: any;
  indiceFuncDestino: any;
  indiceRolOrigen: any;
  indiceRolDestino: any;
  modelo = 0;
  m_usu = 0;
  m_func = 0;
  m_rol = 0;
  numero = null;
  funcionalidadOri: any | undefined;
  funcionalidadDest: any | undefined;
  rolOrigen: any | undefined;
  rolDestino: any | undefined;
  objeto: any | undefined;
  usuarioRoles: any | undefined;
  usuarios: any[] = [];
  usuariosAll: any[] = [];
  roles: any[] = [];
  rolesFiltro:  any[] = [];
  funcionalidades: any[] = [];
  funcionalidadesAll: any[] = [];
  funcionalidadesFiltro: any[] = [];
  ult_asig: any[] = [];
  list_usu_n: string[] = [];
  list_usu_id: string[] = [];
  list_usu_s: string[] = [];
  list_func: any[] = [];
  list_temp: any[] = [];
  list_roles: any[] = [];
  list_roles_temp: any[] = [];
  list_roles_asig: any[] = [];
  list_roles_temp_n: number[] = [];
  list_usu_roles: any[] = [];
  list_acciones: any = [];
  direccionIP: string;
  cadena: string[] = [];
  cadena_temp: string[] = [];
  rol_func_final: any[] = [];
  estado = false;
  cambio2 = false;
  pagina = 1
  rol: string;
  authRole: boolean = false;
  adminRole: boolean = false;
  contador = 0;
  textoTramite = '';
  nombreRol = '';
  tipofiltro = '0'
  listRoles: any;
  valueFiltro: ''
  totalItems: 0;

  @ViewChild(PaginationControlsComponent) paginador: PaginationControlsComponent; // Referencia al paginador


  constructor(private usuariosService: UsuariosService,
              private funcionalidadesService: FuncionalidadesService,
              private rolesService: RolesService,
              private usuarioRolesService: UsuarioRolesService,
              private funcionalidadRolesService: FuncionalidadRolesService,
              public dialog: MatDialog,
              public router: Router,
              private apiservices: ApiService,
              private http: HttpClient) {
  }

  ngOnInit(): void {
    this.rol = localStorage.getItem('rol');
    this.obtenerIp()
    if (this.rol == 'AdminFuncional' || this.rol == 'AdminTI') {
      this.authRole = true;
      this.adminRole = true;

    } else {
      this.router.navigate(['/Home']);
    }
    this.cargarUsuarios();
    this.cargarFuncionalidades();
    this.cargarRoles()
    // this.ultimasAsignaciones();

    $("#cancelFunc").on("click", () => {
      $("#cajaFunc").val('');
      this.indiceFuncOrigen = null;
      this.funcionalidadOri = undefined;
    });

    $("#cancelRol").on("click", () => {
      $("#cajaRol").val('');
      this.indiceRolOrigen = null;
      this.rolOrigen = undefined;
    });
  }

  async cargarUsuarios(): Promise<void> {

    this.usuariosService.traerUsuariosAutenticacion(" ", " ")
      .subscribe(resp => {
        this.usuarios = [];
        resp.data.forEach(value=>
        {
          let userid = this.usuarios.filter(usuario => usuario.idUsuario == value.idUsuario);

          if (userid.length == 0) {
            this.usuarios.push(value)

          }
        })
        this.usuariosAll = this.usuarios;


      });
  }

  obtenerIp() {
    this.apiservices.getIPAddress().subscribe((res: any) => {
      this.direccionIP = res.ip;
      console.log(this.direccionIP)
    });
  }

  async cargarFuncionalidades():Promise<void> {
    this.funcionalidadesService.traerFuncionalidadesAutenticacion(" ", " ")

      .subscribe(resp => {

        resp.data.forEach(item =>
        {

          if(item.codigoAplicativo!="" && item.codigoAplicativo!="00")
          {
            this.funcionalidades.push(item)
          }


        });
        this.funcionalidadesAll=this.funcionalidades;
        this.funcionalidadesFiltro = this.funcionalidades;
      });
  }

  async cargarRoles(): Promise<void> {
    this.rolesService.traerRolesAutransversal(" ", " ")
      .subscribe(resp => {
        this.listRoles = resp.data;
      });

  }

  ultimasAsignaciones(value: string,page:any): void {
    if(page==1)
    {
      console.log('entro?')
      this.paginador.trackByIndex(0);
    }
    this.pagina=page;




    this.usuarioRolesService.traerUltimasAsignacionesAutenticacion(this.tipofiltro,value,page+"","10")

      .subscribe(resp => {

        //Todo pablo


        resp.data=resp.data.filter(usuario => usuario.idUsuario != null);

        this.ult_asig = resp.data;
        this.totalItems = resp.count
        if (this.totalItems == 0) {
          this.modalDocumentoNoEncontrado()
        }
      });
  }

  pageChanged(event) {
    console.log('hola')
    this.pagina = event;
    console.log(event)
    this.ultimasAsignaciones(this.valueFiltro,this.pagina+"")
  }

  modalDocumentoNoEncontrado(): void {
    this.dialog.open(AlertModalInformationComponent, {
      data: {type: 'fail', title: 'Lo sentimos', text: 'No se encontraron resultados.'},
      width: '540px',
    });
  }

  //Todo Revisar pablo
  usuario_seleccionTramite(e: any): void {
    console.log(this.funcionalidadesAll);
    console.log(e.value.nombreAplicativo)

    let texto = e.value.nombreAplicativo;

    if (!!texto) {
      const expresion = new RegExp(`${texto}.*`, 'i');
      this.funcionalidades = [];
      this.funcionalidadesAll.forEach(el => {
        const existe: boolean = expresion.test(el.nombreAplicativo);
        if (existe) {
          this.funcionalidades.push(el);
        }
      });
    } else {
      this.funcionalidades = this.funcionalidadesAll;
    }
  }

  usuario_seleccion(e: any): void {
    console.log(e.value)
    if (!this.list_usu_n.includes(e.value.idUsuario)) {
      this.list_usu_id.push(e.value.idUsuario);
      this.list_usu_n.push(e.value.idUsuario);

      const fullName = `${e.value.nombreUsuario}`

      this.list_usu_s.push(fullName.trim());
    }
  }

  quitar_usuario(item: number): void {
    this.list_usu_s.splice(item, 1);
    this.list_usu_n.splice(item, 1);
    this.list_usu_id.splice(item, 1);
  }

  seleccionFuncOrigen(id: any): void {
    console.log(this.funcionalidades)
    this.indiceFuncOrigen = id;
    this.funcionalidadOri = this.funcionalidades.find(x => x.idAplicativo == id);

  }

  async seleccionFuncDestino(id: any): Promise<void> {
    this.indiceFuncDestino = id;
    console.log(this.funcionalidades)
    this.funcionalidadDest = this.funcionalidades.find(x => x.idAplicativo == id);
    console.log(id)
    console.log(this.funcionalidadDest)
    this.funcionalidadRolesService.traerFuncionalidadRolesAutenticacion("codigo", this.funcionalidadDest.idAplicativo!)
      .subscribe(resp => {

        this.roles = resp.data[0].listaRoles;
      });
    this.indiceRolOrigen = null;

    this.list_roles= this.rol_func_final.filter(x => x.idAplicativo == id);

  }

  seleccionRolOrigen(id: any): void {
    this.indiceRolOrigen = id;
    this.rolOrigen = this.roles.find(x => x.idRol == id);
    console.log(this.roles)
    console.log(this.rolOrigen)
    this.rolOrigen= {
      ...this.rolOrigen,
      idAplicativo:this.funcionalidadDest.idAplicativo
    };
    console.log(this.rolOrigen)
  }

  seleccionRolDestino(id: any): void {
    this.indiceRolDestino = id;
    this.rolDestino = this.list_roles.find(x => x.idRol == id);

  }

  agregarFunc(): void {
    if (this.funcionalidadOri != undefined) {
      if (!this.list_func.includes(this.funcionalidadOri)) {
        this.list_func.push(this.funcionalidadOri);
      }
      this.funcionalidadRolesService.traerFuncionalidadRolesAutenticacion("codigo", this.funcionalidadOri.idAplicativo!)
        .subscribe(resp => {
          this.roles = resp.data[0].listaRoles;
          this.rolesFiltro = this.roles;
        });

      this.indiceFuncOrigen = 0;
      this.funcionalidadDest = this.funcionalidades.find(x => x.idAplicativo == this.funcionalidadOri.idAplicativo);
      this.funcionalidadOri = undefined;
      this.list_roles=[];
    }
  }

  quitarFunc(): void {
    if (this.funcionalidadDest != undefined) {
      this.list_roles_temp = [];
      this.list_roles_temp_n = [];
      this.cadena = [];

      this.list_func.forEach((item,index ) => {
        if(item.idAplicativo==this.funcionalidadDest.idAplicativo)
        {
          this.list_func.splice(index, 1);
        }
      });
      this.rol_func_final.forEach((item,index ) => {
        if(item.idAplicativo==this.funcionalidadDest.idAplicativo)
        {
          this.rol_func_final.splice(index, 1);
        }
      });
      this.list_roles.forEach((item,index ) => {
        if(item.idAplicativo==this.funcionalidadDest.idAplicativo)
        {
          this.list_roles=[];
        }
      });

      this.indiceRolOrigen=null;
      this.indiceFuncDestino = 0;
      this.funcionalidadDest = undefined;
      this.roles = [];
    }
  }

  agregarRol(): void {
    console.log(this.rolOrigen)
    if (this.rolOrigen != undefined) {
      this.estado = false;
      this.rol_func_final.forEach(item => {
        if (this.rolOrigen?.idAplicativo == item.idAplicativo) {
          this.objeto = item;
          this.estado = true;
        }
      });

      if (this.estado) {
        this.ventanaEmergenteWarning(this.errorMasDeUnRol)
          .then(resp => {
            if (resp.value) {
              this.cambio(this.objeto!, this.rolOrigen);
            }
          });
      }

      if (!this.estado) {
        console.log('entro aca')
        this.list_roles.push(this.rolOrigen);
        this.cadena.push(this.rolOrigen.rolName);
        this.rol_func_final.push(this.rolOrigen);
      }
    }
  }

  cambio(antiguo: any, nuevo: any): void {
    this.rol_func_final.forEach(item => {
      if (antiguo.nombreRol == item.nombreRol && antiguo.idRol != item.idRol) {
        this.cambio2 = true;
      }
    });

    if (this.cambio2) {
      this.cadena.push(nuevo.nombreRol!);
      this.list_roles.push(nuevo);
    } else {
      this.cadena = this.cadena.filter(x => x != antiguo.nombreRol);
      this.list_roles = this.list_roles.filter(x => x.nombreRol != antiguo.nombreRol);
      if (!this.cadena.includes(nuevo.nombreRol!)) {
        this.cadena.push(nuevo.nombreRol!);
        this.list_roles.push(nuevo);
      }
    }

    this.rol_func_final = this.rol_func_final.filter(x => x.idAplicativo != antiguo.idAplicativo);
    this.rol_func_final.push(nuevo);

    this.estado = false;
    this.cambio2 = false;
    this.indiceRolOrigen = 0;
    this.rolOrigen = undefined;
    this.objeto = undefined;
  }


  quitarRol(): void {
    if (this.rolDestino != undefined) {
      this.rol_func_final.forEach((item,index ) => {
        if(item.idAplicativo==this.funcionalidadDest.idAplicativo)
        {
          this.rol_func_final.splice(index, 1);
        }
      });
      this.list_roles.forEach((item,index ) => {
        if(item.idAplicativo==this.funcionalidadDest.idAplicativo)
        {
          this.list_roles=[];
        }
      });

      this.indiceRolDestino = 0;
      this.rolDestino = undefined;

    }
  }

  buscarFunc(texto: string): void {
    console.log(this.funcionalidadesAll);

    if (!!texto) {
      const expresion = new RegExp(`${texto}.*`, 'i');
      this.funcionalidades = [];
      this.funcionalidadesAll.forEach(el => {
        const existe: boolean = expresion.test(el.nameTramite);
        if (existe) {
          this.funcionalidades.push(el);
        }
      });
    } else {
      this.funcionalidades = this.funcionalidadesAll;
    }

  }

  borrarFunc(): void {
    this.cargarFuncionalidades();
  }

  buscarRol(texto: string): void {
    console.log(texto)
console.log(this.roles)
    let x = this.roles.filter(x => x.nombreRol.toString().toLowerCase().includes(texto.toString().toLowerCase()));
    if (x.length > 0 && texto!='') {
      this.roles = x
    }else{
      this.roles = this.rolesFiltro;
    }

  }

  borrarRol(): void {
    this.roles = [];
  }

  cancelar(): void {
    this.list_usu_roles = [];
    this.list_usu_id = [];
    this.contador = 0
    this.list_func = [];
    this.list_roles = [];
    this.roles = [];
    this.rol_func_final = [];
    this.list_usu_n = [];
    this.list_usu_s = [];
    this.modelo = 0;
    this.indiceFuncOrigen = 0;
    this.indiceRolOrigen = 0;
  }

  mostrarBotones(num: any, usuADId: any, funcId: any, rolId: any, nomFunc: string): void {
    this.funcionalidadRolesService.consultarFuncionalidadRoles(nomFunc)
      .subscribe(resp => {
        this.list_roles_asig = resp;
      });
    this.numero = num;
    this.m_usu = usuADId;
    this.m_func = funcId;
    this.m_rol = rolId;
  }

  botonCancelar(): void {
    this.numero = null;
    console.log('entro aca?')
    // this.ultimasAsignaciones();
  }

  modificarFunc(num: any, e: any): void {
    this.funcionalidadRolesService.consultarFuncionalidadRoles(e.target[e.target.selectedIndex].text)
      .subscribe(resp => {
        this.list_roles_asig = resp;
        this.ult_asig[num].rolId = undefined;
        this.ult_asig[num].rolName = '';
        this.ult_asig[num].tramiteId = e.target.value;
        this.ult_asig[num].nameTramite = e.target[e.target.selectedIndex].text;
      });
  }

  modificarRol(num: any, e: any): void {
    this.ult_asig[num].rolId = e.target.value;
    this.ult_asig[num].rolName = e.target[e.target.selectedIndex].text;
  }

  modificarUsu(num: any, e: any): void {
    this.usuariosService.traerIdUsuarioPorADId(e.target.value).subscribe(res => {
      this.ult_asig[num].userId = res;
    });
    this.ult_asig[num].userName = e.target[e.target.selectedIndex].text;
    this.ult_asig[num].userADId = e.target.value;
  }

  async guardar(): Promise<void> {
    if (this.list_usu_n.length === 0 || this.rol_func_final.length === 0) {
      this.ventanaEmergenteError(this.informacionIncompleta);
    } else {


      const resp = await this.ventanaEmergenteWarning(this.confirmacionAccion);
      if (resp.value) {
        let listarolestosave=[]
        this.list_usu_n.forEach(item=>
        {

          this.rol_func_final.forEach(roles=>
          {
            let nuevorol={
              idAplicativo: roles.idAplicativo,
              idRole:   roles.idRol,
              idUsuario: item
            }
            listarolestosave.push(nuevorol);
          });

        });
        this.usuarioRolesService.actualizarUsuarioRolesAutenticacion(listarolestosave,localStorage.getItem('clientId')).subscribe((resp) => {
          if (resp.status === 200) {
            this.ventanaEmergenteExito();
            this.registrarAccion('INSERT')
          } else {
            this.ventanaEmergenteError(this.errorAsignacion);
          }
        });

      }
    }
  }

  async procesarAsignaciones() {
    for (const item of this.rol_func_final) {
      const obj = await this.usuarioRolesService.traerUsuarioRoles(item.tramiteMainId!, 3).toPromise();

      if (obj.length === 0) {
        for (const usu of this.list_usu_id) {
          const usuarioRoles = {
            id: '0',
            usersId: usu,
            rolesId: item.rolId,
            tramitesMainId: item.tramiteMainId,
            roles: undefined,
            tramites: undefined,
            fechaCreacion: undefined,
          };
          this.list_usu_roles.push(usuarioRoles);
        }
      } else {
        for (const usu of this.list_usu_id) {
          for (const y of obj) {
            if (usu === y.usersId) {
              this.contador++;
            }
          }
          if (this.contador === 0) {
            const usuarioRoles = {
              id: '0',
              usersId: usu,
              rolesId: item.rolId,
              tramitesMainId: item.tramiteMainId,
              roles: undefined,
              tramites: undefined,
              fechaCreacion: undefined,
            };
            this.list_usu_roles.push(usuarioRoles);
            console.log(this.list_usu_roles);
          } else {
            this.contador = 0;
          }
        }
      }
    }

    if (this.list_usu_roles.length > 0) {
      console.log(this.list_usu_roles)
      this.usuarioRolesService.guardarUsuarioRoles(this.list_usu_roles).subscribe((resp) => {
        if (resp.status === 200) {
          this.ventanaEmergenteExito();
          this.registrarAccion('INSERT')
          // this.cancelar();
          // this.ultimasAsignaciones();
        } else {
          this.ventanaEmergenteError(this.errorAsignacion);
        }
      });
    } else {
      this.ventanaEmergenteError(this.errorAsignacionesRepetidas);
    }
  }

  registrarAccion(type: any) {
    console.log(this.list_usu_roles)
    let user = localStorage.getItem('userName');
    let registros = []
    let fechaHoraActual = new Date();
    if (type == 'INSERT') {
      this.list_usu_roles.map((ele, index) => {
        let accionRegistro = {
          fecha: fechaHoraActual,
          usuario: user,
          operacion: type,
          entidadAfectada: ele.usersId,
          tramite: ele.tramitesMainId,
          rol: ele.rolesId,
          estacion: 'string',
          servidor: "string"
        }
        registros.push(accionRegistro)
      })
    } else if ('UPDATE') {
      let accionRegistro = {
        fecha: fechaHoraActual,
        usuario: user,
        operacion: type,
        entidadAfectada: this.usuarioRoles.usersId,
        tramite: this.usuarioRoles.tramitesMainId,
        rol: this.usuarioRoles.rolesId,
        estacion: 'string',
        servidor: "string"
      }
      registros.push(accionRegistro)
    }
    this.apiservices.registrarAcciones(registros).subscribe(res => {
      console.log(res)
    })
    this.cancelar();
    registros = []

  }

  modificarAsignaciones(num: any): void {
    if (this.ult_asig[num].rolId == undefined) {
      this.ventanaEmergenteError('Debe escoger un rol');
    } else {
      this.contador = 0;
      this.usuarioRolesService.traerUsuarioRoles(this.ult_asig[num].userId!, 1)
        .subscribe(obj => {
          obj.forEach(x => {
            if (x.tramitesMainId == this.ult_asig[num].tramiteId && x.id != this.ult_asig[num].userRolId) {
              this.contador++;
            }
          });

          if (this.contador == 0) {
            this.usuarioRoles = {
              id: this.ult_asig[num].userRolId,
              usersId: this.ult_asig[num].userId,
              rolesId: this.ult_asig[num].rolId,
              tramitesMainId: this.ult_asig[num].tramiteId,
              roles: undefined,
              tramites: undefined,
              fechaCreacion: this.ult_asig[num].fechaCreacion,

            }
            console.log(this.usuarioRoles)
            this.usuarioRolesService.actualizarUsuarioRoles(this.usuarioRoles)
              .subscribe(resp => {
                if (resp.status == 200) {
                  this.ventanaEmergenteExito();
                  this.numero = null;
                  this.registrarAccion('UPDATE')
                  // this.ultimasAsignaciones();
                } else {
                  this.ventanaEmergenteError(this.errorModificacion);
                  this.numero = null;
                  // this.ultimasAsignaciones();
                }
              });
          } else {
            this.ventanaEmergenteError(this.errorModificarUltAsig);
          }
        });
    }
  }

  ventanaEmergenteExito(): void {
    Swal.fire({
      title: 'Operación exitosa',
      iconHtml: '<div class="material-icons">done</div>',
      customClass: {
        icon: 'no-bordes',
        title: 'text-success'
      },
      allowOutsideClick: false,
      showCloseButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#0d6efd'
    });
  }

  ventanaEmergenteError(mensaje: string): void {
    Swal.fire({
      title: 'Operación no exitosa',
      text: `${mensaje}`,
      iconHtml: '<div class="material-icons">close</div>',
      customClass: {
        icon: 'no-bordes',
        title: 'text-danger'
      },
      allowOutsideClick: false,
      showCloseButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#0d6efd'
    });
  }

  ventanaEmergenteWarning(mensaje: string): Promise<SweetAlertResult> {
    let respuesta =
      Swal.fire({
        html: `${mensaje}` +
          '<h4 class="text-warning fw-bold mt-3">¿Desea continuar?</h4>',
        iconHtml: '<div class="material-icons">warning</div>',
        customClass: {
          icon: 'no-bordes'
        },
        allowOutsideClick: false,
        showCloseButton: true,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#0d6efd'
      });
    return respuesta;
  }

  filterMyOptionsTramite($event: any) {

    console.log(this.funcionalidadesAll)
    console.log($event)
    this.funcionalidadesFiltro = [];
    if (!!$event) {
      this.funcionalidadesAll.forEach(item => {
        console.log(item)
        const fullName = item.nombreAplicativo;


        const expresion = new RegExp(`${$event}.*`, 'i');
        const result: boolean = expresion.test(fullName);

        if (result) {
          this.funcionalidadesFiltro.push(item);
        }

      });
    } else {
      this.funcionalidadesFiltro = this.funcionalidadesAll;
    }

  }

  filterMyOptions($event: any) {

    this.usuarios = [];
    if (!!$event) {
      this.usuariosAll.forEach(item => {
        const fullName = `${item.nombreUsuario} `


        const expresion = new RegExp(`${$event}.*`, 'i');
        const result: boolean = expresion.test(fullName);

        if (result) {
          this.usuarios.push(item);
        }

      });
    } else {
      this.usuarios = this.usuariosAll;
    }

  }
}
