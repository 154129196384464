<div class="scroll">
    <app-header></app-header>
    <app-quick-acces></app-quick-acces>
    <app-breadcrumb [ruta]="ruta"></app-breadcrumb>
    <div class="container" *ngIf=" authRole">
        <app-accesibility></app-accesibility>
        <div>
            <h1 class="h1-tipografia-govco title-1">Mi perfil</h1>
        </div>


        <div *ngIf="legal" class="container-pages">
            <app-legal-person-profile></app-legal-person-profile>
        </div>

        <div *ngIf="natural" class="container-pages">
            <app-natural-person-profile></app-natural-person-profile>
        </div>

    </div>
    <div class="container" *ngIf="!authRole">
        <div class="title">
            <h1 class="h1-tipografia-govco title-1">No Permitido</h1>
        </div>
    </div>
    <div class="container-indicador-de-carga-govco" [hidden]="carga">
        <div class="spinner-modal-indicador-de-carga-govco">
            <div class="spinner-indicador-de-carga-govco" style="width: 60px; height: 60px; border-width: 8px;" role="status" aria-hidden="true">
                <span class="visually-hidden">Cargando...</span>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>