import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Usuarios } from 'src/app/Models/usuarios.model';
import { UsuariosService } from 'src/app/Services/roles-usuarios-funcionalidades/usuarios.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-listar-usuarios',
  templateUrl: './listar-usuarios.component.html',
  styleUrls: ['./listar-usuarios.component.scss']
})
export class ListarUsuariosComponent implements OnInit {

  rutaMigaPan = [{ name: 'Inicio', url: '/Home' }, { name: 'Administración', url: '/Home/Administracion' }, { name: 'Consultar funcionarios', url: '/Home/Administracion/usuarios' }]

  titulo = 'Consulta de funcionarios';
  usuarios:any[] = [];
  pagina = 1;
  nombreUsuario: string = '';
  emailUsuario: string = '';
  rol: string;
  authRole: boolean = false;
  adminRole: boolean = false;
  mensajeUsuarioNoEncontrado = 'No se encontró un usuario con ese nombre'
  mensajeUsuarioNoEncontradoEmail = 'No se encontró un usuario con ese correo'
  itemsByPage: number = 10;

  constructor(private usuariosService: UsuariosService, public router: Router) { }

  ngOnInit(): void {
    this.rol = localStorage.getItem('rol');
    if (this.rol == 'AdminFuncional' || this.rol == 'AdminTI') {
      this.authRole = true;
      this.adminRole = true;

    } else {
      this.router.navigate(['/Home']);
    }
    this.cargarUsuarios();

    $("#cancel").on("click", function () {
      $("#caja").val('');
    });
  }

  cargarUsuarios(): void {
    this.usuariosService.traerUsuariosAutenticacion(" "," ")
      .subscribe(resp => {
        this.usuarios=[];
        resp.data.forEach(value =>
        {
          // evitar que salgan usuarios duplicados
          let userid=this.usuarios.filter(usuario  => usuario.idUsuario == value.idUsuario);

          if (userid.length==0)
          {
            this.usuarios.push(value)
          }
        })


      });
  }

  borrar(): void {
    this.nombreUsuario = ''
    this.cargarUsuarios();
  }

  buscar(texto: string): void {
    console.log('aca entro')
    if (texto != '' && texto!=undefined) {
      this.usuariosService.traerUsuariosAutenticacion("nombre",texto)
        .subscribe(resp => {
          this.usuarios=[];
          resp.data.forEach(value =>
          {
            let userid=this.usuarios.filter(usuario  => usuario.idUsuario == value.idUsuario);

            if (userid.length==0)
            {
              this.usuarios.push(value)
            }

          })

          if(this.usuarios.length==0)
          {
            this.ventanaEmergenteError(this.mensajeUsuarioNoEncontrado);
            this.cargarUsuarios();
          }
        });
    } else {
      this.cargarUsuarios();
    }
  }

  buscarPorCorreo(texto: string): void {

    if (texto != '' && texto!=undefined) {
      this.usuariosService.traerUsuariosAutenticacion("email",texto)
        .subscribe(resp => {
          this.usuarios=[];
          resp.data.forEach(value =>
          {
            let userid=this.usuarios.filter(usuario  => usuario.idUsuario == value.idUsuario);

            if (userid.length==0)
            {
              this.usuarios.push(value)
            }

          })

          if(this.usuarios.length==0)
          {
            this.ventanaEmergenteError(this.mensajeUsuarioNoEncontrado);
            this.cargarUsuarios();
          }
        });
    } else {
      this.cargarUsuarios();
    }


  }

  ventanaEmergenteError(mensaje: string): void {
    Swal.fire({
      title: 'Operación no exitosa',
      text: mensaje,
      iconHtml: '<div class="material-icons">close</div>',
      customClass: {
        icon: 'no-bordes',
        title: 'text-danger'
      },
      allowOutsideClick: false,
      showCloseButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#0d6efd'
    });
  }
}
