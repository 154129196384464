import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Services/api/api.service';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationModalComponent } from 'src/app/Shared/confirmation-modal/confirmation-modal.component';


@Component({
  selector: 'app-natural-person-profile',
  templateUrl: './natural-person-profile.component.html',
  styleUrls: ['./natural-person-profile.component.scss']
})
export class NaturalPersonProfileComponent implements OnInit {

  userName1 = localStorage.getItem('userName').split(" ")[0];
  userLastName1 = localStorage.getItem('lastName').split(" ")[0];
  userName2 = localStorage.getItem('userName').split(" ").length > 1?localStorage.getItem('userName').split(" ")[1]:'';
  userLastName2 = localStorage.getItem('lastName').split(" ").length > 1?localStorage.getItem('lastName').split(" ")[1]:'';
  email = localStorage.getItem('email');
  nombreParaMostrar = localStorage.getItem('nombreParaMostrar');

  naturalForm: any = FormGroup;
  disabledInputs: boolean = true;
  registerMode: boolean = false;
  editMode: boolean = false;
  noacept = true;

  public departaments: any[] = [];
  public municipios: any[] = [];
  public paiss: any[] = [];
  public sexoo: any[] = [];
  public generoo: any[] = [];
  public orientacionn: any[] = [];
  public etniaa: any[] = [];
  public estadoCivill: any[] = [];
  public nivelEducativoo: any[] = [];
  public tipoIdentificacionn: any[] = [];
  infoUser: any = {};
  idPersona: any;
  indexDepar: any;


  constructor(private router: Router, private apiservices: ApiService, private fb: FormBuilder, public dialog: MatDialog) {

  }

  ngOnInit(): void {
    this.naturalForm = this.fb.group({
      primerNombre: [{ value: '', disabled: true }, [Validators.required]],
      segundoNombre: [''],
      primerApellido: ['', [Validators.required]],
      segundoApellido: [''],
      tipoDocumento: ['', [Validators.required]],
      numeroIdentificacionn: ['', [Validators.required]],
      email: ['', [Validators.required]],
      telefonoFijo: [''],
      telefonoCelular: ['', [Validators.required]],
      nacionalidad: ['', [Validators.required]],
      departamentoResidencia: ['', [Validators.required]],
      ciudadResidencia: ['', [Validators.required]],
      direccionResidencia: ['', [Validators.required]],
      fechaNacimiento: ['', [Validators.required]],
      sexo: ['', [Validators.required]],
      genero: ['', [Validators.required]],
      orientacionSexual: ['', [Validators.required]],
      etnia: ['', [Validators.required]],
      estadoCivil: ['', [Validators.required]],
      nivelEducativo: ['', [Validators.required]]
    })

    this.naturalForm.disable();

    this.apiservices.getPais().subscribe(resp => this.paiss = resp.data);
    this.apiservices.getDepartaments().subscribe(resp => this.departaments = resp.data);
    this.apiservices.getSexo().subscribe(resp => this.sexoo = resp.data);
    this.apiservices.getGenero().subscribe(resp => this.generoo = resp.data);
    this.apiservices.getOrientacion().subscribe(resp => this.orientacionn = resp.data);
    this.apiservices.getEtnia().subscribe(resp => this.etniaa = resp.data);
    this.apiservices.getEstadoCivil().subscribe(resp => this.estadoCivill = resp.data);
    this.apiservices.getNivelEducativo().subscribe(resp => this.nivelEducativoo = resp.data);
    this.apiservices.getTipoIdentificacion().subscribe(resp => this.tipoIdentificacionn = resp.data);

    if (!this.editMode) {
      this.getUserVentanilla()

    }
  }

  getUserVentanilla() {
    let id = localStorage.getItem('clientId');
    this.apiservices.getIdUserVentanilla(id).subscribe(res => {

      if (res.code == 200) {

        this.getUserInfo(res.data)
      }
    }, err => {
      console.log(err)
    })
  }

  getUserInfo(id: any) {
    this.apiservices.consultarLegalPerson(id).subscribe(resp => {

      this.getFullInfoByDocumentUser(resp.data.numeroIdentificacion)
      //ACA OBTENGO EL NUMERO DE DOCUMENTO DE LA PERSONA
      //HACER CONSULTA POR NUMERO DE DOCUMENTO PQ EL SERVICIO ES EL QUE ME TRAE TODA LA INFO

    })
  }

  getFullInfoByDocumentUser(data: any) {
    this.apiservices.consultarInfoRegistrada(data).subscribe(resp => {

      this.infoUser = resp.data

      this.idPersona = resp.data.idPersona
      this.naturalForm.get('primerNombre').setValue(this.infoUser.primerNombre);
      this.naturalForm.get('segundoApellido').setValue(this.infoUser.segundoApellido);
      this.naturalForm.get('segundoNombre').setValue(this.infoUser.segundoNombre);
      this.naturalForm.get('primerApellido').setValue(this.infoUser.primerApellido);
      this.naturalForm.get('telefonoFijo').setValue(this.infoUser.telefonoFijo);
      this.naturalForm.get('telefonoCelular').setValue(this.infoUser.telefonoCelular);
      this.naturalForm.get('email').setValue(this.infoUser.email);
      this.naturalForm.get('numeroIdentificacionn').setValue(this.infoUser.numeroIdentificacion);
      this.naturalForm.get('sexo').setValue(this.infoUser.sexo);
      this.naturalForm.get('genero').setValue(this.infoUser.genero);
      this.naturalForm.get('orientacionSexual').setValue(this.infoUser.orientacionSexual);
      this.naturalForm.get('etnia').setValue(this.infoUser.etnia);
      this.naturalForm.get('estadoCivil').setValue(this.infoUser.estadoCivil);
      this.naturalForm.get('nivelEducativo').setValue(this.infoUser.nivelEducativo);
      this.naturalForm.get('ciudadResidencia').setValue(this.infoUser.ciudadResi);
      this.naturalForm.get('nacionalidad').setValue(this.infoUser.nacionalidad);
      this.naturalForm.get('departamentoResidencia').setValue(this.infoUser.depaResi);
      this.naturalForm.get('tipoDocumento').setValue(this.infoUser.tipoIdentificacion);
      this.naturalForm.get('fechaNacimiento').setValue(this.infoUser.fechaNacimiento);
    })
  }

  getMunicipios(idDep: number): void {
    this.apiservices.getMunByDepaId(idDep).subscribe(resp => this.municipios = resp.data)
  }

  save() {
    let data = {
      idPersona: this.idPersona,
      tipoIdentificacion: this.infoUser.tipoIdentificacion,
      numeIdentificacion: this.infoUser.numeroIdentificacion,
      pNombre: this.infoUser.primerNombre,
      sNombre: this.infoUser.segundoNombre,
      pApellido: this.infoUser.primerApellido,
      sApellido: this.infoUser.segundoApellido,
      email: this.naturalForm.value.email,
      telefonoFijo: this.naturalForm.value.telefonoFijo,
      telefonoCelular: this.naturalForm.value.telefonoCelular,
      nacionalidad: this.infoUser.nacionalidad,
      departamento: this.infoUser.departamento,
      ciudadNacimiento: this.infoUser.ciudadNacimiento,
      ciudadNacimientoOtro: "string",
      depaResi: this.infoUser.depaResi,
      ciudadResi: this.infoUser.ciudadResi,
      direResi: this.infoUser.direResi,
      estadogeo: 0,
      cx: 0,
      cy: 0,
      dirCodificada: "string",
      zona: 0,
      localidad: 0,
      upz: 0,
      barrio: "string",
      fechaNacimiento: this.infoUser.fechaNacimiento,
      edad: 0,
      sexo: this.infoUser.sexo,
      genero: this.infoUser.genero,
      orientacion: this.naturalForm.value.orientacionSexual,
      etnia: this.infoUser.etnia,
      estadoCivil: this.naturalForm.value.estadoCivil,
      nivelEducativo: this.naturalForm.value.nivelEducativo,
      tipoIdenRl: null,
      numeIdenRl: null,
      nombreRs: null
    }

    this.apiservices.actualizarLegalPerson(data).subscribe(resp => {

      let response = resp
      if (response.code == 200) {
        this.openDialoRegitroExitoso()
      }
    })


  }


  edit() {
    this.naturalForm.get('telefonoFijo').enable()
    this.naturalForm.get('telefonoCelular').enable()
    this.naturalForm.get('email').enable()
    this.naturalForm.get('estadoCivil').enable()
    this.naturalForm.get('nivelEducativo').enable()
    this.naturalForm.get('orientacionSexual').enable()
    this.editMode = true;
  }

  goBack() {
    this.naturalForm.disable();
    this.editMode = false;

  }

  goBackHome() {
    this.router.navigate(['Home']);
  }



  openDialoRegitroExitoso(): void {
    let dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: { type: 'success', title: 'Modificación exitosa', text: 'Información modificada con éxito.', acept: "" },
      height: 'auto',
      width: '700px',
    });
    setTimeout(() => {
      dialogRef.close()
      this.router.navigate(['Home/']);
    }, 3000);
  }

  aceptarPoliticas() {
    this.noacept = !this.noacept
  }

}



