import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss']
})
export class ManagementComponent {

  constructor(private router: Router) { }

  viweAdmin() {
    this.router.navigate(['Home/Administracion']);
  }


}
