<div class="row container-procedure">

    <figure class="col-sm-12 col-md-3 text-start">
        <img src="{{dataTramite.image || 'http://www.saludcapital.gov.co/PublishingImages/btn_tramites_line-19.jpg'}}" alt="Imagen tramite">

    </figure>
    <div class="col-sm-12 col-md-6 text-start">
        <h3>{{dataTramite.title ||'Tramites'}}</h3>
        <p>
            {{dataTramite.descripcion || 'Lista de tramites no disponible por ahora'}}
        </p>
        <button *ngIf="sesionInit" type="button" (click)="registrarSolicittud(dataTramite)"><label>Registrar Solicitud</label></button>
        <button *ngIf="!sesionInit" type="button" (click)="logIn()"><label>Registrar Solicitud</label></button>

    </div>
    <div class="col-sm-12 col-md-3 text-start procedureActions">
        <ul>
            <li><a href="{{dataTramite.urlRequisitos ||''}}" target="_blank"><mat-icon>playlist_add_check</mat-icon><label>Ver Requisitos</label></a>
            </li>
            <li>
                <a href="{{dataTramite.urlManual ||''}}" target="_blank">
                    <mat-icon>book</mat-icon><label>Ver Manual</label>
                </a>
            </li>
            <li *ngIf="dataTramite.tieneCosto"> <strong><mat-icon> attach_money</mat-icon>Costo: {{dataTramite.costo}}</strong></li>
            <li *ngIf="!dataTramite.tieneCosto"> <strong><mat-icon>money_off</mat-icon>Trámite sin costo</strong></li>
            <li>

                <strong><mat-icon>alarm</mat-icon>Duración: {{dataTramite.duracionDias ||'No registra'}} {{dataTramite.tipoDuracion == '1'? 'Días
                    Hábiles':''}}
                    {{dataTramite.tipoDuracion == '2'? 'Días Calendario':''}}</strong>
            </li>
            <li *ngIf="dataTramite.tipoTramite == 'En proceso de virtualización'">
                <strong><mat-icon>computer</mat-icon>{{dataTramite.tipoTramite ||'No registra'}}</strong>
            </li>
            <li *ngIf="dataTramite.tipoTramite == 'Virtual'">
                <strong><mat-icon>computer</mat-icon>{{dataTramite.tipoTramite ||'No registra'}}</strong>
            </li>
            <li *ngIf="dataTramite.tipoTramite == 'Presencial'">
                <strong><mat-icon>person</mat-icon>{{dataTramite.tipoTramite ||'No registra'}}</strong>
            </li>
            <li *ngIf="dataTramite.tipoTramite == 'Parcialmente en línea'">
                <strong><mat-icon>person</mat-icon>
                    <mat-icon>computer</mat-icon>{{dataTramite.tipoTramite ||'No registra'}}</strong>
            </li>
        </ul>
    </div>

</div>