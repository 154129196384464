
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from 'src/app/Services/api/api.service';
import { AlertModalInformationComponent } from 'src/app/Shared/alert-modal-information/alert-modal-information.component';

@Component({
  selector: 'app-register-procedure',
  templateUrl: './register-procedure.component.html',
  styleUrls: ['./register-procedure.component.scss']
})
export class RegisterProcedureComponent implements OnInit {

  tramiteForm: any = FormGroup;
  rol: string | null = '';
  authRole: boolean = false;
  adminRole: boolean = false
  rutaMigaPan = [{ name: 'Inicio', url: '/Home' }, { name: 'Administración', url: '/Home/Administracion' }, { name: 'Registrar información del trámite', url: '/Home/RegsitrarTramite' }]
  route: any;
  pathname: any;
  id: any;
  orderby: any;
  paramsObject: any;
  categorias: any;



  constructor(public fb: FormBuilder, public dialog: MatDialog, private rutaActiva: ActivatedRoute, public router: Router, public api: ApiService) { }

  ngOnInit(): void {
    this.rol = localStorage.getItem('rol');
    if (this.rol == 'Funcionario') {
      this.authRole = true;
    } else if (this.rol == 'AdminFuncional' || this.rol == 'AdminTI') {
      this.authRole = true;
      this.adminRole = true;

    } else {
      this.router.navigate(['/Home']);
    }

    this.getPersonalidadLegalAplicable()

    this.tramiteForm = this.fb.group({
      // id: [""],
      name: ["", [Validators.required]],
      description: ["", [Validators.required]],
      hasCost: [false, [Validators.required]],
      //
      cost: ["0", [Validators.required]],
      durationTypeId: ["", [Validators.required]],
      //
      durationInDays: ["", [Validators.required]],
      imageUrl: ["", [Validators.required]],
      manualLink: ["", [Validators.required]],
      redirectUrl: ["", [Validators.required]],
      requirementsUrl: ["", [Validators.required]],
      paperworkCategoryId: ["", [Validators.required]],
      legalPersonEnabled: ["", [Validators.required]],
      naturalPersonEnabled: ["", [Validators.required]],

    })

  }

  mirar(e: any) {
    if (e.target.value == 'PJPN') {
      this.tramiteForm.get('legalPersonEnabled').setValue(true);
      this.tramiteForm.get('naturalPersonEnabled').setValue(true);
    }
    if (e.target.value == 'PJ') {
      this.tramiteForm.get('legalPersonEnabled').setValue(true);
      this.tramiteForm.get('naturalPersonEnabled').setValue(false);
    }
    if (e.target.value == 'PN') {
      this.tramiteForm.get('legalPersonEnabled').setValue(false);
      this.tramiteForm.get('naturalPersonEnabled').setValue(true);
    }
  }

  costo(e: any) {
    if (e.target.value == 1) {
      this.tramiteForm.get('hasCost').setValue(true);
      this.tramiteForm.get('cost').setValue('');
    }
    if (e.target.value == 2) {
      this.tramiteForm.get('hasCost').setValue(false);
      this.tramiteForm.get('cost').setValue('0');
    }
  }


  getPersonalidadLegalAplicable() {
    this.api.getPersonalidadAplicable().subscribe(resp => {
      this.categorias = resp
    })
  }

  guardarTramite() {
    let data = {
      name: this.tramiteForm.value.name,
      description: this.tramiteForm.value.description,
      hasCost: this.tramiteForm.value.hasCost,
      cost: this.tramiteForm.value.cost,
      durationTypeId: this.tramiteForm.value.durationTypeId,
      durationInDays: this.tramiteForm.value.durationInDays,
      imageUrl: this.tramiteForm.value.imageUrl,
      manualLink: this.tramiteForm.value.manualLink,
      redirectUrl: this.tramiteForm.value.redirectUrl,
      requirementsUrl: this.tramiteForm.value.requirementsUrl,
      paperworkCategoryId: this.tramiteForm.value.paperworkCategoryId,
      legalPersonEnabled: this.tramiteForm.value.legalPersonEnabled,
      naturalPersonEnabled: this.tramiteForm.value.naturalPersonEnabled
    }
    this.api.agregarTramite(data).subscribe(resp => {
      let respuesta:any=resp.body;
      if (resp.status == 200) {
        let aplication=
          {
            nombreAplicativo: this.tramiteForm.value.name,
            urlAplicativo: this.tramiteForm.value.redirectUrl,
            siglaAplicativo: respuesta.estadoTramite,
            idAplicativo: respuesta.nombreTramite,
            idResponsable: localStorage.getItem('clientId'),
            idRoles: ["58EDA51F-7E19-47C4-947F-F359BD1FC732"],
            estado: "true"
          }

        this.api.agregarTramiteAutenticacion(aplication).subscribe(resp => {

          this.openDialoRegitroExitoso()
        })
      }
    })

  }

  openDialoRegitroExitoso(): void {
    let dialogRef = this.dialog.open(AlertModalInformationComponent, {
      data: { type: 'success', title: 'Registro éxitoso.', text: 'Información de registro almacenada con éxito.', acept: "" },

    });
    setTimeout(() => {
      dialogRef.close()
      this.router.navigate(['/Home/CatalogoTramites/']);
    }, 1000);
  }

}
