import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Services/api/api.service';
import { ConfirmationModalComponent } from 'src/app/Shared/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AlertModalInformationComponent } from 'src/app/Shared/alert-modal-information/alert-modal-information.component';
import { filter, map, tap } from 'rxjs';

@Component({
  selector: 'app-reports-module',
  templateUrl: './reports-module.component.html',
  styleUrls: ['./reports-module.component.scss']
})
export class ReportsModuleComponent implements OnInit {
  authRole: boolean = false;
  page: number = 1;
  itemsByPage: number = 10;
  reportName: string = ''
  adminRole: boolean = false;
  rol: string | null = '';
  ruta = [{ name: 'Inicio', url: '/Home' }, { name: 'Administración', url: '/Home/Administracion' }, { name: 'Reportes', url: '/Home/Reportes' }]
  resultado: boolean = false;
  reportes: boolean = false;
  documentNumber: any;
  documentInfo: any = [];
  encuesta: boolean = false;
  encuestasInfo: boolean = false;
  tramitesInfo: boolean = false
  fechaFin: any = ''
  fechaInicio: any = ''
  tramitesList: any = [{}];
  tramiteId: string = ""
  disableFilter: boolean = true;
  typeFilter: any = 0;
  valueFilter: any = '';
  reportesUsuario: any;
  inputNombre: boolean = true;
  idReporte: any = '';
  totalItems: any;
  surveyId: number;
  encuestaInfoData: any = [{}];
  idPregunta: any = ''
  a: number = 10;
  objectKeys: { (o: object): string[]; (o: {}): string[]; };
  mostrarPreguntasDeEncuesta: boolean = false;
  preguntasEncuesta: any = [{}];
  constructor(public dialog: MatDialog, private api: ApiService, private _route: ActivatedRoute, public router: Router) { }

  ngOnInit(): void {
    let type = this._route.snapshot.paramMap.get('tipoReporte')
    if (type == 'Encuestas') {
      this.encuestasInfo = true;
      this.tramitesInfo = false;
      this.surveyId = 2
      this.getReportesUsuario(2)
    }
    if (type == 'Tramites') {
      this.encuestasInfo = false;
      this.tramitesInfo = true;
      this.getReportesUsuario(1)
    }
    this.rol = localStorage.getItem('rol');
    if (this.rol == 'Funcionario') {
      this.authRole = true;
    }
    if (this.rol == 'AdminFuncional' || this.rol == 'AdminTI') {
      this.authRole = true;
      this.adminRole = true;

    } else {
      this.router.navigate(['/Home']);
    }

    this.getListaTramites()
  }

  pageChanged(event) {
    this.page = event
    this.a = event * this.itemsByPage
    if (this.a > this.documentInfo.length) {
      this.a = this.documentInfo.length
    }
    this.consultarInformacionTramites()
  }

  pageChanged2(event) {
    this.page = event;
    this.consultarInformacionEncuesta()
  }

  resultadoBusqueda() {
    if (this.encuestasInfo) {

      if (this.surveyId === 3) {
        this.consultarInformacionEncuesta()
      }
      if (this.surveyId === 2) {
        this.consultarInformacionEncuesta()
      }
    } else {
      this.consultarInformacionTramites()
    }

  }

  guardarReporte() {
    this.createReport()
  }

  //Mientras se desarrolla el srv que consulta los tramites se usa el de autenticidad de documentos 
  consultarInformacionTramites() {
    let data = {
      pageSize: this.itemsByPage,
      pageNumber: this.page,
      reportProcess: {
        // userId: localStorage.getItem('clientId'),
        name: "string",
        initialDate: this.fechaInicio,
        endDate: this.fechaFin,
        type: this.typeFilter,
        value: this.valueFilter,
        paperworkId: this.tramiteId,

      }
    }
    if (this.fechaInicio != '' && this.fechaFin != '' && this.tramiteId != '') {
      this.api.getTramiteReportes(data).subscribe(res => {
        if (res.items.length > 0) {
          this.documentInfo = res.items
          this.totalItems = res.totalRows
          this.resultado = true;
        } else {
          this.modalDocumentoNoEncontrado()
        }

        // this.page = 1

      })
    } else {
      this.openDialogFail()
      this.resultado = false
    }

  }

  consultarInformacionEncuesta() {
    if (this.fechaInicio === '' || this.fechaFin === '') {
      this.openDialogFail();
      this.resultado = false;
      return;
    }

    let reportProcess = {
      reportProcessTypeId: this.surveyId === 2 ? 2 : 3,
      userId: localStorage.getItem('clientId'),
      name: "string",
      initialDate: this.fechaInicio,
      endDate: this.fechaFin,
      type: this.typeFilter,
      value: this.valueFilter,
    };

    switch (this.surveyId) {
      case 2:
        reportProcess['paperworkId'] = this.tramiteId;
        if (this.idPregunta != '') {
          reportProcess['surveyQuestionsId'] = this.idPregunta;
        }
        break;
      case 3:
        if (this.idPregunta != '') {
          reportProcess['surveyQuestionsId'] = this.idPregunta;
        }
        break;
      default:
        this.openDialogFail();
        this.resultado = false;
        return;
    }

    let data = {
      pageSize: this.itemsByPage,
      pageNumber: this.page,
      reportProcess: reportProcess
    };

    this.api.getEncuestaReportes(data).subscribe(res => {
      if (res.items.length == 0) {
        this.modalDocumentoNoEncontrado();
        this.resultado = false;
      } else {
        this.encuestaInfoData = res.items;
        this.totalItems = res.totalRows;
        this.resultado = true;
        this.objectKeys = Object.keys;
      }
    });
  }


  openDialogFail(): void {
    this.dialog.open(AlertModalInformationComponent, {
      data: { type: 'fail', title: 'Lo sentimos', text: 'Los datos ingresados no están completos o contienen errores, por favor verifique nuevamente.' },
      width: '540px',
    });
  }

  modalDocumentoNoEncontrado(): void {
    this.dialog.open(AlertModalInformationComponent, {
      data: { type: 'fail', title: 'Lo sentimos', text: 'No se encontraron resultados.' },
      width: '540px',
    });
  }

  createReport() {
    let data = {};

    const commonData = {
      name: '',
      initialDate: this.fechaInicio,
      endDate: this.fechaFin,
      type: this.typeFilter,
      value: this.valueFilter,
      reportProcessTypeId: this.surveyId,
    };

    if (this.tramitesInfo) {
      data = {
        ...commonData,
        name: 'Reporte TRAMITE',
        paperworkId: this.tramiteId,
        reportProcessTypeId: 1,
      };
    } else if (this.encuestasInfo) {
      data = {
        ...commonData,
        paperworkId: this.tramiteId,
        surveyQuestionsId: this.idPregunta,
      };

      if (this.surveyId == 3) {
        data['name'] = 'Reporte EUX';
        delete data['paperworkId'];
      } else if (this.surveyId == 2) {
        data['name'] = 'Reporte EST';
      }

      if (this.idPregunta == '') {
        delete data['surveyQuestionsId'];
      }
      if (this.tramiteId == '') {
        delete data['paperworkId'];
      }
    } else {
      return;
    }


    this.api.crearReporteTramites(data).subscribe(res => {
      if (res.status == 200) {
        this.tramiteId = ""
        this.getReportesUsuario(this.tramitesInfo ? 1 : this.surveyId);
        this.valueFilter = '';
        this.typeFilter = 0;
        this.fechaFin = '';
        this.fechaInicio = '';
        this.encuestaInfoData = [{}]
        this.documentInfo = [];
        this.resultado = false;
        this.disableFilter = true
      }
    });
  }

  getReportesUsuario(id: any) {
    this.api.getReportesTramites(id).subscribe(res => {
      this.reportes = true;
      this.reportesUsuario = res
    })
  }

  tipoReportes(e: any) {
    if (e.target.value == '1') {
      this.tramitesInfo = true;
      this.encuestasInfo = false
      this.getReportesUsuario(1)
      this.valueFilter = '';
      this.typeFilter = 0;
      this.fechaFin = '';
      this.fechaInicio = '';
      this.encuestaInfoData = [{}]
      this.documentInfo = [];
      this.resultado = false;
      this.disableFilter = true
      this.tramiteId = ""
      this.mostrarPreguntasDeEncuesta = false
      this.preguntasEncuesta = []

    }
    if (e.target.value == '2') {
      this.tramitesInfo = false;
      this.encuestasInfo = true
      this.getReportesUsuario(2)
      this.valueFilter = '';
      this.typeFilter = 0;
      this.fechaFin = '';
      this.fechaInicio = '';
      this.encuestaInfoData = [{}]
      this.documentInfo = [];
      this.resultado = false;
      this.surveyId = 2
      this.disableFilter = true
      this.tramiteId = ""
      this.mostrarPreguntasDeEncuesta = false
      this.preguntasEncuesta = []

    }
  }

  getListaTramites() {
    this.api.getListaTramites().subscribe(resp => {
      this.tramitesList = resp
      this.tramitesList = this.tramitesList.sort((a, b) => {
        if (a.description < b.description) return -1;
        if (a.description > b.description) return 1;
        return 0;
      })
    })
  }

  tipofiltro(e: any) {
    this.valueFilter = '';
    this.idPregunta = '';
    this.disableFilter = false;
    this.typeFilter = e.target.value;

    if (this.typeFilter == 7) {
      this.mostrarPreguntasDeEncuesta = true;
      const a = this.surveyId == 2 ? 2 : 1;
      this.api.getSurveyByType(a).subscribe(res => {
        let preguntas = []
        res.surveyQuestions.map(ele => {
          if (ele.statusId == 0) {
            preguntas.push(ele)
          }
        })
        this.preguntasEncuesta = preguntas
      });
    } else {
      this.mostrarPreguntasDeEncuesta = false;
    }
  }

  borrarReporteTramite(data: any) {
    this.api.borrarReporteTramites(data.id).subscribe(res => {
      if (res.status == 200) {
        this.getReportesUsuario(data.reportProcessTypeId)
      }
    });
  }

  surveyType(e: any) {
    this.mostrarPreguntasDeEncuesta = false
    this.encuestaInfoData = [{}]
    if (e.target.value == 'EUX') {
      this.surveyId = 3
      this.valueFilter = '';
      this.typeFilter = 0;
      this.fechaFin = '';
      this.fechaInicio = '';
      this.preguntasEncuesta = []
      this.resultado = false
      this.getReportesUsuario(3)
    }
    if (e.target.value == 'EST') {
      this.surveyId = 2
      this.valueFilter = '';
      this.typeFilter = 0;
      this.fechaFin = '';
      this.fechaInicio = '';
      this.preguntasEncuesta = []
      this.resultado = false
      this.getReportesUsuario(2)
    }

  }

  cambiarNombreReporte(id: any) {
    this.inputNombre = false;
    this.idReporte = id
  }

  popUpConfirmacion(data: any): void {
    let infoReporte = data
    this.dialog.open(ConfirmationModalComponent, {
      data: { type: 'warning', title: 'Eliminar registro.', text: 'Esta seguro que desea eliminar este reporte?', acept: "" },
      height: 'auto',
      width: '700px',
    }).afterClosed().subscribe(result => {
      if (result) {
        this.borrarReporteTramite(infoReporte)
      }
    });
  }


  confirmarCambiarNombre(event, data: any): void {
    this.inputNombre = true;
    this.idReporte = '';
    data.name = event.target.value;

    this.api.editarReporteTramites(data.id, data)
      .pipe(
        filter(response => response.status === 200),
        tap(response => console.log(response))
      )
      .subscribe(() => {
        this.getReportesUsuario(data.reportProcessTypeId);
      });
  }


  obtenerPdfReporte(reporte: any) {
    let data = reporte;

    const obtenerPdfReporte$ = reporte.reportProcessTypeId === 1 ?
      this.api.obtenerPdfReporteTramite(data) :
      this.api.obtenerPdfReporteEncuesta(data);

    obtenerPdfReporte$
      .pipe(
        filter(response => response.status === 200),
        tap(response => console.log(response)),
        map(response => ({ content: response.body.content, name: response.body.name }))
      )
      .subscribe(({ content, name }) => this.downloadPDF(content, name));
  }

  obtenerExcelReporte(reporte: any) {
    let data = reporte;

    const apiCall = reporte.reportProcessTypeId === 1
      ? this.api.obtenerExcelReporteTramite(data)
      : this.api.obtenerExcelReporteEncuesta(data);

    apiCall.pipe(
      filter(res => res.status === 200),
      tap(res => console.log(res)),
      map(res => ({ content: res.body.content, name: res.body.name }))
    ).subscribe(({ content, name }) => this.download(content, name));
  }

  verPdfReporte(reporte: any) {
    let data = reporte
    this.api.obtenerPdfReporteTramite(data).subscribe(res => {
      if (res.status == 200) {
        this.openPDF(res.body.content)
      }
    });
  }

  private base64ToBlob(base64: string): Blob {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: 'application/pdf' });
  }

  private downloadBlob(blob: Blob, fileName: string) {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  downloadPDF(b64: any, name: string) {
    const blob = this.base64ToBlob(b64);
    this.downloadBlob(blob, name);
  }

  private openPopup(blob: Blob) {
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  }

  openPDF(base64String: string) {
    const blob = this.base64ToBlob(base64String);
    this.openPopup(blob);
  }

  download(b64: any, name: string) {
    const byteCharacters = atob(b64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/vnd.ms-excel' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `archivo.xlsx`;
    link.click();
  }

}
