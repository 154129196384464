<nav class="navbar navbar-expand-lg barra-superior-govco" aria-label="Barra superior">
  <a href="https://www.gov.co/" rel="noopener" target="_blank" aria-label="Portal del Estado Colombiano - GOV.CO"></a>
  <div *ngIf="!auth">
    <button id="spanishButton" style="display:none;"  class="notranslate">ES</button>
  </div>
  <div *ngIf="!auth">
    <button id="englishButton" style="display:none;" class="notranslate">EN</button>
  </div>

  <!-- <button class="idioma-icon-barra-superior-govco float-right" aria-label="Button to change the language of the page to English"></button> -->
  <div *ngIf="auth">


    <ul *ngIf="view" class="options-user">
      <li (click)="Inicio()">Inicio</li>
      <li *ngIf="this.roleUser ==='Ciudadano' && this.pn == true" (click)="viwePerfil()">Mi perfil</li>
      <li *ngIf="this.roleUser ==='Ciudadano' && this.pj == true" (click)="viwePerfil2()">Mi perfil</li>
      <li (click)="openDialogLogOut()">Cerrar Sesión</li>
      <li>Cambiar contraseña</li>
      <li *ngIf="this.roleUser ==='Funcionario' || this.roleUser === 'Coordinador' || this.roleUser === 'AdminTI' || this.roleUser === 'AdminFuncional'"
          (click)="viwePerfil3()">Bandeja
        de
        entrada y gestión</li>
      <li *ngIf="this.roleUser ==='Funcionario' || this.roleUser === 'Coordinador' || this.roleUser === 'AdminTI' || this.roleUser === 'AdminFuncional'"
          (click)="viwePerfil4()">
        Funcionario
      </li>
      <li *ngIf="this.roleUser === 'AdminTI' || this.roleUser === 'AdminFuncional'" (click)="viweAdmin()">Administración</li>
    </ul>
    <mat-icon (click)="viewMenu()">perm_identity</mat-icon>
    <button>{{nombreParaMostrar}}</button>
    <div class="nuevo" style="margin-right: 30px">
      <div class="nuevo2" style="margin-right: 30px">
        <button id="spanishButton" style="display:none;width: 70px"  class="notranslate">ES</button>
      </div>
      <div class="nuevo2" style="margin-right: 30px">
        <button id="englishButton" style="display:none;width: 70px" class="notranslate">EN</button>
      </div>
    </div>
  </div>
</nav>
