<app-header></app-header>
<app-quick-acces></app-quick-acces>
<app-breadcrumb [ruta]="ruta"></app-breadcrumb>
<div class="container" *ngIf="authRole">
    <app-accesibility></app-accesibility>
    <h1 class="h1-tipografia-govco title-1">Reportes</h1>
    <p class="alert alert alert-primary" role="alert">Personalice la consulta que desea realizar respecto a información de la gestión de trámites
        virtuales o de encuestas relacionadas a los trámites virtuales
    </p>
    <div class="row">

        <div class="row col-sm-12 col-md-12 text-start">
            <div>
                <h3 class="h3-tipografia-govco title-3">
                    ¿Qué información desea consultar?
                </h3>
            </div>
            <div role="group" aria-label="Radio Button - Alineación horizontal" class="radio entradas-de-texto-govco col-sm-5 col-md-7 text-center"
                 style="display: flex;">
                <input type="radio" id="radio11" name="radioButton1" value="1" [checked]='tramitesInfo' (change)="tipoReportes($event)">
                <label for=" radio11">Información de gestión de trámites</label>
                <input type="radio" id="radio12" name="radioButton1" value="2" [checked]='encuestasInfo' (change)="tipoReportes($event)">
                <label for=" radio12">Información de encuestas</label>

            </div>
        </div>

        <div *ngIf="encuestasInfo" class="row col-sm-12 col-md-12 text-start">
            <div>
                <h3 class="h3-tipografia-govco title-3">
                    Seleccione la encuesta*
                </h3>
            </div>
            <div role="group" class="entradas-de-texto-govco col-sm-5 col-md-7 text-start fechas">
                <select class="form-select" id="Tipo de Identificación" aria-label="Default select example" (change)="surveyType($event)">
                    <option value="EUX">Encuesta de experiencia de la aplicación</option>
                    <option value="EST" selected>Encuesta de satisfacción de trámites</option>
                </select>
            </div>
        </div>

        <div class="row col-sm-12 col-md-12 text-start">
            <div>
                <h3 class="h3-tipografia-govco title-3">
                    Filtros de busqueda:
                </h3>
            </div>

            <div *ngIf="tramitesInfo && !encuestasInfo" role="group" class="entradas-de-texto-govco row col-sm-12 col-md-12 text-start fechas">

                <div class="row col-sm-12 col-md-4">
                    <label>Nombre del tramite*</label>
                    <select class="form-select" id="Tipo de Identificación" aria-label="Default select example" [(ngModel)]="tramiteId">
                        <option value="" selected disabled>Seleccione una opción</option>
                        <option *ngFor="let tramite of tramitesList" value="{{tramite.id}}">
                            {{tramite.description}}

                        </option>
                    </select>

                </div>
                <div class="row col-sm-12 col-md-4">
                    <label>Fecha inicial *</label>
                    <input type="date" [(ngModel)]="fechaInicio" max="{{fechaFin}}">
                </div>
                <div class="row col-sm-12 col-md-4">
                    <label>Fecha final *</label>
                    <input type="date" [(ngModel)]="fechaFin" min="{{fechaInicio}}">
                </div>


            </div>

            <div *ngIf="encuestasInfo && !tramitesInfo" role="group" class="entradas-de-texto-govco row col-sm-5 col-md-12 text-start fechas">

                <div *ngIf="surveyId === 2" class="row col-sm-12 col-md-4">
                    <label>Nombre del tramite*</label>
                    <select class="form-select" id="Tipo de Identificación" aria-label="Default select example" [(ngModel)]="tramiteId">
                        <option value="" selected disabled>Seleccione una opción</option>
                        <option *ngFor="let tramite of tramitesList" value="{{tramite.id}}">
                            {{tramite.description}}

                        </option>
                    </select>

                </div>
                <div class="row col-sm-12 col-md-4">
                    <label>Fecha inicial *</label>
                    <input type="date" [(ngModel)]="fechaInicio" max="{{fechaFin}}">
                </div>
                <div class="row col-sm-12 col-md-4">
                    <label>Fecha final *</label>
                    <input type="date" [(ngModel)]="fechaFin" min="{{fechaInicio}}">
                </div>

            </div>

        </div>

        <div class="row prueba" *ngIf="!encuestasInfo">
            <div class="col-sm-11 col-md-4">
                <div class="entradas-de-texto-govco">
                    <label for="input-basico-id">Filtrar por:</label>
                    <select class="form-select" id="Tipo de Identificación" aria-label="Default select example" (change)="tipofiltro($event)"
                            [disabled]="tramiteId == ''">
                        <option value="" selected disabled>Seleccione una opción</option>
                        <option value="1">ID solicitud</option>
                        <option value="2">Solicitante</option>
                        <option value="3"> No. Doc de Identidad</option>
                        <option value="4">Fecha de registro</option>
                        <option value="5">Estado de la solicitud</option>
                    </select>
                </div>
            </div>

            <div class="col-sm-11 col-md-4">
                <div class="search-govco">
                    <br />
                    <br />
                    <div class="bar-search-govco">
                        <input type="text" placeholder="Ingrese su búsqueda" class="input-search-govco" aria-label="Buscador"
                               [disabled]="disableFilter" [(ngModel)]="valueFilter" />
                        <div class="icon-search-govco search-icon-search-govco" (click)="resultadoBusqueda()" aria-hidden="true">
                        </div>
                        <div *ngIf="tramiteId != '' && fechaFin != '' && fechaInicio!= ''" class="icon-search-govco search-icon-search-govco"
                             style="background-color: #004884;" (click)="resultadoBusqueda()" aria-hidden="true">
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="resultado" class="col-md-12" class="table-wrapper">
                <div>
                    <h3 class="h3-tipografia-govco title-3">
                        Resultados de la búsqueda
                    </h3>
                </div>

                <br>
                <div class="row col-sm-12 col-md-12 text-start" style="justify-content: end;">
                    <div class=" col-sm-12 col-md-3 camtidadItems descripcion-emergente-govco">
                        <span class="tiptext" style="height: auto; top: 95%;">Número de Registros por página</span>
                        <input type="number" disabledText="true" id="input-basico-id" [(ngModel)]="itemsByPage" placeholder="5" min="5"
                               (ngModelChange)="a = itemsByPage" />
                    </div>

                    <div class="col-sm-12 col-md-3">
                        <div>
                            <pagination-controls (pageChange)="pageChanged($event)" nextLabel="Siguiente"
                                                 previousLabel="Anterior"></pagination-controls>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row col-sm-12 col-md-12  text-end" style=" justify-content: end;">
                    <div class="col-sm-12">

                        <label>Mostrando{{documentInfo.length > 0 ? ((page - 1) * itemsByPage) + 1 : 0}}-{{itemsByPage * page >
                            totalItems?totalItems:itemsByPage * page}} de
                            {{totalItems}} resultados</label>
                    </div>
                </div>
                <br>

                <div class="col-xxl-12 col-md-12 col-lg-12 info_table">
                    <div class="table-responsive">
                        <table class="table" aria-describedby="reporte de tramites">
                            <thead class="encabezado-tabla">
                                <tr>
                                    <th>ID solicitud</th>
                                    <th>Nombre del trámite</th>
                                    <th>Solicitante</th>
                                    <th> No. Doc de Identidad</th>
                                    <th>Fecha de registro</th>
                                    <th>Estado de la solicitud</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let tramite of documentInfo   | paginate: { itemsPerPage: itemsByPage, currentPage: page, totalItems: totalItems }; let primero = first ;let ultimo = last; let indice=index">
                                    <td>{{tramite.procedureId}}</td>
                                    <td>{{tramite.procedureName}}</td>
                                    <td>{{tramite.applicantName}}</td>
                                    <td>{{tramite.identificationNumber}}</td>
                                    <td>{{tramite.dateResolution | date: 'dd/MM/yyyy hh:mm a'}}</td>
                                    <td>{{tramite.statusProcedure}}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div *ngIf="resultado" class="col-sm-12 col-md-12 text-center">

                <br><br>
                <button mat-dialog-close type="button" class="btn-govco fill-btn-govco col-2 col-md-2 pl-2" (click)="guardarReporte()">
                    GUARDAR REPORTE
                </button>

            </div>
            <div *ngIf="reportes" class="col-md-12">
                <div>
                    <h3 class="h3-tipografia-govco title-3">
                        Reportes generados
                    </h3>
                </div>

                <br>
                <div class="col-xxl-12 col-md-12 col-lg-12 info_table">
                    <div class="table-responsive">
                        <table class="table" aria-describedby="reportes creados">
                            <thead class="encabezado-tabla">
                                <tr>
                                    <th>Nombre del reporte</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let reporte of reportesUsuario">
                                    <td disabled (dblclick)="cambiarNombreReporte(reporte.id)">
                                        <input type="text" value="{{reporte.name}}" [disabled]="reporte.id == idReporte?false:true"
                                               (blur)="confirmarCambiarNombre($event,reporte)">
                                    </td>
                                    <td>
                                        <span class="govco-file-excel-1" (click)="obtenerExcelReporte(reporte)"></span>
                                        <span class="govco-file-pdf-1" (click)="obtenerPdfReporte(reporte)"></span>
                                        <span class="govco-search" (click)="verPdfReporte(reporte)"></span>
                                        <span class="govco-trash-alt-1" (click)="popUpConfirmacion(reporte)"></span>
                                    </td>

                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>

            </div>


        </div>

        <div class="row prueba" *ngIf="encuestasInfo">
            <div class="col-sm-12 col-md-4">
                <div class="entradas-de-texto-govco">
                    <label for="input-basico-id">Filtrar por:</label>
                    <select class="form-select" id="Tipo de Identificación" aria-label="Default select example" (change)="tipofiltro($event)">
                        <option *ngIf="typeFilter === 0" value="" selected disabled>Seleccione una opción</option>
                        <option value="2" *ngIf="surveyId == 2">numero de solicitud </option>
                        <option value="3">nombre de usuario</option>
                        <option value="4">correo</option>
                        <option value="5">telefono</option>
                        <option value="6">Número identificación</option>
                        <option value="7">Pregunta</option>
                    </select>
                </div>
            </div>
            <div *ngIf="mostrarPreguntasDeEncuesta" class="col-sm-12 col-md-4">
                <div class="entradas-de-texto-govco">
                    <label for="input-basico-id">Preguntas:</label>
                    <select class="form-select" id="Tipo de Identificación" aria-label="Default select example" [(ngModel)]="idPregunta">
                        <option value="" selected disabled>Seleccione una opción</option>
                        <option *ngFor="let option of preguntasEncuesta" value={{option.id}}>
                            {{option.title}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="col-sm-12 col-md-4">
                <div class="search-govco">
                    <br />
                    <br />
                    <div class="bar-search-govco">
                        <input type="text" placeholder="Ingrese su búsqueda" class="input-search-govco" aria-label="Buscador"
                               [disabled]="disableFilter" [(ngModel)]="valueFilter" />
                        <button class="icon-search-govco icon-close-search-govco" aria-label="Limpiar" [disabled]="disableFilter"></button>
                        <div class="icon-search-govco search-icon-search-govco" (click)="resultadoBusqueda()" aria-hidden="true">
                        </div>
                        <div *ngIf="tramiteId != '' && fechaFin != '' && fechaInicio!= '' && surveyId === 2"
                             class="icon-search-govco search-icon-search-govco" style="background-color: #004884;" (click)="resultadoBusqueda()"
                             aria-hidden="true">
                        </div>
                        <div *ngIf="fechaFin != '' && fechaInicio!= '' && surveyId === 3" class="icon-search-govco search-icon-search-govco"
                             style="background-color: #004884;" (click)="resultadoBusqueda()" aria-hidden="true">
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="resultado" class="col-md-12" class="table-wrapper">
                <div>
                    <h3 class="h3-tipografia-govco title-3">
                        Resultados de la búsqueda
                    </h3>
                </div>

                <br>
                <div class="row col-sm-12 col-md-12 text-start" style="justify-content: end;">
                    <div class=" col-sm-12 col-md-3 camtidadItems descripcion-emergente-govco">
                        <span class="tiptext" style="height: auto; top: 95%;">Número de Registros por página</span>
                        <input type="number" disabledText="true" id="input-basico-id" [(ngModel)]="itemsByPage" placeholder="5" min="5"
                               (ngModelChange)="a = itemsByPage" />
                    </div>

                    <div class="col-sm-12 col-md-3">
                        <div>
                            <pagination-controls (pageChange)="pageChanged2($event)" nextLabel="Siguiente"
                                                 previousLabel="Anterior"></pagination-controls>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row col-sm-12 col-md-12  text-end" style=" justify-content: end;">
                    <div class="col-sm-12">

                        <label>Mostrando{{encuestaInfoData.length > 0 ? ((page - 1) * itemsByPage) + 1 : 0}}-{{itemsByPage * page >
                            totalItems?totalItems:itemsByPage * page}} de
                            {{totalItems}} resultados</label>
                    </div>
                </div>
                <br>
                <br>
                <div class="col-xxl-12 col-md-12 col-lg-12 info_table">
                    <div class="table-responsive">
                        <table class="table" aria-describedby="tableDescActiveRows">
                            <thead class="encabezado-tabla">
                                <tr>

                                    <th *ngFor="let key of objectKeys(encuestaInfoData[0])">{{ key }}</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let item of encuestaInfoData   | paginate: { itemsPerPage: itemsByPage, currentPage: page, totalItems: totalItems }; let primero = first ;let ultimo = last; let indice=index">

                                    <td *ngFor="let key of objectKeys(item)">{{ item[key] }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            <div *ngIf="resultado" class="col-sm-12 col-md-12 text-center">

                <br><br>
                <button *ngIf="encuestasInfo" mat-dialog-close type="button" class="btn-govco fill-btn-govco col-2 col-md-2 pl-2"
                        (click)="guardarReporte()">GUARDAR REPORTE</button>

            </div>

            <div *ngIf="reportes" class="col-md-12">
                <div>
                    <h3 class="h3-tipografia-govco title-3">
                        Reportes generados
                    </h3>
                </div>

                <br>

                <table class=" table table-general" aria-describedby="tableDescActiveRows">
                    <thead class="encabezado-tabla">
                        <tr>
                            <th>Nombre del reporte</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let reporte of reportesUsuario">
                            <td disabled (dblclick)="cambiarNombreReporte(reporte.id)">
                                <input type="text" value="{{reporte.name}}" [disabled]="reporte.id == idReporte?false:true"
                                       (blur)="confirmarCambiarNombre($event,reporte)">
                            </td>
                            <td>
                                <span class="govco-file-excel-1" (click)="obtenerExcelReporte(reporte)"></span>
                                <span class="govco-file-pdf-1" (click)="obtenerPdfReporte(reporte)"></span>
                                <span class="govco-search" (click)="verPdfReporte(reporte)"></span>
                                <span class="govco-trash-alt-1" (click)="popUpConfirmacion(reporte)"></span>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>


        </div>
        <div class="prueba2 col-sm-12 col-md-12 text-end">
            <button (click)="router.navigate(['/Home/Administracion']);">Regresar</button>
        </div>
    </div>


    <div class="container" *ngIf="!authRole">
        <div class="title">
            <h1 class="h1-tipografia-govco title-1">No Permitido</h1>
        </div>
    </div>
</div>
<app-footer></app-footer>