<div>
    <figure class="container">


        <div class="modal-icon">
            <span class="modal-title-govco" *ngIf="data.type === 'success'" class="modal-icon-govco modal-success-icon"></span>
            <span class="modal-title-govco" *ngIf="data.type === 'warning'" class="modal-icon-govco modal-warning-icon"></span>
            <span class="modal-title-govco" *ngIf="data.type === 'fail'" class="modal-icon-govco modal-error-icon"></span>
        </div>
        <figcaption>
            <h2 class="modal-title-govco" *ngIf="data.type === 'warning'" style=" color: #f9d005c4;">{{data.title}}</h2>
            <h2 class="modal-title-govco" *ngIf="data.type === 'success'" style=" color: forestgreen;">{{data.title}}</h2>
            <h2 class="modal-title-govco" *ngIf="data.type === 'fail'" style=" color: red;">{{data.title}}</h2>
        </figcaption>
        <p class="modal-text-govco">
            {{data.text}}
        </p>
    </figure>
</div>