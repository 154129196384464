<app-header></app-header>
<app-quick-acces></app-quick-acces>
<app-breadcrumb [ruta]="ruta"></app-breadcrumb>
<div class="container">
  <app-accesibility></app-accesibility>
  <div class="title">
    <h1 class="h1-tipografia-govco title-1">Servicios en línea</h1>
  </div>
  <mat-accordion [multiple]="true">
    <mat-expansion-panel *ngFor="let item of services;let primero = first;let ultimo = last; let indice=index"
                         [expanded]='item.id == abrir? true: false ' (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-description>
          <div class="containerTitle">
            <h3 class="indiceAcordion">{{indice+1}}</h3>
            <h3 class="title">{{services[indice].title}}</h3>
          </div>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        <a *ngFor="let item of item.tramites;let primero = first;let ultimo = last; let indice=index" href="{{item.urlRedirect}}" target="_blank">
          <figure>
            <figcaption>
              <label>{{item.name}}</label>
            </figcaption>
            <img src="{{item.imageUrl}}" alt="servicios en linea">
          </figure>
        </a>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<app-go-back-up class="up"></app-go-back-up>
<app-footer></app-footer>