<app-header></app-header>
<app-quick-acces></app-quick-acces>
<app-breadcrumb [ruta]="rutaMigaPan"></app-breadcrumb>
<div class="container entradas-de-texto-govco">
    <app-accesibility style="position: absolute; z-index: 2;"></app-accesibility>
    <div class="title">
        <h1 class="h1-tipografia-govco title-1">{{titulo}}</h1>
    </div>
    <div class="mt-4">
        <div class="row col-sm-12 col-md-4">
            <!-- <div class="mb-1 fw-bold">
                <label>Buscar por</label>
            </div>
            <div class="input-group">
                <span class="input-group-text fondo-blanco color-gris cursor" (click)="buscar(texto.value)">
                    <i class="fa-solid fa-magnifying-glass"></i>
                </span>
                <input type="text" class="form-control lineas-buscadores" placeholder="Buscar" #texto id="caja">
                <span class="input-group-text fondo-blanco color-gris cursor" (click)="borrar()" id="cancel">
                    <i class="fa-solid fa-circle-xmark"></i>
                </span>
            </div> -->
            <div class="search-govco">
                <div class="mb-1 fw-bold">
                    <label>Buscar por nombre del rol</label>
                </div>
                <div class="bar-search-govco">
                    <input type="text" placeholder="Nombre del rol" class="input-search-govco" aria-label="Buscador" [(ngModel)]="nombreRol" />
                    <button class="icon-search-govco icon-close-search-govco" aria-label="Limpiar" (click)="borrar()"></button>
                    <div class="icon-search-govco search-icon-search-govco" aria-hidden="true" (click)="buscar(nombreRol)"></div>
                </div>

            </div>
        </div>
        <div class="col-xxl-12 col-md-12 col-lg-12 info_table mt-3">
            <div class="table-responsive">
                <table class="table">
                    <thead class="fondo-gris">
                        <tr class="text-center">
                            <th scope="col">Código del rol</th>
                            <th scope="col">Nombre del rol</th>
                            <th scope="col">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let rol of roles | paginate: { itemsPerPage: 10, currentPage: pagina }" class="text-center">
                          <td>{{ rol.idRole }}</td>
                          <td *ngIf="dato == rol.idRole" class="d-flex justify-content-center text-center">
                          <input type="text" [(ngModel)]="nuevoNombre" class="text-center">
                            </td>
                          <td [ngClass]="{'ocultar': dato == rol.idRole}">{{ rol.displayName }}</td>
                          <td>
                                <span class="govco-check" *ngIf="dato == rol.idRole" (click)="editarRol(rol.idRole!, nuevoNombre!, rol.isEnabled!)">
                                    Guardar
                                </span>
                            <span class="govco-times" *ngIf="dato == rol.idRole" (click)="botonCancelar()">
                                    Cancelar
                                </span>
                            <a class="text-primary cursor ms-2 me-5" (click)="mostrarBotones(rol.idRole, rol.displayName!)" *ngIf="rol.isEnabled == true"
                               [ngClass]="{'ocultar': dato == rol.idRole}">
                              <i class="govco-edit"></i>
                            </a>
                            <a class="text-danger cursor ms-5 me-2" (click)="desactivarRol(rol)" *ngIf="rol.isEnabled == true"
                               [ngClass]="{'ocultar': dato == rol.idRole}">
                              <i class="govco-times"></i>
                            </a>
                            <div class="d-flex justify-content-between">
                              <div class="col-3"></div>
                              <a class="text-success col-9" *ngIf="rol.isEnabled == false" (click)="activarRol(rol)">
                                <i class="govco-plus"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-flex justify-content-between mt-5">
                    <button class="govco-plus" (click)="botonesCrearRol()"><i class="text-success fa-solid fa-circle-plus fa-2xl me-2"></i>Crear
                        nuevo
                        rol</button>
                    <div *ngIf="mostrar" class="d-flex justify-content-center">
                        <input type="text" [(ngModel)]="nuevoRol" class="form-control me-4" placeholder="Nombre rol">
                        <div class="d-flex justify-content-center">
                            <button class="btn btn-primary me-4" (click)="crearRol(nuevoRol!)">Crear</button>
                            <button class="secondaryButton" (click)="cancelarRol()">Cancelar</button>
                        </div>
                    </div>
                    <pagination-controls (pageChange)="pagina = $event" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <div class="prueba2 col-sm-12 col-md-12 text-end">
        <button (click)="router.navigate(['/Home/Administracion']);">Regresar</button>
    </div>
</div>
<app-go-back-up class="up"></app-go-back-up>
<!-- <div class="container">
    <div class="title">
        <h1 class="h1-tipografia-govco title-1">No Permitido</h1>
    </div>
</div> -->
<app-footer></app-footer>
