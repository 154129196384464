import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { RegisterPersonModalComponent } from '../register-person-modal/register-person-modal.component';
import { AuthService } from 'src/app/Services/Authentication/auth.service';
import { ApiService } from 'src/app/Services/api/api.service';
import { UsuarioRolesService } from 'src/app/Services/roles-usuarios-funcionalidades/usuarios-roles.service';
import { UsuarioRoles } from 'src/app/Models/usuarioRoles.model';
import { UsuariosService } from 'src/app/Services/roles-usuarios-funcionalidades/usuarios.service';

@Component({
  selector: 'app-quick-acces',
  templateUrl: './quick-acces.component.html',
  styleUrls: ['./quick-acces.component.scss']
})
export class QuickAccesComponent implements OnInit {

  @Output() $clientId: EventEmitter<any> = new EventEmitter<any>();
  infoUser: any = {};
  rolesUser: any;

  constructor(private msaService: MsalService, private router: Router, public dialog: MatDialog, private apiAuth: AuthService, private apiservices: ApiService, public usuarioRolService: UsuarioRolesService, private userService: UsuariosService) { }

  id: any = '';
  sesionActiva: boolean = false;
  sesionData: any = {};
  rol: string | null = '';
  authRole: boolean = false;
  rutaEncuesta = ''
  list_usu_roles: UsuarioRoles[] = [];

  ngOnInit(): void {
    this.validarAutentificacion()
    this.rol = localStorage.getItem('rol');
    if (this.rol != 'nn' && this.rol != null) {
      this.authRole = true;
      this.rutaEncuesta = '/Home/Encuesta/EST'
    }
  }

  /**
   * Funcion que abre el componente de login si se autentifica, responde el servicio con la info
   */
  logIn() {
    this.msaService.loginPopup().subscribe({
      next: res => {
        this.sesionData = res
        if (this.sesionData.uniqueId != '') {
          console.log(this.sesionData)
          this.apiAuth.setValorToken(this.sesionData)
          this.getUserVentanilla(this.sesionData.uniqueId)
        }
      },
      error: error => {
        this.apiAuth.logoutSys();
      }
    });
  }


  /**
   * Funcion que consulta el id del usuario registrado en VU como PN/PJ asociado al OID generado en el login
   * @param idUsuario
   */
  getUserVentanilla(idUsuario: any) {
    this.apiservices.getIdUserVentanilla(idUsuario).subscribe(res => {
      if (res.code == 200 && res.data != -1) {
        localStorage.setItem('idUser', res.data)
        this.consultarRolesAsignadosPorAdmin()
      } else if (res.code == 200 && res.data == -1) {
        this.addPerson()
      }
    }, err => {
      console.log(err)
    })
  }

  getInfoUser(idUsuarioVentanilla: any) {
    this.apiservices.consultarLegalPerson(idUsuarioVentanilla).subscribe(resp => {
      console.log('acaaaaa',resp)
      if(resp.data == null){
        this.openRegisterModal()
      }else{

      let datosPersonales = {
        personTypeId: 1,
        numeroIdentificacion: resp.data.numeroIdentificacion,
        fullName: resp.data.fullName,
        tipoIdentificacion: resp.data.tipoIdentificacion,
        primerNombre: resp.data.primerNombre,
        segundoNombre: resp.data.segundoNombre,
        primerApellido: resp.data.primerApellido,
        segundoApellido: resp.data.segundoApellido,
        email: resp.data.email,
        telefonoFijo: resp.data.telefonoFijo,
        telefonoCelular: resp.data.telefonoCelular,
        tipoIdentificacionRL: resp.data.tipoIdentificacionRL || 1,
        numeroIdentificacionRL: resp.data.numeroIdentificacionRL || null,
        razonSocial: resp.data.razonSocial || null,
        direResi: resp.data.direResi || "DireccionCasa",
        departamento: resp.data.departamento || 1,
        municipio: "MunicipioCasa"
      }
      this.infoUser = datosPersonales
      console.log(this.infoUser)
      if (this.infoUser.razonSocial) {
        localStorage.setItem('tipoPersona', 'PJ');
        this.infoUser.personTypeId = 2
      }
      if
        (this.infoUser.razonSocial == null) {
        localStorage.setItem('tipoPersona', 'PN');
        this.infoUser.personTypeId = 1
      }

      this.sesionData.idTokenClaims["idCodigoVentanilla"] = parseInt(idUsuarioVentanilla, 10);
      this.sesionData.idTokenClaims["datosPersonales"] = this.infoUser;
      this.sesionData.idTokenClaims["roles"] = [this.rolesUser];
      this.sesionData.idTokenClaims["tieneCodigoVentanilla"] = true;
      this.sesionData.idTokenClaims["aceptoPoliticaSeguridad"] = true;
      console.log("voy a cambiar el usuario")
      console.log(this.sesionData)
      this.apiAuth.setCurrentUser(this.sesionData);
      // if (this.rolesUser === undefined) {
      //   this.addRolVu()
      // }
      // if (this.rolesUser.rol === 'AdminTI' || this.rolesUser.rol === 'Admin' || this.rolesUser.rol === 'Funcionario') {
      //   this.router.navigate(['/Home/functionary']);
      // } else {
      //   location.reload()
      // }

      if (this.rolesUser === undefined) {
        this.addRolVu();
      } else {
        switch (this.rolesUser.rol) {
          case 'AdminTI':
          case 'Admin':
          case 'Funcionario':
            this.router.navigate(['/Home/functionary']);
            break;
          default:
            location.reload();
            break;
        }
      }
    }
    })
  }

  addRolVu() {
    this.list_usu_roles.push({
      id: "0",
      usersId: localStorage.getItem('clientId'),
      rolesId: "447dacb5-eeb9-48f3-a204-05b88992db7c",
      // OLD tramitesMainId: "d010d048-02e8-42d8-b61f-320911047ff4",
       tramitesMainId: "34029A78-EAAC-4CEA-9AD2-6040665C9916",
      roles: undefined,
      tramites: undefined,
      fechaCreacion: undefined
    })
    this.usuarioRolService.guardarUsuarioRoles(this.list_usu_roles).subscribe((resp) => {
      location.reload()
    }, (error) => {
      console.error("Error al guardar roles de usuario:", error);
      // Puedes manejar el error aquí según tus necesidades
      this.guardarUsuarioBdVu()
    })
  }

  guardarUsuarioBdVu() {
    let miObjeto = JSON.parse(localStorage.getItem('currentUser'));
    console.log(miObjeto.datosPersonales.numeroIdentificacion)
    console.log(miObjeto.datosPersonales.primerNombre)
    console.log(miObjeto.datosPersonales.primerApellido)
    let data = {
      id: localStorage.getItem('clientId'),
      email: localStorage.getItem('email'),
      pwd: localStorage.getItem('clientId'),
      firtsName: miObjeto.datosPersonales.primerNombre,
      lastName: miObjeto.datosPersonales.primerApellido,
      userName: localStorage.getItem('userName'),
      position: "NA",
      area: "NA"
    }
    this.userService.guardarUsuario(data).subscribe(res => {
      if (res.status == 200) {
        console.log(res)
        this.addRolVu()
      }
    })
  }

  /**
   *
   * @param data
   */
  getRoleUser(data: any) {
    this.apiservices.getUserRole(data).subscribe(res => {
      if (res.code == 200 && res.data.length > 0) {
        localStorage.setItem('rol', res.data[0].rol);
        this.rolesUser = res.data[0]
        this.getInfoUser(localStorage.getItem('idUser'))
        // location.reload()

      }
      if (res.code == 200 && res.data.length == 0) {
        localStorage.setItem('rol', 'nn');
        location.reload()
      }
    }, err => {
      alert('Error del servidor')
      location.reload()
    })
  }

  consultarRolesAsignadosPorAdmin() {
    let id = localStorage.getItem('clientId')
    this.userService.traerUsuariosAutenticacion("codigo",id).subscribe(res => {

      console.log(res)
      let authData = JSON.parse(localStorage.getItem(id + '-b2c_1_iniciosesionconregistro.8c611a0d-cdee-4ab8-8c13-99a223fc4f35-saludcapitalb2c.b2clogin.com-'));

      res.data.forEach((ele) => {
        // id de ventanilla unica en la integracion de autenticacion transversal
        if (ele.idAplicativo === '61861ead-09a8-45fd-a152-e1bc2dbf0d48') {
          localStorage.setItem('rol', ele.nombreRol);
          this.rolesUser =  {
            codigoUsuario: ele.idUsuario,
            email: localStorage.getItem('email'),
            rol: ele.nombreRol,
            descripcion: ele.nombreRol,
            value: ele.nombreRol,
            nombreCompleto: localStorage.getItem('userName')

          }


          console.log(this.rolesUser)
        }
      })
      this.getInfoUser(localStorage.getItem('idUser'))
      //location.reload()

    })
  }

  addPerson() {
    let data = {
      oid: this.sesionData.uniqueId,
      email: this.sesionData.idTokenClaims.emails[0]
    }
    this.apiservices.addUserSistem(data).subscribe(res => {
      location.reload()
      this.router.navigate(['Home']);
    })
  }

  openRegisterModal(): void {
    this.dialog.open(RegisterPersonModalComponent, {
      data: { infoDoc: '' }
    });
  }

  validarAutentificacion() {
    this.id = localStorage.getItem('clientId');
    if (this.id != null) {
      if (this.id === '') {
        this.sesionActiva = false;
      } else {
        this.sesionActiva = true;
      }
    }
  }

}
