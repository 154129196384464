<div class="confirmContainer">
    <div class="close">
        <mat-dialog-actions>
            <mat-icon mat-dialog-close>close</mat-icon>
        </mat-dialog-actions>
    </div>
    <figure>


        <div class="modal-icon">
            <span *ngIf="data.type === 'success'" class="modal-icon-govco modal-success-icon"></span>
            <span *ngIf="data.type === 'warning'" class="modal-icon-govco modal-warning-icon"></span>
            <span *ngIf="data.type === 'fail'" class="modal-icon-govco modal-error-icon"></span>
        </div>
        <figcaption>
            <h2 *ngIf="data.type === 'warning'" style=" color: #f9d005c4;">{{data.title}}</h2>
            <h2 *ngIf="data.type === 'success'" style=" color: forestgreen;">{{data.title}}</h2>
            <h2 *ngIf="data.type === 'fail'" style=" color: red;">{{data.title}}</h2>
        </figcaption>
        <p>
            {{data.text}}
        </p>
    </figure>
    <div *ngIf="data.type != 'success'">
        <button [mat-dialog-close]="true" type="submit">CONFIRMAR</button>
        <button [mat-dialog-close]="false" type="submit">CANCELAR</button>

    </div>
</div>