// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const urlApiManager = 'https://apm-aeu-sds-dev-shared.azure-api.net/tramites-shared/api/v1'

export const environment = {
  production: false,
  urlSwaggerFundaciones:"https://apm-aeu-sds-dev-shared.azure-api.net/Tramites-Recoperjuridica-Fun/validationApi/CertificateExist/GetDocumentbyVerificationCode",
  urlSwaggerDispMedicos:"https://apm-aeu-sds-dev-shared.azure-api.net/Tramites-Certdispmedicos/api/v1/Document/GetDocumentByVerificationCode",
  urlSwaggerDiscapacidad:"https://apm-aeu-sds-dev-shared.azure-api.net/Tramites-Certdiscapacidad/api/Certificados/GetDataVerificationCode",
  urlSwaggerExhumacion:"https://apm-aeu-sds-dev-shared.azure-api.net/Tramites-Exhumacion/api/Request/GetDataVerificationCode",
  urlSwaggerInhumacion:"https://apm-aeu-sds-dev-shared.azure-api.net/inhumacioncremacion/api/Request/GetRequestById",
  urlSwaggerSharedInhumacion :"https://apm-aeu-sds-dev-shared.azure-api.net/tramites-shared/api/v1/ValidacionDocumentos/validationString",
  urlSwaggerGetIdInhumacion :"https://apm-aeu-sds-dev-shared.azure-api.net/inhumacioncremacion/api/Request/ConsultarLicenciaMod" ,
  urlSwaggerTitulos:"https://apm-aeu-sds-dev-shared.azure-api.net/Tramites-Auttitulos/api/v1/Reports/GetDataVerificationCode",
  //urlSwaggerFundaciones:"https://localhost:5001/validationApi/CertificateExist/GetDocumentbyVerificationCode",
  urlApiManagerShared: "https://apm-aeu-sds-dev-shared.azure-api.net/tramites-shared/api",
  urlApiManagerSecurity: "https://apm-aeu-sds-dev-shared.azure-api.net/security/api",
  //urlApiManagerSecurity: "https://localhost:5001/api",
  baseUrl: "https://localhost:7080/api",
  backUrl: "https://apm-aeu-sds-dev-shared.azure-api.net/VentanillaVT/api",

  tokenUrl: "https://login-appservice-back1.azurewebsites.net/auth/",
  addressUrl: 'http://sig.saludcapital.gov.co/wsdireccion/direccion.asmx?op=obtenerCodDireccion',

B2C_CONFIG: {
    TENANT_NAME: 'saludcapitalb2c',
    CLIENT_ID: '6dfed1c9-50b8-49e6-8bd6-09d850f5ccba',
   // CLIENT_ID: '95c4a873-525a-4acf-8fd9-16d185f971a4',
    SIGN_UP_SIGN_IN: 'b2c_1_InicioSesionConRegistro'
  },
  //Lista de generos
  getSexList: `${urlApiManager}/Sexo/GetSexo`,
  //lista de etnias
  getEthnicityList: `${urlApiManager}/Etnia/GetEtnia`

  // tokenUrl: "",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
