import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../../environments/env';
import { Observable } from 'rxjs';

const url = environment.backUrl;
const urlApiManagerShared = environment.urlApiManagerShared;
const urlApiManagerSecurity = environment.urlApiManagerSecurity;
const urlSwaggerFundaciones = environment.urlSwaggerFundaciones;
const urlSwaggerDispMedicos = environment.urlSwaggerDispMedicos;
const urlSwaggerDiscapacidad = environment.urlSwaggerDiscapacidad;
const urlSwaggerExhumacion = environment.urlSwaggerExhumacion;
const urlSwaggerTitulos = environment.urlSwaggerTitulos;
const urlSwaggerSharedInhumacion = environment.urlSwaggerSharedInhumacion;
const urlSwaggerGetIdInhumacion = environment.urlSwaggerGetIdInhumacion;
const urlSwaggerInhumacion = environment.urlSwaggerInhumacion;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // urlApiManagerShared = environment.urlApiManagerShared
  // urlApiManagerSecurity = environment.urlApiManagerSecurity
  // urlBack = environment.backUrl
  private apiUrl = 'https://api64.ipify.org?format=json';

  constructor(private http: HttpClient) { }

  //HOME------------------------------------------

  /**
   * Servicio que trae la lista de tramites
   * @param json
   * @returns
   */
  getTramites(json: any): Observable<any> {
    return this.http.post(`${url}/Paperwork/GetByPaginated`, json)
  }

  /**
   * srv que trae la cantidad de tramites por eqtiqueta
   * @returns
   */
  getTramitesByModality(): Observable<any> {
    return this.http.get(`${url}/Paperwork/GetCountByTags`)
  }


  /**
   * Servicio que consulta la utenticidad de un documento por el id
   * @param id
   * @returns
   */
  validateAuthenticity(id: string): Observable<any> {
    return this.http.get(`${url}/ProcedureRequest/CheckDocumentAuthenticity/` + id)
  }

  /**
   * Servicio que trae las imagenes y los respectivos links de el portal para el ciudadano
   * @returns
   */
  getImagesInfoCitizienPortal(): Observable<any> {
    return this.http.get(`${url}/UserPortals/GetAll`)
  }

  //SERVICIOS EN LINEA

  /**
  * Trae los servicios en linea con sus respectivas imagenes y links
  * @returns
  */
  getOnlineServices(): Observable<any> {
    return this.http.get(`${url}/OnlineService/GetAll`, { observe: 'response' });
  }

  //APIMANAGER-----------------------------
  //REGISTRO USUARIO
  /**
 * Obtener lsita de paises
 */
  getPais(): Observable<any> {
    return this.http.get(`${urlApiManagerShared}/v1/Pais/GetPais`);
  }

  /**
   * Obtener lista de sexos
   */
  getSexo(): Observable<any> {
    return this.http.get(`${urlApiManagerShared}/v1/Sexo/GetSexo`);
  }

  /**
   * Obtener lista Generos
   */
  getGenero(): Observable<any> {
    return this.http.get(`${urlApiManagerShared}/v1/Sexo/GetGenero`);
  }

  /**
   *Obtener lista de Orientacion Sexual
   */
  getOrientacion(): Observable<any> {
    return this.http.get(`${urlApiManagerShared}/v1/Sexo/GetOrientacion`);
  }

  /**
   * Obtener lista de Etnia
   */
  getEtnia(): Observable<any> {
    return this.http.get(`${urlApiManagerShared}/v1/Etnia/GetEtnia`);
  }

  /**
   *Obtener lista Estado Civil
   */
  getEstadoCivil(): Observable<any> {
    return this.http.get(`${urlApiManagerShared}/v1/EstadoCivil/GetEstadoCivil`);
  }

  /**
   *Obtener lista de Nivel Educativo
   */
  getNivelEducativo(): Observable<any> {
    return this.http.get(`${urlApiManagerShared}/v1/NivelEducativo/GetNivelEducativo`);
  }

  /**
   * Obtener lista de Tipo de Identificacion
   */
  getTipoIdentificacion(): Observable<any> {
    return this.http.get(`${urlApiManagerShared}/v1/TipoIdentificacion/GetTipoIdentificacion`);
  }

  /**
   * Guardar Datos P. Juridica
   */
  saveLegalPerson(data: any): Observable<any> {
    return this.http.post(`${urlApiManagerShared}/v2/Persona/AddPersonaJuridica`, data);
  }

  /**
   * Obtener informacion completa registrada por numero de documento
  */
  consultarInfoRegistrada(id: number): Observable<any> {
    return this.http.get(`${urlApiManagerShared}/v2/Persona/GetInfoUserByIdDocument/` + id);
  }
  /**
   * obtener Datos P. Juridica
   */

  consultarLegalPerson(id: number): Observable<any> {
    return this.http.get(`${urlApiManagerShared}/v2/Persona/GetInfoUserById/` + id);
  }

  /**
   * actualizar Datos P. Juridica
   */
  //Si se usa el metodo el apimanager no permite actualizar
  actualizarLegalPerson(data: any): Observable<any> {
    return this.http.put(`${urlApiManagerShared}/v2/Persona/UpdatePerson`, data);
  }

  /**
   * Guardar Datos P. Naturla
   */
  saveNaturalPerson(data: any): Observable<any> {
    return this.http.post(`${urlApiManagerShared}/v2/Persona/AddPersonaNatural`, data);
  }


  /**
   * Obtener listado de departamentos en SDS
   */
  getDepartaments(): Observable<any> {
    return this.http.get(`${urlApiManagerShared}/v1/Departamento/GetDepartamento`);
  }



  getMunByDepaId(depaId: number): Observable<any> {
    return this.http.get(`${urlApiManagerShared}/v1/Municipio/GetMunicipioByIdDepartamento/${depaId}`);
  }

  addPoliticaSeguridad(data: any): Observable<any> {
    return this.http.post(`${urlApiManagerShared}/v1/PoliticaSeguridad/AddPoliticaSeguridad`, data);
  }

  getPoliticaSeguridad(data: any): Observable<any> {
    return this.http.get(`${urlApiManagerShared}/v1/PoliticaSeguridad/GetPoliticaSeguridad/` + data);
  }

  getIdUserVentanilla(data: any): Observable<any> {
    return this.http.get(`${urlApiManagerSecurity}/v2/Security/GetCodeVentanillaByIdUser/` + data);
  }

  getUserRole(data: any): Observable<any> {
    return this.http.get(`${urlApiManagerSecurity}/v2/Security/GetRoleByIdUser/` + data);
  }

  addUserRole(data: any): Observable<any> {
    return this.http.post(`${urlApiManagerSecurity}/v2/Security/AddUserRole`, data);
  }

  addUserSistem(data: any): Observable<any> {
    return this.http.post(`${urlApiManagerSecurity}/v2/Security/AddUser`, data)
  }

  getDireccion(dir: string): Observable<any> {
    return this.http.get(`${urlApiManagerShared}/v1/Direccion/GetDireccion/` + dir);
  }

  /**
   * Completar registro
   */
  actualizarRegistroPersona(data: any): Observable<any> {
    return this.http.put(`${urlApiManagerSecurity}/v2/Security/UpdateUser`, data);
  }

  /**
   * traer las solicitudes del funcionario logueado
   */
  getAll(): Observable<any> {
    let data = {
      pageSize: 10,
      pageNumber: 1,
      textToSearch: ""
    }
    let API_URL = `https://wa-aeu-sds-dev-ventanillavt-sst.azurewebsites.net/api/ProcedureRequest/GetAll`;
    return this.http.post(API_URL, data);
  }

  //ADMINISTRACION------------------------------------------------------------------
  /**
   * Trae el catalogo de tramites
   * @param data
   * @returns
   */
  getPaperWorkProcedure(data: any): Observable<any> {
    let API_URL = (`${url}/Paperwork/GetAll`);
    return this.http.post(API_URL, data)
  }

  // getProcedureById(data: any): Observable<any> {
  //   return this.http.get(`https://localhost:7080/api/Paperwork/id?id=` + data);
  //   return this.http.get(`https://app-sds-ventanillavt-qa-eastus2.azurewebsites.net//api/Paperwork/id?id=` + data);
  // }

  /**
   * Trae la lista de personalidad legal aplicable
   * @returns
   */
  getPersonalidadAplicable(): Observable<any> {
    return this.http.get(`${url}/Master/GetPaperworkCategory`);
  }

  /**
   * Crear un nuevo tramite
   * @param data
   * @returns
   */
  agregarTramite(data: any) {
    return this.http.post(`${url}/Paperwork`, data, { observe: 'response' });
  }
  /**
   * Crear un nuevo tramite Autenticacion
   * @param data
   * @returns
   */
  agregarTramiteAutenticacion(data: any) {
    return this.http.post(`${urlApiManagerSecurity}/v2/Security/PostAplication`, data, { observe: 'response' });
  }

  /**
   * Edita los datos de un tramite por el id
   * @param id
   * @param data
   * @returns
   */
  editarTramite(id: any, data: any) {
    return this.http.put(`${url}/Paperwork/` + id, data, { observe: 'response' });
  }

  /**
   * Elinina un tramite por su id
   * @param data
   * @returns
   */
  eliminarTramite(data: any) {
    return this.http.delete(`${url}/Paperwork/` + data, { observe: 'response' });
  }

  //REPORTES-----------------------------------------------------------

  getTramiteReportes(json: any): Observable<any> {
    return this.http.post(`${url}/ProcedureRequest`, json)
  }

  getEncuestaReportes(json: any): Observable<any> {
    return this.http.post(`${url}/Survey/GetResponseByFilter`, json)
  }

  getListaTramites() {
    return this.http.get(`${url}/Paperwork`);
  }

  crearReporteTramites(json: any): Observable<any> {
    return this.http.post(`${url}/Report`, json, { observe: 'response' })

  }

  getReportesTramites(id: any) {
    return this.http.get(`${url}/Report/` + id)
  }

  borrarReporteTramites(data: any) {
    return this.http.delete(`${url}/Report/` + data, { observe: 'response' });
  }

  editarReporteTramites(id: any, data: any) {
    return this.http.put(`${url}/Report/UpdateName/` + id, data, { observe: 'response' });
  }

  obtenerPdfReporteTramite(json: any): Observable<any> {
    return this.http.post(`${url}/ProcedureRequest/DownLoadPdf`, json, { observe: 'response' })
  }

  obtenerExcelReporteTramite(json: any): Observable<any> {
    return this.http.post(`${url}/ProcedureRequest/DownloadExcel`, json, { observe: 'response' })
  }

  obtenerPdfReporteEncuesta(json: any): Observable<any> {
    return this.http.post(`${url}/Survey/DownloadPdf`, json, { observe: 'response' })
  }

  obtenerExcelReporteEncuesta(json: any): Observable<any> {
    return this.http.post(`${url}/Survey/DownloadExcel`, json, { observe: 'response' })
  }
  ///////////===>HASTA ASQUI BIEN



  //SESION HACIA OTROS TRAMITES
  sesionUsuario(json: any): Observable<any> {
    return this.http.post(`${url}/User`, json, { observe: 'response' })

  }

  //ENCUESTAS---------------------------------------------------------------------------------------------------------
  //Obtiene la encuesta por el id
  getSurveyByid(data: any): Observable<any> {
    return this.http.get(`${url}/Survey/` + data);
  }

  //Obtiene la encuesta por el tipo
  getSurveyByType(data: any): Observable<any> {
    return this.http.get(`${url}/Survey/GetBySurveyTypeId/` + data);
  }

  //Obtiene la encuestas generadas
  getSurveys(): Observable<any> {
    return this.http.get(`${url}/Survey/`);
  }

  //eliminar encuesta por id
  deleteSurveys(data: any): Observable<any> {
    return this.http.delete(`${url}/Survey/` + data, { observe: 'response' });
  }
  ///////////===>HASTA ASQUI Revisando

  //GUARDA las respuestas del ciudadano
  // saveAnswersform(json: any): Observable<any> {
  //   return this.http.post(`${url}/Survey/AddUserResponse`, json, { observe: 'response' })

  // }
  saveAnswersform(json: any, headers?: HttpHeaders): Observable<any> {
    const requestOptions = {
      headers,
      observe: 'response' as const
    };

    return this.http.post(`${url}/Survey/AddUserResponse`, json, requestOptions);
  }

  //Actualiza la encuesta por el id
  udapteSurveyById(id: any, data: any): Observable<any> {
    return this.http.put(`${url}/Survey/` + id, data, { observe: 'response' });

  }


  //crea encuestas
  createSurvey(data: any): Observable<any> {
    return this.http.post(`${url}/Survey`, data, { observe: 'response' });

  }

  consultarRegistroAcciones(pagina: number, registros: number): Observable<any[]> {
    return this.http.get<any[]>(`${url}/Auditoria/list?pagina=${pagina}&regPorPagina=${registros}`);
  }

  registrarAcciones(json: any): Observable<any> {
    return this.http.post(`${url}/Auditoria`, json, { observe: 'response' })
  }

  getIPAddress(): Observable<any> {
    return this.http.get(this.apiUrl);
  }

  obtenerDatos(procedure: string, filter?: string, filterType?: string, pagination?: string,tramite?:string): Observable<any> {
    const urlsrv = `${url}/Integration/${procedure}?filter=${filter}&filterType=${filterType}&tramite=${tramite}&pagination=${pagination}`;

    return this.http.get<any>(urlsrv);
  }
  obtenerDatosFechaObligatoria(procedure: string, filter?: string, filterType?: string, pagination?: string, initialDate?: string, finalDate?: string,tramite?:string): Observable<any> {
    const urlsrv = `${url}/Integration/${procedure}?filter=${filter}&initialDate=${initialDate}&finalDate=${finalDate}&filterType=${filterType}&tramite=${tramite}&pagination=${pagination}`;

    return this.http.get<any>(urlsrv);
  }

  obtenerDatosRangoFecha(procedure: string, initialDate: string, finalDate: string, pagination?: string,tramite?:string): Observable<any> {
    const urlsrv = `${url}/Integration/${procedure}?initialDate=${initialDate}&finalDate=${finalDate}&filterType=rangofechas&tramite=${tramite}&pagination=${pagination}`;

    return this.http.get<any>(urlsrv);
  }


  obtenerDocumentoFundaciones(codigo: string): Observable<any> {
    const urlsrv = `${urlSwaggerFundaciones}/${codigo}`;

    return this.http.get<any>(urlsrv);
  }
  obtenerDocumentoDispMedicos(codigo: string): Observable<any> {
    const urlsrv = `${urlSwaggerDispMedicos}/${codigo}`;

    return this.http.get<any>(urlsrv);
  }
  obtenerDocumentoDiscapacidad(codigo: string): Observable<any> {
    const urlsrv = `${urlSwaggerDiscapacidad}/${codigo}`;

    return this.http.get<any>(urlsrv);
  }
  obtenerDocumentoExhumacion(codigo: string): Observable<any> {
    const urlsrv = `${urlSwaggerExhumacion}/${codigo}`;

    return this.http.get<any>(urlsrv);
  }
  obtenerDocumentoInhumacion(codigo: string): Observable<any> {
    const urlsrv = `${urlSwaggerInhumacion}/${codigo}`;

    return this.http.get<any>(urlsrv);
  }
  obtenerDataInhumacion(codigo: string): Observable<any> {
    const urlsrv = `${urlSwaggerSharedInhumacion}/${codigo}`;

    return this.http.get<any>(urlsrv);
  }
  obtenerIdInhumacion(codigo: string): Observable<any> {
    const urlsrv = `${urlSwaggerGetIdInhumacion}/${codigo}/idcontrol`;

    return this.http.get<any>(urlsrv);
  }
  obtenerDocumentoTitulos(codigo: string): Observable<any> {
    const urlsrv = `${urlSwaggerTitulos}/${codigo}`;

    return this.http.get<any>(urlsrv);
  }


  // obtenerDireccionIP(): Observable<any> {
  //   return this.http.get(this.apiUrl);
  // }

  // getRequest(idrol: any,filter:string,search:string,pagenumber:any,pagesise:any): Observable<any> {
  //   let url = `${idrol}?search=${search}&filter=${filter}&page=${pagenumber}&pageSize=${pagesise}`;
  //   return this.http.get(`${ environment.apiUrlBack }/api/Request/GetRequestsByIdRol/${url}`);
  // }
  //https://localhost:7080/api/Integration/getProcedure19?filter=1026580648&filterType=nume_identificacion&pagination=0
}
