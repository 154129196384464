import { filter } from 'rxjs';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api/api.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertModalInformationComponent } from 'src/app/Shared/alert-modal-information/alert-modal-information.component';
import { ConfirmationModalComponent } from 'src/app/Shared/confirmation-modal/confirmation-modal.component';
import { RegisterPersonModalComponent } from '../register-person-modal.component';
import { MsalService } from '@azure/msal-angular';
import { AddressService } from 'src/app/Services/address.service';
import { event } from 'jquery';
import { UsuariosService } from 'src/app/Services/roles-usuarios-funcionalidades/usuarios.service';
import { UsuarioRolesService } from 'src/app/Services/roles-usuarios-funcionalidades/usuarios-roles.service';
import { UsuarioRoles } from 'src/app/Models/usuarioRoles.model';

@Component({
  selector: 'app-register-natrual-person',
  templateUrl: './register-natrual-person.component.html',
  styleUrls: ['./register-natrual-person.component.scss']
})
export class RegisterNatrualPersonComponent implements OnInit {

  upzSeleccionado: any;
  localidadSeleccionada: any;
  barrioSeleccionado: any;
  zonaSeleccionado: any;
  naturalForm: any = FormGroup;
  disabledInputs: boolean = true;
  registerMode: boolean = false;
  zoneList: any;
  localityList: any;
  upzList: any;
  neighborhoodList: any;
  public departaments: any[] = [];
  public municipios: any[] = [];
  public municipioN: any[] = [];
  public paiss: any[] = [];
  public sexoo: any[] = [];
  public generoo: any[] = [];
  public orientacionn: any[] = [];
  public etniaa: any[] = [];
  public estadoCivill: any[] = [];
  public nivelEducativoo: any[] = [];
  public tipoIdentificacionn: any[] = [];
  list_usu_roles: UsuarioRoles[] = [];
  nacionalidadPersona: string = "Nacional";
  datosDireccion: boolean = false;
  direccionConfirmada: boolean = false;
  direccionAreaUrbanaBgta: boolean = true;
  direccionAreaUrbanaBgtaString: any = '';
  tipodir: any = 'urbana';


  constructor(private router: Router, @Inject(MAT_DIALOG_DATA) public data: { register: boolean }, private apiservices: ApiService, private fb: FormBuilder, public dialog: MatDialog, public msal: MsalService, private userService: UsuariosService, private tramiteSharedService: AddressService, private usuarioRolesService: UsuarioRolesService) {

  }

  ngOnInit(): void {

    let nombres:string[] = localStorage.getItem('userName').split(" ");
    let apellidos:string[] = localStorage.getItem('lastName').split(" ");

    console.log(nombres.length);
    console.log(apellidos.length);

    this.naturalForm = this.fb.group({

      primerNombre: [nombres[0], [Validators.required]],
      segundoNombre: [nombres.length > 1?nombres[1]:''],
      ciudadNacimientoOtro: [''],
      primerApellido: [apellidos[0], [Validators.required]],
      segundoApellido: [apellidos.length > 1?apellidos[1]:''],
      tipoDocumento: ['', [Validators.required]],
      numeroIdentificacion: ['', [Validators.required]],
      email: [{ value: localStorage.getItem('email'), disabled: true }, [Validators.required]],
      telefonoFijo: [''],
      telefonoCelular: ['', [Validators.required]],
      nacionalidad: ['', [Validators.required]],
      departamentoResidencia: [''],
      ciudadResidencia: [''],
      ciudadNacimiento: [''],
      departamentoNacimiento: [''],
      direccionResidencia: ['', [Validators.required]],
      fechaNacimiento: ['', [Validators.required]],
      sexo: ['', [Validators.required]],
      genero: ['', [Validators.required]],
      orientacionSexual: ['', [Validators.required]],
      etnia: ['', [Validators.required]],
      estadoCivil: [''],
      nivelEducativo: ['', [Validators.required]],
      confirmEmail: [{ value: localStorage.getItem('email'), disabled: true }, [Validators.required]],

      cx: [''],
      cy: [''],
      zona: [''],
      localidad: [''],
      upz: [''],
      barrio: ['']



    })

    this.registerMode = !this.data.register
    this.disabledInputs = !this.data.register

    this.apiservices.getPais().subscribe(resp => this.paiss = resp.data);
    this.apiservices.getDepartaments().subscribe(resp => this.departaments = resp.data);
    this.apiservices.getSexo().subscribe(resp => this.sexoo = resp.data);
    this.apiservices.getGenero().subscribe(resp => this.generoo = resp.data);
    this.apiservices.getOrientacion().subscribe(resp => this.orientacionn = resp.data);
    this.apiservices.getEtnia().subscribe(resp => this.etniaa = resp.data);
    this.apiservices.getEstadoCivil().subscribe(resp => this.estadoCivill = resp.data);
    this.apiservices.getNivelEducativo().subscribe(resp => this.nivelEducativoo = resp.data);
    this.apiservices.getTipoIdentificacion().subscribe(resp => this.tipoIdentificacionn = resp.data);


    this.datosUbicacion()
  }



  async obtenerDatos() {
    try {
      const zone = await this.tramiteSharedService.getZones().toPromise();
      this.zoneList = zone.data

      const localidad = await this.tramiteSharedService.getLocalities().toPromise();
      this.localityList = localidad.data;

      const upz = await this.tramiteSharedService.getUpz().toPromise();
      this.upzList = upz.data;

      const neighborhood = await this.tramiteSharedService.getNeighborhood().toPromise();
      this.neighborhoodList = neighborhood.data;
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }

  datosUbicacion() {
    this.obtenerDatos();
  }

  filtrarLocalidad() {
    this.tramiteSharedService.getLocalities()
      .subscribe((localidad) => {
        this.localityList = localidad.data.filter((ele, index) => ele.id_subred == this.naturalForm.value.zona)
      });

  }


  filtrarUpz() {
    this.tramiteSharedService.getUpz()
      .subscribe((upz) => {
        this.upzList = upz.data.filter((ele, index) => ele.id_localidad == this.naturalForm.value.localidad)
      });
  }

  filtrarBarrio() {
    this.tramiteSharedService.getNeighborhood()
      .subscribe((neighborhood) => {
        this.neighborhoodList = neighborhood.data.filter((ele, index) => ele.id_upz == this.naturalForm.value.upz);
      })
  }


  getMunicipios(idDep: number): void {
    this.apiservices.getMunByDepaId(idDep).subscribe(resp => this.municipios = resp.data)
  }

  openDialogFail(): void {
    this.dialog.open(ConfirmationModalComponent, {
      data: { type: 'warning', title: 'Eliminar registro.', text: 'Los datos ingresados se perderán y será redirecionado a la página principal.', acept: "" },
      height: 'auto',
      width: '700px',
    }).afterClosed().subscribe(result => {

      if (result) {
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      }
    });
  }

  async onLogOut() {
    this.msal.logoutPopup().subscribe((data) => {
      console.log(data)
    })
    localStorage.clear();
  }

  openRegisterModal(): void {
    this.dialog.open(RegisterPersonModalComponent, {
      data: { infoDoc: '' },
      disableClose: true


    });
  }




  SaveNaturalPerson() {
    //console.log(this.naturalForm);
    let data = {
      tipoDocumento: this.naturalForm.value.tipoDocumento,
      numeroIdentificacion: this.naturalForm.value.numeroIdentificacion,
      primerNombre: this.naturalForm.value.primerNombre,
      segundoNombre: this.naturalForm.value.segundoNombre,
      primerApellido: this.naturalForm.value.primerApellido,
      segundoApellido: this.naturalForm.value.segundoApellido,
      email: localStorage.getItem('email'),
      telefonoFijo: this.naturalForm.value.telefonoFijo,
      telefonoCelular: this.naturalForm.value.telefonoCelular,
      nacionalidad: this.naturalForm.value.nacionalidad,
      departamento: this.naturalForm.value.departamentoNacimiento ? this.naturalForm.value.departamentoNacimiento : '1',
      ciudadNacimiento: this.naturalForm.value.ciudadNacimiento ? this.naturalForm.value.ciudadNacimiento : '1',
      ciudadNacimientoOtro: this.naturalForm.value.ciudadNacimientoOtro ? this.naturalForm.value.ciudadNacimientoOtro : '1',
      departamentoResidencia: this.naturalForm.value.departamentoResidencia,
      ciudadResidencia: this.naturalForm.value.ciudadResidencia,
      direccionResidencia: this.naturalForm.value.direccionResidencia,
      fechaNacimiento: this.naturalForm.value.fechaNacimiento,
      sexo: this.naturalForm.value.sexo,
      genero: this.naturalForm.value.genero,
      orientacionSexual: this.naturalForm.value.orientacionSexual,
      etnia: this.naturalForm.value.etnia,
      estadoCivil: this.naturalForm.value.estadoCivil,
      nivelEducativo: this.naturalForm.value.nivelEducativo,
      // cx: this.naturalForm.value.cx,
      // cy: this.naturalForm.value.cy,
      cx: 1,
      cy: 1,
      upz: this.naturalForm.value.upz != '' ? this.naturalForm.value.upz : 1,
      zona: this.naturalForm.value.zona != '' ? this.naturalForm.value.zona : 1,
      barrio: this.naturalForm.value.barrio != '' ? this.naturalForm.value.barrio : 1,
      localidad: this.naturalForm.value.localidad != '' ? this.naturalForm.value.localidad : 1
    }
    this.apiservices.saveNaturalPerson(data).subscribe(resp => {
      if (resp.code === 200) {
        if (resp.data) {
          this.registroCompleto(resp.data)
        }
      } else if (resp.code === 202) {
        let dialogRef = this.dialog.open(AlertModalInformationComponent, {
          data: { type: 'fail', title: 'Registro Fallido.', text: resp.data.message, acept: "" },
          height: 'auto',
          width: '700px',
        });
        setTimeout(() => {
          dialogRef.close()
          // window.location.reload()
        }, 2000);
      }
    }, err => {
      let dialogRef = this.dialog.open(AlertModalInformationComponent, {
        data: { type: 'fail', title: 'Registro Fallido.', text: err.error.message, acept: "" },
        height: 'auto',
        width: '700px',
      });
      setTimeout(() => {
        dialogRef.close()
        //window.location.reload()
      }, 2000);
    })


  }

  registroCompleto(idPersonRegister: any) {
    let tiempoTranscurrido = Date.now();
    let hoy = new Date(tiempoTranscurrido);
    let data = {
      oid: localStorage.getItem('clientId'),
      email: localStorage.getItem('email'),
      nombreCompleto: localStorage.getItem('userName'),
      created: hoy,
      idPersonaVentanilla: idPersonRegister,
      isEnabled: true
    }
    this.apiservices.actualizarRegistroPersona(data).subscribe(resp => {
      if (resp.code == 200) {
        this.guardarUsuarioBdVu()
      }
    })
  }

  guardarUsuarioBdVu() {
    let data = {
      id: localStorage.getItem('clientId'),
      email: localStorage.getItem('email'),
      pwd: this.naturalForm.value.numeroIdentificacion,
      firtsName: this.naturalForm.value.primerNombre,
      lastName: this.naturalForm.value.primerApellido,
      userName: localStorage.getItem('userName'),
      position: "NA",
      area: "NA"
    }
    this.userService.guardarUsuario(data).subscribe(res => {
      if (res.status == 200) {
        this.addUserRole()
      }
    })
  }
  //AGREGAR ROL CON EL SRV DE ASIGNACION DE ROL DE LOGIN
  addRolVu() {
    this.list_usu_roles.push({
      id: "0",
      usersId: localStorage.getItem('clientId'),
      rolesId: "447dacb5-eeb9-48f3-a204-05b88992db7c",
      // OLD tramitesMainId: "d010d048-02e8-42d8-b61f-320911047ff4",
      tramitesMainId: "34029A78-EAAC-4CEA-9AD2-6040665C9916",
      roles: undefined,
      tramites: undefined,
      fechaCreacion: undefined
    })
    this.usuarioRolesService.guardarUsuarioRoles(this.list_usu_roles).subscribe((resp) => {
      this.openDialoRegitroExitoso()
    })
  }

  addUserRole() {
    let data = {
      idUser: localStorage.getItem('clientId'),
      //Siempre que se ingresa de esta manera es un ciudadano
      idRole: '58EDA51F-7E19-47C4-947F-F359BD1FC732'
    }
    this.apiservices.addUserRole(data).subscribe(resp => {
      if (resp.code == 200) {
        localStorage.setItem('rol', 'Ciudadano');
        localStorage.setItem('tipoPersona', 'PN');
        this.addRolVu()
      }
    })
  }

  openDialoRegitroExitoso(): void {
    let dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: { type: 'success', title: 'Registro éxitoso.', text: 'Información de registro almacenada con éxito.', acept: "" },
      height: 'auto',
      width: '700px',
    });
    setTimeout(() => {
      dialogRef.close()
      location.reload();
    }, 3000);
  }


  obtenerNacionalidad() {
    if (this.naturalForm.value.nacionalidad != 170) {
      this.nacionalidadPersona = "Extranjero"
    } else {
      this.nacionalidadPersona = "Nacional"
    }
  }

  obtenerMunicipio() {
    this.apiservices.getMunByDepaId(this.naturalForm.value.departamentoNacimiento).subscribe(resp => this.municipios = resp.data)
  }

  cambioDepResi() {
    if (this.naturalForm.value.departamentoResidencia == 3) {
      this.datosDireccion = true;
    } else {
      this.datosDireccion = false;
    }
    this.apiservices.getMunByDepaId(this.naturalForm.value.departamentoResidencia).subscribe(resp => this.municipioN = resp.data)
  }

  cambioTipoDir($event: any) {
    this.tipodir = $event
    if ($event == 'urbana') {
      this.cambioDepResi()
      this.naturalForm.get('direccionResidencia').setValue('');
      this.naturalForm.get('zona').setValue('');
      this.naturalForm.get('upz').setValue('');
      this.naturalForm.get('localidad').setValue('');
      this.naturalForm.get('barrio').setValue('');
    } else if ($event == 'rural') {
      this.cambioDepResi()
    }

  }



  edit() {
    this.disabledInputs = false;
  }

  reload() {
    this.router.navigate(['Home']);
  }

  getAddress($event: any) {
    this.direccionConfirmada = true;
    this.naturalForm.get('direccionResidencia').setValue($event);
    this.buscarDireccionBgt($event)

  }

  buscarDireccionBgt(dir: string) {
    this.apiservices.getDireccion(dir).subscribe(res => {
      if (res.data.localidad === "no se encontro" && this.naturalForm.value.departamentoResidencia == 3) {
        this.direccionAreaUrbanaBgta = false;
      } else {
        this.zonaSeleccionado = res.data.idzona
        this.localidadSeleccionada = res.data.idlocalidad
        this.barrioSeleccionado = res.data.idbarrio
        this.upzSeleccionado = res.data.idupz
        // this.direccionAreaUrbanaBgtaString = res.data.localidad
        this.naturalForm.get('zona').setValue(res.data.idzona);
        this.naturalForm.get('upz').setValue(res.data.idupz);
        this.naturalForm.get('localidad').setValue(res.data.idlocalidad);
        this.naturalForm.get('barrio').setValue(res.data.idbarrio);
        this.naturalForm.get('cx').setValue(res.data.longitud);
        this.naturalForm.get('cy').setValue(res.data.latitud);
        this.direccionAreaUrbanaBgta = true;
      }
    })
  }



}
