<app-header ($carga)="estadoCarga($event)"></app-header>
<app-quick-acces></app-quick-acces>
<app-breadcrumb [ruta]="ruta"></app-breadcrumb>
<div class="container" *ngIf="authRole">
    <app-accesibility style="position: absolute; z-index: 2;"></app-accesibility>
    <h1 class="h1-tipografia-govco title-1">Catálogo de trámites</h1>
    <p class="alert alert alert-primary" role="alert">A continuación se muestra el catálogo de trámites expuestos en la sección de Trámites del Portal
        de Trámites y Servicios
    </p>
    <div class="row">

        <div class="col-sm-12 col-md-12">
            <div class="row prueba">
                <div class="prueba2 col-sm-12 col-md-12 text-end">

                    <div class=" col-sm-12 col-md-1 camtidadItems descripcion-emergente-govco">
                        <div>
                            <span class="tiptext" style="height: auto; top: 95%;">Número de Registros por página</span>

                        </div>
                        <input type="number" disabledText="true" id="input-basico-id" [(ngModel)]="itemsByPage" (ngModelChange)="a = itemsByPage"
                               placeholder="5" min="5" />
                    </div>

                    <div class="col-sm-12 col-md-3">
                        <div>
                            <pagination-controls (pageChange)="pageChanged($event)" nextLabel="Siguiente"
                                                 previousLabel="Anterior"></pagination-controls>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4">
                    <div class="entradas-de-texto-govco">
                        <label for="input-basico-id">Filtrar por:</label>
                        <select class="form-select" id="Tipo de Identificación" aria-label="Default select example" [(ngModel)]="filtro"
                                (change)="clearFilter($event)">
                            <option value="" selected disabled>Seleccione una opción</option>
                            <option value="2">Nombre del trámite</option>
                            <option value="3">Duración</option>
                            <option value="4">Costo</option>
                            <option value="5">url inicio del trámite</option>
                            <option value="6">Personalidad legal</option>
                            <option value="7"> url gov.co</option>
                        </select>
                    </div>
                </div>

                <div class="col-sm-12 col-md-3">
                    <div class="search-govco">
                        <br />
                        <br />
                        <div class="bar-search-govco">
                            <input type="text" placeholder="Ingrese su búsqueda" class="input-search-govco" aria-label="Buscador"
                                   [(ngModel)]="busqueda" disabled="{{filter}}" />
                            <button class="icon-search-govco icon-close-search-govco" aria-label="Limpiar"></button>
                            <div class="icon-search-govco search-icon-search-govco" (click)="consultar()" disabled="false" aria-hidden="true">
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="col-md-12" class="table-wrapper">
                        <div class="entradas-de-texto-govco col-sm-12 col-md-12 text-end">
                            <a class="descripcion-emergente-govco" [routerLink]=" ['/Home/RegsitrarTramite']">
                                <div>
                                    <span class="tiptext" style="height: auto; right: 95%; text-align: start;">Registre la información general de un
                                        trámite </span>
                                </div>
                                <span class="govco-plus-circle"></span>
                            </a>
                        </div>
                        <div class="col-xxl-12 col-md-12 col-lg-12 info_table">
                            <div class="table-responsive">
                                <table class="table" aria-describedby="reporte de tramites">
                                    <caption><label>Mostrando {{tramites2.length > 0 ? ((page - 1) * itemsByPage) + 1 : 0}}-{{itemsByPage >
                                            tramites2.length?
                                            tramites2.length:a}} de
                                            {{tramites2.length}} resultados</label></caption>
                                    <thead>
                                        <tr>
                                            <th>Nombre del trámite</th>
                                            <th class="descripcion-emergente-govco">
                                                <div>
                                                    <span class="tiptext" style="height: auto; left: 95%;">Tiempo de gestión del funcionario</span>
                                                </div>
                                                Duración
                                            </th>
                                            <th>Costo</th>
                                            <th>Clasificación</th>
                                            <th class="descripcion-emergente-govco">
                                                <div>
                                                    <span class="tiptext" style="height: auto; left: 95%;">url de la pantalla hacia donde es
                                                        direccionado
                                                        el
                                                        ciudadano</span>
                                                </div>
                                                url inicio del trámite
                                            </th>
                                            <th>Personalidad legal</th>
                                            <th>url manual</th>
                                            <th>url gov.co</th>

                                            <th>
                                                Acciones
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let tramite of tramites2   | paginate: { itemsPerPage: itemsByPage, currentPage: page }; let primero = first ;let ultimo = last; let indice=index">
                                            <td>{{tramite.name}}</td>
                                            <td>{{tramite.durationInDays}}</td>
                                            <td>{{tramite.hasCost?'Si': 'No'}}</td>
                                            <td>{{tramite.categoryName}}</td>
                                            <td>{{tramite.requirementsUrl}}</td>
                                            <td *ngIf="tramite.naturalPersonEnabled && tramite.legalPersonEnabled">Persona Natural/Persona Juridica
                                            </td>
                                            <td *ngIf="tramite.naturalPersonEnabled && !tramite.legalPersonEnabled">Persona Natural</td>
                                            <td *ngIf="!tramite.naturalPersonEnabled && tramite.legalPersonEnabled">Persona Juridica</td>
                                            <td>{{tramite.manualLink}}</td>
                                            <td>{{tramite.redirectUrl? tramite.redirectUrl: 'No llega'}}</td>
                                            <td>
                                                <span class=" govco-edit" style="color: #004884;" (click)="editarTramite(tramite)"></span>
                                                <span class="govco-minus-circle" style="color: #004884;" (click)="popUpConfirmacion(tramite)"></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div class="prueba2 col-sm-12 col-md-12 text-end">
                <div class="col-sm-12 col-md-5" (click)="this.router.navigate(['/Home/Administracion']);">
                    <button>Regresar</button>
                </div>
            </div>
        </div>

    </div>
    <hr>

</div>
<div class="container-indicador-de-carga-govco" [hidden]="carga">
    <div class="spinner-modal-indicador-de-carga-govco">
        <div class="spinner-indicador-de-carga-govco" style="width: 60px; height: 60px; border-width: 8px;" role="status" aria-hidden="true">
            <span class="visually-hidden">Cargando...</span>
        </div>
    </div>
</div>
<div class="container" *ngIf="!authRole">
    <div class="title">
        <h1 class="h1-tipografia-govco title-1">No Permitido</h1>
    </div>
</div>
<app-footer></app-footer>
