import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api/api.service';

@Component({
  selector: 'app-administration-module',
  templateUrl: './administration-module.component.html',
  styleUrls: ['./administration-module.component.scss']
})
export class AdministrationModuleComponent implements OnInit {

  rol: string | null = '';
  authRole: boolean = false;
  rutaMigaPan = [{ name: 'Inicio', url: '/Home' }, { name: 'Administración', url: '/Home/Administracion' }]

  constructor(private api: ApiService, public router: Router,) { }

  ngOnInit(): void {
    this.rol = localStorage.getItem('rol');
    if (this.rol == 'Funcionario' || this.rol == 'AdminFuncional' || this.rol == 'AdminTI') {
      this.authRole = true;
    } else {
      this.router.navigate(['/Home']);
    }


  }

}
