<div class="container-formalities">
    <h1 class="h1-tipografia-govco title-1">Trámites y servicios</h1>
    <div class="container-envelope">
        <figure>
            <img src="../../../assets/Images/Title_agilinea.jpg" alt="Agilínea">
        </figure>
    </div>
    <div class="alert alert alert-primary" role="alert">
        <p>A continuación encontrará un listado de los trámites disponibles para su registro y solicitud. Antes de registrar su solicitud deberá ver
            los requisitos.
            Ante cualquier inquietud, por favor lea atentamente el manual de instrucciones disponibles para cada trámite. Esta información
            puede ayudarle a evitar reprocesos y demoras en la gestión al registrar su trámite de manera correcta.
        </p>
    </div>
    <div class="queryProcedure">
        <label>
            <h2 class="h3-tipografia-govco title-3">Escoja la categoría del trámite que busca</h2>
        </label>
        <div>
            <input class="miSelect" type="search" placeholder="Buscar Trámite" [(ngModel)]="textProcedure">
            <button (click)="consultProcedure()">
                <mat-icon>search</mat-icon>
            </button>
        </div>
    </div>
    <div class="totalTramites">
        <label>Mostrando {{totalTramites > 0 ? ((page - 1) * 5) + 1 : 0}}-{{mostrandoA > totalTramites? totalTramites:mostrandoA}} de
            {{totalTramites}} resultados</label>
    </div>
    <div class="filterButtons">
        <div *ngFor="let item of listaCategorias;let primero = first;let ultimo = last; let indice=index">
            <button *ngIf="listaCategorias[indice].selected" type="button" (click)="validarIdCategoria(listaCategorias[indice].tagId)"
                    class="buttonSelect">
                <label>{{listaCategorias[indice].name}}</label>
                <label>({{listaCategorias[indice].count}})</label>
                <mat-icon *ngIf="!listaCategorias[indice].defaultLabel" (click)="deleteLabel(indice)">close</mat-icon>
            </button>
            <button *ngIf="!listaCategorias[indice].selected" type="button" (click)="validarIdCategoria(listaCategorias[indice].tagId)">
                <label>{{listaCategorias[indice].name}}</label>
                <label>({{listaCategorias[indice].count}})</label>
                <mat-icon *ngIf="!listaCategorias[indice].defaultLabel" (click)="deleteLabel(indice)">close</mat-icon>
            </button>
        </div>

    </div>

    <div class="containerProcedure">
        <ul class="meal-list">
            <li
                *ngFor="let tramite of tramites   | paginate: { itemsPerPage: 5, currentPage: page }; let primero = first ;let ultimo = last; let indice=index">
                <app-procedure ($login)="loginTramite($event)" [tramite]="tramite" [indice]="indice"></app-procedure>
            </li>
        </ul>
        <div class="has-text-centered">
            <pagination-controls (pageChange)="pageChanged($event)" nextLabel="Siguiente" previousLabel="Anterior"></pagination-controls>
        </div>
    </div>
    <div>