<app-header></app-header>
<app-quick-acces></app-quick-acces>
<app-breadcrumb [ruta]="rutaMigaPan"></app-breadcrumb>
<div class="container  entradas-de-texto-govco">
    <app-accesibility style="position: absolute; z-index: 2;"></app-accesibility>
    <div class="title">
        <h1 class="h1-tipografia-govco title-1">{{titulo}}</h1>
    </div>
    <div class="row col-sm-12 col-md-12 mt-3">

      <div class="d-flex col-md-9">
        <label class="mt-1 me-3">Funcionarios</label>
        <mat-select [(value)]="idUser" class="form-select" aria-label="Default select example" placeholder="Lista de funcionarios"
                    (selectionChange)="seleccionUsuario($event)">
          <mat-option class="color-gris">
            <ngx-mat-select-search ngModel (ngModelChange)="filterMyOptions($event)"
                                   noEntriesFoundLabel="No se encontraron resultados"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let usuario of usuarios" [value]="usuario.idUsuario" (select)="usuario.idUsuario== idUser">
            {{ usuario.firtsName }} {{ usuario.lastName }}{{ usuario.nombreUsuario }} ({{usuario.email}})
          </mat-option>
        </mat-select>
      </div>

        <div class="d-flex col-md-12 mt-3">
            <div class="d-flex col-md-5 mt-2 me-5">
                <label class="azul-titulos fw-bold">{{ subtitulo1 }}</label>
            </div>
            <div class="d-flex col-md-5 mt-2 ms-5">
                <label class="azul-titulos fw-bold">{{ subtitulo2 }}</label>
            </div>
        </div>
        <div class="d-flex col-md-12 mt-3">
            <div class="card col-md-5 mt-1 me-5 scroll-listas">
                <ul class="list-group col-md-12">
                    <li class="list-group-item cursor" *ngFor="let item of list_func_ob" value="{{ item.idAplicativo }}"
                        [ngClass]="{'active': indice === item.idAplicativo}" (click)="cargarRoles(item.idAplicativo!)">{{ item.nombreAplicativo }}</li>
                </ul>
            </div>
            <div class="card col-md-5 mt-1 ms-5 scroll-listas">
                <ul class="list-group col-md-12">
                    <li class="list-group-item" *ngFor="let item of list_rol_s">{{ item }}</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="prueba2 col-sm-12 col-md-12 text-end">
        <button (click)="router.navigate(['/Home/Administracion']);">Regresar</button>
    </div>
</div>
<app-go-back-up class="up"></app-go-back-up>
<!-- <div class="container">
    <div class="title">
        <h1 class="h1-tipografia-govco title-1">No Permitido</h1>
    </div>
</div> -->
<app-footer></app-footer>
