<div class="other-lineServices">
    <h3>Otras Ventanillas Virtuales</h3>

    <ul>
        <li>
            <figure>
                <img src="../../../assets/Images/LogoWEB_Capital_Alcaldia_-01.png" alt="">
                <figcaption>
                    <a href="https://www.capitalsalud.gov.co/afiliados/" target="_blank">
                        <label>EPS Capital Salud <br>- Trámites en línea ></label>
                    </a>
                </figcaption>
            </figure>

        </li>
        <li>
            <a href="https://tramites.minsalud.gov.co/tramitesservicios/" target="_blank">
                <figure>
                    <img src="../../../assets/Images/logo-gobierno-Ministerio-de-Salud-y-Proteccion-Social-minsalud.png" alt="">
                    <figcaption>
                        <label>Ventanilla de Trámites y Servicios<br> del Ministerio de Salud y Protección Social >
                        </label>
                    </figcaption>
                </figure>
            </a>
        </li>
        <li>
            <a href="https://enlinea.invima.gov.co/rs/login/loginUsuario.jsp" target="_blank">
                <figure>
                    <img src="../../../assets/Images/logo invima.jpg" alt="">
                    <figcaption>
                        <label>INVIMA - Trámites<br> en línea ></label>
                    </figcaption>
                </figure>
            </a>
        </li>
    </ul>
</div>