<app-header></app-header>
<app-quick-acces></app-quick-acces>
<app-breadcrumb [ruta]="rutaMigaPan"></app-breadcrumb>

<div class="container entradas-de-texto-govco">

    <app-accesibility style="position: absolute; z-index: 2;"></app-accesibility>
    <div class="title">
        <h1 class="h1-tipografia-govco title-1">{{titulo}}</h1>
    </div>
    <div class="row col-sm-12 col-md-12">
        <div class="col-sm-12 col-md-4 mt-3">
            <div class="mb-1 fw-bold">
                <label>Buscar por</label>
            </div>
            <div class="search-govco row">
                <div class="bar-search-govco">
                    <input type="text" placeholder="Nombre Funcionario" class="input-search-govco" aria-label="Buscador"
                    [(ngModel)]="nombreUsuario" />
                    <button class="icon-search-govco icon-close-search-govco" aria-label="Limpiar" (click)="borrar()"></button>
                    <div class="icon-search-govco search-icon-search-govco" aria-hidden="true" (click)="buscar(nombreUsuario)"></div>
                </div>
            </div>

            <div class="search-govco row mt-3">
                <input type="text" placeholder="Correo del Funcionario" class="input-search-govco" aria-label="Buscador"
                    [(ngModel)]="emailUsuario" />
                <button class="icon-search-govco icon-close-search-govco" aria-label="Limpiar" (click)="borrar()"></button>
                <div class="icon-search-govco search-icon-search-govco" aria-hidden="true" (click)="buscarPorCorreo(emailUsuario)"></div>
            </div>
        </div>
        <div class="row col-sm-12 col-md-12 text-start" style="justify-content: end;">
            <div class=" col-sm-12 col-md-1 camtidadItems descripcion-emergente-govco">
                <span class="tiptext" style="height: auto; top: 95%;">Número de Registros por página</span>
                <input type="number" disabledText="true" id="input-basico-id" [(ngModel)]="itemsByPage" placeholder="5" min="5"
                       (ngModelChange)="itemsByPage" />
            </div>
        </div>
        <div class="table-responsive mt-3">
            <table class="table">
                <thead class="fondo-gris">
                    <tr class="text-center">
                        <th scope="col">Nombre funcionario</th>
                        <th scope="col">Nombre usuario</th>
                        <th scope="col" class="col-3">Correo</th>
                        <th scope="col">Cargo</th>
                        <th scope="col">Área</th>
                        <th scope="col" class="col-1">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let usuario of usuarios | paginate: { itemsPerPage: itemsByPage, currentPage: pagina }" class="text-center">
                        <td>{{ usuario.firtsName }} {{ usuario.lastName }}{{ usuario.nombreUsuario }}</td>
                        <td>{{ usuario.nombreUsuario }}</td>
                        <td>{{ usuario.email }}</td>
                        <td>{{ usuario.position }}</td>
                        <td>{{ usuario.area }}</td>
                        <td>
                            <a class="text-primary cursor me-3" [routerLink]="['/Home/Administracion/cons-func-rol-usu', usuario.idUsuario]"><i
                                   class="govco-eye-1"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="d-flex justify-content-end mt-2">
                <pagination-controls class="clase-paginador" (pageChange)="pagina = $event" previousLabel="Anterior"
                                     nextLabel="Siguiente"></pagination-controls>
            </div>
        </div>
    </div>
    <div class="prueba2 col-sm-12 col-md-12 text-end">
        <button (click)="router.navigate(['/Home/Administracion']);">Regresar</button>
    </div>

</div>
<app-go-back-up class="up"></app-go-back-up>
<!-- <div class="container">
    <div class="title">
        <h1 class="h1-tipografia-govco title-1">No Permitido</h1>
    </div>
</div> -->
<app-footer></app-footer>
