<div class="container-authencial">
    <h3>Consulte la autenticidad<br> de un documento</h3>
    <div>
        <p>
            En esta sección puede validar la autenticidad del documento emitido por esta entidad.
            Por favor digite el código de verificación que viene anexo en el documento
        </p>
      <div>
        <label for="tramitelist">Tramite<span class="required">(*)</span></label>
        <select class="form-select" id="tramitelist" aria-label="Default select example" [(ngModel)]="Codigo">
          <option *ngFor="let item of listatramites" [value]="item.codTramite">{{item.nameTramite}}</option>
        </select>
      </div>
      <div>
        <label >Codigo de Verificación<span class="required">(*)</span></label>
        <input type="text" placeholder="Ingrese código de verificación" [(ngModel)]="documentNumber">
        <button type="button" (click)="validarDocument()"><label>CONSULTAR</label></button>
      </div>

        <a *ngIf="authRole" href="Home/BandejaDeCiudadano">Consulte su bandeja de solicitudes</a>
    </div>
</div>
