<app-header></app-header>
<app-quick-acces></app-quick-acces>
<app-breadcrumb [ruta]="rutaMigaPan"></app-breadcrumb>
<div class="container" *ngIf="authRole">
  <app-accesibility style="position: absolute; z-index: 2;"></app-accesibility>
  <div class="title">
    <h1 *ngIf="rol == 'AdminFuncional' || rol == 'AdminTI'" class="h1-tipografia-govco title-1">Administrador</h1>
    <h1 *ngIf="rol == 'Funcionario'" class="h1-tipografia-govco title-1">Funcionario</h1>

  </div>
  <div class="container-pages">
    <app-inbox-management class="card"></app-inbox-management>
    <app-management *ngIf="rol == 'AdminFuncional' || rol == 'AdminTI'" class="card"></app-management>
  </div>

</div>
<app-go-back-up class="up"></app-go-back-up>
<div class="container" *ngIf="!authRole">
  <div class="title">
    <h1 class="h1-tipografia-govco title-1">No Permitido</h1>
  </div>
</div>
<app-footer></app-footer>