<app-header></app-header>
<app-quick-acces></app-quick-acces>
<app-breadcrumb [ruta]="rutaMigaPan"></app-breadcrumb>

<div class="container entradas-de-texto-govco">
    <app-accesibility style="position: absolute; z-index: 2;"></app-accesibility>
    <div class="title">
        <h1 class="h1-tipografia-govco title-1">{{titulo}}</h1>
    </div>
    <div class="row">
        <div class="col-sm-12 col-md-12">
            <div class="col-md-12 mt-2 me-5">
                <div class="col-sm-12 col-md-3">
                    <label>Trámites</label>
                </div>

              <div class="col-sm-12 col-md-4">
                <mat-select class="form-select" aria-label="Default select example" placeholder="Escoja el trámite"
                            (selectionChange)="func_seleccion($event, 1)">
                  <mat-option class="color-gris">
                    <ngx-mat-select-search ngModel (ngModelChange)="filterMyOptions($event)"
                                           noEntriesFoundLabel="No se encontraron resultados">
                      <mat-icon ngxMatSelectSearchClear class="">close</mat-icon>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let item of funcionalidades" [value]="item.nombreAplicativo">
                    {{ item.nombreAplicativo }}
                  </mat-option>
                </mat-select>
              </div>

            </div>
            <div class="d-flex col-md-12 mt-2 me-5 mt-5">
                <div class="d-flex col-md-4 me-3">
                    <label class="azul-titulos fw-bold">Rol(es) disponible(s) para el trámite seleccionado</label>
                </div>
                <div class="d-flex col-md-1">
                </div>
                <div class="d-flex col-md-4">
                    <label class="azul-titulos fw-bold">Rol(es) seleccionado(s)</label>
                </div>
            </div>
            <div class="d-flex justify-content-start mt-4">
                <div class="card col-md-4 mt-1 me-5 scroll-listas">
                    <ul class="list-group col-md-12">
                      <li class="list-group-item cursor" *ngFor="let item of list_roles_origen" value="{{ item.idRol }}"
                          (click)="seleccionRolOrigen(item.idRol)" [ngClass]="{'active': indiceRolOrigen === item.idRol}">
                        {{ item.nombreRol }}
                        </li>
                    </ul>
                </div>
                <div class="mt-3 align-self-center flex-column">
                    <div class="mb-3">
                        <a class="text-primary cursor" (click)="agregarRol()"><i class="govco-arrow-right"></i></a>
                    </div>
                    <div class="mb-3">
                        <a class="text-primary cursor" (click)="quitarRol()"><i class="govco-arrow-left"></i></a>
                    </div>
                </div>
                <div class=" card col-md-4 mt-1 ms-5 scroll-listas">
                    <ul class="list-group col-md-12">
                      <li class="list-group-item cursor" *ngFor="let item of list_roles_destino" value="{{ item.idRol }}"
                          (click)="seleccionRolDestino(item.idRol)" [ngClass]="{'active': indiceRolDestino === item.idRol}">
                        {{ item.nombreRol }}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="d-flex justify-content-start mt-4">
                <label class="azul-titulos fw-bold col-md-2 align-self-center">Usuarios activos</label>

                <div class="col-sm-12 col-md-4">
                    <div class="search-govco">
                        <div class="bar-search-govco">
                            <input type="text" placeholder="Nombre funcionario" class=" input-search-govco" aria-label="Buscador"
                                   [(ngModel)]="nombreUsuario" />
                            <button class="icon-search-govco icon-close-search-govco" aria-label="Limpiar" (click)="borrar()"></button>
                            <div class="icon-search-govco search-icon-search-govco" aria-hidden="true" (click)="buscar(nombreUsuario)"></div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="table-responsive mt-3">
              <table class="table cabecera"  aria-describedby="mydesc">
              <thead class="fondo-gris">
                        <tr>
                            <th scope="col"><input type="checkbox" class="me-3" id="all" value="0" (change)="todos($event)"><span>Selección</span>
                            </th>
                            <th scope="col" class="text-center">Nombre funcionario</th>
                            <th scope="col" class="text-center">Nombre usuario</th>
                            <th scope="col" class="text-center">Correo</th>
                            <th scope="col" class="text-center">Cargo</th>
                            <th scope="col" class="text-center">Área</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let usuario of usuarios | paginate: { itemsPerPage: 10, currentPage: paginador1,totalItems: usuarios.length }"
                            class="text-center">
                            <td><input class="me-3" type="checkbox" [checked]="usuario.inicio" (change)="agregarUsuario($event)"
                                       value="{{ usuario.idUsuario }}">
                            </td>
                            <td class="text-center">{{ usuario.firtsName }} {{ usuario.lastName }}{{ usuario.nombreUsuario }}</td>
                            <td class="text-center">{{ usuario.nombreUsuario }}</td>
                            <td class="text-center">{{ usuario.email }}</td>
                            <td class="text-center">{{ usuario.perfil }}</td>
                            <td class="text-center">{{ usuario.area }}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-flex justify-content-end mt-2">
                    <pagination-controls class="clase-paginador" (pageChange)="paginador1= $event" previousLabel="Anterior"
                                         nextLabel="Siguiente"></pagination-controls>
                </div>
            </div>

            <div class="d-flex justify-content-end mt-5 mb-3">
                <div class="d-flex justify-content-end col-md-10">
                    <div class="me-2">
                        <button class="btn btn-primary" (click)="guardar()">ASIGNAR</button>
                    </div>
                    <div class="ms-2">
                        <button class="btn btn-primary" (click)="cancelar()">CANCELAR</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <h1 class="h1-tipografia-govco title-1">{{tituloUltimasAsignaciones}}</h1>
                <div class="col-sm-12 col-md-12">
                    <div class="entradas-de-texto-govco col-sm-12 col-md-4">
                        <label for="input-basico-id">Selecciona la información sobre la cual deseas realizar su consulta</label>
                        <select class="form-select" id="Tipo de Identificación" aria-label="Default select example" [(ngModel)]="tipofiltro">
                            <option value="0" selected disabled>Seleccione una opción</option>
                            <option value="nombre">Nombre de usuario</option>
                            <option value="aplicativo">Trámite</option>
                            <option value="rol">Rol</option>
                            <option value="fecha">Fecha de registro</option>

                        </select>
                    </div>
                </div>
                <div *ngIf="tipofiltro == 'nombre'" class="col-sm-12 col-md-4">
                    <div class="entradas-de-texto-govco">
                        <label for="input-basico-id">Nombre de usuario</label>
                        <input type="text" [(ngModel)]="valueFiltro">
                    </div>
                </div>
                <div *ngIf="tipofiltro == 'aplicativo'" class="col-sm-12 col-md-4">
                    <div class="entradas-de-texto-govco">
                        <label for="input-basico-id">Trámite</label>
                        <select class="form-select" id="aplicativo" aria-label="Default select example" [(ngModel)]="valueFiltro">
                            <option value="" selected disabled>Seleccione una opción</option>
                          <option *ngFor="let func of funcionalidades" value="{{ func.idAplicativo }}">{{ func.nombreAplicativo }}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="tipofiltro == 'rol'" class="col-sm-12 col-md-4">
                    <div class="entradas-de-texto-govco">
                        <label for="input-basico-id">Rol</label>
                      <select class="form-select" id="roles" aria-label="Default select example" [(ngModel)]="valueFiltro">
                      <option value="" selected disabled>Seleccione una opción</option>
                        <option *ngFor="let rol of listRoles" value="{{ rol.idRole}}">{{ rol.displayName }}</option>

                        </select>
                    </div>
                </div>
                <div *ngIf="tipofiltro == 'fecha'" class="col-sm-12 col-md-4">
                    <div class="entradas-de-texto-govco">
                        <label for="input-basico-id">Fecha de registro</label>
                        <input type="date" [(ngModel)]="valueFiltro">
                    </div>
                </div>
                <div *ngIf="tipofiltro != '0'" class="col-sm-12 col-md-4" style="margin-top: 50px;">
                  <button class="btn btn-primary" (click)="ultimasAsignaciones(valueFiltro,1)">Consultar</button>
                </div>
            </div>
            <div class="d-flex justify-content-start">
                <div class="d-flex justify-content-start col-md-10">
                    <label class="azul-titulos fw-bold">Últimas asignaciones</label>
                </div>
            </div>
            <div class="table-responsive mt-3">
                <table class="table cabecera ">
                    <thead class="fondo-gris">
                        <tr class="text-center">
                            <th scope="col">Usuario</th>
                            <th scope="col">Correo electrónico</th>
                            <th scope="col">Código trámite</th>
                            <th scope="col">trámite</th>
                            <th scope="col">Código rol</th>
                            <th scope="col">Rol</th>
                        </tr>
                    </thead>
                    <tbody class="text-center">
                        <tr *ngFor="let asig of ult_asig, let i = index " class="text-center">
                            <td *ngIf="numero == i" class="d-flex justify-content-center">
                                <select class="form-select w-50 h-25" aria-label="Default select example" [(ngModel)]="m_usu"
                                        (change)="modificarUsu(i, $event)">
                                    <option disabled value="0" class="color-gris">Escoja usuario</option>
                                    <option *ngFor="let usu of usuarios" value="{{ usu.adId }}">{{ usu.userName }}</option>
                                </select>
                            </td>
                            <td [ngClass]="{'ocultar': numero == i}">{{ asig.usuario }}</td>
                            <td [ngClass]="{'ocultar': numero == i}" style="word-break: break-all;">{{asig.email}}</td>
                            <td>{{ asig.siglaAplicativo+"-"+ asig.codAplicativo  }}</td>
                            <td *ngIf="numero == i" class="d-flex justify-content-center">
                                <select class="form-select w-50 h-25" aria-label="Default select example" [(ngModel)]="m_func"
                                        (change)="modificarFunc(i, $event)">
                                    <option disabled value="0" class="color-gris">Escoja funcionalidad</option>
                                    <option *ngFor="let func of funcionalidades" value="{{ func.id }}">{{ func.nameTramite }}
                                    </option>
                                </select>
                            </td>
                            <td [ngClass]="{'ocultar': numero == i}">{{ asig.aplicativo }}</td>
                            <td>{{ asig.idRol }}</td>
                            <td *ngIf="numero == i" class="d-flex justify-content-center">
                                <select class="form-select w-50 h-25" aria-label="Default select example" [(ngModel)]="m_rol"
                                        (change)="modificarRol(i, $event)">
                                    <option disabled value="0" class="color-gris">Escoja rol</option>
                                    <option *ngFor="let rols of list_roles_asig" value="{{ rols.idRol}}">{{ rols.rol }}</option>
                                </select>
                            </td>
                            <td [ngClass]="{'ocultar': numero == i}">{{ asig.rol }}</td>

                        </tr>
                    </tbody>
                </table>
                <mat-paginator class="paginator" [hidePageSize]="true" [length]="totalItems" [pageSize]="10"
                               (page)="pageChanged2($event)"></mat-paginator>
                <!-- <pagination-controls class="text-end" (pageChange)="cambioPag2($event)" previousLabel="Anterior"
                                     nextLabel="Siguiente"></pagination-controls> -->

            </div>
        </div>
    </div>
    <div class="prueba2 col-sm-12 col-md-12 text-end">
        <button (click)="router.navigate(['/Home/Administracion']);">Regresar</button>
    </div>
</div>
<app-go-back-up class="up"></app-go-back-up>
<!-- <div class="container">
    <div class="title">
        <h1 class="h1-tipografia-govco title-1">No Permitido</h1>
    </div>
</div> -->
<app-footer></app-footer>
