import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Funcionalidades } from 'src/app/Models/funcionalidades.model';
import { UsuarioRoles } from 'src/app/Models/usuarioRoles.model';
import { Usuarios } from 'src/app/Models/usuarios.model';
import { UsuarioRolesService } from 'src/app/Services/roles-usuarios-funcionalidades/usuarios-roles.service';
import { UsuariosService } from 'src/app/Services/roles-usuarios-funcionalidades/usuarios.service';
import {$e} from "@angular/compiler/src/chars";

@Component({
  selector: 'app-cons-func-rol-usu',
  templateUrl: './cons-func-rol-usu.component.html',
  styleUrls: ['./cons-func-rol-usu.component.scss']
})
export class ConsFuncRolesUsuComponent implements OnInit {

  rutaMigaPan = [{ name: 'Inicio', url: '/Home' }, { name: 'Administración', url: '/Home/Administracion' }, { name: 'Consulta de trámites y roles', url: '/Home/Administracion/cons-func-rol-usu' }]

  titulo = 'Consulta de trámites y roles que posee un funcionario';
  subtitulo1 = 'Trámites en los que tiene permisos y roles';
  subtitulo2 = 'Roles autorizados para esta funcionalidad';
  usuarios: any[] = [];


  usuarioseleccionado:any;
  usuariosAll: any[] = [];
  list_rol_s: string[] = [];
  list_func_ob: any[] = [];
  list_usu_rols:  any[] = [];
  indice: number | undefined;
  idUser: string | undefined;
  rol: string;
  authRole: boolean = false;
  adminRole: boolean = false;

  constructor(private usuariosService: UsuariosService,
    private usuarioRolesService: UsuarioRolesService,
    private route: ActivatedRoute,
    public router: Router) {
    if (this.route.snapshot.paramMap.get('id') != null) {
      this.idUser = this.route.snapshot.paramMap.get('id')!;
    }
  }

  async ngOnInit(): Promise<void> {
    this.rol = localStorage.getItem('rol');
    if (this.rol == 'AdminFuncional' || this.rol == 'AdminTI') {
      this.authRole = true;
      this.adminRole = true;

    } else {
      this.router.navigate(['/Home']);
    }
    await this.cargarUsuarios();

  }

  async cargarUsuarios(): Promise<void> {

    this.usuariosService.traerUsuariosAutenticacion(" "," ")
      .subscribe(resp => {
        this.usuarios=[];

        resp.data.forEach(value =>
        {
          let userid=this.usuarios.filter(usuario  => usuario.idUsuario == value.idUsuario);

          if (userid.length==0)
          {
            this.usuarios.push(value)
            if(value.idUsuario==this.idUser)
            {
              this.usuarioseleccionado=value.idUsuario;

            }
          }
        })

        this.usuariosAll = this.usuarios;

        if (this.idUser != undefined) {
          this.cargarUsuariosRoles();
        }
      });

  }

  regresar(): void {
    this.router.navigateByUrl('usuarios');
  }

  async cargarUsuariosRoles(): Promise<void> {
    this.usuariosService.traerUsuariosAutenticacion("codigo", this.idUser + "")
      .subscribe(resp => {


        this.list_usu_rols=resp.data.filter(aplicativo  => aplicativo.codigoAplicativo != "");

        this.cargarFuncionalidadesObj();
      });
  }

  cargarFuncionalidadesObj(): void {
    this.list_func_ob = [];
    this.list_usu_rols.forEach(resp => {
      this.list_func_ob.push(resp);
      this.list_func_ob = this.list_func_ob.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
    });

  }

  cargarRoles(id: any): void {
    this.indice = id;
    this.list_rol_s = [];
    this.list_usu_rols.forEach(resp => {
      if (resp.idAplicativo === id) {
        this.list_rol_s.push(resp.nombreRol!.trim()!);
      }
    });
  }

  async seleccionUsuario(e: any): Promise<void> {

    this.indice = 0;
    this.list_usu_rols = [];
    this.list_rol_s = [];
    this.usuariosService.traerUsuariosAutenticacion("codigo", e.value + "")
      .subscribe(resp => {

        this.list_usu_rols=resp.data.filter(aplicativo  => aplicativo.codigoAplicativo != "");

        this.cargarFuncionalidadesObj();
      });
  }

  filterMyOptions($event: any) {
    this.usuarios = [];
    if (!!$event) {
      this.usuariosAll.forEach(item => {
        const fullName = `${item.nombreUsuario}`

        //fullName.includes($event);

        const expresion = new RegExp(`${$event}.*`, 'i');
        const result: boolean = expresion.test(fullName);

        if (result) {
          this.usuarios.push(item);
        }

      });
    } else {
      this.usuarios = this.usuariosAll;
    }
  }
}
