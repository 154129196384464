<div class="container-citizen-portal">
    <h3>Portales para el ciudadano</h3>
    <button (click)="cambio($event)">
        <mat-icon id="up">keyboard_arrow_up</mat-icon>
    </button>
    <ul>
        <li *ngFor="let item of listaInfoImagenes, let indice=index">

            <div *ngIf="listaInfoImagenes[indice].imageUrl!=''; else noImage">
                <a href='{{listaInfoImagenes[indice].urlRedirect}}' target="_blank">
                    <img src='{{listaInfoImagenes[indice].imageUrl}}' alt='{{listaInfoImagenes[indice].name}}' height="50px">
                </a>
            </div>
              
            <ng-template #noImage>
                <a href='{{listaInfoImagenes[indice].urlRedirect}}' target="_blank"> {{listaInfoImagenes[indice].name}}</a>
            </ng-template>
        </li>
    </ul>
    <button (click)="cambio($event)">
        <mat-icon id="down">keyboard_arrow_down</mat-icon>
    </button>
</div>