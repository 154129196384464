<form role="form" required>
    <div class="close">
        <mat-dialog-actions>
            <mat-icon mat-dialog-close>close</mat-icon>
        </mat-dialog-actions>
    </div>

    <div class="row">

        <legend>
            <h3>{{data.titulo}}</h3>

        </legend>

        <div class="col-sm-12 col-md-12 text-center">
            <div class="entradas-de-texto-govco entrada">
                <label for="Razón Social">Trámite:<span class="required">(*)</span></label>
                <input type="text" id="Trámite:" placeholder="" value={{data.infoTramite.nombreTramite}} disabled />
            </div>
            <div class="entradas-de-texto-govco">
                <label for="input-basico-id">{{data.admin? 'Solicitante:':'Autorizador*'}}</label>
                <select class="form-select" id="Tipo de Identificación" aria-label="Default select example" [disabled]="data.readOnly">
                    <option value="" selected disabled>Seleccione una opción</option>
                    <option value="">FUNCIONARIO 1</option>
                    <option value="">FUNCIONARIO 2</option>
                    <option value="">FUNCIONARIO 3</option>
                </select>
            </div>
            <div class="textArea">
                <label for="Razón Social">Mensaje<span class="required">(*)</span></label>
                <textarea placeholder="" cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5" [disabled]="data.readOnly">
                          Ejemplo: Se solicita la reasignación del trámite a razón de______
                          (Exponer razones, debería ser por vacaciones o temas de expertise únicamente).
                </textarea>

            </div>

            <div class="col-12 col-md-12 pl-4 text-center" *ngIf="!data.readOnly && !data.admin">
                <div class="col-12">
                    <button type="button" [mat-dialog-close]="true" class="btn-govco fill-btn-govco">Enviar</button>

                </div>
            </div>

            <div class="col-12 col-md-12 pl-4 text-center" *ngIf="data.admin">
                <button class="col-6" type="button" [mat-dialog-close]="true" class="btn-govco fill-btn-govco"
                        (click)="reasignarSolicitud(data)">REASIGNAR</button>
                <button class="col-6" type="button" [mat-dialog-close]="false" class="btn-govco fill-btn-govco">NEGAR REASIGNACIÓN</button>

            </div>

        </div>
    </div>
</form>