import { Component } from '@angular/core';

@Component({
  selector: 'app-online-services',
  templateUrl: './online-services.component.html',
  styleUrls: ['./online-services.component.scss']
})
export class OnlineServicesComponent {

}
