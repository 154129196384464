import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api/api.service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {

  encuestaExpApp = {
    id: "",
    name: "",
    description: "",
    surveyQuestions: [
      {
        id: "",
        surveyId: "",
        title: "",
        tooltip: "",
        questionTypeId: 1,
        mandatory: true,
        statusId: 0,
        correctAnswer: "nn",
        answersQuestions: [
          {
            id: "",
            surveyQuestionsId: "",
            identifier: "",
            description: "",
            tooltip: "",
            statusId: 0
          }
        ]
      }
    ]
  }

  viewInfoSolicitante: boolean = false;
  infoSolicitante: any = { nombre: '', email: '', telefono: '', id: '' };
  pregunta: any = []
  isDisabled: boolean = false;
  surveyType: any = '';
  pathname: any = window.location.pathname;
  viewErrorPop: boolean = false;
  viewSuccessPop: boolean = false;
  arrayIds: any = [];
  cantiPregObl: number = 0;
  idSolicitud: string = '';
  idTramite: string = '';
  tramitesList: any = [{}];
  tramiteId: string = ""
  clientId: any;

  SurveyTypeId=0;

 subpreguntas:any[]=[];
  responsesubpreguntas:any[]=[];

  tuTokenAqui = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ2ZXIiOiIxLjAiLCJpc3MiOiJodHRwczovL3NhbHVkY2FwaXRhbGIyYy5iMmNsb2dpbi5jb20vOGM2MTFhMGQtY2RlZS00YWI4LThjMTMtOTlhMjIzZmM0ZjM1L3YyLjAvIiwic3ViIjoiMjNjYTg3NmYtNGQ2Mi00ZmY1LWI1MzUtNTdjZGZlMDRlZDIxIiwiYXVkIjoiNmRmZWQxYzktNTBiOC00OWU2LThiZDYtMDlkODUwZjVjY2JhIiwiZXhwIjoxNzAyNTA4NjEzLCJub25jZSI6IjRjM2MzOTFmLTE2NzYtNGVkZS04MTRkLWMzNDY0NjllNDEzNyIsImlhdCI6MTcwMjUwNTAxMywiYXV0aF90aW1lIjoxNzAyNTA1MDEzLCJvaWQiOiIyM2NhODc2Zi00ZDYyLTRmZjUtYjUzNS01N2NkZmUwNGVkMjEiLCJnaXZlbl9uYW1lIjoiTmF0YWxpYSIsImZhbWlseV9uYW1lIjoiU2FudGFuYSIsIm5hbWUiOiJFbXByZXNhIE5hdGFsaWEiLCJlbWFpbHMiOlsibnNhbnRhbmFAc29haW50LmNvbSJdLCJ0ZnAiOiJCMkNfMV9JbmljaW9TZXNpb25Db25SZWdpc3RybyIsIm5iZiI6MTcwMjUwNTAxMywiaWRDb2RpZ29WZW50YW5pbGxhIjoyMjg5LCJkYXRvc1BlcnNvbmFsZXMiOnsicGVyc29uVHlwZUlkIjoyLCJudW1lcm9JZGVudGlmaWNhY2lvbiI6MCwiZnVsbE5hbWUiOiItIiwidGlwb0lkZW50aWZpY2FjaW9uIjo1LCJwcmltZXJOb21icmUiOiItIiwic2VndW5kb05vbWJyZSI6Ii0iLCJwcmltZXJBcGVsbGlkbyI6Ii0iLCJzZWd1bmRvQXBlbGxpZG8iOiItIiwiZW1haWwiOiItIiwidGVsZWZvbm9GaWpvIjoiLSIsInRlbGVmb25vQ2VsdWxhciI6Ii0iLCJ0aXBvSWRlbnRpZmljYWNpb25STCI6MSwibnVtZXJvSWRlbnRpZmljYWNpb25STCI6MTIzNDQ0NTIxLCJyYXpvblNvY2lhbCI6IjEyMzQxMjM0MTIzNCIsImRpcmVSZXNpIjoiRGlyZWNjaW9uQ2FzYSIsImRlcGFydGFtZW50byI6MSwibXVuaWNpcGlvIjoiTXVuaWNpcGlvQ2FzYSJ9LCJyb2xlcyI6W3siY29kaWdvVXN1YXJpbyI6IjIzY2E4NzZmLTRkNjItNGZmNS1iNTM1LTU3Y2RmZTA0ZWQyMSIsImVtYWlsIjoibnNhbnRhbmFAc29haW50LmNvbSIsInJvbCI6IkFkbWluVEkiLCJkZXNjcmlwY2lvbiI6IkFkbWluVEkiLCJ2YWx1ZSI6IkFkbWluVEkiLCJub21icmVDb21wbGV0byI6Ik5hdGFsaWEifV0sInRpZW5lQ29kaWdvVmVudGFuaWxsYSI6dHJ1ZSwiYWNlcHRvUG9saXRpY2FTZWd1cmlkYWQiOnRydWV9.Zn7HDt7ZFaMERq3sfFtVfl3RbinkgZxslXBs06YtmJU'

  constructor(private api: ApiService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.clientId = localStorage.getItem('clientId')
    let miObjeto = JSON.parse(localStorage.getItem('currentUser'));
    this.route.paramMap.subscribe(params => {
      this.surveyType = params.get('id');
    });

    this.infoSolicitante = true;
    if (localStorage.getItem('clientId') != null) {
      this.infoSolicitante = {
        nombre: localStorage.getItem('userName') + ' ' + localStorage.getItem('lastName'),
        email: localStorage.getItem('email'),
        telefono: miObjeto.datosPersonales.telefonoFijo != '' ? miObjeto.datosPersonales.telefonoFijo : miObjeto.datosPersonales.telefonoCelular,
        id: miObjeto.datosPersonales.numeroIdentificacion

      }
    }


    this.obtenerEncuesta()

  }



  getquestion(idquestion:any,idanswer:any) {

    if(idanswer.target!=undefined)
    {
      const inputElement = idanswer.target as HTMLInputElement; // Asegúrate de que el target es un input
      const value = inputElement.value; // Captura el valor actual del campo
      idanswer=value;
    }

    this.encuestaExpApp.surveyQuestions.forEach((element,index) =>{
      if(element.id==idquestion)
      {

        let extraquestions=[]
        let questiontype=0;
        element.answersQuestions.forEach(answers =>{

          if(answers.identifier==idanswer)
          {
            this.pregunta[index]=answers;
          }

          if(answers.identifier.includes(("P-"+idanswer)) )
          {

            extraquestions.push(answers);
            questiontype=Number.parseInt( answers.identifier.substring(answers.identifier.lastIndexOf("~")+1,answers.identifier.length));

          }
        });

        if(extraquestions.length!=0)
        {
          this.subpreguntas[index].available=true;
          this.subpreguntas[index].questiontype=questiontype;
          this.subpreguntas[index].subquestions=extraquestions;
        }
        else
        {
          this.subpreguntas[index].available=false;
          this.subpreguntas[index].questiontype=0;
          this.subpreguntas[index].subquestions=[];
        }
      }
    })
  }

  getanswersubquestion(idquestion:any,idanswer:any,reponse:any)
  {
    if(idanswer.target!=undefined)
    {
      const inputElement = idanswer.target as HTMLInputElement; // Asegúrate de que el target es un input
      const value = inputElement.value; // Captura el valor actual del campo
      idanswer=value;
    }
      this.subpreguntas.forEach((value,index) => {
        value.subquestions.forEach(answers => {
            if(answers.surveyQuestionsId==idquestion)
            {
               if(answers.identifier==idanswer)
               {


                 let type=idanswer.substring(idanswer.lastIndexOf("~")+1,idanswer.length);
                 if(type=="2")
                 {
                   const inputElement = reponse.target as HTMLInputElement; // Asegúrate de que el target es un input
                   const value = inputElement.value; // Captura el valor actual del campo

                   this.responsesubpreguntas[index].surveyQuestionsId=answers.surveyQuestionsId;
                   this.responsesubpreguntas[index].answer=value
                   this.responsesubpreguntas[index].available=true;
                   this.responsesubpreguntas[index].idanswer=answers.id;
                 }
                 if(type!="2" && type!="4")
                  {
                   this.responsesubpreguntas[index].surveyQuestionsId=answers.surveyQuestionsId;
                   this.responsesubpreguntas[index].answer=answers.description
                   this.responsesubpreguntas[index].available=true;
                   this.responsesubpreguntas[index].idanswer=answers.id;
                 }

               }
            }
        });
      });
  }

  getListaTramites() {
    this.api.getListaTramites().subscribe(resp => {
      this.tramitesList = resp
      this.tramitesList = this.tramitesList.sort((a, b) => {
        if (a.description < b.description) return -1;
        if (a.description > b.description) return 1;
        return 0;
      })
    })
  }

  verificarPreguntas() {
    this.encuestaExpApp.surveyQuestions.forEach(question => {
      question.answersQuestions = question.answersQuestions.filter(answer => answer.statusId !== 1);
    });
    this.encuestaExpApp.surveyQuestions = this.encuestaExpApp.surveyQuestions.filter((question) => question.statusId !== 1);

    this.encuestaExpApp.surveyQuestions.forEach(element =>{
        this.subpreguntas.push({
          idsurvey:element.surveyId,
          questiontype:0,
          subquestions:[],
          available:false
        })
      this.responsesubpreguntas.push({
        idanswer:"",
        surveyQuestionsId:"",
        answer:"",
        available:false
      })
    })
  }

  obtenerEncuesta() {
    let id = ''
    if (this.surveyType == 'EUX') {
      id = '1'
      this.api.getSurveyByType(id).subscribe(res => {
        this.encuestaExpApp = res
        this.SurveyTypeId=1;
        this.validarPreguntas()
      })
    }
    if (this.surveyType == 'EST') {
      this.route.paramMap.subscribe(params => {
        this.idSolicitud = params.get('idS');
        this.idTramite = params.get('idT');
        this.getListaTramites()
      });


      id = '2'
      this.api.getSurveyByType(id).subscribe(res => {
        this.encuestaExpApp = res
        this.SurveyTypeId=2;
        this.validarPreguntas()
      })
    }
    if (this.surveyType != 'EST' && this.surveyType != 'EUX') {
      this.api.getSurveyByid(this.surveyType).subscribe(res => {
        this.encuestaExpApp = res
        this.SurveyTypeId=res.surveyTypeId;
        this.validarPreguntas()
        this.isDisabled = true
      })
    }

  }


  aceptarSerContactado(e: any) {
    if (e.target.value == 1) {
      this.infoSolicitante = true
      let miObjeto = JSON.parse(localStorage.getItem('currentUser'));
      this.infoSolicitante = {
        nombre: localStorage.getItem('userName') + ' ' + localStorage.getItem('lastName'),
        email: localStorage.getItem('email'),
        telefono: miObjeto.datosPersonales.telefonoFijo != '' ? miObjeto.datosPersonales.telefonoFijo : miObjeto.datosPersonales.telefonoCelular,
        id: miObjeto.datosPersonales.numeroIdentificacion
      }
    } else if (e.target.value == 2) {
      this.infoSolicitante = false
    }

  }
  filterAnswers(answersQuestions: any[]): any[] {
    return answersQuestions.filter(answer => !answer.identifier.includes('PE'));
  }

  getDivision(text:string,position:number): string {

    let newtext="";
    if(position==0)
    {
      newtext=text.substring(0,text.indexOf("~"));
    }
    else
    {
      newtext=text.substring(text.lastIndexOf("~")+1,text.length);
    }
    return newtext;

  }
  getMiddle(text:string): string {

    let newtext="";

      newtext=text.substring(text.indexOf("~")+1,text.lastIndexOf("~"));

    return newtext;

  }

  goToLink(url: string): void {
    window.open(url, '_blank'); // Abre en una nueva pestaña
  }

  enviarEncuesta() {

    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);

    let data: any = {
      userId: localStorage.getItem('clientId') != null ? localStorage.getItem('clientId') : '23ca876f-4d62-4ff5-b535-57cdfe04ed21',
      date: hoy.toISOString(),
      sendNotification: this.infoSolicitante ? true : false,
      userSurveyResponses: []
    }



    if (this.SurveyTypeId == 2) {
      data.paperworkId = this.tramiteId;
      data.procedureRequestId = this.idSolicitud;
      data.surveyId = this.encuestaExpApp.id;
    } else if (this.SurveyTypeId == 1) {
      data.surveyId = this.encuestaExpApp.id;
    } else {
      return; // surveyType is invalid, exit function
    }


    if (this.pregunta.length === 0) {
      this.viewErrorPop = true;
      setTimeout(() => {
        this.viewErrorPop = false;
      }, 3000);
      return;
    }

    this.pregunta.forEach((ele) => {
      data.userSurveyResponses.push({ answersQuestionId: ele.id,ResponseQuestion:"" });
    });

    this.responsesubpreguntas.forEach((ele) => {

      if(ele.available)
      {
        data.userSurveyResponses.push({ answersQuestionId: ele.idanswer,ResponseQuestion:ele.answer });
      }


    });

    data.userSurveyResponses = data.userSurveyResponses.filter(
      (response) => response.answersQuestionId !== null
    );

    let respuestas=0;
    this.pregunta.forEach(element =>
    {

     if(this.arrayIds.includes(element.surveyQuestionsId) )
     {
       respuestas++;
     }
    });




    if (respuestas === this.cantiPregObl) {

      ///SI ESTA LOGUEADO SE USA EL PROPIO TOKEN
      if (localStorage.getItem('clientId') != null) {

        this.api.saveAnswersform(data).subscribe((res) => {
          if (res.status === 200) {
            this.viewSuccessPop = true;
            setTimeout(() => {
              this.viewSuccessPop = false;
              //window.close();
            }, 5000);
          }
        });
      } else {

        ///SI NO ESTA LOGUEADO SE QUEMA EL TOKEN
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.tuTokenAqui}` // Reemplaza tuTokenAqui con la lógica para obtener el token
        });
        this.api.saveAnswersform(data, headers).subscribe((res) => {
          if (res.status === 200) {
            this.viewSuccessPop = true;
            setTimeout(() => {
              this.viewSuccessPop = false;
              //window.close();
            }, 5000);
          }
        });

      }
    } else {
      this.viewErrorPop = true;
      setTimeout(() => {
        this.viewErrorPop = false;
      }, 3000);
    }
  }




  validarPreguntas() {
    console.log(this.encuestaExpApp.surveyQuestions);
    this.encuestaExpApp.surveyQuestions.forEach(ele => {

      if (ele.mandatory && ele.statusId == 0) {
        this.cantiPregObl += 1
        this.arrayIds.push(ele.id)

      }

    })

    this.verificarPreguntas()
  }
}
