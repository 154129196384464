<form role="form" required>
    <div class="close">
        <mat-dialog-actions>
            <mat-icon mat-dialog-close>close</mat-icon>
        </mat-dialog-actions>
    </div>

    <div class="row">

        <legend>
            <h3>Reasignar trámite</h3>

        </legend>

        <div class="col-sm-12 col-md-12">
            <div class="col-sm-12 col-md-7 entradas-de-texto-govco entrada">
                <label for="Razón Social">Trámite:<span class="required">(*)</span></label>
                <input type="text" id="Trámite:" placeholder="" value={{data.infoTramite.nombreTramite}} disabled />
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 entradas-de-texto-govco">
                    <select class="form-select" id="Tipo de Identificación" aria-label="Default select example" [disabled]="data.readOnly">
                        <option value="" selected disabled>Seleccionar usuario</option>
                        <option value="">FUNCIONARIO 1</option>
                        <option value="">FUNCIONARIO 2</option>
                        <option value="">FUNCIONARIO 3</option>
                    </select>
                </div>

                <div class="col-sm-12 col-md-6 entradas-de-texto-govco">
                    <select class="form-select" id="Tipo de Identificación" aria-label="Default select example" [disabled]="data.readOnly">
                        <option value="" selected disabled>Seleccionar Rol</option>
                        <option value="">Rol 1</option>
                        <option value="">Rol 2</option>
                        <option value="">Rol 3</option>
                    </select>
                </div>
            </div>
            <div>
                <button [mat-dialog-close]="true" type="submit">CONFIRMAR</button>
            </div>

        </div>
    </div>
</form>