import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FuncionalidadRoles } from 'src/app/Models/funcionalidadRoles.model';
import { Funcionalidades } from 'src/app/Models/funcionalidades.model';
import { FuncionalidadRolesFull } from 'src/app/Models/funcionalidadesRolesFull.model';
import { RolesSingle } from 'src/app/Models/rolesSingle.model';
import { ApiService } from 'src/app/Services/api/api.service';
import { FuncionalidadRolesService } from 'src/app/Services/roles-usuarios-funcionalidades/funcionalidades-roles.service';
import { FuncionalidadesService } from 'src/app/Services/roles-usuarios-funcionalidades/funcionalidades.service';
import { RolesService } from 'src/app/Services/roles-usuarios-funcionalidades/roles.service';
import Swal from 'sweetalert2';
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-crear-func',
  templateUrl: './crear-editar-func.component.html',
  styleUrls: ['./crear-editar-func.component.scss']
})
export class CrearFuncComponent implements OnInit {

  titulo!: string;
  datoTabla!: string;
  mensaje = 'Campo obligatorio';
  mensajeRoles = 'Debe seleccionar al menos un rol';
  codigoExistente = 'El código de la funcionalidad ya existe';
  funcExistente = 'El nombre de la funcionalidad ya existe';
  errorGuardado = 'No se pudo hacer las asignaciones de los roles a la funcionalidad seleccionada';
  resultado: any;
  cadena: string[] = [];
  list_roles_origen: any[] = [];
  list_roles_destino: any[] = [];
  list_asignaciones: FuncionalidadRoles[] = [];
  tablaPrevisualizar!: FuncionalidadRolesFull;
  guardarAsignaciones!: FuncionalidadRoles;
  rolSolo!: RolesSingle;
  rolOrigen: RolesSingle | undefined;
  rolDestino: RolesSingle | undefined;
  formulario!: FormGroup;
  indiceRolOrigen: any;
  indiceRolDestino: any;
  funcionalidad: Funcionalidades | undefined;
  nombre: string | undefined;
  cadenaRoles = '';
  correcto = false;
  fecha!: Date;
  formato!: string;
  rutaMigaPan = [{ name: 'Inicio', url: '/Home' }, { name: 'Administración', url: '/Home/Administracion' }, { name: 'Trámites y roles', url: '/Home/Administracion/cons-func-rol-usu' }]
  rol: string;
  authRole: boolean = false;
  adminRole: boolean = false;
  validation: boolean = false;
  aplicativo: any ;
  constructor(private funcionalidadesService: FuncionalidadesService,
    private funcionalidadRolesService: FuncionalidadRolesService,
    private rolesService: RolesService,
    private fb: FormBuilder,
    public router: Router,
    private route: ActivatedRoute,
    private apiservices: ApiService) {
    this.nombre = this.route.snapshot.paramMap.get('nombre')?.toString();

    this.crearFormulario();
  }

  ngOnInit(): void {
    this.rol = localStorage.getItem('rol');
    if (this.rol == 'AdminFuncional' || this.rol == 'AdminTI') {
      this.authRole = true;
      this.adminRole = true;

    } else {
      this.router.navigate(['/Home']);
    }
    if (this.nombre != undefined) {
      this.titulo = 'Editar trámites y roles';
      this.datoTabla = 'editar';
      //this.funcionalidadRolesService.consultarFuncionalidadRoles(this.nombre!)


      this.funcionalidadRolesService.traerFuncionalidadRolesAutenticacion("nombre",this.nombre)
        .subscribe(resp => {

          this.resultado = resp.data;

        this.aplicativo=this.resultado[0];



          this.resultado[0].listaRoles.forEach(item => {
            this.rolSolo = {
              rolId: item.idRol,
              rolName: item.nombreRol
            }
            this.list_roles_destino.push(this.rolSolo);

            this.cadena.push(item.nombreRol);
          });
          this.cargarFormulario();
        });
    } else {
      this.titulo = 'Crear roles y trámites';
      this.datoTabla = 'crear';
    }
    this.cargarRoles();
  }

  get nombreObligatorio(): boolean {
    return this.formulario.get('Nombre')?.touched && this.formulario.get('Nombre')?.errors?.['required'];
  }

  get codigoTramiteObligatorio(): boolean {
    return this.formulario.get('CodigoTramite')?.touched && this.formulario.get('CodigoTramite')?.errors?.['required'];
  }

  crearFormulario(): void {
    this.formulario = this.fb.group({
      CodigoTramite: [{ value: '', disabled: true }, Validators.required],
      Nombre: [{ value: '', disabled: true }, Validators.required]
    });
  }

  cargarFormulario(): void {
    this.formulario.controls['CodigoTramite'].setValue(this.resultado[0].siglaAplicativo+"-"+this.resultado[0].codigoAplicativo);
    this.formulario.controls['Nombre'].setValue(this.resultado[0].nombreAplicativo); }

  cargarRoles(): void {
    this.rolesService.traerRolesAutransversal(" "," ")
      .subscribe(resp => {
        resp.data.forEach(item => {
          if (item.isEnabled) {
            this.rolSolo = {
              rolId: item.idRole,
              rolName: item.displayName
            }

            this.list_roles_origen.push(this.rolSolo);
          }
        });
      });
  }

  seleccionRolOrigen(e: any): void {
    this.indiceRolOrigen = e;
    this.rolOrigen = this.list_roles_origen.find(x => x.rolId == e);
  }

  seleccionRolDestino(e: any): void {
    this.indiceRolDestino = e;
    this.rolDestino = this.list_roles_origen.find(x => x.rolId == e);
  }

  agregarRol(): void {
    if (this.rolOrigen != undefined) {
      if (!this.cadena.includes(this.rolOrigen.rolName)) {
        this.list_roles_destino.push(this.rolOrigen);
        this.cadena.push(this.rolOrigen.rolName);
      }
      this.indiceRolOrigen = 0;
      this.rolOrigen = undefined;
    }
  }

  quitarRol(): void {
    if (this.rolDestino != undefined) {
      this.list_roles_destino = this.list_roles_destino.filter(x => x.rolId != this.rolDestino?.rolId);
      this.cadena = this.cadena.filter(x => x != this.rolDestino?.rolName);
      this.indiceRolDestino = 0;
      this.rolDestino = undefined;
    }
  }

  editar(): void {
    this.validation = false;
    this.correcto = false;
    this.formulario.get('CodigoTramite')?.enable();
    this.formulario.get('Nombre')?.enable();
    this.cadenaRoles = '';
  }

  verTablaPrevisualizar(): void {
    this.validation = true
    this.correcto = true;
    this.formulario.get('CodigoTramite')?.disable();
    this.formulario.get('Nombre')?.disable();
    this.cadena.forEach(item => {
      this.cadenaRoles = this.cadenaRoles + '*' + item + ' ';
    });
    this.tablaPrevisualizar = {
      tramiteMainId: 0,
      // codigo: '0',
      codTramite: this.formulario.get('CodigoTramite')?.value,
      nameTramite: this.formulario.get('Nombre')?.value,
      rolId: 0,
      rolName: this.cadenaRoles,
      estadoRol: false,
      estadoTramite: false
    }
  }

  cancelar(): void {
    this.router.navigate(['/Home/Administracion/funcionalidades']);

  }

  previsualizar(): void {
    if (this.formulario.invalid) {
      return Object.values(this.formulario.controls).forEach(control => {
        control.markAsTouched();
      });
    }

    if (this.list_roles_destino.length == 0) {
      this.ventanaEmergenteError(this.mensajeRoles);
      this.ventanaEmergenteError(this.mensajeRoles);
    }  else {
      this.verTablaPrevisualizar();
    }

  }
  NombreTramiteIndefinido()
  {
    this.funcionalidadesService.revisarCodigoFuncionalidad(this.formulario.get('CodigoTramite')?.value)
      .subscribe(resp => {
        if (resp > 0) {
          this.ventanaEmergenteError(this.codigoExistente);
        } else {
          this.funcionalidadesService.revisarNombreFuncionalidad(this.formulario.get('Nombre')?.value)
            .subscribe(resp => {
              if (resp > 0) {
                this.ventanaEmergenteError(this.funcExistente);
              } else {
                this.verTablaPrevisualizar();
              }
            });
        }
      });
  }
  CodigoTramiteDiferente()
  {
    this.funcionalidadesService.revisarCodigoFuncionalidad(this.formulario.get('CodigoTramite')?.value)
      .subscribe(resp => {
        if (resp > 0) {
          this.ventanaEmergenteError(this.codigoExistente);
        } else {
          if (this.resultado[0].nameTramite != this.formulario.get('Nombre')?.value) {
            this.funcionalidadesService.revisarNombreFuncionalidad(this.formulario.get('Nombre')?.value)
              .subscribe(resp => {
                if (resp > 0) {
                  this.ventanaEmergenteError(this.funcExistente);
                } else {
                  this.verTablaPrevisualizar();
                }
              });
          } else {
            this.verTablaPrevisualizar();
          }
        }
      });
  }

  NombreTramiteDiferente()
  {
    this.funcionalidadesService.revisarNombreFuncionalidad(this.formulario.get('Nombre')?.value)
      .subscribe(resp => {
        if (resp > 0) {
          this.ventanaEmergenteError(this.funcExistente);
        } else {
          this.verTablaPrevisualizar();
        }
      });
  }

  async guardar() {

    let roles:any = []
    let cadenaRoles:string = ''

    this.list_roles_destino.forEach((item: { rolId: any; }) => {
      roles.push(item.rolId);
    });
    cadenaRoles = roles.toString()


    let aplicativoObj = {
      nombreAplicativo: this.aplicativo.nombreAplicativo,
      urlAplicativo: this.aplicativo.urlAplicativo,
      siglaAplicativo: this.aplicativo.siglaAplicativo,
      idRoles: roles,
      roles: cadenaRoles,
      idResponsable:JSON.parse(localStorage.getItem('currentUser')+"").oid,
      idAplicativo:this.aplicativo.idAplicativo,
      codigoAplicativo: this.aplicativo.codigoAplicativo,
      estado:(this.aplicativo.estado =="Habilitado")+""
    }

    this.funcionalidadRolesService.guardarFuncionalidadAutenticacion(aplicativoObj)
      .subscribe(rta => {
        if (rta.status == 200) {
          this.ventanaEmergenteExito();
          this.registrarAccion('INSERT')

        } else {
          this.ventanaEmergenteError(this.errorGuardado);
        }
      });


  }

  BorrarFuncionalidad()
  {
    this.funcionalidadRolesService.borrarFuncionalidadRoles(this.resultado[0].tramiteMainId)
      .subscribe(rta => {
        if (rta.status == 200) {
          this.list_asignaciones = [];
          this.listaAsignaciones(this.resultado[0].tramiteMainId);
          this.funcionalidadRolesService.guardarFuncionalidadRoles(this.list_asignaciones)
            .subscribe(answ => {
              if (answ.status == 200) {
                this.ventanaEmergenteExito();
                this.registrarAccion('UPDATE')
              } else {
                this.ventanaEmergenteError(this.errorGuardado);
              }
            });
        } else {
          this.ventanaEmergenteError(this.errorGuardado);
        }
      });
  }
  EditarFuncionalidad()
  {
    this.funcionalidadesService.editarFuncionalidad(
      this.crearFuncionalidad(this.resultado[0].tramiteMainId))
      .subscribe(resp => {
        if (resp.status == 200) {
          this.funcionalidadRolesService.borrarFuncionalidadRoles(this.resultado[0].tramiteMainId)
            .subscribe(rta => {
              if (rta.status == 200) {
                this.list_asignaciones = [];
                this.listaAsignaciones(this.resultado[0].tramiteMainId);
                this.funcionalidadRolesService.guardarFuncionalidadRoles(this.list_asignaciones)
                  .subscribe(answ => {
                    if (answ.status == 200) {
                      this.ventanaEmergenteExito();
                    } else {
                      this.ventanaEmergenteError(this.errorGuardado);
                    }
                  });
              } else {
                this.ventanaEmergenteError(this.errorGuardado);
              }
            });
        } else {
          this.ventanaEmergenteError(this.errorGuardado);
        }
      });
  }

  registrarAccion(type: any) {
    let user = localStorage.getItem('userName');
    let registros = []
    let codigosRoles = '';
    let fechaHoraActual = new Date();

    if (type === 'INSERT') {
      this.list_asignaciones.forEach((ele) => {
        let accionRegistro = {
          fecha: fechaHoraActual,
          usuario: user,
          operacion: type,
          entidadAfectada: 'N/A',
          tramite: ele.tramitesMainId,
          rol: ele.rolesId,
          estacion: 'string',
          servidor: "string"
        }
        registros.push(accionRegistro)
      })
    } else if (type === 'UPDATE') {
      let tramiteId = '';
      this.list_asignaciones.forEach((ele) => {
        codigosRoles += ele.rolesId + '|';
        tramiteId = ele.tramitesMainId
      })

      let accionRegistro = {
        fecha: fechaHoraActual,
        usuario: user,
        operacion: type,
        entidadAfectada: 'N/A',
        tramite: tramiteId,
        rol: codigosRoles,
        estacion: 'string',
        servidor: "string"
      }
      registros.push(accionRegistro)
    }

    this.apiservices.registrarAcciones(registros).subscribe(res => {
      console.log(res)
    })
    registros = []

  }

  traerFechaString(i: number): string {
    this.fecha = new Date();

    let mes = (this.fecha.getMonth() + 1) < 10 ? '0' + (this.fecha.getMonth() + 1).toString() : (this.fecha.getMonth() + 1).toString();
    let dia = (this.fecha.getDate()) < 10 ? '0' + (this.fecha.getDate()).toString() : (this.fecha.getDate()).toString();
    let hora = (this.fecha.getHours()) < 10 ? '0' + (this.fecha.getHours()).toString() : (this.fecha.getHours()).toString();
    let min = (this.fecha.getMinutes()) < 10 ? '0' + (this.fecha.getMinutes()).toString() : (this.fecha.getMinutes()).toString();
    let seg = (this.fecha.getSeconds() + i) < 10 ? '0' + (this.fecha.getSeconds() + i).toString() : (this.fecha.getSeconds() + i).toString();

    this.formato = this.fecha.getFullYear().toString() + '-' + mes + '-' + dia + ' ' + hora + ':' +
      min + ':' + seg + '.' + this.fecha.getMilliseconds().toString();

    return this.formato;
  }

  listaAsignaciones(id: string): void {
    this.list_roles_destino.forEach(value =>
    {
      this.guardarAsignaciones = {
        id: '0',
        tramitesMainId: id,
        rolesId: value.rolId,
        estado: true,
        roles: undefined,
        tramites: undefined,
      }

      this.list_asignaciones.push(this.guardarAsignaciones);
    })
  }

  crearFuncionalidad(id: number): Funcionalidades {
    this.funcionalidad = {
      id: id,
      codTramite: this.formulario.get('CodigoTramite')?.value,
      nameTramite: this.formulario.get('Nombre')?.value,
      estado: true
    }
    return this.funcionalidad;
  }

  ventanaEmergenteError(mensaje: string): void {
    Swal.fire({
      title: 'Operación no exitosa',
      text: `${mensaje}`,
      iconHtml: '<div class="material-icons">close</div>',
      customClass: {
        icon: 'no-bordes',
        title: 'text-danger'
      },
      allowOutsideClick: false,
      showCloseButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#0d6efd'
    });
  }

  ventanaEmergenteExito(): void {
    Swal.fire({
      title: 'Operación exitosa',
      iconHtml: '<div class="material-icons">done</div>',
      customClass: {
        icon: 'no-bordes',
        title: 'text-success'
      },
      allowOutsideClick: false,
      showCloseButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#0d6efd'
    }).then((result) => {
      if (result.isConfirmed || result.dismiss === Swal.DismissReason.close) {
        this.router.navigate(['/Home/Administracion/funcionalidades']);
      }
    });;
  }
}
